import { ContractType } from './ContractTypes';

export interface ProductPurchaseType {
  productId: string;
  lineitemId: string;
  name: string;
  manufacturer: string;
  quantityPurchased: number;
  quantityRefunded: number;
  imageLink: string;
  state: purchaseStates;
  expiresDate: string;
  term: number;
  termLabelSingular: string;
  termLabelPlural: string;
  daysLeft: number;
  taxRate: number;
  taxJurisdiction: string;
  contractAdh?: boolean;
  contractCategory?: string;
  currentCategoryID?: string;
  underwriterName?: string;
  underwriterClaimsOutlink?: string;
  underwriterLogo?:string;
  adminHandoff?:boolean;
  contractSaleId?: string;
  saleExternalID?: string;
  hashedSaleID?: string;
  contracts?: ContractType[];
  contractBullets?: any;
  history?: any[];
  customClaimLink?: string;
  shopLogo?: string;
  shopName?: string;
  resolutionPromoCode?: string;
  resolutionViewed?: boolean;
  coverageStartType?: any;
  purchaseDate?: any;
  contractEffectiveDate?: string;
  hideClaimSupportLink?: string;
  isEligibleForTransfer?: boolean;
  cartId: string;
  shopID: string;
  quotePackageId?: string;
}

// eslint-disable-next-line no-shadow
export enum purchaseStates {
  active = 'active',
  eligible = 'eligible',
  expired = 'expired',
  fulfilled = 'fulfilled',
  inactive = 'inactive',
  ineligible = 'ineligible',
}

interface IObjectKeys {
  [key: string]: string | number;
}

export interface TransferOrderDataI extends IObjectKeys {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
}
