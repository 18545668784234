import React, { Component } from 'react';
import classnames from 'classnames';
import CloseModalButton from '../../Shared/CloseModalButton';

// Claim Form nav
import ClaimRoadMap from './ClaimRoadMap';

// Claim Form Steps
import ClaimContact from './Steps/ClaimContact';
import ClaimType from './Steps/ClaimType';
import ClaimParts from './Steps/ClaimParts';
import ClaimQuestions from './Steps/ClaimQuestions';
import ClaimDescription from './Steps/ClaimDescription';
import ClaimDocumentation from './Steps/ClaimDocumentation';
import ClaimDate from './Steps/ClaimDate';
import Summary from './Steps/Summary';

// Claim Form end states
import ClaimRejection from './ClaimRejection';

// Claim Form shared
import {
  validateClaim,
  checkDateBeforeSubmission,
  buildEmailLink,
  createIssueTypeSet,
  insertArrayAt,
  removeArrayAt,
} from './claim-utilities';

import applyMask from '../../Shared/phoneMask';

interface claimFormProps {
  phone: string;
  email: string;
  isOpen: boolean,
  newClaim: any;
  issueTypes: any[],
  _createClaim: any;
  _cancelModal: any;
  _setError?: any;
  _clearError?: any;
  history?: any;

  contract?: any;
  error?: any;
  claimFormSuccess?: boolean;
  isProcessing?: boolean;
  fullName?: string;
}

const buildInitialStateObject = () => ({
  step: 0,
  completed: 0,
  email: '',
  phone: '',
  secondaryPhone: '',
  isBusinessClaim: null,
  issueTypes: [],
  affectedParts: [],
  issueType: '',
  description: '',
  failureDate: '',
  errors: [],
  fileNames: ['', '', ''],
  files: [],
  receiptFile: null,
  receiptFileName: '',
  handoffType: '',
  mailToLink: '',
  usedAsIntended: null,
  stillFunctional: null,
  optionalStepIncluded: false,
  partsStepIncluded: false,
  maxFileSize: 10 * 1024 * 1024,
  formStepsNames: [
    'ClaimContact',
    'ClaimType',
    'ClaimDescription',
    'ClaimDocumentation',
    'ClaimDate',
  ],
  formSteps: [
    ClaimContact,
    ClaimType,
    // conditional steps added based on parts existing and chosen claim type
    // ClaimParts,
    // ClaimQuestions,
    ClaimDescription,
    ClaimDocumentation,
    ClaimDate,
  ],
});

class ClaimForm extends Component<claimFormProps, any> {
  static defaultState = buildInitialStateObject();

  state = { ...ClaimForm.defaultState };

  stateUpdate = null;

  shouldStateUpdate = false;

  componentDidUpdate = () => {
    const { step } = this.state;

    if (!this.props.isProcessing && this.shouldStateUpdate && !this.props.error) {
      // ensure we do not update state until we receive feedback from submission :(
      this.setState(this.stateUpdate);
      this.shouldStateUpdate = false;
    }

    if (!this.props.isOpen && step < 0 && !this.props.error) {
      this.setState({ ...ClaimForm.defaultState });
    }

    if (this.props.error) {
      this.props._setError(this.props.error);
    }
  };

  componentWillUnmount() {
    // Reset component map
    ClaimForm.defaultState = buildInitialStateObject();

    if (this.props.error) {
      this.props._clearError();
    }
  }

  // Fields Update Methods
  handleFiles = (fileInfo: any) => {
    const { files, fileNames } = fileInfo;
    const calculateBytes = this.state.maxFileSize;
    for (const file of files) {
      if (file.size > calculateBytes) {
        this.setState({ errors: ['files'] });
        return;
      }
    }
    this.setState({ fileNames, files, errors: [] });
  };

  handleReceiptChange = (fileInfo: any) => {
    const { receiptFile, receiptFileName } = fileInfo;
    const calculateBytes = this.state.maxFileSize;
    if (receiptFile.size > calculateBytes) {
      this.setState({ errors: ['files'] });
      return;
    }

    this.setState({ receiptFileName, receiptFile, errors: [] });
  };

  updateField = (field: string, value: any) => {
    const {
      step,
      errors,
      formSteps,
      optionalStepIncluded,
      partsStepIncluded,
      formStepsNames,
    } = this.state;
    const { contract } = this.props;

    if (field === 'fileNames') {
      this.handleFiles(value);
      return;
    }

    if (field === 'receiptFile') {
      this.handleReceiptChange(value);
      return;
    }

    let fieldsToUpdate = {};
    if (field === 'issueTypes') {
      const stepToSplice = partsStepIncluded ? 3 : 2;
      const cachedSteps = formSteps;
      const cachedStepsNames = formStepsNames;
      const issueTypeSet = createIssueTypeSet(value);
      const nonCoveredAccidental = issueTypeSet.has('droppedOrDamaged') && !contract.contractAdh;
      const hasUnsupportedType = nonCoveredAccidental || issueTypeSet.has('lossOrTheft');

      if (hasUnsupportedType && optionalStepIncluded) {
        fieldsToUpdate = {
          formSteps: removeArrayAt(cachedSteps, stepToSplice),
          formStepsNames: removeArrayAt(cachedStepsNames, stepToSplice),
          optionalStepIncluded: false,
        };
      }

      if (!hasUnsupportedType && !optionalStepIncluded) {
        fieldsToUpdate = {
          formSteps: insertArrayAt(cachedSteps, stepToSplice, [ClaimQuestions]),
          formStepsNames: insertArrayAt(cachedStepsNames, stepToSplice, 'ClaimQuestions'),
          optionalStepIncluded: true,
        };
      }
    }

    const newErrors = step ? [] : errors.filter((error) => error !== field);

    if (field === 'phone' || field === 'secondaryPhone') {
      this.setState({ [field]: applyMask(value), errors: newErrors });
    } else {
      this.setState({ [field]: value, errors: newErrors, ...fieldsToUpdate });
    }
  };

  // Navigation methods
  navigateToStep = (stepName: string) => () => {
    const { formStepsNames } = this.state;
    const stepIndexFromName = formStepsNames.indexOf(stepName);
    this.setState({ step: stepIndexFromName });
  };

  selectStep = () => () => {
    const {
      state: {
        completed,
        step: currentStep,
        partsStepIncluded,
        formSteps,
        formStepsNames,
      },
      props: { contract },
    } = this;

    let partsStepDetails = {};
    if (currentStep === 0 && formSteps.length === 5) {
      if (contract.productParts && contract.productParts.length && !partsStepIncluded) {
        partsStepDetails = {
          partsStepIncluded: true,
          formSteps: insertArrayAt(formSteps, 2, [ClaimParts]),
          formStepsNames: insertArrayAt(formStepsNames, 2, 'ClaimParts'),
        };
      }
    }

    const nextStep = currentStep + 1 > formSteps.length - 1 ? -1 : currentStep + 1;
    // Check for errors in current form step
    const errors = (currentStep === completed) && (nextStep < completed) && (nextStep >= 0)
      ? []
      : this.validateStep();
    if (errors.length) {
      this.setState({ errors });
      return;
    }

    // Proceed through normal form flow
    const newCompleted = nextStep > completed ? nextStep : completed;

    this.setState({
      completed: newCompleted,
      errors,
      step: nextStep,
      ...partsStepDetails,
    });
  };

  selectPrevStep = (prevStep:number) => () => {
    this.setState({ step: prevStep });
  };

  // Validation method
  validateStep = () => {
    const { state, props } = this;
    const { errors, stateUpdate } = validateClaim(state, props);
    if (Object.keys(stateUpdate)) {
      this.setState(stateUpdate);
    }
    if (
      stateUpdate.handoffType === 'lossOrTheft'
      || stateUpdate.handoffType === 'nonAdhAccident'
    ) {
      this.submitClaim(true);
    }
    return errors;
  };

  // Submit claim
  submitClaim = (isHandoff: boolean) => {
    const {
      props,
      props: { _createClaim, contract, fullName },
      state: {
        phone,
        secondaryPhone,
        failureDate,
        description,
        files,
        receiptFile,
        issueTypes,
        affectedParts,
        issueType,
        handoffType,
        email,
        usedAsIntended,
        stillFunctional,
        isBusinessClaim,
      },
    } = this;

    let formattedFailure = failureDate;
    const failureDateSplit = failureDate.split('/');

    const issueTypeSet = createIssueTypeSet(issueTypes);
    const includesAccidental = issueTypeSet.has('droppedOrDamaged');
    const isUncovered = issueTypeSet.has('arrivedDamaged') || issueTypeSet.has('lossOrTheft');

    if (failureDateSplit.length === 3) {
      const year = failureDateSplit.pop();
      // @ts-ignore
      failureDateSplit.unshift(year);
      formattedFailure = failureDateSplit.join('-');
    }

    const oemApplicable:boolean = (
      (checkDateBeforeSubmission(failureDate, contract) && !includesAccidental && !isUncovered)
      || issueTypeSet.has('arrivedDamaged')
    );

    let stateUpdate:any = {};

    if (oemApplicable) {
      const handoff = issueTypeSet.has('arrivedDamaged') ? 'arrivedDamaged' : 'inOemTerm';
      stateUpdate = {
        ...stateUpdate,
        errors: ['Before oem expiry'],
        step: -1,
        handoffType: handoff,
      };
    } else if (
      isBusinessClaim
      && contract.termsKey !== 'dopCommercial'
      && contract.termsKey !== 'extCommercial'
      && contract.shopName !== 'Plunge'
    ) {
      stateUpdate = {
        ...stateUpdate,
        handoffType: 'invalidBusinessClaim',
        step: -1,
        errors: ['isBusinessClaim'],
      };
    }

    const phoneNumber = phone || props.phone;

    const formData = new FormData();
    formData.append('contractSaleId', contract.contractSaleId);
    formData.append('email', email);
    formData.append('phoneNumber', phoneNumber);
    formData.append('secondaryPhoneNumber', secondaryPhone);
    formData.append('failureDate', formattedFailure);
    formData.append('descriptionOfProblem', description);
    formData.append('isHandoff', String(isHandoff || oemApplicable));
    formData.append('issueType', issueType);
    formData.append('claimIssueTypes', JSON.stringify(issueTypes));
    formData.append('claimProductParts', JSON.stringify(affectedParts));
    formData.append('usedAsIntended', String(usedAsIntended));
    formData.append('stillFunctional', String(stillFunctional));
    formData.append('isBusinessClaim', String(isBusinessClaim));

    for (const file of files) {
      formData.append('supportingInfo', file);
    }

    if (!contract.receiptFile && receiptFile) {
      // @ts-ignore
      formData.append('receiptFile', receiptFile);
    }

    if (handoffType || oemApplicable) {
      const mailToLink = buildEmailLink({
        contractSaleID: contract.contractSaleId,
        retailerEmail: contract.retailerSupportEmail,
        sku: contract.sku,
        productName: contract.name,
        purchaseDate: contract.purchaseDate,
        fullName: fullName || '',
        phoneNumber,
        secondaryPhone,
        failureDate,
        description,
        email,
      });

      stateUpdate = {
        ...stateUpdate,
        step: -1,
        mailToLink,
      };
    }
    _createClaim(formData);

    this.shouldStateUpdate = true;
    this.stateUpdate = {
      ...stateUpdate,
      formStepsNames: [
        'ClaimContact',
        'ClaimType',
        'ClaimDescription',
        'ClaimDocumentation',
        'ClaimDate',
      ],
      formSteps: [
        ClaimContact,
        ClaimType,
        ClaimDescription,
        ClaimDocumentation,
        ClaimDate,
      ],
    };
  };

  render() {
    const {
      props: {
        contract,
        email,
        phone,
        isProcessing,
        claimFormSuccess,
        newClaim,
        issueTypes,
        _cancelModal,
        _clearError,
        error,
        history,
      },
      state,
      state: {
        mailToLink,
        optionalStepIncluded,
        formSteps,
        formStepsNames,
        partsStepIncluded,
      },
      updateField,
      selectStep,
      submitClaim,
      selectPrevStep,
      navigateToStep,
    } = this;

    if (error && error.clydeDesc === 'Open Claim Exists on Contract.') {
      _cancelModal();
      _clearError();
      history?.push('/products');
    }

    const { handoffType } = state;
    const { step } = state;
    const CurrentStep = formSteps[step];
    const stepName = formStepsNames[step];
    const stepOptions = stepName && stepName === 'ClaimParts' ? contract.productParts : issueTypes;

    if (claimFormSuccess) {
      return (
        <section className={classnames('claim-form', { 'claim-form--response': handoffType })} >
          <CloseModalButton onClick={ _cancelModal } className='clyde-modal__close'/>
          <ClaimRejection
            pid={ contract.productId }
            cid={ contract.contractId }
            handoffType={ handoffType }
            shopName={ contract.shopName }
            email={ contract.retailerSupportEmail }
            supportLink={ contract.oemWarrantyLink }
            closeModal={ _cancelModal }
            mailToLink={ mailToLink }
            newClaim={ newClaim }
            shopUrl={ contract.shopUrl }
          />
        </section>
      );
    }

    return (
      <section className={classnames('claim-form', { 'claim-form--response': handoffType })} >
        <CloseModalButton onClick={ _cancelModal } className='clyde-modal__close'/>
        { step >= 0
          && <ClaimRoadMap
            contractName={ contract.name }
            contractId={ contract.hashedSaleID }
            contractTerm={ contract.term }
            contractAdh={ contract.contractAdh }
            contractExpiry={ contract.expiresDate }
            imageLink={ contract.imageLink }
            selectStep={ selectStep }
            formState={ state }
            propsEmail={ email }
            propsPhone={ phone }
            pid={ contract.productId }
            cid={ contract.contractId }
            isProcessing={ isProcessing }
            submitClaim={ submitClaim}
            isResolution={ false }
            selectPrevStep={ selectPrevStep }
            optionalStepIncluded={ optionalStepIncluded }
            currentStep={ CurrentStep }
            navigateToStep={ navigateToStep }
            partsStepIncluded={ partsStepIncluded }
          />
        }
        { step >= 0
          && <CurrentStep
            formState={ { ...state } }
            propsEmail={ email }
            propsPhone={ phone }
            displayReceiptCapture={ !contract.receiptFile && contract.csSource === 'checkoutWidget' }
            updateField={ updateField }
            iterateStep={ selectStep }
            optionalStepIncluded={ optionalStepIncluded }
            stepOptions={ stepOptions }
            productParts={ contract.productParts }
          />
        }
        {
          step === -1
          && <Summary
              contractName={ contract.name }
              contractId={ contract.hashedSaleID }
              contractTerm={ contract.term }
              contractAdh={ contract.contractAdh }
              contractExpiry={ contract.expiresDate }
              imageLink={ contract.imageLink }
              selectStep={ selectStep }
              formState={ state }
              propsEmail={ email }
              propsPhone={ phone }
              pid={ contract.productId }
              cid={ contract.contractId }
              isProcessing={ isProcessing }
              submitClaim={ submitClaim }
              isResolution={ false }
              selectPrevStep={ selectPrevStep }
              optionalStepIncluded={ optionalStepIncluded }
              currentStep={ CurrentStep }
              navigateToStep={ navigateToStep }
              partsStepIncluded={ partsStepIncluded }
            />
        }
        <div className='claim-form-spacer' />
      </section>
    );
  }
}

export default ClaimForm;
