import React from 'react';

import AllProduct from './AllProduct';
import CommercialAppliance from './CommercialAppliance';
import Drone from './Drone';
import Jewelry from './Jewelry';
import MusicalInstrument from './MusicalInstrument';
import Test from '../../../../containers/TestTerms';

import { UnderwriterProps } from '../../../../types/TermsTypes';

const NewLeaf: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'allProd':
      Terms = <AllProduct contractTerms={ contractTerms } />;
      break;
    case 'commercialAppliance':
      Terms = <CommercialAppliance contractTerms={ contractTerms } />;
      break;
    case 'drone':
      Terms = <Drone contractTerms={ contractTerms } />;
      break;
    case 'jewelry':
      Terms = <Jewelry contractTerms={ contractTerms } />;
      break;
    case 'musicalInstrument':
      Terms = <MusicalInstrument contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <Test />;
      break;
  }

  return Terms;
};

export default NewLeaf;
