import React, { useState } from 'react';
import { Button, FormGroup } from 'reactstrap';
import classnames from 'classnames';

import { ReactComponent as RightArrow } from '../../../../images/right-arrow.svg';

interface ClaimPartsProps {
  formState: {
    issueTypes: any[];
    affectedParts: any[];
    contract: any;
  };
  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  stepOptions: any[],
  productParts: any[],
}

const ClaimParts = ({
  iterateStep,
  updateField,
  formState: { issueTypes, affectedParts },
  productParts,
} : ClaimPartsProps) => {
  const [onConfirm, toggleConfirm] = useState(false);

  const onSelectPart = (part: any) => (e: any) => {
    const { key, type: eventType } = e;
    if (eventType === 'keydown' && key !== 'Enter') {
      return;
    }

    const partsCopy = [...affectedParts];
    let partFiltered = false;

    const updatedTypes = partsCopy.filter((existing) => {
      const keepPart = existing.partId !== part.partId;
      if (!keepPart) {
        partFiltered = true;
      }
      return keepPart;
    });

    if (!partFiltered) {
      updatedTypes.push(part);
    }

    updateField('affectedParts', updatedTypes);
  };

  const handleNext = () => {
    if (onConfirm || !affectedParts.length) {
      iterateStep()();
    } else {
      toggleConfirm(true);
    }
  };

  return (
    <section className={`claim-form__step claim-form__parts`}>
      <h2>File a claim</h2>
      <h3>Product Issue</h3>
      <p className='claim-form__step__issue-tagline'>What is wrong with your product?</p>
      <div className='claim-form__issues__back' onClick={ iterateStep() }>
        <h4>Product Issues</h4>
        <span>
          { issueTypes.map((type) => type.label).join(', ') }
        </span>
        <RightArrow className='claim-form__issues__back__arrow' />
      </div>
      { onConfirm
        ? <div className='claim-form__issues__back' onClick={ () => toggleConfirm(false) }>
          <h4>Product Parts Issues</h4>
          <span>
            { affectedParts.map((type) => type.name).join(', ') }
          </span>
          <RightArrow className='claim-form__issues__back__arrow' />
        </div>
        : <>
          <p>If you're having an issue with any of the parts below, select them.</p>
          <ul className='claim-form__issue-type__list' data-test='issueCard-'>
            { productParts.map((part: any) => <div
                className={classnames(
                  'claim-form__issue-type__type-item',
                  { selected: affectedParts.findIndex((effected:any) => effected.partId === part.partId) > -1 },
                )}
                onClick={ onSelectPart(part) }
                key={ part.partId }
                tabIndex={ 1 }
                role='button'
                onKeyDown={ onSelectPart(part) }
              >
                { part.name }
              </div>)}
          </ul>
        </>
      }
      <FormGroup>
        <Button
          className='button--general claim-form__step__next-button'
          onClick={ handleNext }
          disabled={ !issueTypes.length }
        >
          Next
        </Button>
      </FormGroup>
    </section>
  );
};

export default ClaimParts;
