import React from 'react';
import TermsAccordion from '../../Shared/TermsAccordion';

import { TermsProps } from '../../../../types/TermsTypes';

class CommercialFurniture extends React.Component<TermsProps, any> {
  constructor(props: TermsProps) {
    super(props);
    this.state = {
      cancellationsOpen: false,
      coverageDateOpen: false,
      coveragePlanOpen: false,
      deductibleOpen: false,
      definitionsOpen: false,
      eligibilityOpen: false,
      entireAgreementOpen: false,
      exclusionsOpen: false,
      fileClaimOpen: false,
      generalProvisionsOpen: false,
      guarantyOpen: false,
      importantInfoOpen: false,
      limitLiabilityOpen: false,
      providedServicesOpen: false,
      recoverPaymentOpen: false,
      renewabilityOpen: false,
      responsibilitiesOpen: false,
      statesOpen: false,
      territoryOpen: false,
      transferabilityOpen: false,
      whatIsCoveredOpen: false,
    };
  }

  toggle = (section: string) => {
    this.setState((state: any) => ({ [section]: !state[section] }));
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
          <p>We hope You enjoy the added comfort and protection this Service Agreement provides.  The information contained in this important terms and conditions document (the “Service Agreement”, “Agreement”) is intended to be Your guide in knowing what is covered and how coverage works under Your Plan. If You ever need assistance regarding Your Agreement, contact the Administrator at any time. Be sure to keep this Agreement document and Your Sales Receipt/Invoice together, as they will come in handy when You have a Claim!</p>
          <p><strong>FOR FAST CLAIM SERVICE VISIT: </strong><a href="http://www.safeware.com/" target="_blank" rel="noopener noreferrer">http://www.safeware.com/</a></p>
        </div>

        <TermsAccordion
          toggle={ this.state.definitionsOpen }
          _toggleFunc={ () => this.toggle('definitionsOpen') }
          header="DEFINITIONS"
          content={
            <React.Fragment>
              <strong>Throughout this Service Agreement, the following capitalized words have the stated meaning –</strong>
              <ol>
                <li><strong> "Administrator", "SAFEWARE": </strong>the party authorized by Us who is responsible for administrating benefits to You in accordance with the terms and conditions of this Service Agreement, 5700 Perimeter Drive, Suite E, Dublin, OH 43017 [(800) 800-1492], Safeware, The Insurance Agency Inc., in all states except in Alabama, Arkansas, Georgia, Minnesota, Missouri, Montana, Nevada, New Mexico, Oregon, Texas , Utah, and Wyoming where it is Safeware Inc, except in Florida, Indiana, Kentucky, Maryland, New Jersey, New York, and Oklahoma where it is Safeware Group Inc., except in Arizona, New Hampshire, and Wisconsin where it is Safeware, except in Vermont where it is Safeware Group.</li>
                <li><strong>"Claim":</strong> a request for service under the provisions of this Agreement sent by You.</li>
                <li><strong>"Commercial Use":</strong> means used in a business office setting, professional/medical office settings, hotel room settings, or commercially owned residential  property  used for short term vacation rental through a 3rd party service provider and NOT used for resale or in an outdoor, private rental property, in-home daycare business, educational institution, hotel lobby, call centers and other 24/7 business applications.</li>
                <li><strong>"Covered Product(s)", "Product(s)":</strong> the eligible furniture item(s) purchased by You that is/are to be covered under this Service Agreement.</li>
                <li><strong>"Deductible":</strong> the amount You are required to pay, per Claim, prior to receiving covered services under this Service Agreement (if any).</li>
                <li><strong>Manufacturer’s Defect:</strong> the breakdown of a covered component specifically listed below under Your Plan, that results in it no longer being able to function as originally designed and intended; which is caused by defects in the manufacturer’s materials or workmanship occurring during normal use of the Product, and NOT due to normal wear and tear.</li>
                <li><strong>"Plan":</strong> the specific "COVERAGE PLAN OPTION" under this Agreement that You have selected and purchased, as confirmed on Your Sales Receipt/Invoice.</li>
                <li><strong>"Power Surge": </strong>damage to the Product resulting from an oversupply of voltage to Your Product while properly connected to a surge protector approved by the Underwriter’s Laboratory Inc. (UL), but not including damages resulting from the improper installation or improper connection of the Product to a power source.</li>
                <li><strong>"Product Purchase Price": </strong>the amount paid by You for the Covered Product; excluding any applicable taxes and/or fees.</li>
                <li><strong>"Retailer":</strong> the party authorized by Us to sell this Service Agreement to You.</li>
                <li><strong>"Sales Receipt/Invoice": </strong>the receipt document (paper or e-mail) provided to You as proof of Your Agreement purchase that confirms the Plan selected by You, coverage Term, any applicable Deductible and the purchase date of this Service Agreement.</li>
                <li><strong>"Term": </strong>the period of time in which the provisions of this Service Agreement are valid, as indicated on Your Sales Receipt/Invoice.</li>
                <li><strong>"We", "Us", "Our", "Obligor", "Provider"</strong>: the party obligated to provide service under this Service Agreement as the service agreement provider, Northcoast Warranty Services, Inc. 800 Superior Ave. E., 21st Fl., Cleveland, OH 44114  (In Florida: this Service Agreement is an agreement between You and Technology Insurance Company, Inc., License No. 03605, 59 Maiden Lane, 43rd  Floor, New York, NY 10038).</li>
                <li><strong>"You", "Your":</strong> the purchaser of this Service Agreement who is to receive the coverage provided hereunder.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.territoryOpen }
          _toggleFunc={ () => this.toggle('territoryOpen') }
          header="TERRITORY"
          content={
            <React.Fragment>
              <p>THIS SERVICE AGREEMENT IS VALID AND ELIGIBLE FOR PURCHASE IN THE FOLLOWING JURISDICTIONS ONLY: the contiguous United States, plus Alaska and Hawaii.</p>
              <p><strong>NOTICE: the following jurisdictions are expressly EXCLUDED and this Service Agreement is NOT VALID IN: Wyoming, all outlying U.S. territories (including but not limited to Puerto Rico), and all Canadian provinces/territories.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.eligibilityOpen }
          _toggleFunc={ () => this.toggle('eligibilityOpen') }
          header="PRODUCT ELIGIBILITY"
          content={
            <React.Fragment>
              <p><strong>In order to be eligible for coverage under this Agreement, the item must:</strong></p>
              <ul>
                <li>Be purchased as new from an authorized Retailer, manufactured for use in the United States, and come with a valid U.S. manufacturer’s or Retailer’s warranty of at least one year; </li>
                <li>Be solely intended for Commercial Use (as defined); </li>
                <li>NOT be covered under any other insurance, warranty, guarantee and/or service contract providing the same benefits as outlined in this Agreement.</li>
              </ul>
              <p><strong>Additionally, the item must be one of the following types of furniture to be eligible:</strong></p>
              <ul>
                <li>Conference Tables (laminates &amp; veneers only)</li>
                <li>Desks</li>
                <li>Task/Desk chairs (fabric &amp; vinyl only) </li>
                <li>Conference chairs</li>
                <li>File cabinets including drawers and glides handles (metal, laminate or veneer only)</li>
                <li>Lounge/Reception furniture</li>
                <li>Training Room Furniture</li>
                <li>White/glass Marker Boards</li>
                <li>Adjustable Desks</li>
                <li>Electrical Components in Furniture (such as lights, lift mechanisms)</li>
                <li>Adjustable Beds (NOTE: mattresses are NOT covered or eligible)</li>
                <li>Sofas</li>
                <li>Bedside Tables</li>
                <li>Wardrobe/Dressers</li>
                <li>TV Cabinets/Stands/Medial Consoles</li>
                <li>Coffee Tables</li>
                <li>End Tables</li>
                <li>Lamps</li>
                <li>Mirrors</li>
                <li>Micro Fridge Storage Units (does not apply to appliances)</li>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.responsibilitiesOpen }
          _toggleFunc={ () => this.toggle('responsibilitiesOpen') }
          header="YOUR RESPONSIBILITIES"
          content={
            <React.Fragment>
              <p><strong>PRODUCT PROTECTION: </strong>If damage or breakdown of the Product is suspected, You should promptly take reasonable precautions in order to protect against further damage.</p>
              <p><strong>REPORT CLAIMS: </strong>All claims must be reported individually as independent claims.  All Accidental STAINS and Accidental DAMAGE must be reported within ten (10) days of accident occurrence to qualify for coverage.  All other claims must be reported within thirty (30) days of Manufacturer’s Defect to qualify for coverage.</p>
              <p><strong>MAINTENANCE AND INSPECTIONS: </strong>If specified in the Product manufacturer’s warranty and/or owner’s manual, You must perform all of the care, maintenance and inspections for the Product as indicated. Proof of the completion of such maintenance, care and/or inspection services may be required at time of Claim.</p>
              <p><strong>INSTRUCTIONS: </strong>If provided, You must follow all reasonable instructions given to you by the Administrator, including providing reasonable and safe access to the Product for repair.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.importantInfoOpen }
          _toggleFunc={ () => this.toggle('importantInfoOpen') }
          header="IMPORTANT PRODUCT INFORMATION"
          content={
            <React.Fragment>
              <p>In the event Your original Covered Product is ever exchanged by the manufacturer or Retailer, please call toll free 1-800-228-2731 as soon as possible to update Your coverage information with the make, model, and serial number of the exchanged product. (NOTE: Your original Agreement Term does not extend in these cases and the coverage period shall end on the original Plan expiration date.)</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coverageDateOpen }
          _toggleFunc={ () => this.toggle('coverageDateOpen') }
          header="AGREEMENT TERM – EFFECTIVE DATE OF COVERAGE"
          content={
            <React.Fragment>
              <p><strong>COVERAGE under Your Plan becomes effective</strong> at different times; depending on whether Your Claim is: (a) a Manufacturer’s Defect that is still covered under the manufacturer or Retailer’s warranty; (b) a Manufacturer’s Defect that is no longer covered under the manufacturer or Retailer’s warranty because it has expired; or (c) a covered occurrence that is NOT a Manufacturer’s Defect (such as an ADH event or Power Surge event, as applicable to Your Plan).</p>
              <ol>
                <li><strong>Coverage for damages to Your Product resulting from a covered occurrence that is NOT a Manufacturer’s Defect </strong>begins on Your Agreement purchase date (or Product delivery date, if different) and continues for the Term shown on Your Sales Receipt/Invoice.</li>
                <li><strong>Coverage for a Manufacturer’s Defect does not become effective until the manufacturer or Retailer’s warranty has expired.</strong> Upon expiration of the manufacturer or Retailer’s warranty, coverage for a Manufacturer’s Defect becomes effective and continues for the remainder of the Term shown on Your Sales Receipt/Invoice.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.whatIsCoveredOpen }
          _toggleFunc={ () => this.toggle('whatIsCoveredOpen') }
          header="WHAT IS COVERED – GENERAL"
          content={
            <React.Fragment>
              <p><strong>In accordance with the AGREEMENT TERM-EFFECTIVE DATE OF COVERAGE provision outlined above, in the event of a covered Claim for an eligible Product this Agreement provides for the services described in the "COVERAGE PLAN OPTION" section below, as applicable to Your purchased Plan and Covered Product. </strong></p>
              <p><strong>IMPORTANT DISCLOSURES REGARDING "WHAT IS COVERED": </strong>Coverage described in this Service Agreement will not replace or provide duplicative benefits during any active manufacturer’s or Retailer’s warranty period. During such period, anything covered under that warranty is the sole responsibility of the manufacturer or Retailer and will not be considered under this Agreement; regardless of the manufacturer’s or Retailer’s ability to fulfill its obligations. </p>
              <p><strong>ALL COVERAGE PROCLAIMED UNDER THIS SERVICE AGREEMENT IS EXPRESSLY SUBJECT TO THE "LIMIT OF LIABILITY" AND "EXCLUSIONS" PROVISIONS. READ THESE SECTIONS CAREFULLY.</strong></p>
              <ul>
                <li><strong>About Repairs</strong>. If Your original Product is eligible for repairs, parts used for repairs may be new, used, refurbished or non-original manufacturer parts that perform to the factory specifications of Your original Covered Product. At the Administrator’s sole discretion, repair service to Your Product may be fulfilled in the form of repair advice, a stain-removal package shipped to You to aid in stain removal, or dispatch of a professional servicing technician to Your location (see HOW TO FILE A CLAIM and HOW COVERED SERVICES WILL BE PROVIDED sections for further details).</li>
                <li>
                  <p><strong>About Replacements</strong>. If We determine that Your original Product needs to be replaced, We will make every reasonable effort to replace it with a same match; however, there may be some cases where it is impossible to procure an exact match (for example, the manufacturer may have deemed the model or color obsolete). In such situations, We will provide You with a replacement that is at least of equal features and functionality, but it may be a different brand, model, color or size as Your original Covered Product, and may be new, used, or refurbished.</p>
                  <p>NOTICE: If the Covered Product is comprised of multiple items forming a matching set, once an affected piece has been replaced, that replacement piece will not be eligible for coverage under this Service Agreement; however, any/all remaining original Products that are unaffected will continue to be covered for the remainder of Your Agreement Term. We will not replace or provide compensation for any unaffected Products that do not match a replacement item that We may provide. Any/all parts, components, or entire units for which We provide replacement will automatically become Our property entirely.</p>
                </li>
                <li><strong>About Reimbursements</strong>. If We determine that providing You with reimbursement towards the replacement of Your original Covered Product is best, at Our sole discretion, such reimbursement may come in the form of a check, ACH transfer, store credit, or gift card, and the value of such will in no event exceed the LIMIT OF LIABILITY of this Agreement.</li>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coveragePlanOpen }
          _toggleFunc={ () => this.toggle('coveragePlanOpen') }
          header="COVERAGE PLAN OPTIONS"
          content={
            <React.Fragment>
              <p><strong>As indicated on Your Sales Receipt/Invoice and applicable to You.</strong></p>
              <p><strong>POWER &amp; STRUCTURE PLAN: </strong>This Plan provides for required services to Your Covered Product when necessitated by a covered Claim for the following:</p>
              <ul>
                <li><strong>Components covered under Manufacturer’s Defect and Power Surge (as applicable to Your Product):</strong></li>
                <ul>
                  <li>Zippers or buttons</li>
                  <li>The following manual or electrical mechanisms: reclining/inclining (including hand wands/levers), heating, and vibrating</li>
                  <li>Integral electrical components</li>
                  <li>Frame</li>
                  <li>Loss of silvering on mirrors</li>
                  <li>Power cord (when attached/embedded within the Product and essential to the basic function of the Product)</li>
                  <li>Remote (including attaching cord, when applicable)</li>
                </ul>
                <li><strong>Additionally, this Plan covers the following specific types of single-incident accidental damage from handling ("ADH"):</strong></li>
                <ul>
                  <li><strong>Accidental STAINS caused by:</strong></li>
                  <ul>
                    <li>Cosmetics</li>
                    <li>Dye transfer</li>
                    <li>Food or beverages</li>
                    <li>Human or pet urine</li>
                    <li>Ink</li>
                    <li>Nail polish or nail polish remover</li>
                  </ul>
                  <li><strong>Accidental DAMAGE resulting in:</strong></li>
                  <ul>
                    <li>Breakage of the following manual or electrical mechanisms: sleeper, reclining/inclining (including hand wands/levers), heating, and vibrating</li>
                    <li>Breakage of integral electrical components</li>
                    <li>Breakage of frame</li>
                    <li>Burns or singe marks (caused by brief contact with flame or heat)</li>
                    <li>Gouges or chips that penetrate the finish exposing the under layer</li>
                    <li>Liquid marks or rings</li>
                    <li>Breakage, chips, or scratches of glass or mirrors</li>
                    <li>Checking, cracking, bubbling or peeling of finish</li>
                    <li>Punctures, cuts, or rips</li>
                  </ul>
                </ul>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.deductibleOpen }
          _toggleFunc={ () => this.toggle('deductibleOpen') }
          header="DEDUCTIBLE"
          content={
            <React.Fragment>
              <p>You are not required to pay a Deductible prior to receiving service under this Agreement. However, You are responsible for all service charges for non-covered claims and for No Problem Found claims.  No Problem Found claims are claims where the servicer is not able to find or diagnose any covered Manufacturer’s Defect in the Product.  We may suspend Your coverage, until you have fully paid the servicer, if You do not pay the servicer for the non-covered or No Problem Found claims. Additional time and mileage charges may be applicable for in-home services outside of 50 contiguous land miles or the normal service radius of the authorized service center which may not be covered by this Agreement and would be Your responsibility.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.providedServicesOpen }
          _toggleFunc={ () => this.toggle('providedServicesOpen') }
          header="HOW COVERED SERVICES WILL BE PROVIDED"
          content={
            <React.Fragment>
              <p><strong>When You file a Claim, the Administrator will advise which of the following is applicable to Your Plan and will provide instructions for how to proceed for servicing. After eligibility is confirmed, service to Your Product may be fulfilled in the form of repair advice, a stain-removal package shipped to You to aid in stain removal, or dispatch of a professional servicing technician; at the Administrator’s sole discretion. To determine which service is best suited for Your situation, the Administrator may request photos of the affected Product.</strong></p>
              <p>Depending on the nature of Your Claim, the Administrator will coordinate the following:</p>
              <ul>
                <li>For a stain-related covered Claim, a professional cleaning solution package may be sent to You prior to the Administrator arranging for service at Your business property.  Should this not resolve the stain damage to Your Product, the Administrator may then arrange for a professional technician to service the problem at Your business property, at no additional cost to You.</li>
                <li>For a non-stain related covered Claim, a professional technician may be sent to service the problem at Your business property (at no additional cost to You). In the event the Administrator arranges for Your Covered Product to be serviced at Your business property, You must ensure the following: (1) accessibility to the Covered Product; (2) a non-threatening and safe environment; and (3) an adult over the age of 18 present for the entire period of time in which Our authorized technician is on Your business property for arranged service.</li>
              </ul>
              <p>In the event the Administrator dispatches a servicing technician to Your business property and it is then determined that servicing/repair must be made off-site, such will be performed at no additional cost to You. NOTICE: Service at Your residence will only be provided by Our authorized service technician during regular business hours, local time, Monday through Friday (excluding USA holidays).</p>
              <p><strong>Be sure to review the WHAT IS COVERED-GENERAL section; especially, "About Repairs", "About Replacements" and "About Reimbursements".</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.fileClaimOpen }
          _toggleFunc={ () => this.toggle('fileClaimOpen') }
          header="HOW TO FILE A CLAIM"
          content={
            <React.Fragment>
              <p><strong>IMPORTANT: The submission of a Claim does not automatically mean that the damage to or breakdown of the Product is Covered under Your Plan.  In order for a Claim to be considered, You must contact the Administrator first for Claim approval and authorization. THIS AGREEMENT MAY NOT PROVIDE ANY COVERAGE IF YOU MAKE UNAUTHORIZED REPAIRS.</strong></p>
              <p><strong>NOTICE: All claims must be reported individually as independent claims.  All Accidental STAINS and Accidental DAMAGE must be reported within ten (10) days of accident occurrence to qualify for coverage.  All other claims must be reported within thirty (30) days of Manufacturer’s Defect to qualify for coverage.</strong></p>
              <p><strong>COMPLETE THE FOLLOWING STEPS TO HAVE YOUR CLAIM CONSIDERED UNDER THIS AGREEMENT:</strong></p>
              <ol>
                <li>Go online to <a href="www.safeware.com/solution-center/report-new-claim.com" target="_blank" rel="noopener noreferrer">www.safeware.com/solution-center/report-new-claim.com</a> or call toll-free 1-800-800-1492 with Your Sales Receipt/Invoice readily available. Available 24/7.</li>
                <li>Explain the problem with Your Product, the date of discovery, and provide the Administrator any additional information/documentation in order to validate Your Claim; which may include submitting photos of the damage/problem.</li>
                <li>After confirmation of Claim eligibility under Your Plan, the Administrator will issue Claim authorization along with additional information regarding how Your Product will be further serviced (refer to the HOW COVERED SERVICES WILL BE PROVIDED section above for further details).</li>
              </ol>
              <p>In the event the Agreement Term expires during time of an approved Claim, Your coverage will be automatically extended until the date in which the Claim in progress has been fulfilled completely in accordance with the terms and conditions of the Service Agreement.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.limitLiabilityOpen }
          _toggleFunc={ () => this.toggle('limitLiabilityOpen') }
          header="LIMIT OF LIABILITY"
          content={
            <React.Fragment>
              <p><strong>SINGLE COVERED PRODUCT MAXIMUM: </strong>For any one Covered Product, the maximum amount that We are obligated to pay, at Our discretion, is the LESSER of the cost of: (a) authorized repairs; (b) a replacement; or (c) reimbursement.</p>
              <p><strong>AGGREGATE COVERED PRODUCT MAXIMUM: </strong>There is a claims limit for stain coverage of 2 claims per 12 month period.</p>
              <p><strong>NOTE:</strong> Payment may be fulfilled in the form of repairs, replacement or reimbursement (See "About Repairs", "About Replacements", and "About Reimbursements" provisions under the WHAT IS COVERED-GENERAL section.)</p>
              <p>THE RETAILER SHALL HAVE NO LIABILITY WHATSOEVER FOR ANY CLAIMS OR SERVICES IN ASSOCIATION WITH THIS AGREEMENT. ADDITIONALLY, NEITHER WE NOR THE ADMINISTRATOR NOR THE RETAILER SHALL BE LIABLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES; INCLUDING BUT NOT LIMITED TO: PROPERTY DAMAGE, LOST TIME OR LOST DATA RESULTING FROM A MANUFACTURER’S DEFECT OR OTHERWISE COVERED CLAIM, FROM DELAYS IN SERVICE OR THE INABILITY TO RENDER SERVICE FOR THE COVERED PRODUCT, OR RESULTING FROM THE UNAVAILABILITY OF REPAIR OR REPLACEMENT PARTS/COMPONENTS/ITEMS OR INABILITY TO PROVIDE EXACT MATCH REPLACEMENT, OR FOR ANY AND ALL PRE-EXISTING CONDITIONS KNOWN TO YOU; INCLUDING ANY INHERENT PRODUCT FLAWS.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.exclusionsOpen }
          _toggleFunc={ () => this.toggle('exclusionsOpen') }
          header="EXCLUSIONS (WHAT IS NOT COVERED)"
          content={
            <React.Fragment><strong>
              <p>THE FOLLOWING TYPES OF FURNITURE ARE NOT ELIGIBLE FOR COVERAGE UNDER THIS AGREEMENT:</p>
              <ol>
                <li>Items that are manufactured and intended for outdoor use;</li>
                <li>Ready-to-assemble ("RTA") furniture;</li>
                <li>Rattan, bamboo, wicker, woven resin, nubuck and other buffed leathers, suedes and leathers embossed patterns other than those simulating natural cow hide non-bovine leather, splits or bi-cast leather, silk, "X" coded and/or non-color fast fabrics, splits or bi-cast leather,;</li>
                <li>Velvet or velvet types of furniture;</li>
                <li>Orlon furniture;</li>
                <li>Mattresses;</li>
                <li>Items with protective finishes;</li>
                <li>Items made or upholstered with Your own material;</li>
                <li>Decorative items, robotics, TV lifts, electronics, USB ports, electrical outlets, or clocks; or</li>
                <li>Any accessories and/or add-on options purchased separately and not essential to the basic function of the Product.</li>
              </ol>
              <p>ADDITIONALLY, AS RELATED AND APPLICABLE TO YOUR COVERED PRODUCT(S), THIS AGREEMENT DOES NOT COVER ANY FAILURE/BREAKDOWN, DAMAGE, REPAIRS, OR SERVICES IN CONNECTION WITH OR RESULTING FROM:</p>
              <ol>
                <li>Any item that is sold as-is, pre-owned, showroom-displayed, distress sale or a going-out-of business sale;</li>
                <li>A pre-existing condition known to You ("pre-existing condition" refers to damages or defects associated with the Covered Product that existed before this Agreement was purchased); </li>
                <li>Any item that has been used outdoors or in a privately owned rental, in-home daycare business, educational institution, call centers and other 24/7 business applications, hotel public space usage or any other usage not defined in Commercial Usage definition or Eligibility Section;</li>
                <li>Shipping or delivery charges associated with the initial purchase of the Covered Product; </li>
                <li>Initial installation and assembly</li>
                <li>Removal/disposal of any NON-Covered Product;</li>
                <li>Any Claim for service to or replacement of the Covered Product that has not been prior authorized by the Administrator;</li>
                <li>Any item that is not a part of a covered Claim, or any item that was not included with the purchase of this Service Agreement;</li>
                <li>Servicing of the Covered Product in association with a non-covered Claim;</li>
                <li>Any upgrades, attachments, accessories or peripherals, or any breakdown or damage to or resulting from these items;</li>
                <li>Any items that are consumer replaceable and designed to be replaced over time throughout the life of the Covered Product; including, but not limited to: chargers, batteries, fuses and connectors (UNLESS OTHERWISE STATED AS COVERED UNDER YOUR PLAN); </li>
                <li>Routine, periodic or preventative maintenance; </li>
                <li>Normal wear and tear; including but not limited to: scuffing, scrapes or surface abrasions that do not penetrate through the Product’s finish; softening of cushions conforming to the shape of the user (body impressions); soiling buildup that accumulates over time from repeated use (such as stains from perspiration, body oil or hair oil); and loosening of chair legs, rungs or joints;</li>
                <li>Anything that is covered under other insurance, warranty, guarantee and/or service contract providing the same benefits as outlined in this Agreement; </li>
                <li>Any kind of manufacturer recall or rework order on the Covered Product, of which the manufacturer is responsible for providing, regardless of the manufacturer’s ability to pay for such repairs; </li>
                <li>Fortuitous events; including, but not limited to: environmental conditions, exposure to weather conditions or perils of nature; collapse of structure, breakage of skylights, roofs or water pipes; appliance malfunctions including but not limited to air conditioners and water heaters; fire, any kind of precipitation, humidity, lightning, dirt/sand, smoke; explosion or collision of or with another object; nuclear radiation, radioactive contamination, riot, war or hostile action, governmental act, or internet or other telecommunications malfunction; </li>
                <li>Theft or mysterious disappearance, loss (unforeseen disappearance) or vandalism of or to the Covered Product;</li>
                <li>Adjustment, manipulation, modification, removal or unauthorized repairs of any internal component/part of a Covered Product performed by anyone other than a service center/technician authorized by the Administrator; </li>
                <li>Any item that has been confirmed by Our authorized servicer to have removed or altered serial numbers; </li>
                <li>Stains or damage caused by transit, delivery, redelivery, movement between residences or storage performed by anyone other than a service center/technician authorized by the Administrator;</li>
                <li>Stains or damage caused by use of improper cleaning methods or improper cleaning materials, or damage caused by the application of topical treatments (other than those provided by the Administrator specifically for use with the Covered Product) or lack of compliance with the provisions of the manufacturer’s warranty;</li>
                <li>Lack of providing manufacturer’s recommended maintenance or operation/storage of the Covered Product in conditions outside manufacturer specifications, or use of the Covered Product in a manner that would cause coverage under the manufacturer’s warranty to be voided, or use of the Product in a manner that is inconsistent with its design or manufacturer specifications; </li>
                <li>Rust, corrosion, warping, bending, animals, animal inhabitation or insect infestation; </li>
                <li>Abuse (meaning, the intentional treatment of the Covered Product in a harmful, injurious, malicious or offensive manner which results in its damage and/or breakdown), neglect, negligence, misuse, intentional harm or malicious mischief of or to the Covered Product; </li>
                <li>Odors, including odors that remain after a visible stain is cleaned;</li>
                <li>Pet/animal damage, such as damage from teeth, claws, or beaks;</li>
                <li>Dye transfer (UNLESS OTHERWISE STATED UNDER YOUR PLAN), dye lot or texture variation;</li>
                <li>Fading of the upholstery, color loss, and/or discoloration; </li>
                <li>Flattening of nap, pilling, snags or fraying/matting of material, or shrinking of material;</li>
                <li>Stress tears (tearing or ripping of upholstery within one-half inch (1/2") of and parallel to the seam line);</li>
                <li>Wood surface scratch, dent, chip or gouge that does not penetrate through the finish;</li>
                <li>Leather surface scratches that do not penetrate through the upholstery;</li>
                <li>Peeling of any kind of leather;</li>
                <li>Nicks, scratches or wrinkles that are inherent to the leather/hide and were present at time of Product purchase;</li>
                <li>Inherent design flaws including but not limited to natural inconsistencies in upholstery, leather, vinyl or delamination of microfiber;</li>
                <li>Stains or damage from acid, bleach, caustic solutions, mildew, mold or recurring damage;</li>
                <li>Correction for dye lot or texture variations arising from the service or replacement of any part of a Covered Product or the replacement of an entire Covered Product;</li>
                <li>Reprogramming or any kind of data transfer or loss;</li>
                <li>Any service performed in conflict with the TERRITORY provision of this Service Agreement.</li>
              </ol>
              <p>IMPORTANT: IF THE ADMINISTRATOR AUTHORIZES SERVICE FOR A CLAIM ON YOUR COVERED PRODUCT, AND IT IS THEN DETERMINED BY OUR AUTHORIZED SERVICE CENTER/TECHNICIAN TO BE EXCLUDED UNDER THE TERMS AND CONDITIONS OF THIS AGREEMENT, OR RESULTS IN A "NO PROBLEM FOUND" DIAGNOSIS, YOU MAY BE RESPONSIBLE FOR ALL REPAIR COSTS INCLUDING SHIPPING COSTS.</p>
              <p>SHOULD THE MANUFACTURER OR SELLING RETAILER OF YOUR PRODUCT BECOME INSOLVENT OR SUBJECT TO BANKRUPTCY PROCEEDINGS, OR THE MANUFACTURER NO LONGER PROVIDE PRODUCT SUPPORT OR PARTS, AND ALL PARTS SOURCES HAVE BEEN EXHAUSTED DURING THE COVERAGE PERIOD OF THIS PLAN, ADMINISTRATOR AND WE SHALL BE EXCUSED FROM PERFORMANCE HEREUNDER AND YOU SHALL RECEIVE A FULL REFUND OF THE PURCHASE PRICE PAID BY YOU FOR THE PLAN LESS CLAIMS PAID.</p>
            </strong></React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.cancellationsOpen }
          _toggleFunc={ () => this.toggle('cancellationsOpen') }
          header="CANCELLATION"
          content={
            <React.Fragment>
              <p>You may cancel this Agreement at any time by informing the Administrator orally or in writing of Your cancellation request. NOTICE: The following cancellation provisions apply to the original purchaser of this Agreement only.<strong> NO CANCELLATION FEE APPLIES.</strong></p>
              <p><strong>IF YOU CANCEL THIS AGREEMENT:</strong></p>
              <ul>
                <li>Within 30 days of the Agreement purchase date, You will receive a 100% refund of the full Agreement Purchase Price paid by You, minus any Claims paid by Us (except in Arizona, Georgia, Missouri &amp; Nevada where Claims deduction is prohibited). If Your refund is not paid or credited within thirty (30) days after Your cancellation request to Us, We will add an extra 10% to Your due refund for every thirty (30) days the refund is not paid by Us.</li>
                <li>After 30 days from the Agreement purchase date, You will receive a pro-rata refund of the Agreement Purchase Price paid by You, minus any Claims paid by Us (except in Arizona, Georgia, Missouri &amp; Nevada where Claims deduction is prohibited). And, If Your refund is not paid or credited within thirty (30) days after their cancellation request to Us, We will add an extra 10% to Your due refund for every thirty (30) days the refund is not paid by Us.</li>
              </ul>
              <p><strong>WE MAY ONLY CANCEL THIS AGREEMENT FOR:</strong></p>
              <ul>
                <li>Non-payment of the Agreement purchase price/fee by You;</li>
                <li>Material misrepresentation by You; or </li>
                <li>Substantial breach of duties under this Agreement by You in relation to the Covered Product or its use.</li>
              </ul>
              <p>If We cancel this Agreement, We will provide written notice to You at least 15 days (30 days in Georgia) (21 days in Washington) prior to the effective date of cancellation. Such notice will be sent to Your current address in Our file (email or physical address as applicable), with the reason for and effective date of such cancellation. If We cancel this Agreement, You will receive a refund based upon the same criteria as outlined above.</p>
              <p>In the event the purchase of this Agreement was financed, any refund due will be provided to the financial institution as their interest may appear.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.recoverPaymentOpen }
          _toggleFunc={ () => this.toggle('recoverPaymentOpen') }
          header="OUR RIGHT TO RECOVER PAYMENT"
          content={
            <React.Fragment>
              <p>If You have a right to recover against another party for anything We have paid under this Service Agreement, Your rights shall become Our rights. You shall do whatever is reasonably necessary to enable Us to enforce these rights. We shall recover only the excess after You are fully compensated for Your loss.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.generalProvisionsOpen }
          _toggleFunc={ () => this.toggle('generalProvisionsOpen') }
          header="GENERAL PROVISIONS"
          content={
            <React.Fragment>
              <ol>
                <li><strong>Subcontract.</strong> We may subcontract or assign performance of Our obligations to third parties, but We shall not be relieved of Our obligations to You when doing so.</li>
                <li><strong>Waiver; Severability.</strong> The failure of any party to require performance by the other party of any provision hereof will not affect the full right to require such performance at any time thereafter; nor will the waiver by either party of a breach of any provision hereof be taken or held to be a waiver of the provision itself. In the event that any provision of these terms and conditions will be unenforceable or invalid under any applicable law or be so held by applicable court decision, such unenforceability or invalidity will not render these terms and conditions unenforceable or invalid as a whole and in such event, such provisions will be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.</li>
                <li><strong>Notices.</strong> You expressly consent to be contacted, for any and all purposes, at any telephone number, or physical or electronic address You provide Us. All notices or requests pertaining to this Service Agreement will be in writing and may be sent by any reasonable means including by mail, email, facsimile, text message or recognized commercial overnight courier. Notices to You are considered delivered when sent to You by email or fax number that You provided to Us, or three (3) days after mailing to the street address You provided.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.guarantyOpen }
          _toggleFunc={ () => this.toggle('guarantyOpen') }
          header="GUARANTY"
          content={
            <React.Fragment>
              <p>This is not an insurance policy; it is a service contract. We have obtained an insurance policy to insure Our performance under this Service Agreement. Should We fail to pay any Claim or fail to replace the Product covered under this Service Agreement within sixty (60) days after the Claim has been submitted, or in the event You cancel this Service Agreement, and We fail to refund any unearned portion of the Service Agreement price, You are entitled to make a direct Claim against the insurer, Wesco Insurance Company, at 866-505-4048 or 59 Maiden Lane, 43rd Floor, New York, NY 10038.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.renewabilityOpen }
          _toggleFunc={ () => this.toggle('renewabilityOpen') }
          header="RENEWABILITY"
          content={
            <React.Fragment>
              <p>This Service Agreement is not renewable.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.transferabilityOpen }
          _toggleFunc={ () => this.toggle('transferabilityOpen') }
          header="TRANSFERABILITY"
          content={
            <React.Fragment>
              <p>This Service Agreement cannot be transferred to any other party or item.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.entireAgreementOpen }
          _toggleFunc={ () => this.toggle('entireAgreementOpen') }
          header="ENTIRE AGREEMENT"
          content={
            <React.Fragment>
              <p>This Service Agreement; including Your Sales Receipt/Invoice, terms, conditions, limitations, exceptions and exclusions, constitute the entire agreement between Us and You and no representation, promise or condition not contained herein shall modify these items, except as required by law.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.statesOpen }
          _toggleFunc={ () => this.toggle('statesOpen') }
          header="SPECIAL STATE REQUIREMENTS"
          content={
            <React.Fragment>
              <p><strong>Regulation of service contracts varies based on state of purchase and Retailer location. Any provision within this Agreement that conflicts with the laws of the state in which this Agreement was purchased (or where applicable, the state in which You are located), shall automatically be considered modified in conformity with such enforceable laws and regulations, and the language set forth below for such state shall supersede any other provision within this Agreement to the contrary.</strong></p>
              <p><strong>THIS SERVICE AGREEMENT IS NOT AVAILABLE OR VALID IN WYOMING &amp; PUERTO RICO.</strong></p>
              <br /><p><strong>Connecticut:</strong> This Service Contract is an agreement between the Obligor/Provider, Northcoast Warranty Services, Inc., 800 Superior Avenue E., 21st Floor, Cleveland, OH 44114, (866) 927-3097, and You. In the event of a dispute with Administrator, You may contact The State of Connecticut, Insurance Department, P.O. Box 816, Hartford, CT 06142-0816, Attn: Consumer Affairs. The written complaint must contain a description of the dispute, the purchase or lease price of the Product, the cost of repair of the Product and a copy of the warranty Service Contract. GUARANTY is amended as follows: If We fail to pay or to deliver service on a claim within sixty (60) days after proof of loss has been filed, or in the event You cancel this Service Agreement and We fail to issue any applicable refund within sixty (60) days after cancellation, file a claim against the insurer, Wesco Insurance Company at 59 Maiden Lane, 43rd Floor, New York, NY 10038, by calling 1-866-505-4048. CANCELLATION is amended as follows: This Service Contract may be cancelled by the Service Contract Holder if the Product covered under this Service Contract is returned, sold, lost, stolen or destroyed. SERVICE CONTRACT HOLDER’S RESPONSIBILITY: It is the responsibility of the Service Contract Holder to follow the manufacturer’s specifications for the use and care/maintenance of the covered Product.</p>
              <br /><p><strong>Georgia:</strong> This Service Agreement shall be non-cancelable by us except for fraud, material misrepresentation, or failure to pay consideration due therefore. The cancellation shall be in writing and shall conform to the requirements of Code 33-24-44. You may cancel at any time upon demand and surrender of the Service Agreement and we shall refund the excess of the consideration paid for the Service Agreement above the customary short rate for the expired term of the Service Agreement. This Service Agreement excludes coverage for incidental and consequential damages and pre-existing conditions only to the extent such damages or conditions are known to you or reasonably should have been known to you. The following is added as the last paragraph to "Items Not Covered" above: Should the manufacturer of Your Product go out of business or the manufacturer no longer provide product support and all parts sources have been exhausted during the coverage period of this service agreement, the seller, obligor and the administrator shall be excused from performance hereunder and You shall receive a full refund of the service agreement purchase price paid by You.</p>
              <br /><p><strong>Indiana:</strong> This Contract is not insurance and is not subject to Indiana insurance law. Your proof of payment to the Seller for this Service Agreement shall be considered proof of payment to the insurance company which guarantees Our obligations to You. If We fail to perform or make payment due under this Contract within sixty (60) days after You request the performance or payment, You may request the performance or payment directly from the insurer that issued the provider's Service Contract reimbursement policy, including any applicable requirement under the Contract that the provider refund any part of the cost of the Contract upon cancellation of the Contract. This Service Agreement excludes coverage for pre-existing conditions only to the extent such damages or conditions are known to you or reasonably should have been known to you or caused by You.</p>
              <br /><p><strong>Oregon:</strong> This Service Contract is an agreement between the Obligor/Provider, Northcoast Warranty Services, Inc., 800 Superior Avenue E., 21st Floor, Cleveland, OH 44114, (866) 927-3097, and You.</p>
              <br /><p><strong>Utah:</strong> This Service Agreement is subject to limited regulation by the Utah Insurance Department. To file a complaint, contact the Utah Insurance Department. Coverage afforded under this Service Agreement is not guaranteed by the Utah Property and Casualty Guarantee Association. This Service Agreement may be canceled due to unauthorized Repair, which results in a material change in the nature or extent of the risk, occurring after the first effective date of the current policy, which causes the risk of loss to be substantially and materially increased beyond that contemplated at the time the policy was issued or last renewed.</p>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default CommercialFurniture;
