const defaultState = {
  visibleDict: {},
};

const modalReducer = function user(state = defaultState, action:any) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        visibleDict: {
          [action.modalID]: true,
        },
      };
    case 'CANCEL_MODAL':
      return {
        visibleDict: {},
      };
    default:
      return state;
  }
};

export default modalReducer;
