import '../importer.scss';
import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Auth from '../containers/Auth';
import VerifySuccess from './Auth/VerifySuccess';
import UnsubSuccess from './Auth/UnsubSuccess';
import VerifyError from './Auth/VerifyError';
import GuestContractSection from '../containers/GuestContract';
import ProductSection from '../containers/Product';
import PDP from '../containers/PDP';
import AccountSection from '../containers/Account';
import CheckoutSection from '../containers/Checkout';
import ContractTerms from '../containers/ContractTerms';
import Errors from '../containers/Errors';
import ClaimDetail from '../containers/ClaimDetail';

import ProtectedRoute from '../components/ProtectedRouteCheck';

interface AppProps {
  children?: any;
  isUser: boolean;
  _checkLogin: any;
  _sendAnalytics: any;
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    this.props._checkLogin();
    this.props._sendAnalytics();
    this.initGoogleInterval();
  }

  initGoogleInterval() {
    let attempts = 0;
    const interval = setInterval(() => {
      attempts += 1;
      if (attempts > 5) {
        console.warn('Too many tries to connect to google', attempts);
        clearInterval(interval);
        return;
      }
      // @ts-ignore
      if (!window.gapi) {
        return;
      }
      // @ts-ignore
      window.gapi.load('auth2', () => {
        const params = {
          // @ts-ignore
          client_id: window._env_.GOOGLE_CLIENT_ID,
        };
        // @ts-ignore
        window.gapi.auth2.init(params).then(() => {
          clearInterval(interval);
        });
      });
    }, 200);
  }

  render() {
    const { isUser } = this.props;
    return (
      <Fragment>
          <Switch>
            <Redirect path='/contracts' to='/get-contract' />

            <Route path="/signup" component={ Auth } />
            <Route path="/login" component={ Auth } />
            <Route path="/request-pw" component={ Auth } />
            <Route path="/change-pw" component={ Auth } />
            <Route path="/verify-success" component={ VerifySuccess } />
            <Route path="/verify-error" component={ VerifyError } />
            <Route path="/unsub-success" component={ UnsubSuccess } />

            <Route path="/get-contract/:hash" component={ GuestContractSection } />
            <Route path="/get-contract" component={ GuestContractSection } />

            <ProtectedRoute
              path='/products/:contractSaleId'
              isUser={ isUser }
              component={ PDP } />

            <ProtectedRoute
              path='/products'
              isUser={ isUser }
              component={ ProductSection } />

            <Route path='/terms' component={ ContractTerms } />

            <ProtectedRoute path="/claims/:claimId" isUser={ isUser } component={ ClaimDetail } />
            <ProtectedRoute
              path='/account/:action'
              isUser={ isUser }
              component={ AccountSection } />

            <ProtectedRoute
              path='/account'
              isUser={ isUser }
              component={ AccountSection } />

            <Route path="/checkout/:slug/:orderID" component={ CheckoutSection } />
            <Route path="/checkout/:cartID" component={ CheckoutSection } />
            <Route path="/checkout" component={ CheckoutSection } />

            <Route path="/get-contract" component={ GuestContractSection } />
            <Redirect path='/' to='/login' />
          </Switch>
          <Errors/>
      </Fragment>
    );
  }
}

export default App;
