import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ContractBullets from '../../Shared/ContractBullets';
import ProductPlaceholder from '../../../images/product-placeholder.svg';
import { ProductPurchaseType } from '../../../types/ProductTypes';
import ClydeModal from '../../../containers/Modal';
import ClaimForm from '../../../containers/ClaimForm';
import ClaimApprovedForm from '../../../containers/ClaimApprovedForm';
import ClaimLinkModal from '../../Claim/ClaimLinkModal';
import TermsAndConditions from '../../ContractTerms/Templates';
import DownloadBar from '../../ContractTerms/Shared/DownloadBar';
import UnderwriterHandoff from './FileClaimHandoffs/UnderwriterHandoff';
import XClaimHandoff from './FileClaimHandoffs/XClaimHandoff';
import ContractDatesModal from '../ContractDatesModal';
import ClaimButton from './ClaimButton';
import { ButtonMailto } from '../../Shared/EmailTo';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

interface ProductContractDetailCardProps {
  product: ProductPurchaseType;
  newClaim: any;
  isGuest: boolean;
  _showModal: any,
  _cancelModal: any
  issueTypesProcessing?: boolean;
  issueTypes?: any;
  contractTerms?: any;
  _createAdminHandoffClaim: any;
  _changeTermsKey?:any;
  _getProductTermsInfo?: any;
  _getIssueTypes?: any;
  claimHistory?:any;
  history?: any;
}

class ProductContractDetailCard extends React.Component<ProductContractDetailCardProps,
  any> {
  state = {
    showUnderwriterClaimHandoffModal: false,
    showXCoverHandoffModal: false,
  };

  componentDidUpdate(prevProps: any) {
    const {
      product,
      issueTypes,
      isGuest,
      issueTypesProcessing,
    } = prevProps;

    if (product.contractSaleId !== this.props.product.contractSaleId) {
      this.props._getProductTermsInfo(this.props.product);
    }

    if (!issueTypes?.length && !isGuest && !issueTypesProcessing) {
      this.props._getIssueTypes();
    }
  }

  openContractHistoryModal = (e: any) => {
    const { _showModal } = this.props;
    if (e.type === 'keydown' && e.key !== 'Enter') {
      return;
    }
    _showModal('contract-dates');
  };

  renderClaimButton = (flag: boolean, claimBtnOptions: any) => {
    const {
      product,
      isGuest,
      _showModal,
    } = claimBtnOptions;

    return (
        <div className='claim-btn-container'>
          <div className='claim-btn-container__top'>
            <ClaimButton
              product={ product }
              isGuest={ isGuest }
              shouldDisableClaimBtn={ flag }
              _showModal={ _showModal }
              _showUnderwriterClaimHandoffModal={ () => this.setState({ showUnderwriterClaimHandoffModal: true }) }
              _showXCoverHandoffModal={ () => this.setState({ showXCoverHandoffModal: true }) }
              />
            <button className=' button--general btn  pdp-detail__cta__button--view-terms pdp-detail__ctas__terms' onClick={ () => _showModal('terms') } data-test='viewTerms'>View Terms</button>
          </div>
          {
            flag
            && <div className='claim-btn-container__bottom'>
                <span>Processing your claim, reach out to </span>
                <ButtonMailto label="support@joinclyde.com" mailto="mailto:support@joinclyde.com" />
                <span> if you have any questions.</span>
              </div>
          }
        </div>
    );
  };

  render() {
    const {
      contractTerms,
      product,
      newClaim,
      isGuest,
      claimHistory,
      _showModal,
      _changeTermsKey,
      _createAdminHandoffClaim,
    } = this.props;
    const {
      showUnderwriterClaimHandoffModal,
      showXCoverHandoffModal,
    } = this.state;

    const claimInfo = claimHistory && claimHistory.claimInfo ? claimHistory.claimInfo : {};
    const xcoverLink = process.env.NODE_ENV === 'production'
      ? 'https://xcover.com/login'
      : 'https://staging.xcover.com/login';
    const claimsOutlink = showXCoverHandoffModal ? xcoverLink : product.underwriterClaimsOutlink;
    const showApprovedForm = newClaim.id && newClaim.claimState && newClaim.claimState !== 'denied';
    const expiresDate = dayjs(product.expiresDate, 'MMMM Do YYYY');
    const isExpired = dayjs().isAfter(expiresDate);
    const { claimState = '', resolutionState = '' } = claimInfo;
    const activeClaimStates = [
      'inProcess',
      'underClydeReview',
      'underAdminReview',
      'needMoreInfo',
      'approved',
      'resolutionInProgress',
    ];
    const claimBtnOptions = {
      product,
      isGuest,
      _showModal,
    };
    let shouldDisableClaimBtn = false;

    if (activeClaimStates.indexOf(claimState) > -1) {
      shouldDisableClaimBtn = true;

      if (claimState === 'approved' && resolutionState === 'resolutionFulfilled') {
        shouldDisableClaimBtn = false;
      }
    }

    return (
      <>
        {
          showUnderwriterClaimHandoffModal
            && <UnderwriterHandoff
              claimsOutlink={ claimsOutlink }
              cobrandedLogo={ product.underwriterLogo }
              contractSaleId={ product.contractSaleId }
              _createAdminHandoffClaim={ _createAdminHandoffClaim }
              _closeModal={ () => { this.setState({ showUnderwriterClaimHandoffModal: false }); } } />
        }
        {
          showXCoverHandoffModal
            && <XClaimHandoff
              claimsOutlink={ claimsOutlink }
              cobrandedLogo={ product.underwriterLogo }
              bookingId={ product.quotePackageId }
              _closeModal={ () => { this.setState({ showXCoverHandoffModal: false }); } } />
        }

        <section className='pdp-detail clyde-shadow'>
          <div className='pdp-detail__left' >
            <div
              className='pdp-detail__img'
              style={ { backgroundImage: `url(${product.imageLink || ProductPlaceholder})` } }>
            </div>
          </div>
          <div className='pdp-detail__right' >
            <div className='pdp-detail__details'>
              <div className='pdp-detail__details__status-container'>
                <h4 className={ `pdp-detail__status ${product.state}`} data-test='productState'>{ product.state }</h4>
              </div>
              <div className='pdp-detail__details__info-main-container'>
                <span>
                  <h3 className='pdp-detail__name' data-test='productName'>{ product.name }</h3>
                  { product.manufacturer && <h5 className='pdp-detail__manufacturer'>{ product.manufacturer }</h5> }
                </span>

                <div className='pdp-detail__details__right'>
                  { expiresDate.isValid() && product.coverageStartType === 'dop'
                    && <button
                      className={classnames(
                        'button-no-style',
                        'pdp-detail__expires-date',
                        { 'pdp-detail__expires-date--hidden': !product.contractEffectiveDate },
                      )}
                      onClick={ this.openContractHistoryModal }
                      onKeyDown={ this.openContractHistoryModal }
                    >
                      <div className='pdp-detail__expires-date__top'>
                        { isExpired ? 'Expired' : 'Expires' }
                      </div>
                      <div className='pdp-detail__expires-date__bottom'>
                        { expiresDate.format('MMM D') } <br />
                        { expiresDate.format('YYYY') }
                      </div>
                    </button>
                  }
                </div>
              </div>
              <div className='pdp-detail__details__info-secondary-container'>
                <div className='pdp-detail__ids-div'>
                  <h4 className='clyde-muted-text' data-test='productId'>
                    ID: { product.lineitemId || product.hashedSaleID }
                  </h4>
                  {
                    product.quotePackageId
                      && <h4 className='clyde-muted-text' data-test='bookingId'>Booking ID: {product.quotePackageId}</h4>
                  }
                </div>
                {
                  product.coverageStartType === 'ext'
                    && <h4 className='clyde-muted-text' >
                      <span>Expires { product.term } { product.term === 1 ? 'year' : 'years' } after limited warranty</span>
                    </h4>
                }
                { product.coverageStartType === 'dop'
                    && <Button
                      className='pdp-detail__expires-button'
                      onClick={ this.openContractHistoryModal }
                      data-test='contractHistory'
                    >
                      { product.contractEffectiveDate ? 'View Contract Dates' : 'Contract Dates Pending' }
                    </Button>
                }
              </div>
            </div>
            <em className='pdp-detail__warning'></em>
            <ContractBullets bullets={ product.contractBullets } />
            <div className='pdp-detail__ctas'>
              { this.renderClaimButton(shouldDisableClaimBtn, claimBtnOptions)}
            </div>
            { isGuest
              && <p className='pdp-detail__ctas__sign-up'>
                You must <Link target='_blank' to='/signup'> sign up </Link>
                to file a claim.
              </p>
            }
          </div>

          <ClydeModal
            modalID='terms'
            customClass='clyde-modal--terms'
            bottomBar={ <DownloadBar contractTerms={ contractTerms } /> }
          >
            <TermsAndConditions contractTerms={ contractTerms } _changeTermsKey={ _changeTermsKey } isModal />
          </ClydeModal>

          <ClydeModal
            modalID='claim-form'
            customClass={classnames(
              'clyde-modal--full',
              'claim-modal',
              'clyde-modal--hide-close',
            )}
          >
            { showApprovedForm
              ? <ClaimApprovedForm contract={ product } claimInfo={ newClaim }/>
              : <ClaimForm contract={ product } history={ this.props.history } />
            }
          </ClydeModal>

          <ClydeModal modalID='claim-link' customClass='clyde-modal--claim-link'>
            <ClaimLinkModal
              externalClaimUrl={ product.customClaimLink || '' }
              shopLogoSource={ product.shopLogo || '' }
              shopName={ product.shopName || '' }
            />
          </ClydeModal>

          <ClydeModal modalID='contract-dates' customClass='contract-dates-modal'>
            <ContractDatesModal
              agnosticExpires={ expiresDate && expiresDate.isValid() ? expiresDate.format('MMM D YYYY') : '' }
              product={ product }
            />
          </ClydeModal>
        </section>
      </>
    );
  }
}

export default ProductContractDetailCard;
