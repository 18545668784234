import React from 'react';
import classnames from 'classnames';

interface approvedStepsProps {
  currentStep: number;
  steps: any;
  _setStep: any;
}

const ApprovedSteps = ({
  currentStep,
  steps,
  _setStep,
} : approvedStepsProps) => (
    <ul className='claim-form__response__step-map'>
      <li
        key='nextSteps-0'
        className={classnames(
          'claim-form__response__step-map__step',
          {
            'claim-form__response__step-map__step--completed': currentStep > 0,
          },
        )}
        onClick={ _setStep(0) }
      >
        <span className='claim-form__response__step-map__step__number'>1</span>
        <span className='claim-form__response__step-map__step__name'>File Claim</span>
      </li>
      {
        steps.map((step: any, index: number) => (
          <li
            key={`nextSteps-${index}`}
            className={classnames(
              'claim-form__response__step-map__step',
              {
                'claim-form__response__step-map__step--pending': index + 1 > currentStep,
                'claim-form__response__step-map__step--completed': index + 1 < currentStep,
              },
            )}
            onClick={ _setStep(index + 1) }
          >
            <div className='claim-form__response__step-map__connection' />
            <span className='claim-form__response__step-map__step__number'>{ index + 2 }</span>
            <span className='claim-form__response__step-map__step__name'>{ step }</span>
          </li>
        ))
      }
    </ul>
);

export default ApprovedSteps;
