import React from 'react';
import CloseModalButton from '../Shared/CloseModalButton';

interface ClydeModalProps {
  modalID: string;
  visibleDict: any;
  hideCloseBtn?: boolean;
  _cancelModal: any;

  bottomBar?: any;
  customClass?: string;
  children?: any;
  _onClose?: any;
}

class ClydeModal extends React.Component<ClydeModalProps> {
  componentWillUnmount() {
    document.body.classList.remove('overflow-off');
    this.props._cancelModal();
  }

  closeModal = () => {
    const { _onClose, _cancelModal } = this.props;
    if (typeof _onClose === 'function') {
      _onClose();
    }

    _cancelModal();
  };

  render() {
    return (
      <div
        id={ this.props.modalID }
        className={ `clyde-modal__background ${this.props.visibleDict[this.props.modalID] ? 'active' : ''}` }>
        <div
          className={ `clyde-modal ${this.props.visibleDict[this.props.modalID] ? 'active' : ''} ${this.props.customClass}` }
          onClick={ this.closeModal }>

          <div className="clyde-modal__top clyde-shadow no-select" onClick={ (e) => e.stopPropagation() }>
            {
              !this.props.hideCloseBtn
              && <CloseModalButton onClick={ this.closeModal } className='clyde-modal__close'/>
            }

            <div className="clyde-modal__content">
              { this.props.children }
            </div>
          </div>

          <div className="clyde-modal__bottom" onClick={ (e) => e.stopPropagation() }>
            { this.props.bottomBar ? this.props.bottomBar : '' }
          </div>
        </div>
      </div>
    );
  }
}

export default ClydeModal;
