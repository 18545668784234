import React, { useState } from 'react';
import { FormGroup, Button, FormText } from 'reactstrap';
import ReceiptCapture from './ReceiptCapture';
import ClaimPhotoInput from './ClaimPhotoInput';

interface ClaimDocumentationProps {
  formState: {
    fileNames: string[];
    files: any[];
    receiptFileName: string;
    receiptFile: any;
    errors: any[];
  };
  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  displayReceiptCapture: boolean;
  productParts?: any[],
}

const ClaimDocumentation = ({
  formState: {
    fileNames,
    files,
    errors,
    receiptFileName,
    receiptFile,
  },
  updateField,
  iterateStep,
  optionalStepIncluded,
  displayReceiptCapture,
} : ClaimDocumentationProps) => {
  const [showWarning, toggleWarning] = useState(false);

  const sendUpdate = (idx: number) => (e: any) => {
    const { value } = e.target;
    const eventFiles = e.target.files;
    const updatedNames = [...fileNames];
    const updatedFiles = [...files];
    updatedNames[idx] = value;
    updatedFiles[idx] = eventFiles[0];
    updateField('fileNames', { fileNames: updatedNames, files: updatedFiles });
  };

  const deleteFile = (idx: number) => (e: any) => {
    e.preventDefault();
    const newNames = [...fileNames];
    const newFiles = [...files];
    newNames.splice(idx, 1);
    newFiles.splice(idx, 1);
    newNames.push('');
    updateField('fileNames', { fileNames: newNames, files: newFiles });
  };

  const updateReceipt = (e: any) => {
    const { value } = e.target;
    const eventFiles = e.target.files;
    updateField('receiptFile', { receiptFileName: value, receiptFile: eventFiles[0] });
  };

  const handleSubmit = () => {
    if (!fileNames[0] && !showWarning) {
      toggleWarning(true);
      return;
    }
    iterateStep()();
  };

  return (
    <section className={`claim-form__step claim-form__documentation`}>
      <h2>File a claim</h2>
      <h3>Documentation</h3>
      <p className="claim-form__form-sub-title">Please upload at least one photo of your product's issue.</p>
      <FormText color='muted' className='claim-form__step__secondary-prompt'>
        Providing photos that clearly document the issue will help us resolve you claim faster.
      </FormText>
      <div className='claim-form__documentation__upload-wrapper'>
        { fileNames.map((name: string, idx: number) => (
          <ClaimPhotoInput
          name={ name }
          index={ idx }
          fileNames={ fileNames }
          deleteFile={ deleteFile }
          sendUpdate={ sendUpdate }
          key={ `claim-photo-input-${idx}` }
          />
        ))}
      </div>
      { displayReceiptCapture
        && <ReceiptCapture
          receiptFileName={ receiptFileName }
          updateReceipt={ updateReceipt }
        />
      }
      { errors.includes('files')
        && <span data-test='fileWarning' className='claim-form__documentation__file-warning'>
          Files must be less than 10mb in size.
        </span>
      }
      <FormGroup>
          <Button
            className='button--general claim-form__step__next-button'
            onClick={ handleSubmit }
            disabled={ displayReceiptCapture && !receiptFileName }
            data-test='next'
          >
            Next
          </Button>
          { showWarning
            && <span className='claim-form__documentation__bypass-warning'>
              Are you sure? Claims without photos require additional review which may delay the claim process.
            </span>
          }
        </FormGroup>
    </section>
  );
};

export default ClaimDocumentation;
