import http from '../config/http';
import { HiClydeCartLineItem, AvailableItemType } from '../types/LineItemTypes';
import { CartInfoParams } from '../types/CartTypes';

const CHECKOUT_URL = '/product/contract';
const GET_AVAILABLE_CONTRACTS_URL = '/product/contract';
const GET_CART_INFO_URL = '/cart';

export function updateCheckout(order:any) {
  return {
    type: 'UPDATE_CHECKOUT',
    order,
  };
}

export function loadingCheckout() {
  return {
    type: 'LOADING_CHECKOUT',
  };
}

export function failedCheckout(err:any) {
  return {
    type: 'FAIL_CHECKOUT',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function checkout(order:any) {
  return (dispatch:any) => {
    dispatch(loadingCheckout());
    return http.post(CHECKOUT_URL, order)
      .then((response) => {
        dispatch(updateCheckout(response.data));
      })
      .catch((err) => {
        dispatch(failedCheckout(err));
      });
  };
}

export function updateGetAvailableContracts(contracts:any[]) {
  return {
    type: 'UPDATE_GET_AVAILABLE_CONTRACTS',
    contracts,
  };
}

export function loadingGetAvailableContracts() {
  return {
    type: 'LOADING_GET_AVAILABLE_CONTRACTS',
  };
}

export function failedGetAvailableContracts(err:any) {
  return {
    type: 'FAIL_GET_AVAILABLE_CONTRACTS',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getAvailableContracts(lineItemId: string, shouldAddToOrder?:boolean) {
  return (dispatch:any) => {
    dispatch(loadingGetAvailableContracts());
    return http.get(`${GET_AVAILABLE_CONTRACTS_URL}?lineitemId=${lineItemId}`)
      .then((response) => {
        dispatch(updateGetAvailableContracts(response.data));
        if (shouldAddToOrder) {
          dispatch(addAllToOrder());
        }
        dispatch(storeCartLocal());
      })
      .catch((err) => {
        dispatch(failedGetAvailableContracts(err));
      });
  };
}

export function updateGetCartInfo(cart: any) {
  return {
    type: 'UPDATE_GET_CART_INFO',
    cart,
  };
}

export function loadingGetCartInfo() {
  return {
    type: 'LOADING_GET_CART_INFO',
  };
}

export function failedGetCartInfo(err:any) {
  return {
    type: 'FAIL_GET_CART_INFO',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getCartInfo({ cartID, slug, orderID }: CartInfoParams) {
  return (dispatch:any) => {
    dispatch(loadingGetCartInfo());
    const url = (slug && orderID)
      ? `${GET_CART_INFO_URL}/${slug}/${orderID}`
      : `${GET_CART_INFO_URL}/${cartID}`;
    return http.get(url)
      .then((response) => {
        dispatch(updateGetCartInfo(response.data));
        dispatch(storeCartLocal());
      })
      .catch((err) => {
        dispatch(failedGetCartInfo(err));
      });
  };
}

export function addToAvailableItems(product: AvailableItemType) {
  return {
    type: 'ADD_TO_AVAILABLE_ITEMS',
    product,
  };
}

export function reAddToAvailableItems(product: AvailableItemType) {
  return {
    type: 'RE_ADD_TO_AVAILABLE_ITEMS',
    product,
  };
}

export function clearRemovedItems() {
  return {
    type: 'CLEAR_REMOVED_ITEMS',
  };
}

export function addToOrder(lineitem: HiClydeCartLineItem) {
  return {
    type: 'ADD_TO_ORDER',
    lineitem,
  };
}

export function removeItem(lineitemId: string) {
  return {
    type: 'REMOVE_ITEM',
    lineitemId,
  };
}

export function addAllToOrder() {
  return {
    type: 'ADD_ALL_TO_ORDER',
  };
}

export function updateOrder(lineitem: HiClydeCartLineItem) {
  return {
    type: 'UPDATE_ORDER',
    lineitem,
  };
}

export function resetCheckout() {
  return {
    type: 'RESET_CHECKOUT',
  };
}

export function updateOrderTax() {
  return {
    type: 'UPDATE_ORDER_TAX',
  };
}

export function storeCartLocal() {
  return {
    type: 'STORE_CART_LOCAL',
  };
}

export function inflateLocalCart() {
  return {
    type: 'INFLATE_LOCAL_CART',
  };
}

export const setHiClydeLinkSource = (hiClydeLinkSource: string) => ({
  type: 'SET_HI_CLYDE_LINK_SOURCE',
  hiClydeLinkSource,
});

export const checkHiClydeLinkSource = () => ({
  type: 'CHECK_HI_CLYDE_LINK_SOURCE',
});
