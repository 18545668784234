import React from 'react';
import BlixLogo from '../../images/blix-logo.png';
import DailySaleLogo from '../../images/dailysale-logo.png';
import HydrowLogo from '../../images/hydrow-logo.png';
import MolekuleLogo from '../../images/molekule-logo.png';
import TuftAndNeedleLogo from '../../images/tuft-and-needle-logo.png';
import ChiliLogo from '../../images/chili-logo.png';
import TempoLogo from '../../images/tempo-logo.png';
import AutoBrushLogo from '../../images/autobrush-logo.png';
import { ReactComponent as NotCovered } from '../../images/not-covered.svg';

const NoProducts = (props : Record<string, unknown>) => {
  const logosElements = [
    <img src={ TempoLogo } alt='Tempo' width='76px' height='17px' />,
    <img src={ MolekuleLogo } alt='Molekule' width='110px' height='11.62px' />,
    <img src={ HydrowLogo } alt='Hydrow' width='72px' height='17px' />,
    <img src={ DailySaleLogo } alt='Daily' width='69.26px' height='17px' />,
    <img src={ ChiliLogo } alt='Chili' width='54px' height='18px' />,
    <img src={ BlixLogo } alt='Blix' width='34.7px' height='17px' />,
    <img src={ TuftAndNeedleLogo } alt='Tuft & Needle' width='117px' height='13px' />,
    <img src={ AutoBrushLogo } alt='Auto Brush' width='112px' height='18.67px' />,
  ];

  return (
    <div className='no-products-container'>
      <div className='not-covered'>
        <NotCovered className='not-covered__icon' />
      </div>
      <section className='info-section'>
          <h2 className='info-section__title' >No Purchase History</h2>
          <p className='info-section__text'>You haven’t purchased any products yet. If you have, make sure you’re logged in with the email associated with your product(s).</p>
      </section>
      <section className='partner-logos-section'>
        <p className='partner-logos-section__info-text'>
          Here are some companies we provide product protection for:
        </p>
        <div className='partner-logos-section__logos-container'>
          <div className='partner-logos-section__logos-container--inner'>
            {
              logosElements.map((element, idx) => (
                <div className='partner-logos-individual' key={`partner-logo-${idx}`}>
                  {element}
                </div>
              ))
            }
          </div>
        </div>
      </section>
    </div>
  );
};

export default NoProducts;
