import { connect } from 'react-redux';
import { logout, clearLogoutFlash } from '../actions/userActions';
import UserHeader from '../components/Shared/UserHeader';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    logoutFlash: state.user.logoutFlash,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    _logout: () => {
      dispatch(logout());
    },
    _clearLogoutFlash: () => {
      dispatch(clearLogoutFlash());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserHeader);
