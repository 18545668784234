import React from 'react';

import dayjs from 'dayjs';
import StepsList from './Steps/StepsList';
import ProgressBar from './ProgressBar/ProgressBar';

interface claimRoadMapProps {
  isProcessing?: boolean;
  isResolution?: boolean;
  formState: any;
  imageLink: string;
  contractName: string;
  contractId: string;
  contractExpiry: string;
  contractTerm: string;
  contractAdh: boolean;
  propsEmail: string;
  propsPhone: string;
  pid: string;
  cid: string;
  optionalStepIncluded?: boolean;
  selectStep: (step: number) => any;
  selectPrevStep: (step: number) => any;
  submitClaim: (e: any) => any;
  currentStep: any;
  navigateToStep: (stepName: string) => any;
  partsStepIncluded: boolean;
}

const ClaimRoadMap = ({
  imageLink,
  contractName,
  contractId,
  contractExpiry,
  contractAdh,
  contractTerm,
  propsEmail,
  propsPhone,
  pid,
  cid,
  isResolution,
  formState: {
    step,
    completed,
    handoffType,
    email,
    phone,
    secondaryPhone,
    issueTypes,
    affectedParts,
    description,
    fileNames,
    failureDate,
  },
  selectStep,
  selectPrevStep,
  optionalStepIncluded,
  navigateToStep,
  partsStepIncluded,
}: claimRoadMapProps) => {
  const formattedDate = failureDate ? failureDate.split('-') : [];
  formattedDate.push(formattedDate[0]);
  formattedDate.shift();

  const issueLabel = issueTypes.map((it: any) => (it.label)).join(', ');
  const partsInfo = affectedParts.map((part: any) => (part.name)).join(', ');
  const filesProvided = Boolean(fileNames.join(''));
  const isReview = step < 0 && !handoffType;

  let productName = '';
  if (contractName) {
    productName = contractName.length > 40
      ? `${contractName.slice(0, 37)}...`
      : contractName;
  }

  let expirationText = dayjs(contractExpiry).utc().local().format('MMMM Do, YYYY');
  if (contractAdh === false) {
    expirationText = `${contractTerm && contractTerm.toString() === '1' ? '1 year' : `${contractTerm} years`}`;
    expirationText += ` after the manufacturer's warranty`;
  }

  return (
    <nav className={`claim-form__nav ${handoffType ? 'desktop-hidden' : ''}`}>
      <div className='claim-form__nav__summary'>
        {!handoffType
          && <>
            <header className='claim-form__nav__header'>
              <div className='header-top'>
                <div
                  className='claim-form__nav__header__image'
                  style={{ backgroundImage: `url(${imageLink})` }}
                />
                <div className='claim-form__nav__header__product-info'>
                  <h2>File a claim</h2>
                  <h3>{productName}</h3>
                  <p>ID: {contractId}</p>
                  <p>Expires {expirationText}</p>
                  <a href={`/terms?pid=${pid}&cid=${cid}`} target='_blank' rel='noopener noreferrer'>See what your contract covers</a>
                </div>
              </div>
              <div className='header-bottom'>
                {!isReview
                  && <ProgressBar
                    selectStep={selectStep}
                    selectPrevStep={selectPrevStep}
                    currentStep={step}
                    optionalStepIncluded={optionalStepIncluded}
                    partsStepIncluded={partsStepIncluded}
                  />
                }
              </div>
            </header>

            {
              !isReview
              && <StepsList
                completed={ completed }
                step={ step }
                propsEmail={ propsEmail }
                email={ email }
                propsPhone={ propsPhone }
                phone={ phone }
                secondaryPhone={ secondaryPhone }
                issueLabel={ issueLabel }
                description={ description }
                isReview={ isReview }
                isResolution={ isResolution }
                isSummary={ false }
                optionalStepIncluded={ optionalStepIncluded }
                filesProvided={ filesProvided }
                affectedParts={ affectedParts }
                partsInfo={ partsInfo }
                fileNames={ fileNames }
                formattedDate={ formattedDate }
                navigateToStep={ navigateToStep }
                partsStepIncluded={ partsStepIncluded }
              />
            }

          </>
        }
      </div>
    </nav>
  );
};

export default ClaimRoadMap;
