/**
 * Product actions
 * Intended to get flags to show/hide functionality
 */

import http from '../config/http';

const GET_MERCHANT_SETTINGS_URL = '/merchant-settings';

export function loadingGetMerchantSettings() {
  return {
    type: 'LOADING_GET_MERCHANT_SETTINGS',
  };
}

export function updateGetMerchantSettings(settings:any) {
  return {
    type: 'UPDATE_GET_MERCHANT_SETTINGS',
    settings,
  };
}

export function failedGetMerchantSettings(err:any) {
  return {
    type: 'FAIL_GET_MERCHANT_SETTINGS',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getMerchantSettings(contractSaleId: string) {
  return (dispatch: any) => {
    dispatch(loadingGetMerchantSettings());
    return http.get(`${GET_MERCHANT_SETTINGS_URL}/${contractSaleId}`)
      .then((response) => {
        dispatch(updateGetMerchantSettings(response.data));
      })
      .catch((err) => {
        dispatch(failedGetMerchantSettings(err));
      });
  };
}
