import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import validator from 'validator';

import {
  FormGroup,
  Button,
  Input,
  Label,
  FormFeedback,
  FormText,
} from 'reactstrap';

interface ClaimContactProps {
  propsEmail: string;
  propsPhone: string;
  formState: {
    phone: string;
    email: string;
    secondaryPhone: string;
    isBusinessClaim: boolean | null;
    errors: any;
  };
  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  stepOptions: any[],
  productParts?: any[],
}

const ClaimContact = ({
  formState: {
    phone,
    email,
    secondaryPhone,
    isBusinessClaim,
    errors,
  },
  propsEmail,
  propsPhone,
  updateField,
  iterateStep,
} : ClaimContactProps) => {
  const emailValue = propsEmail || email;
  let phoneValue = phone;

  const emailDisabled = Boolean(propsEmail);
  let phoneDisabled = false;

  // Marker 2022-03-02 EK: This is temporary validation to deal with any breakage that might happen with an invalid phone number
  // See MRCY-675 for details. Must be removed when we add proper Error handling and validation to HI-c
  if (propsPhone && validator.isMobilePhone(propsPhone, 'any')) {
    phoneValue = propsPhone;
    phoneDisabled = true;
  }

  const [phoneValid, setPhonevalid] = useState(validator.isMobilePhone(phoneValue, 'any'));

  useEffect(() => {
    setPhonevalid(validator.isMobilePhone(phoneValue, 'any'));
  }, [phoneValue]);

  const sendChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      setPhonevalid(validator.isMobilePhone(value, 'any'));
    }
    updateField(name, value);
  };

  const isCustomerClass = classnames(
    'radio-button-container__item',
    { selected: isBusinessClaim === false },
  );

  const isBusinessClass = classnames(
    'radio-button-container__item',
    { selected: isBusinessClaim },
  );

  return (
    <section className={`claim-form__step claim-form__contact-info`}>
      <h2>File a claim</h2>
      <h3>Contact Info</h3>
      <p>We'll use this info to contact you if we have more questions</p>
      <form onSubmit={iterateStep()}>
        <FormGroup>
          <Label>Email</Label>
          { emailDisabled
            && <FormText className='claim-form__step__input-explain' color='muted' inline>
              We have your email from time of purchase.
            </FormText>
          }
          <Input
            name='email'
            data-test='email'
            value={emailValue}
            onChange={sendChange}
            disabled={emailDisabled}
            invalid={errors.includes('email')}
          />
          <FormFeedback className='claim-form__step__input-feedback'>Please input a valid email</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>Phone Number</Label>
          { phoneDisabled
            && <FormText color='muted' className='claim-form__step__input-explain' inline>
              We have your phone number from time of purchase.
            </FormText>
          }
          <Input
            name='phone'
            value={phoneValue}
            onChange={(e) => sendChange(e)}
            disabled={phoneDisabled}
            invalid={errors.includes('phone') || (!phoneValid && phoneValue !== '') }
            data-test='phoneNumber'
          />
          <FormFeedback className='claim-form__step__input-feedback'>Please input a valid phone number</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label>
            Secondary Phone Number (optional)
            <FormText color='muted'>
              We’ll try you at this number if we have trouble reaching you for more info about your claim.
            </FormText>
          </Label>
          <Input
            name='secondaryPhone'
            value={secondaryPhone}
            onChange={sendChange}
            invalid={errors.includes('secondaryPhone')}
            data-test='secondaryPhoneNumber'
          />
          <FormFeedback>Please input a valid phone number or empty this field</FormFeedback>
        </FormGroup>
        <FormGroup tag='fieldset'>
          <span>Did the incident occur from personal or commercial use of the product?</span>
          <div className="radio-button-container">
            <div className={ isCustomerClass } data-test='personal'>
              <Label check className='claim-form__form__additional-question-label'>
                <Input
                  name='isBusinessClaim'
                  onChange={ (e) => { updateField(e.target.name, false); } }
                  checked={ isBusinessClaim === false }
                  type='radio'
                />
                <span>Personal</span>
              </Label>
            </div>
            <div className={ isBusinessClass } data-test='commercial'>
              <Label check className='claim-form__form__additional-question-label'>
                <Input
                  name='isBusinessClaim'
                  onChange={ (e) => { updateField(e.target.name, true); } }
                  checked={ Boolean(isBusinessClaim) }
                  type='radio'
                />
                <span>Commercial</span>
              </Label>
            </div>
          </div>
        </FormGroup>
        { errors.includes('isBusinessClaim')
          && <span className='claim-form__contact-step__error'>Please input an option</span>
        }
        <FormGroup>
          <Button
            className="button--general claim-form__step__next-button"
            onClick={iterateStep()}
            disabled={ errors.length > 0 || !phoneValid }
            data-test='next'
          >
            Next
          </Button>
        </FormGroup>
      </form>
    </section>
  );
};

export default ClaimContact;
