import React from 'react';
import Img from 'react-image';
import coveredBySrc from '../../images/covered_by_xcover.svg';

interface CoBrandingProps {
  imgSrc: string;
}

const CoBranding: React.FC<CoBrandingProps> = (props) => {
  const { imgSrc } = props;
  return (
    imgSrc
      ? <div className="co-branding co-branding--double" >
          <div className="co-branding__left" >
            <Img alt="" src={ imgSrc } />
          </div>
          <div className="co-branding__center"></div>
          <div className="co-branding__right" >
            <Img alt="Covered by XCover" src={ coveredBySrc } />
          </div>
        </div>
      : <div className="co-branding co-branding--single" >
          <Img alt="Covered by XCover" src={ coveredBySrc } />
        </div>
  );
};

export default CoBranding;
