import React from 'react';

import CommercialFurniture from './CommercialFurniture';
import ConsumerElectronics from './ConsumerElectronics';
import Furniture from './Furniture';
import Test from '../../../../containers/TestTerms';

import { UnderwriterProps } from '../../../../types/TermsTypes';

const Safeware: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'commercialFurniture':
      Terms = <CommercialFurniture contractTerms={ contractTerms } />;
      break;
    case 'consumerElectronics':
      Terms = <ConsumerElectronics contractTerms={ contractTerms } />;
      break;
    case 'furniture':
      Terms = <Furniture contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <Test />;
      break;
  }

  return Terms;
};

export default Safeware;
