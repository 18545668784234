import React from 'react';
import {
  Input, FormGroup, FormFeedback, Button,
} from 'reactstrap';

interface ClaimDateProps {
  formState: {
    failureDate: string;
    errors: any[];
  };
  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  stepOptions: any[],
  productParts?: any[],
}

const ClaimDate = ({
  updateField,
  iterateStep,
  formState: {
    failureDate,
    errors,
  },
} : ClaimDateProps) => (
  <section className={`claim-form__step claim-form__failure-date`}>
    <h2>File a claim</h2>
    <h3>Failure Date</h3>
    <p>When did your product stop functioning properly?</p>
    <form>
      <FormGroup>
        <Input
          type='date'
          name='failureDate'
          placeholder='MM/DD/YYYY'
          className='claim-form__date__input'
          value={failureDate}
          onChange={(e) => updateField(e.target.name, e.target.value)}
          invalid={errors.length > 0}
          data-test='failureDate'
        />
        <FormFeedback>{ errors[0] }</FormFeedback>
      </FormGroup>
      <FormGroup>
          <Button
            className='button--general claim-form__step__next-button'
            onClick={iterateStep()}
            disabled={errors.length > 0}
            data-test='reviewClaim'
          >
            Review Claim
          </Button>
        </FormGroup>
    </form>
  </section>
);

export default ClaimDate;
