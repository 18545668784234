import React, { useState, useEffect } from 'react';
import {
  FormGroup, Button, Label, Input, FormFeedback,
} from 'reactstrap';
import { isEmail, isMobilePhone, isPostalCode } from 'validator';

import classnames from 'classnames';
import { ReactComponent as VenmoLogo } from '../../../../images/venmo-logo.svg';
import { ReactComponent as PaypalLogo } from '../../../../images/paypal-logo.svg';

import { getProvincesByCountry, countryCodes } from '../../../Shared/regionCodes';

import ClaimSuccessInfo from './ClaimSuccessInfo';
import ResolutionError from './ResolutionError';

interface payoutProps {
  payoutType: string;
  payoutStep: string;
  addressConfirmed: boolean | null;
  customerName: string;
  phone: string;
  email: string;
  listedAddress: {
    address1: string,
    city: string,
    state: string,
    zip: string,
  };
  hasBankAccountConnected: boolean;
  resolutionError: any;
  resolutionSuccess: boolean;
  _closeModal: any;
  _setPayoutType: any;
  _setPayoutStep: any;
  _confirmAddress: any;
  _setErrors: any;
  _submitResolution: any;
  _submitUserBilling: any;
}

interface payoutAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  addressReason: string;
}

const PayoutSnippet = ({
  payoutType,
  payoutStep,
  addressConfirmed,
  email,
  phone,
  customerName,
  listedAddress,
  hasBankAccountConnected,
  resolutionError,
  resolutionSuccess,
  _closeModal,
  _setPayoutType,
  _setPayoutStep,
  _confirmAddress,
  _submitResolution,
}: payoutProps) => {
  const [payoutContact, setContact] = useState('');
  const [addresseeName, setAddresseeName] = useState('');
  const [checked, setChecked] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  // eslint-disable-next-line no-shadow
  const [payoutAddress, setPayoutAddress] = useState<payoutAddress>({
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    addressReason: '',
  });

  const submitAddress = () => {
    setErrors([...errors]);
    const country = payoutAddress.country || 'US';
    const {
      city, state, zip, address1, addressReason,
    } = payoutAddress;

    if (addresseeName.length < 3) {
      errors.push('addressee');
    }

    if (addressConfirmed && !errors.length) {
      const submitResolutionFunction = _submitResolution(
        payoutContact,
        { addressConfirmed, customerName: addresseeName },
      );
      submitResolutionFunction();
      return;
    } if (errors.length) {
      return;
    }

    if (address1.length < 4) {
      errors.push('address1');
    }

    if (city.length < 3) {
      errors.push('city');
    }

    if (countryCodes.indexOf(country) < 0) {
      errors.push('country', 'state', 'zip');
    } else { // validator will error on invalid country
      if (getProvincesByCountry(country).indexOf(state.toUpperCase()) < 0) {
        errors.push('state');
      }
      if (country === 'CA' || country === 'US') { // get around PostalCodeLocale type not exported from validators
        if (!isPostalCode(zip, country) || !zip) {
          errors.push('zip');
        }
      }
    }

    if (addressReason.length < 5) {
      errors.push('addressReason');
    }

    if (!errors.length) {
      const submitResolutionFunction = _submitResolution(payoutContact, {
        addressConfirmed,
        customerName: addresseeName,
        ...payoutAddress,
      });
      submitResolutionFunction();
      return;
    }

    setErrors(errors);
  };

  useEffect(() => {
    if (payoutType === 'venmo') {
      setContact(phone || '');
    } else if (payoutType === 'paypal') {
      setContact(email || '');
    } else if (payoutType === 'check') {
      setContact(email || '');
      setAddresseeName(customerName || '');
    }
  }, [payoutType, email, phone, customerName]);

  let inputValid = false;
  if (payoutType === 'paypal' && isEmail(payoutContact)) inputValid = true;
  if (payoutType === 'venmo' && isMobilePhone(payoutContact, 'any')) inputValid = true;
  if (payoutType === 'ach' && hasBankAccountConnected) inputValid = true;

  const updatePayoutAddress = (e: any) => {
    const { name } = e.currentTarget;
    let { value } = e.currentTarget;
    const newErrors: string[] = errors.filter((error: string) => error !== name);
    if (name === 'country' || name === 'state') {
      value = value.toUpperCase();
    }
    setErrors(newErrors);
    setPayoutAddress({ ...payoutAddress, [name]: value });
  };

  const updateAddressee = (e: any) => {
    const { value } = e.currentTarget;
    const newErrors: string[] = errors.filter((error: string) => error !== 'addressee');
    if (value.length < 3) {
      newErrors.push('addressee');
    }
    setErrors(newErrors);
    setAddresseeName(value);
  };

  const generateClass = (tileName: string) => {
    const defaultClass = 'claim-form__step__payout-select__tile';
    return tileName === payoutType
      ? `${defaultClass} claim-form__step__payout-select__tile--selected`
      : defaultClass;
  };

  if (resolutionSuccess) {
    return (
      <ClaimSuccessInfo
        claimInfo={{ claimState: 'payoutSuccess', payoutType }}
        contract={{}}
        _closeModal={ _closeModal }
      />
    );
  }

  if (resolutionError.message && !resolutionSuccess) {
    return (
      <ResolutionError type='payout' _closeModal={ _closeModal } />
    );
  }
  if (payoutStep === 'checkPayoutForm') {
    return (
      <section className='claim-form__step claim-form__step--approved-step '>
        <h4>Home Address</h4>
        <FormGroup>
          <div className='claim-form__payout__addressee'>
            <Label>Who should this be addresssed to?</Label>
            <Input
              name='addressee'
              type='text'
              placeholder='Your name'
              value={ addresseeName }
              onChange={(e) => updateAddressee(e)}
              invalid={ errors.indexOf('addressee') >= 0 }
            />
            <FormFeedback>Please enter the full name of the addressee</FormFeedback>
          </div>
          <span>Is this the correct mailing address?</span>
          <p>{ listedAddress.address1 }, { listedAddress.city }, { listedAddress.state } { listedAddress.zip }</p>
          <FormGroup>
            <Button
              onClick={ _confirmAddress(true) }
              className={classnames(
                'claim-form__step--check__address-button',
                { 'claim-form__step--check__address-button--selected': addressConfirmed },
              )}
            >
              Yes
            </Button>
            <Button
              onClick={ _confirmAddress(false) }
              className={classnames(
                'claim-form__step--check__address-button',
                { 'claim-form__step--check__address-button--selected': addressConfirmed === false },
              )}
            >
              No
            </Button>
          </FormGroup>
          <p>Mailing times can vary. Typical mailing times range between 8-10 business days.</p>
          { addressConfirmed === false
            && <>
              <FormGroup>
              <Label>What address should we send it to?</Label>
              <div className='claim-form__payout__address__row'>
                <div className='payout__address__row__cell--large'>
                  <Label>Address 1</Label>
                  <Input
                    name='address1'
                    placeholder='123 Main St'
                    value={ payoutAddress.address1 }
                    onChange={ updatePayoutAddress }
                    invalid={ errors.indexOf('address1') >= 0 }
                  />
                  <FormFeedback>Please enter an address</FormFeedback>
                </div>
                <div>
                  <Label>Address 2</Label>
                  <Input
                    name='address2'
                    placeholder='Apt #'
                    value={ payoutAddress.address2 }
                    onChange={ updatePayoutAddress }
                  />
                </div>
              </div>

              <div>
                <Label>City</Label>
                <Input
                  name='city'
                  placeholder='Your town'
                  value={ payoutAddress.city }
                  onChange={ updatePayoutAddress }
                  invalid={ errors.indexOf('city') >= 0 }
                />
                <FormFeedback>Please enter your city</FormFeedback>
              </div>

              <div className='claim-form__payout__address__row'>
                <div>
                  <Label>State</Label>
                  <Input
                    name='state'
                    placeholder='AA'
                    value={ payoutAddress.state }
                    onChange={ updatePayoutAddress }
                    maxLength={ 2 }
                    invalid={ errors.indexOf('state') >= 0 }
                  />
                  <FormFeedback>Please enter a 2-digit state code</FormFeedback>
                </div>
                <div>
                  <Label>Postal Code</Label>
                  <Input
                    name='zip'
                    placeholder='#####'
                    value={ payoutAddress.zip }
                    onChange={ updatePayoutAddress }
                    invalid={ errors.indexOf('zip') >= 0 }
                  />
                  <FormFeedback>Please enter a valid zip</FormFeedback>
                </div>
                <div>
                  <Label>Country Code</Label>
                  <Input
                    name='country'
                    placeholder='US'
                    value={ payoutAddress.country }
                    onChange={ updatePayoutAddress }
                    invalid={ errors.indexOf('country') >= 0 }
                    maxLength={ 2 }
                  />
                  <FormFeedback>Only US / CA are supported</FormFeedback>
                </div>
                </div>
                <Label>Why is the address different?</Label>
                <Input
                  name='addressReason'
                  placeholder='It is different because...'
                  value={ payoutAddress.addressReason }
                  onChange={ updatePayoutAddress }
                  invalid={ errors.indexOf('addressReason') >= 0 }
                />
                <FormFeedback>Please provide a reason</FormFeedback>
            </FormGroup>
            <p>
            Changing mailing addresses can cause a delay in fulfillment, as we want to be sure that you're shipping it to yourself.
            </p>
            <div className='claim-form__payout__check'>
              <input
                id='verify-claim-form-info'
                type='checkbox'
                className='claim-form__payout__check__input'
                checked={ checked }
                onChange={ () => setChecked(!checked) } />
              <label htmlFor="verify-claim-form-info">
                I confirm that the information I am providing is correct.
              </label>
            </div>
          </>
          }
        </FormGroup>
          <FormGroup>
            <Button
              className='button--general claim-form__step__payout-button'
              onClick={ submitAddress }
              disabled={ (!addressConfirmed && !checked) || errors.length !== 0 }
            >
              Submit
            </Button>
          </FormGroup>
      </section>
    );
  }
  return (
    <section className='claim-form__step claim-form__step--approved-step '>
      <h4>How would you like to be paid?</h4>
      <div className='claim-form__step__payout-select'>
        <div
          className={ generateClass('paypal') }
          onClick={_setPayoutType('paypal')}
        >
          <PaypalLogo />
        </div>
        <div
          className={ generateClass('venmo') }
          onClick={_setPayoutType('venmo')}
        >
          <VenmoLogo />
        </div>
        { /*
          ACH is diabled for now -- uncomment later
          <div
            className={ generateClass('ach') }
            onClick={_setPayoutType('ach')}
          >
            <h4>Bank Transfer</h4>
            <p>(3-5 business days)</p>
          </div>
        */ }
      </div>
      <p className='claim-form__step__payout-info' />

      <FormGroup>
        { payoutType === 'paypal'
          && <>
            <Label>Confirm Your Paypal Account Email</Label>
            <Input
              name='email'
              type='email'
              value={ payoutContact }
              onChange={(e) => setContact(e.target.value)}
            />
          </>
        }
        { payoutType === 'venmo'
          && <>
            <Label>Confirm the Phone Number of Your Venmo Account</Label>
            <Input
              name='email'
              type='tel'
              value={ payoutContact }
              onChange={(e) => setContact(e.target.value)}
            />
          </>
        }
        { /*
          ACH is diabled for now -- uncomment later

          payoutType === 'ach' && !hasBankAccountConnected &&
          <>
            <Label>You haven't connected an account yet</Label>
            <PlaidModalButton
              hasBankAccountConnected={ hasBankAccountConnected }
              _onPlaidSuccess={ _submitUserBilling }
            />
          </>
        */ }
      </FormGroup>

      <FormGroup>
        <Button
          className='button--general claim-form__step__payout-button'
          onClick={ payoutType === 'check' ? _setPayoutStep('checkPayoutForm')
            : _submitResolution(payoutContact) }
          disabled={ (payoutType.length <= 0 || !inputValid) && payoutType !== 'check' }
        >
          Submit
        </Button>
      </FormGroup>
      <p className='claim-form__step__payout-info'>
        * Please note that upon the resolution of this claim, your Clyde Protection Plan will be considered fulfilled and expired.
      </p>
    </section>
  );
};

export default PayoutSnippet;
