import { connect } from 'react-redux';

import {
  getProduct,
} from '../actions/productActions';

import {
  getClaim,
  resetSelectedClaim,
} from '../actions/claimActions';

import ClaimDetailPage from '../components/Claim/ClaimDetailPage';
import { cancelModal, showModal } from '../actions/modalActions';

function mapStateToProps(state: any) {
  return {
    selectedProduct: state.product.selectedProduct,
    claim: state.claim.claim,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _getClaim: (claimId: string) => {
      dispatch(getClaim(claimId));
    },
    _getProduct: (contractSaleId: string) => {
      dispatch(getProduct(contractSaleId));
    },
    _showModal: (modalID: string) => {
      dispatch(showModal(modalID));
    },
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _resetSelectedClaim: () => {
      dispatch(resetSelectedClaim());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimDetailPage);
