import React from 'react';
import { Link } from 'react-router-dom';

interface TestTermsProps {
  testTerms: any;
  _getTestContracts: any;
}

class Test extends React.Component<TestTermsProps> {
  componentDidMount() {
    this.props._getTestContracts();
  }

  createContractName = (key: string) => {
    const result = key.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
          <h3>Hey there! Looks like we connected your store with test contracts.</h3>
          <br />
          <p><strong>Often, we'll do this because we're not sure which kind of contracts to use on your site or you're in the process of building your store. Please send an email to <a href="mailto:hello@joinclyde.com">hello@joinclyde.com</a> when you're ready for us to match! In the meantime, customize the look and feel of your offering using <a href="https://app.joinclyde.com/customize">app.joinclyde.com/customize</a>.</strong></p>
          <br />
          <p>Here are a few examples of contracts so you can get the look and feel :-)</p>
          {
            this.props.testTerms.map((testTerm: any) => (
              <p key={testTerm.contractId}>
                <strong>{ this.createContractName(testTerm.termsAndConditionsKey) } Contract:</strong>
                <br />
                <Link to={ `/terms?cid=${testTerm.contractId}` }>https://www.hiclyde.com/terms?cid={ testTerm.contractId }</Link>
              </p>
            ))
          }
          <br />
        </div>
      </div>
    );
  }
}

export default Test;
