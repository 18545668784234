import React from 'react';

import Ebikes from './Ebikes';
import NotebookLaptopProtection from './NotebookLaptopProtection';
import JewelryWatchPlatinum from './JewelryWatchPlatinum';
import ElectronicsProtection from './ElectronicsProtection';

import { UnderwriterProps } from '../../../../types/TermsTypes';
import PDFOnlyTerms from '../PDFOnlyTerms';

const Amynta: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'ebikes':
      Terms = <Ebikes contractTerms={ contractTerms } />;
      break;
    case 'notebookLaptopProtection':
      Terms = <NotebookLaptopProtection contractTerms={ contractTerms } />;
      break;
    case 'jewelryWatchPlatinum':
      Terms = <JewelryWatchPlatinum contractTerms={ contractTerms } />;
      break;
    case 'electronicsProtection':
      Terms = <ElectronicsProtection contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <PDFOnlyTerms contractTerms={ contractTerms } />;
      break;
  }

  return Terms;
};

export default Amynta;
