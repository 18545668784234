import React from 'react';
import { ReactComponent as Checkmark } from '../../../../images/nav-check.svg';

interface StepsListProps {
  completed: number;
  step: number;
  propsEmail: string;
  email: string;
  propsPhone: string;
  phone: string;
  secondaryPhone: string;
  issueLabel: string;
  description: string;
  isReview: boolean;
  isResolution: boolean | undefined;
  isSummary: boolean;
  optionalStepIncluded: boolean | undefined;
  filesProvided: boolean;
  affectedParts: any[];
  partsInfo: any[];
  fileNames: any[];
  formattedDate: any[];
  navigateToStep: (stepName: string) => any;
  partsStepIncluded: boolean;
}

const StepsList = ({
  isReview,
  completed,
  step,
  isResolution,
  affectedParts,
  partsInfo,
  navigateToStep,
  isSummary,
  propsEmail,
  email,
  propsPhone,
  phone,
  secondaryPhone,
  issueLabel,
  description,
  optionalStepIncluded,
  filesProvided,
  fileNames,
  formattedDate,
  partsStepIncluded,
}: StepsListProps) => {
  let descriptionStep = 2;
  let documentationStep = 3;
  let failureDateStep = 4;

  if (optionalStepIncluded) {
    descriptionStep += 1;
    documentationStep += 1;
    failureDateStep += 1;
  }

  if (partsStepIncluded) {
    descriptionStep += 1;
    documentationStep += 1;
    failureDateStep += 1;
  }

  const steps = [
    {
      label: 'Contact Info',
      data: <>
        { propsEmail || email }
        { (propsPhone || phone) && <><br/> {(propsPhone || phone)}</> }
        { secondaryPhone && <><br/> {secondaryPhone}</> }
      </>,
      stepNumber: 0,
      stepName: 'ClaimContact',
    },
    {
      label: 'Issue Types',
      data: issueLabel,
      stepNumber: 1,
      stepName: 'ClaimType',
    },
    {
      label: 'Description',
      data: description,
      stepNumber: descriptionStep,
      stepName: 'ClaimDescription',
    },
    {
      label: 'Documentation',
      data: filesProvided
        ? <>
          { fileNames.map((fileName: string, idx: number) => (
            <div key={`roadmap-file-${idx}`}>
              { fileName.split('\\').pop() }
            </div>
          ))}
        </> : 'None provided.',
      stepNumber: documentationStep,
      stepName: 'ClaimDocumentation',
    },
    {
      label: 'Failure Date',
      data: formattedDate.join('/'),
      stepName: 'ClaimDate',
      stepNumber: failureDateStep,
    },
  ];

  return (
    <ul className='claim-form__nav__step-list'>
    { steps.map((stepData) => {
      let className = 'claim-form__nav__step';
      if (stepData.stepNumber <= completed) {
        className += ' completed';
      }
      if (stepData.stepNumber === step) {
        className += ' active';
      }

      return (
        <li
          key={`${stepData.label}-${stepData.stepName}`}
          className={ className }
        >
          <div className='claim-form__nav__step__header' onClick={navigateToStep(stepData.stepName)}>
            <span className='claim-form__nav__step__dot'>
              { <Checkmark height='70%' width='70%' /> }
            </span>
            <div className='claim-form__nav__item-header__label' data-test='claimStep'>
              <h4>{ stepData.label }</h4>
              {(completed > stepData.stepNumber || isReview) && !isResolution && <button data-test='edit'>Edit</button> }
            </div>
          </div>
          { (stepData.stepNumber < completed || isReview || isResolution)
            && <div className='claim-form__nav__step__data'>{ stepData.data }</div>
          }
          { stepData.stepNumber === 1 && affectedParts.length > 0 && isReview
            && <>
              <h4 className='claim-form__step__title--margin-left'>Affected Parts</h4>
              <div className='claim-form__nav__step__data'>{ partsInfo }</div>
            </>
          }
        </li>
      );
    }) }

    { isSummary && isResolution
      && <li className='claim-form__nav__step completed'>
        <div className='claim-form__nav__step__header'>
          <span className='claim-form__nav__step__dot'>
            <Checkmark height='70%' width='70%' />
          </span>
          <div className='claim-form__nav__item-header__label'>
            <h4>Claim complete</h4>
          </div>
        </div>
      </li>
    }
  </ul>
  );
};

export default StepsList;
