import { connect } from 'react-redux';
import {
  createUser,
  authUser,
  googleOauthUser,
  failGoogleOauthUser,
  requestPassword,
  changePassword,
  getCSRF,
  clearAuthErrors,
  resendVerify,
  setPasswordResetSuccessFalse,
  setPasswordChangeSuccessFalse,
} from '../actions/userActions';
import Auth from '../components/Auth';

function mapStateToProps(state: any) {
  return {
    user: state.user,
    error: state.user.error,
    passwordResetSuccess: state.user.passwordResetSuccess,
    csrf: state.user.csrf,
    isProcessing: state.user.isProcessing,
    changeSuccess: state.user.changeSuccess,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _createUser: (user: any, csrf: string) => {
      dispatch(createUser(user, csrf));
    },
    _authUser: (user: any, csrf: string) => {
      dispatch(authUser(user, csrf));
    },
    _googleOauthUser: (token: string) => {
      dispatch(googleOauthUser(token));
    },
    _failGoogleOauthUser: () => {
      dispatch(failGoogleOauthUser());
    },
    _requestPassword: (user: any, csrf: string) => {
      dispatch(requestPassword(user, csrf));
    },
    _changePassword: (user: any, csrf: string) => {
      dispatch(changePassword(user, csrf));
    },
    _resendVerify: (email: string, csrf: string) => {
      dispatch(resendVerify(email, csrf));
    },
    _getCSRF: () => {
      dispatch(getCSRF());
    },
    _clearAuthErrors: () => {
      dispatch(clearAuthErrors());
    },
    _setPasswordResetSuccessFalse: () => {
      dispatch(setPasswordResetSuccessFalse());
    },
    _setPasswordChangeSuccessFalse: () => {
      dispatch(setPasswordChangeSuccessFalse());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Auth);
