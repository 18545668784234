import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ReactComponent as ClaimSuccessImg } from '../../images/checkout-success.svg';
import CloseModalButton from '../Shared/CloseModalButton';

interface CheckoutSuccessProps {
  isUser: boolean;
  _closeModal: any;
  _resetCheckout: any;

  isPlural?: boolean;
}

const CheckoutSuccess = (props: CheckoutSuccessProps) => {
  const pCopy = props.isUser
    ? `In the next few minutes you'll receive an order confirmation email from Clyde. In the meantime, head over 
    to your products page to review terms and conditions.`
    : `In the next few minutes you'll receive an order confirmation email from Clyde. In the meantime, create an 
    account to track your claims and get real-time updates on claim status.`;

  const theButton = props.isUser
    ? <Link to="/products" >
        <Button id="loggedin-checkout-success" onClick={ props._resetCheckout } className="button--general">Go to Products</Button>
      </Link>
    : <Link to="/signup" >
        <Button id="loggedout-checkout-success" onClick={ props._resetCheckout } className="button--general">Create an account</Button>
      </Link>;

  return (
    <section className="checkout-success-modal">
      <div className="checkout-success-modal__content" data-test="checkoutSuccessModal">
        <CloseModalButton onClick={ props._closeModal } className='clyde-modal__close'/>
        <div className="checkout-success-modal__content__left">
          <ClaimSuccessImg />
        </div>
        <div className="checkout-success-modal__content__right">
          <h2>Your product{ props.isPlural ? 's are' : ' is '} covered</h2>
          <p>{ pCopy }</p>
          { theButton }
        </div>
      </div>
    </section>
  );
};

export default CheckoutSuccess;
