const applyMask = (value: string) => {
  if (!value) {
    return '';
  }
  const x: any = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  const cleanedValue = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
  return cleanedValue;
};

export default applyMask;
