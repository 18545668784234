import React from 'react';

import CoBranding from '../../Shared/CoBranding';
import SummaryTiles from '../SummaryTiles';
import Amynta from './Amynta';
import Asurion from './Asurion';
import Fortegra from './Fortegra';
import Guardsman from './Guardsman';
import NewLeaf from './NewLeaf';
import Safeware from './Safeware';
import Velocare from './Velocare';
import Uniters from './Uniters';
import Hornbeam from './Hornbeam';
import Test from './Test';
import PDFOnlyTerms from './PDFOnlyTerms';

export interface TemplatesProps {
  contractTerms: any;
  isModal: boolean;
  _changeTermsKey:any;
}

class Templates extends React.Component<TemplatesProps> {
  componentDidUpdate(prevProps: any) {
    if (prevProps.contractTerms.underwriterName !== this.props.contractTerms.underwriterName) {
      this.underWriterSwitcher();
    }
  }

  underWriterSwitcher = () => {
    const { contractTerms, _changeTermsKey } = this.props;
    if (contractTerms.underwriterName !== 'Test'
      && contractTerms?.pdfUrl) {
      return <PDFOnlyTerms contractTerms={ contractTerms } />;
    }

    switch (contractTerms.underwriterName) {
      case 'Amynta':
        return <Amynta contractTerms={ contractTerms } />;
      case 'Asurion':
        return <Asurion contractTerms={ contractTerms } />;
      case 'Fortegra':
        return <Fortegra contractTerms={ contractTerms } />;
      case 'Guardsman':
        return <Guardsman contractTerms={ contractTerms } _changeTermsKey={ _changeTermsKey } />;
      case 'New Leaf':
        return <NewLeaf contractTerms={ contractTerms } />;
      case 'Safeware':
        return <Safeware contractTerms={ contractTerms } />;
      case 'Velocare':
        return <Velocare contractTerms={ contractTerms } />;
      case 'Uniters':
        return <Uniters contractTerms={ contractTerms } />;
      case 'Hornbeam':
        return <Hornbeam contractTerms={ contractTerms } />;
      case 'Test':
      default:
        return <Test contractTerms={ contractTerms } />;
    }
  };

  render() {
    const { contractTerms, isModal } = this.props;

    let termsButton = null;
    if (contractTerms?.underwriterName && !isModal) {
      const termsPDFUrl = contractTerms?.pdfUrl
        ? contractTerms?.pdfUrl
        : `/TermsAndConditions/${contractTerms.underwriterName.replace(' ', '')}/${contractTerms.termsKey}.pdf`;

      termsButton = (
        <div className='contract-terms-page--download-btn-container'>
          <a
            className={ `${contractTerms?.termsKey === 'test' ? 'link--disabled' : ''}` }
            href={ termsPDFUrl }
            target="_BLANK"
            rel="noreferrer">
            <button className={ `btn contract-terms-page--download-btn ${contractTerms?.termsKey === 'test' ? 'button--disabled' : 'button--general'}` } >
              View and Download PDF Terms
            </button>
          </a>
        </div>
      );
    }
    return (
      <div className={ `terms ${isModal ? 'terms--modal' : 'terms--static'}` }>
        <CoBranding imgSrc={ contractTerms.shopLogo } />
        <SummaryTiles contractTerms={ contractTerms } isModal={ isModal } />
        <div className='terms-container'>
          <h1 className='terms-header'>Terms &amp; Conditions</h1>
          <p className='terms-sub-header'>Please read this Agreement carefully, as it describes the protection You will receive in return for payment by You.</p>
          { this.underWriterSwitcher() }
        </div>
        { termsButton }
      </div>
    );
  }
}

export default Templates;
