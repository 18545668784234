import React from 'react';
import { Redirect } from 'react-router-dom';
import UserHeader from '../../containers/UserHeader';
import FindContractForm from './FindContractForm';
import GuestProductContract from './GuestProductContract';
import LoggedOutFooter from '../Shared/LoggedOutFooter';

interface GuestContractProps {
  _getContract: any;
  _resetSelectedContract: any;
  _showModal: any;
  _cancelModal: any;
  _changeTermsKey:any;
  _createAdminHandoffClaim:any;
  findContractError: any;
  selectedContract?: any;
  contractTerms?: any;
  match?: any;
  isUser?: boolean;
  newClaim?: any;
}

class GuestContract extends React.Component<GuestContractProps> {
  componentDidMount() {
    document.title = 'Clyde | Find Your Contract';
    const { hash } = this.props.match.params;
    if (hash) {
      this.props._getContract(hash);
    }
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.props.isUser) {
      return <Redirect to="/products" />;
    }

    return (
      <React.Fragment>
        <main className="app-container">
          <UserHeader />
          {
            this.props.selectedContract
              ? <GuestProductContract
                  contractTerms={ this.props.contractTerms }
                  product={ this.props.selectedContract || undefined }
                  newClaim={ this.props.newClaim }
                  _cancelModal={ this.props._cancelModal }
                  _changeTermsKey={ this.props._changeTermsKey }
                  _showModal={ this.props._showModal }
                  _resetSelectedContract={ this.props._resetSelectedContract }
                  _createAdminHandoffClaim={ this.props._createAdminHandoffClaim } />
              : <FindContractForm
                  _getContract={ this.props._getContract }
                  error={ this.props.findContractError } />
          }
        </main>
        <LoggedOutFooter isGuest={true} />
      </React.Fragment>
    );
  }
}

export default GuestContract;
