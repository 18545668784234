import React from 'react';
import { ReactComponent as LogoXCover } from '../../images/logo-clyde-xcover.svg';

interface ClydeFooterProps{
  isGuest? : boolean;
}

const ClydeFooter = (props: ClydeFooterProps) => {
  const year = (new Date()).getFullYear();
  return (
    <footer className={`clyde-footer ${props.isGuest ? 'clyde-footer__guest' : ''}`} >
      <div className='clyde-footer__logo'>
        <LogoXCover />
      </div>
      <div className='clyde-footer__content'>
        <div className='clyde-footer__content__copyright'>{ year } &copy; Clyde Technologies, Inc. All rights reserved</div>
        <div className='clyde-footer__content__links'>
          <a target='_blank' rel='noopener noreferrer' data-test='termsOfService' href='https://joinclyde.com/legal/terms-of-use'>Terms of Service</a>
          <a target='_blank' rel='noopener noreferrer' data-test='privacyPolicy' href='https://www.joinclyde.com/legal/privacy-policy'>Privacy Policy</a>
          <a target='_blank' rel='noopener noreferrer' data-test='joinClyde' href='https://www.joinclyde.com'>joinclyde.com</a>
        </div>
      </div>
    </footer>
  );
};

export default ClydeFooter;
