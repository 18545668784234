import React from 'react';
import TermsAccordion from '../../Shared/TermsAccordion';

import { TermsProps, TermsState } from '../../../../types/TermsTypes';

class Jewelry extends React.Component<TermsProps, TermsState> {
  constructor(props: TermsProps) {
    super(props);
    this.state = {
      cancellationOpen: false,
      coveredComponentsOne: false,
      coveredComponentsTwo: false,
      coveredOpen: false,
      definitionsOpen: false,
      exchangedGoodsOpen: false,
      exclusionsOpen: false,
      howServiceIsProvidedOpen: false,
      liabilityOpen: false,
      majorPlanCoveragesOpen: false,
      majorPlanExclusionsOpen: false,
      planTermsOpen: false,
      purchaserRecordsOpen: false,
      renewOpen: false,
      repeatServiceOpen: false,
      responsibilitiesOpen: false,
      transferabilityOpen: false,
    };
  }

  toggle = (section: string) => {
    this.setState((state: any) => ({ [section]: !state[section] }));
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
          <p>Please read this Agreement carefully. It describes the protection You will receive in return for payment by You. You must keep this Agreement, Your sales invoice and receipt for the product You purchased. They are integral parts of this Agreement and You may be required to produce them in order to obtain service. You must maintain the Covered Product as recommended by the manufacturer’s owner manual and warranty. Refer to the Declarations Page of this Agreement, or Your sales receipt or invoice to determine the term of this Agreement, whether You purchased a Replacement Plan, Repair Plan or Labor Only Plan, and if there is a deductible required to obtain service.</p>
          <p><strong>NOTICE:</strong> (1) The retailer selling You this Agreement may be retaining a portion of its selling price. The purchase of this Agreement is not required to either purchase Your product or to obtain financing. (2) Any person who knowingly and with intent to injure, defraud or deceive any insurer and files a statement of claim or any application containing false, incomplete or misleading information is guilty of a felony of the third degree.</p>
        </div>

        <TermsAccordion
          toggle={ this.state.definitionsOpen }
          _toggleFunc={ () => this.toggle('definitionsOpen') }
          header="Definitions"
          content={
            <React.Fragment>
              <ol>
                <li>‍<strong>"Obligor", "We", "Us" and "Our": </strong>The company obligated under this Agreement, 4warranty Corporation, [10151 Deerwood Park Blvd., Bldg. 100, Suite 500, Jacksonville Florida 32256 (800-867-2216)], in all states except in Florida and Oklahoma where it is LYNDON SOUTHERN INSURANCE COMPANY, [10151 Deerwood Park Blvd, Bldg. 100, Suite 500, Jacksonville, FL 32256 (800)888-2738], in Wisconsin where it is The Service Doc, [10151 Deerwood Park Blvd, Bldg. 100, Suite 500, Jacksonville, FL 32256 (800)888-2738] and in Washington where it is Dealers Alliance Corporation, 240 N. Fifth Street, Suite 350, Columbus, OH 43215.</li>
                <li><strong>"You" and "Your": </strong>The purchaser of the Covered Product(s) and any authorized transferee/assignee of the purchaser.</li>
                <li><strong>"Administrator": </strong>New Leaf Service Contracts, LLC, 909 Lake Carolyn Parkway, Suite 900, Irving, TX 75039 855.624.2512.</li>
                <li><strong>"Selling Retailer": </strong>The entity selling the Covered Product and this Agreement.</li>
                <li><strong>"Covered Product": </strong>The consumer item(s) which You purchased concurrently with and is covered by this Agreement.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.replaceOpen }
          _toggleFunc={ () => this.toggle('replaceOpen') }
          header="Replacement Plan"
          content={
            <React.Fragment>
              <ol>
                <li>‍<strong>TERM: </strong>For the Replacement Plan, the term of this Agreement begins on the date of purchase and continues for a period of two (2) years or until a claim is paid, whichever occurs first. Coverage is effective upon the expiration of the shortest portion of the manufacturer’s warranty. A renewal service Agreement is not available for the Replacement Plan. THIS AGREEMENT DOES NOT REPLACE THE MANUFACTURER’S WARRANTY.‍</li>
                <li><strong>COVERAGE: </strong>We will replace Your Covered Product, when required, due to a mechanical or electrical breakdown, including those experienced during normal wear and tear. A mechanical or electrical breakdown caused by a direct result of a power surge is also covered. The Covered Product will be replaced with a new or refurbished product of like kind or similar quality, up to the purchase price of the Covered Product, excluding sales tax and shipping and handling. The Covered Product must fail during normal usage. Any replacement product purchased with funds or provided as a result of a claim being paid under the terms of this Agreement will require the purchase of a new Agreement.‍</li>
                <li><strong>LIMIT OF LIABILITY: </strong>The limit of liability under the replacement plan is the value of the Covered Product at the time of purchase, excluding sales tax, diagnostic fees, delivery and handling. This Agreement shall expire upon replacement of Your Covered Product or issuance of a compensation check in lieu of replacement.</li>
                <li>‍<strong>What to do when Your Covered Product fails to Operate: </strong>Do not return the Covered Product to the Selling Retailer where You purchased Your Covered Product. Contact the Administrator and You will be advised on how to obtain replacement. Call the toll-free number at [855.624.2512] or go online to [www.newleafsc.net/jewelry/terms]. <br /><br />You must provide the original sales receipt in order for a claim to be processed. Products found to be non-defective will be returned to You. You are responsible for all costs of postage, insurance, packaging and shipping. Please make sure Your product is properly protected with bubble wrap or other protective materials. Replacement will not be provided if Your Covered Product is damaged in shipping.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.repairOpen }
          _toggleFunc={ () => this.toggle('repairOpen') }
          header="Repair Plan"
          content={
            <React.Fragment>
              <ol>
                <li><strong>TERM: </strong>For the Repair Plan, the term of this Agreement begins on the date of purchase and continues for the period indicated on the face of this Agreement or as indicated in the Declarations Page, Your sales receipt or invoice. Coverage is effective upon the expiration of the shortest portion of the manufacturer’s warranty. In the event Your Covered Product is being serviced by an authorized service center when this Agreement expires, the term of this Agreement will be extended until covered repair has been completed. THIS AGREEMENT DOES NOT REPLACE THE MANUFACTURER’S WARRANTY.</li>
                <li><strong>COVERAGE: </strong>Parts will be replaced with those of like kind and quality at our sole discretion. We may use new or remanufactured parts in repairing Your Covered Product. If the Covered Product cannot be repaired, if the cost of the repair exceeds the original purchase price, or if parts are no longer available or are discontinued by the manufacturer, the Covered Product will be replaced as determined by Us with a product of similar features.<br /><br />For Jewelry:  We will furnish the parts and labor necessary to repair or replace Your Covered Product only in the event of mechanical breakdown or defects in workmanship and/or materials, including normal wear and tear. We will cover broken, bent or worn prongs, clasps and hinges; knotted or broken links in necklaces and bracelets; broken or lost pins and earring posts; restringing of stretched pearl necklaces; and, reshanking. We will cover mechanical failure of watches, including accidental breakage of stem or band, case and crystal. Coverage also includes the following services for Your Product:, ring sizing as result of weight change; refinishing and polishing; rhodium plating white gold; earring repair; chain soldering; resetting Product diamonds and gemstones; repair of chipped or cracked stones (including center stones); and replacement for loss of diamond or gemstone center stones or side/enhancement stones up to a maximum of .50 carat, due to a defect in the setting.<br /><br />For Watches: If You purchased the Watch Plan, coverage includes necessary materials and labor costs to repair Your Product to a usable and wearable condition; provided, such repair is necessitated by Product wear during normal usage of the Product and under the conditions for which is was designed. Coverage also includes battery replacement and replacement for loss of stones from bezel up to a maximum of .10 carat.‍</li>
                <li><strong>LIMIT OF LIABILITY: </strong>Our limit of liability for Your Covered Product under the Repair Plan is the cost of authorized repairs, or replacement as determined by Us, with a product of similar features. In no event will our total liability for repairs and/ or replacement exceed Your purchase price for the Covered Product, excluding sales tax, diagnostic fees, delivery and installation costs. Upon replacement, there is no longer any obligation for the replaced product under this Agreement. Service costs, trip charges, breakdown charges, inspection fees, diagnostic fees or estimate charges for repairs not covered under this Agreement are Your responsibility.‍</li>
                <li><strong>NO LEMON POLICY: </strong>This Agreement provides that following the expiration of the manufacturer warranty term, and subject to Our Limit of Liability, after [three (3)] service repairs have been completed for the same problem on an individual product that requires a fourth repair, as determined by Us, We will replace it with a product of comparable performance or pay You the remaining Limit of Liability. If We replace Your Covered Product, there is no longer any obligation for the replaced product under this Agreement.‍</li>
                <li><strong>HOW TO GET SERVICE: </strong>You must contact the Administrator for the appropriate authorized service center.Call the toll-free number at [855.624.2512] or go online to [www.newleafsc.net]. All repairs must be authorized by the Administrator prior to performance of work. Claims on unauthorized repairs may be denied. You may be asked for a credit card number prior to service being performed. Many oversights, which are not covered under this Agreement, can be due to simple circumstances such as the Covered Product not being switched on, being unplugged, or a fuse blown at the junction box. For a Covered Product that uses batteries as the prime power supply, check that the batteries do not need replacing or recharging. If You refuse service on a covered item after We have dispatched the repair servicer to Your location You will be billed for that servicer’s applicable trip charge.‍</li>
                <li><strong>SERVICE DELIVERABLES: </strong>There may be a deductible required to obtain service for Your Covered Product as indicated on the Declarations Page of this Agreement. You will receive service on Your Covered Product as described below:<br /><br />Depot Service: If depot service is included with Your Agreement We will provide 3-way shipping to and from a depot service center of our choice.<br /><br />Carry-In: Unless otherwise provided in this Agreement, Covered Products must be delivered and picked up by You at Our authorized service center during normal business hours. If You purchased Above Ground Pool Coverage, it will be Your responsibility to bring above ground pumps and ladder into the service repair facility or to the selling retailer for repair.<br /><br />‍Reimbursement: Once Your Covered Product is repaired You may file a claim with Us for reimbursement. We are not responsible for delay in service or loss of use of Your Covered Product, quality of service or workmanship or defective replacement parts provided by the servicer. How to file a reimbursement claim: You must mail Us a copy of the completed rental or repair invoice. The service facility or rental invoice must include the Make, Model and Serial Number of Your Covered Product, the reason for repair and cause of loss of Your Covered Product, and Your name, address and phone number. We will reimburse You as indicated above in this Agreement, less any applicable deductible as indicated on the Declarations page.‍</li>
                <li><strong>ACCIDENTAL DAMAGE IN HANDLING ("ADH"): </strong>Your Covered Product is protected against accidental damage in handling such as drops and liquid spills. ADH only covers operational or mechanical failure caused by an accident from handling and does not include protection against theft, mysterious disappearance, misplacement, viruses, reckless, abusive, willful or intentional conduct associated with handling and use of the Covered Product, cosmetic damage and/or other damage that does not affect the unit functionality, damage caused during shipment between You and Our service providers and any other limitations listed in the "What is Not Covered" section of this Agreement. Any resultant damage from this type of treatment is NOT covered by ADH. For the purpose of this Agreement, Accidental Damage is defined as a single, unexpected, sudden and unintentional event and does not include accumulated damage from continual or multiple events. The use of this coverage requires an explanation of where and when the accident occurred as well as a detailed description of the actual event.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coveredOpen }
          _toggleFunc={ () => this.toggle('coveredOpen') }
          header="What Is Not Covered"
          content={
            <React.Fragment>
              <ol>
                <li>incidental or consequential damages or loss of use resulting from the broken or damaged product;</li>
                <li>any and all pre-existing conditions that occur prior to the effective date of this contract;</li>
                <li>damage from abuse, misuse, introduction of foreign objects into the product, tampering with prongs, bezels or other elements </li>designed to secure diamonds or gemstones, unauthorized product modifications or alterations, or failure to follow the manufacturer’s instructions;
                <li>water damage if used under conditions which exceed the product manufacturer’s water resistance guidelines;</li>
                <li>third-party actions (fire, collision, vandalism, theft, etc.);</li>
                <li>the elements or acts of god;</li>
                <li>war, invasion or act of foreign enemy, hostilities, civil war, rebellion, riot, strike, labor disturbance, lockout or civil </li>commotion;
                <li>preventative maintenance;</li>
                <li>inherent defects that are the responsibilities of the manufacturer;</li>
                <li>flaws in gemstones;</li>
                <li>loss of diamonds or any other gemstones of the covered jewelry product over .50 carat;</li>
                <li>unauthorized repairs and/or parts;</li>
                <li>parts failure due to a manufacturer recall regardless of the manufacturer’s ability to pay for such repairs;</li>
                <li>accessories used in conjunction with a covered product;</li>
                <li>loss of use during the period the covered product is at a repair facility or otherwise awaiting parts;</li>
                <li>damage or loss resulting from the failure to obtain inspections required by the original manufacturer’s warranty or retailer’s </li>warranty;
                <li>periodic checkups and/or maintenance as directed by the manufacturer;</li>
                <li>products with removed or altered serial numbers;</li>
                <li>service that occurs outside the fifty (50) United States of America and the District of Columbia;</li>
                <li>any product used in a commercial setting or rental basis;</li>
                <li>rust or corrosion on any covered part and failures as a result of rust or corrosion;</li>
                <li>loss or damage resulting from failure to provide manufacturer’s or retailer’s recommended maintenance;</li>
                <li>initial ring sizing.</li>
                <li>products that are lost and/or mysteriously disappear.</li>
              </ol>
              <p><strong>IN NO EVENT SHALL THE ADMINISTRATOR/OBLIGOR OR ANY OF THE ADMINISTRATOR/OBLIGOR’S AGENTS BE LIABLE FOR SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES WHETHER IN CONTRACT, TORT, OR NEGLIGENCE. THIS AGREEMENT WILL NOT COVER LOSS OR DAMAGE NOT SPECIFICALLY LISTED UNDER "WHAT IS COVERED".</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.conditionsOpen }
          _toggleFunc={ () => this.toggle('conditionsOpen') }
          header="Conditions"
          content={
            <React.Fragment>
              <ol>
                <li>‍<strong>Renewal: </strong>The Replacement Plans are not renewable. Repair Plans may be renewed at Our discretion.‍</li>
                <li><strong>Transferability: </strong>This Service Agreement is only for the benefit of the original purchaser of this Service Agreement, or original receiver of the covered Product in the case of a gift. It is not transferable to anyone else.‍</li>
                <li><strong>Territories: </strong>The Agreement territory is limited to the United States of America, including the District of Columbia, only. It does not include Canadian or U.S. Territories including Guam, Puerto Rico, or U.S. Virgin Islands.‍</li>
                <li><strong>Subrogation: </strong>If We pay for a loss, We may require You to assign Us Your rights of recovery against others. We will not pay for a loss if You impair these rights to recover. Your rights to recover from others may not be waived. You will be made whole before We retain any amount We may recover.‍</li>
                <li><strong>Arbitration: </strong>In the event of a disagreement between You and Us concerning costs, either party may make a written demand for arbitration. This must be done within sixty (60) days after the day You filed Your claim. Each party will select an arbitrator. The two (2) arbitrators will select an umpire. Each party will pay the expenses of the respective arbitrator selected. The expenses of the umpire will be shared equally. Unless both parties agree otherwise, arbitration will take place in the county and state in which You live. Local rules will apply. A majority decision will be binding.</li>
                <li>‍<strong>Cancellation: You</strong> may cancel this Agreement for any reason at any time. If You cancel Your Agreement within thirty (30) days of receipt of Your Agreement You must return to the Selling Retailer for a full refund. If You cancel after thirty (30) days of receipt of Your Agreement, You must first return to the Selling Retailer or to the Obligor should the Selling Retailer not be available, and You will receive a pro-rata refund based on the time expired less a twenty-five dollar ($25) cancellation fee, or ten percent (10%) of the purchase price (whichever is less), less the cost of claims paid. We may not cancel this Agreement except for fraud, material misrepresentation, or non-payment by You, or if required to do so by a regulatory authority. Notice of such cancellation will be in writing and given at least thirty (30) days prior to cancellation. If We cancel, the return premium is based upon one hundred percent (100%) of the unearned pro-rata premium.</li>
                <li>‍<strong>Entire Agreement: </strong>This is the entire Service Agreement between the parties, and no representation, promise or condition not contained herein shall modify these items.‍</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.insuranceOpen }
          _toggleFunc={ () => this.toggle('insuranceOpen') }
          header="Insurance"
          content={
            <React.Fragment>
              <p><strong>The obligor under this Agreement is insured by "LYNDON SOUTHERN INSURANCE COMPANY", [10151 Deerwood park blvd, bldg. 100, suite 500, jacksonville, fl 32256 (800) 888-2738], EXCEPT IN GEORGIA WHERE THE OBLIGOR IS INSURED BY "INSURANCE COMPANY OF THE SOUTH", [10151 deerwood park blvd., bldg. 100, suite 500, jacksonville, fl 32256 (800) 888-2738], EXCEPT IN CALIFORNIA WHERE THE OBLIGOR IS INSURED BY "RESPONSE INDEMNITY COMPANY OF CALIFORNIA", [10151 deerwood park blvd., bldg. 100, suite 500, jacksonville, fl 32256 (800) 888-2738], AND EXCEPT IN New York, rhode island, WASHINGTON AND wisconsin WHERE THE OBLIGOR IS INSURED BY "DEALERS ASSURANCE COMPANY", [240 N. FIFTH STREET, SUITE 350, COLUMBUS, OH 43215, (800) 282-8913]. If the Administrator fails to provide service or pay a claim within sixty (60) days YOU MAY submit YOUR CLAIM direclty to the insurer at the above address.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.statesOpen }
          _toggleFunc={ () => this.toggle('statesOpen') }
          header="State Requirements And Disclosures"
          content={
            <React.Fragment>
              <p><strong>THIS AGREEMENT IS AMENDED TO COMPLY WITH THE FOLLOWING REQUIREMENTS AND DISCLOSURES.</strong></p>
              <p><strong>Alabama: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.</p>
              <br /><p><strong>Arizona: </strong>In the "WHAT IS NOT COVERED" section of this Agreement, exclusion (D) is removed. CANCELLATION section is amended as follows: No claim incurred or paid will be deducted from the amount to be returned in the event of cancellation. Arbitration does not preclude the consumer’s right to file a complaint with the [Arizona Department of Insurance Consumer Affairs Division, (800) 325-2548].</p>
              <br /><p><strong>Arkansas: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.</p>
              <br /><p><strong>California: </strong>For residents of California, the Administrator of this Agreement is 4warranty Corporation [10151 Deerwood Park Blvd., Bldg. 100, Suite 500, Jacksonville, Florida 32256]. CANCELLATION section is amended as follows. If You cancel Your Agreement within sixty (60) days of receipt of Your Agreement You must return to the Selling Retailer for a full refund. If You cancel after sixty (60) days of receipt of Your Agreement, You must first return to the Selling Retailer or to the Obligor should the Selling Retailer not be available, and You will receive a pro-rata refund based on the time expired less a twenty-five dollar ($25) cancellation fee, or ten percent (10%) of the purchase price (whichever is less), less the cost of claims paid. A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement. Arbitration provision does not prohibit a California resident from following the process to resolve complaints as outlined by the California Bureau of Electronic and Appliance Repair (BEAR). To learn more about this process, You may contact BEAR at [1-800-952-5210], or You may write to Department of Consumer Affairs, [4244 S. Market Court, Suite D, Sacramento, CA 95834], or You may visit their website at <a href="http://www.bear.ca.gov">www.bear.ca.gov</a>. Informal dispute resolution is not available.</p>
              <br /><p><strong>Connecticut: </strong>If You purchased this Agreement in Connecticut, You may pursue arbitration to settle disputes between You and the provider of this Agreement. You may mail Your complaint to: State of Connecticut, Insurance Department, [P.O. Box 816, Hartford, Connecticut 06142-0816, Attention: Consumer Affairs]. The written complaint must describe the dispute, identify the price of the product and cost of repair, and include a copy of this Agreement. CANCELLATION section is amended as follows: You may cancel this Agreement if You return the Product or the Product is sold, lost, stolen, or destroyed.</p>
              <br /><p>‍<strong>Florida: </strong>The rate charged for this service contract is not subject to regulation by the Florida Office of Insurance Regulation. Arbitration section of this Agreement is removed.‍</p>
              <br /><p><strong>Georgia: </strong>Coverage is effective upon the expiration of the shortest portion of the manufacturer’s warranty. In the "WHAT IS NOT COVERED" section of this Agreement, exclusion (2) is removed and replaced with: Any and all pre-existing conditions known by You that occur prior to the effective date of this Agreement and/or any sold "AS-IS" including but not limited to floor models, demonstration models, etc.</p>
              <p>CANCELLATION section is amended as follows: If You cancel after thirty (30) days of receipt of Your Agreement, You will receive a pro rata refund of the Agreement price. In the event of cancellation by US, notice of such cancellation will be in writing and given at least thirty (30) days prior to cancellation. Cancellation will comply with Section 33-24-44 of the Code of Georgia. Claims paid and cancellation fees shall not be deducted from any refund owed as a result of cancellation. Any refund owed and not paid as required is subject to a penalty equal to twenty-five percent (25%) of the refund owed and interest of eighteen percent (18%) per year until paid; however, such penalty shall not exceed fifty percent (50%) of the amount of the refund. We may not cancel this Agreement except for fraud, material misrepresentation, or non-payment by You. Arbitration section of this Agreement is removed.‍</p>
              <br /><p><strong>Hawaii: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Iowa: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Maine: </strong>CANCELLATION section is amended as follows: The provider of the Agreement shall mail a written notice to the Service Agreement Holder at the last known address of the Service Agreement Holder contained in the records of the provider at least fifteen (15) days prior to cancellation by the provider. The notice must state the effective date of the cancellation and the reason for the cancellation. If a Agreement is cancelled by the provider for a reason other than nonpayment of the provider fee, the provider shall refund to the Service Agreement Holder one hundred percent (100%) of the unearned pro-rata provider fee, less any claims paid. An administrative fee not to exceed ten percent (10%) of the provider fee paid by the Service Agreement Holder may be charged by the provider. A monthly penalty equal to ten percent (10%) of the outstanding provider fee outstanding must be added to a refund that is not paid or credited within forty-five (45) days after the return of the Agreement to the provider.</p>
              <br /><p>‍<strong>Maryland: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Massachusetts: </strong>CANCELLATION section is amended as follows: The provider shall mail a written notice to the Service Agreement Holder, including the effective date of the cancellation and the reason for the cancellation at the last known address of the Service Agreement Holder contained in the records of the provider at least five (5) days prior to cancellation by the provider unless the reason for cancellation is nonpayment of the provider fee, material misrepresentation or a substantial breach of duties by the Service Agreement Holder relating to the Covered Product or its use. A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Michigan: </strong>If performance under this Agreement is interrupted because of a strike or work stoppage at Our place of business, the effective period of the Agreement shall be extended for the period of the strike or work stoppage.‍</p>
              <br /><p><strong>Minnesota: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Mississippi: </strong>Arbitration section of this Agreement is removed.‍</p>
              <br /><p><strong>Missouri: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Nevada: </strong>CANCELLATION section is amended as follows: No claim incurred or paid will be deducted from the amount to be returned in the event of cancellation. We may not cancel this Agreement without providing You with written notice at least fifteen (15) days prior to the effective date of cancellation. Such notice shall include the effective date of cancellation and the reason for cancellation. A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement. Arbitration section of this Agreement is removed. ‍</p>
              <br /><p><strong>New Hampshire:</strong> In the event You do not receive satisfaction under this Agreement, You may contact the New Hampshire Insurance Department, [21 South Fruit Street, Concord, NH 03301, (603) 271-2261]. Arbitration section of this Agreement is removed.‍</p>
              <br /><p><strong>New Jersey:</strong> CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>New Mexico:</strong> CANCELLATION section is amended as follows: We may not cancel this Agreement without providing You with written notice at least fifteen (15) days prior to the effective date of cancellation. Such notice shall include the effective date of cancellation and the reason for cancellation. If this Agreement has been in force for a period of seventy (70) days, We may not cancel it before the expiration of the Agreement term or one (1) year, whichever occurs first, unless: 1) You fail to pay any amount due; 2) You are convicted of a crime which results in an increase in the service required under the Agreement; 3) You engage in fraud or material misrepresentation in obtaining this Agreement; or 4) You commit any act, omission, or violation of any terms of this Agreement after the effective date of this Agreement which substantially and materially increases the service required under this Agreement. A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within sixty (60) days of receipt of a returned Agreement.‍</p>
              <br /><p><strong>New York:</strong> CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>North Carolina:</strong> CANCELLATION section is amended as follows: We may not cancel this Agreement except for nonpayment by You or for violation of any of the terms and conditions of this Agreement.‍</p>
              <br /><p><strong>Oklahoma: </strong>This Agreement is not issued by the manufacturer or wholesale company marketing the product covered by this Agreement. This Agreement will not be honored by such manufacturer or wholesale company. Oklahoma service warranty Statutes do not apply to commercial use references in service warranty contracts. Coverage afforded under this contract is not guaranteed by the Oklahoma Insurance Guaranty Association. CANCELLATION section is amended as follows: In the event You cancel this Agreement, return of premium shall be based upon ninety percent (90%) of the unearned pro rata premium. In the event We cancel this Agreement, return of premium shall be based upon one hundred percent (100%) of unearned pro rata premium. Arbitration does not preclude Your right to a judicial review. If Agreement by arbitration is not reached within three months from the date of the demand for arbitration, You retain the right to sue the tortfesor. ‍</p>
              <br /><p><strong>Oregon: </strong>Upon failure of the Obligor to perform under the Agreement, the insurer shall pay on behalf of the Obligor any sums the Obligor is legally obligated to pay and any service that the Obligor is legally obligated to perform. Termination of the reimbursement policy shall not occur until a notice of termination has been mailed or delivered to the Director of the Department of Consumer and Business Services. This notice must be mailed or delivered at least 30 days prior to the date of termination. CANCELLATION section is amended as follows: You, the Service Agreement Holder may apply for reimbursement directly to the insurer if a refund or credit is not paid before the 46th day after the date on which Your Agreement is returned to the provider. Arbitration section of this Agreement is removed. ‍</p>
              <br /><p><strong>South Carolina: </strong>If You purchased this Agreement in South Carolina, complaints or questions about this Agreement may be directed to the South Carolina Department of Insurance, [P.O. Box 100105, Columbia, South Carolina 29202-3105, telephone number 803-737-6180]. CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement.‍</p>
              <br /><p><strong>Texas: </strong>If You purchased this Agreement in Texas, unresolved complaints or questions concerning the regulations of service contracts may be addressed to the Texas Department of Licensing and Regulation, [P.O. Box 12157, Austin, Texas 78711, telephone number (512) 463-2906 or (800) 803-9202]. Administrator: New Leaf Service Contracts, LLC, 909 Lake Carolyn Parkway, Suite 900, Irving, TX 75039 Lic # 222. CANCELLATION section is amended as follows: You, the Service Agreement Holder may apply for reimbursement directly to the insurer if a refund or credit is not paid before the 46th day after the date on which Your Agreement is returned to the provider.‍</p>
              <br /><p><strong>Utah: </strong>This Agreement is subject to limited regulation by the Utah Insurance Department. To file a complaint, contact the Utah Insurance Department. Coverage afforded under this Agreement is not guaranteed by the Utah Property and Casualty Guaranty Association. Proof of loss should be furnished by You to the Administrator as soon as reasonably possible. Failure to furnish such notice or proof within the time required by this Agreement does not invalidate or reduce a claim. CANCELLATION section is amended as follows: We can cancel this Agreement during the first sixty (60) days of the initial annual term by mailing to You a notice of cancellation at least thirty (30) days prior to the effective date of cancellation except that We can also cancel this Agreement during such time period for non-payment of premium by mailing You a notice of cancellation at least ten (10) days prior to the effective date of cancellation. After sixty (60) days have elapsed, We may cancel this Agreement by mailing a cancellation notice to You at least ten (10) days prior to the cancellation date for non-payment of premium and thirty (30) days prior to the cancellation date for any of the following reasons: (a) material misrepresentation, (b) substantial change in the risk assumed, unless the We should reasonably have foreseen the change or contemplated the risk when entering into the Agreement or (c) substantial breaches of contractual duties, conditions, or warranties. The notice of cancellation must be in writing to You at Your last known address and contain all of the following: (1) the Agreement number, (2) the date of notice, (3) the effective date of the cancellation and, (4) a detailed explanation of the reason for cancellation. </p>
              <p>Any matter in dispute between You and the company may be subject to arbitration as an alternative to court action pursuant to the rules of (the American Arbitration Association or other recognized arbitrator), a copy of which is available on request from the company. Any decision reached by arbitration shall be binding upon both You and the company. The arbitration award may include attorney's fees if allowed by state law and may be entered as a judgment in any court of proper jurisdiction.  ‍</p>
              <br /><p><strong>Washington: </strong>A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned service agreement. We may not cancel this Agreement without providing You with written notice at least twenty-one (21) days prior to the effective date of cancellation. Such notice shall include the effective date of cancellation and the reason for cancellation. You are not required to wait sixty (60) days before filing a claim directly with the insurer. ARBITRATION section is amended to add the following: The Insurance Commissioner of Washington is the Service Provider’s attorney to receive service of process in any action, suit or proceeding in any court, and the state of Washington has jurisdiction of any civil action in connection with this Agreement. Arbitration proceedings shall be held at a location in closest proximity to the service Agreement holder’s permanent residence. You may file a direct claim with the insurance company at any time.</p>
              <br /><p><strong>Wisconsin: </strong>Arbitration section of this Agreement is removed. CANCELLATION section is amended as follows: Claims paid or the cost of repairs performed shall not be deducted from the amount to be refunded upon cancellation of this Agreement. THIS AGREEMENT IS SUBJECT TO LIMITED REGULATION BY THE OFFICE OF THE COMMISSIONER. Proof of loss should be furnished by You to the Administrator as soon as reasonably possible and within one (1) year after the time required by this Agreement. Failure to furnish such notice or proof within the time required by this Agreement does not invalidate or reduce a claim. A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within forty-five (45) days of receipt of returned Service Agreement. If Administrator fails to provide, or reimburse or pay for, a service that is covered under this Agreement within sixty-one (61) days after You provide proof of loss, or if the Administrator becomes insolvent or otherwise financially impaired, You may file a claim directly with the Insurer for reimbursement, payment, or provision of the service.‍</p>
              <br /><p><strong>Wyoming: </strong>CANCELLATION section is amended as follows: A ten percent (10%) penalty per month shall be applied to refunds not paid or credited within thirty (30) days of receipt of returned Service Agreement. Arbitration section of this Agreement is removed.</p>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default Jewelry;
