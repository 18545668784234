export const showModal = (modalID: string) => ({
  type: 'SHOW_MODAL',
  modalID,
});

export const cancelModal = () => {
  document.body.classList.remove('overflow-off');
  return {
    type: 'CANCEL_MODAL',
  };
};
