import React from 'react';
import { AvailableItemType } from '../../types/LineItemTypes';

interface CartRemovedItemProps {
  item: AvailableItemType;
  index: number;
  _reAddItem: any;
}

class CartRemovedItem extends React.Component<CartRemovedItemProps, any> {
  reAddItem = () => {
    this.props._reAddItem(this.props.item);
  };

  render() {
    const { item } = this.props;
    return (
      <div className="cart-removed-item clyde--bold-small">
        { item.name } ({ item.quantityAvailable }) &nbsp;<div className="fake-link fake-link--alt" onClick={ this.reAddItem } >Add Coverage</div>
      </div>
    );
  }
}

export default CartRemovedItem;
