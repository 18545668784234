import React from 'react';
import CartItem from './CartItem';
import CartRemovedItem from './CartRemovedItem';
import { AvailableItemType } from '../../types/LineItemTypes';

interface CartProps {
  availableItems?: AvailableItemType[];
  removedItems?: AvailableItemType[];
  tax: number;
  total: number;
  totalContracts: number;
  _updateOrder: any;
  _removeItem: any;
  _reAddItem: any;
}

class Cart extends React.Component<CartProps> {
  render() {
    return (
      <div className="cart">
        <div className="cart__items">
          {
            this.props.availableItems
              ? this.props.availableItems.map((item: any, i: number) => (
                  <CartItem
                    key={ i }
                    index={ i }
                    item={ item }
                    _updateOrder={ this.props._updateOrder }
                    _removeItem={ this.props._removeItem } />
              ))
              : ''
          }
        </div>
        <div className="cart__removed-items">
          {
            this.props.removedItems && this.props.removedItems.length > 0
              ? this.props.removedItems.map((item:any, i:number) => (
              <CartRemovedItem
                key={i} index={i} item={ item }
                _reAddItem={ this.props._reAddItem }
              />))
              : ''
          }
        </div>
        <table className="cart__sum-table" >
          <tbody>
          <tr>
            <td className="cart__sum-table__row cart__sum-table__row--left" >
              Protection Plan ({ this.props.totalContracts.toFixed(0) }):
            </td>
            <td className="cart__sum-table__row cart__sum-table__row--right">
              ${ (this.props.total - this.props.tax).toFixed(2) }
            </td>
          </tr>
          <tr>
            <td className="cart__sum-table__row cart__sum-table__row--left" >
              Tax:
            </td>
            <td className="cart__sum-table__row cart__sum-table__row--right" >
              ${ this.props.tax.toFixed(2) || 0.00}
            </td>
          </tr>
          <tr>
            <td className="cart__sum-table__row cart__sum-table__row--left cart__sum-table__row--bold" >
              Order Total:
            </td>
            <td id="cart__sum-table__total" className="cart__sum-table__row cart__sum-table__row--right cart__sum-table__row--bold" >
              ${ this.props.total.toFixed(2) }
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Cart;
