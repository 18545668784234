import React from 'react';
import { Button } from 'reactstrap';
import { ReactComponent as HorizontalLogo } from '../../images/horizontal-logo.svg';
import { ReactComponent as PartnerSVG } from '../../images/partner.svg';
import { ReactComponent as NextStepsSVG } from '../../images/double-arrow-right.svg';
import { ReactComponent as StatusSVG } from '../../images/claim-status.svg';

interface ClaimHandoffProps {
  shopLogoSource: string;
  externalClaimUrl: string;
  shopName: string;
}

const ClaimHandoff = ({
  shopLogoSource,
  externalClaimUrl,
  shopName,
} : ClaimHandoffProps) => (
    <div className='claim-link-modal'>
      <section className='claim-link-modal__logos'>
        <HorizontalLogo />
        { shopLogoSource && <img alt={shopName} src={ shopLogoSource } /> }
      </section>
      <h2 className='claim-link-modal__header'>Your claim will be managed by {shopName}! They’ll take it from here.</h2>
      <section className='claim-link-modal__deck'>
        <div className='claim-link-modal__deck__card'>
          <PartnerSVG />
          <h5>Partner</h5>
          <p>
            { shopName } are experts on your product & will lead communication on how to resolve your issue.
          </p>
        </div>
        <div className='claim-link-modal__deck__card'>
          <NextStepsSVG />
          <h5>Next Steps</h5>
          <p>
            Submit a ticket with customer service & they will walk you through next steps to fixing your product.
          </p>
        </div>
        <div className='claim-link-modal__deck__card'>
          <StatusSVG />
          <h5>Claim Status</h5>
          <p>
            Check the status of your claim on Clyde & we will update you on all progress made.
          </p>
        </div>
      </section>
      <a target="_blank" rel="noopener noreferrer" href={ externalClaimUrl }>
        <Button className='button--general'>File a Claim</Button>
      </a>
    </div>
);

export default ClaimHandoff;
