import React from 'react';
import TermsAccordion from '../../Shared/TermsAccordion';

import { TermsProps } from '../../../../types/TermsTypes';

class NotebookLaptopProtection extends React.Component<TermsProps, any> {
  constructor(props: TermsProps) {
    super(props);
    this.state = {
      additionalBenefitsOpen: false,
      baseCoverageOpen: false,
      cancellationOpen: false,
      contractTermOpen: false,
      coveredGeneralOpen: false,
      deductibleOpen: false,
      definitionsOpen: false,
      eligibilityOpen: false,
      entireAgreementOpen: false,
      exclusionsOpen: false,
      generalProvisionsOpen: false,
      guarantyOpen: false,
      howToFileOpen: false,
      limitOfLiabilityOpen: false,
      optionalCoveragesOpen: false,
      placeOfServiceOpen: false,
      privacyOpen: false,
      productInformationOpen: false,
      recoverPaymentOpen: false,
      renewabilityOpen: false,
      responsibilitiesOpen: false,
      statesOpen: false,
      transferabilityOpen: false,
    };
  }

  toggle = (section: string) => {
    this.setState((state: any) => ({ [section]: !state[section] }));
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
        <p>Thank You for Your recent purchase of the Protection Plan (the "Service Contract", "Contract"). We hope You enjoy the added comfort and protection this Service Contract provides. Please keep this important Contract document, Your Contract Purchase Receipt and Your Device Purchase Receipt (if separate), as You will need them in the event of a Claim. The following information is intended to serve as a valuable reference guide to help You determine what is covered by this Service Contract.</p>
        </div>

        <TermsAccordion
          toggle={ this.state.definitionsOpen }
          _toggleFunc={ () => this.toggle('definitionsOpen') }
          header='Definitions'
          content={
            <React.Fragment>
            <p><strong>Throughout this Service Contract, the following capitalized words have the stated meaning</strong></p>
              <ol>
                <li><strong>"We", "Us", "Our", "Obligor", "Provider":</strong> the party or parties obligated to provide service under this Service Contract as the service contract provider, AMT Warranty Corp., 59 Maiden Lane, 43rd Floor, New York, NY 10038 (Florida Residents: this Service Contract is an agreement between You and Technology Insurance Company, Inc., License No. 03605, 59 Maiden Lane, 43rd Floor, New York, New York, 10038).</li>
                <li><strong>"Administrator":</strong> the entity responsible for administrating benefits to You in accordance with the Service Contract terms and conditions, Warrantech Consumer Product Services, Inc., PO Box 959, Bedford, TX 76095 (Florida Residents: this Service Contract is administered by WCPS of Florida, Inc., License No. 80202).</li>
                <li><strong>"Retailer":</strong> the merchant authorized by Us to sell this Service Contract to You.</li>
                <li><strong>"You", "Your":</strong> the purchaser of this Service Contract who is to receive the coverage</li>
                provided hereunder.
                <li><strong>"New":</strong> an eligible item that is purchased as brand-new, concurrently with this Service Contract, which has a new manufacturer’s original warranty included.</li>
                <li><strong>"Used":</strong> an item that was either (a) purchased as refurbished and concurrently with this Service Contract or (b) purchased previously and separately from this Service Contract; that is fully operational and not damaged as of the Contract’s purchase date; which may or may not have any remaining coverage under the manufacturer’s original equipment warranty. NOTICE: the Retailer and/or Administrator may require the submission of photographs of the item, as well as written confirmation from You that it is fully operational and not currently damaged.</li>
                <li><strong>"Covered Device", "Device":</strong> the New or Used item(s) that is/are to be covered under this Service Contract.</li>
                <li><strong>"Contract Purchase Receipt":</strong> the receipt document (paper or e-mail) provided to You as proof of Your Contract purchase that confirms the Term, Deductible and purchase date of Your Contract.</li>
                <li><strong>"Contract Purchase Price":</strong> the amount paid by You for this Service Contract (excluding any applicable taxes and/or fees), as confirmed on Your Contract Purchase Receipt.</li>
                <li><strong>"Device Purchase Receipt":</strong> the receipt document (paper or email) provided as proof of Your Device purchase that indicates the date in which the Device was purchased along with the Device Purchase Price.</li>
                <li><strong>"Device Purchase Price":</strong> the amount paid by You for the Covered Device; excluding any applicable taxes and/or fees.</li>
                <li><strong>"Claim":</strong> a demand for payment in accordance with this Contract sent by You to the Administrator or Us.</li>
                <li><strong>"Waiting Period":</strong> the period of time starting on the Contract purchase date, through thirty (30) days thereafter, during which time no Claims are considered for coverage under this Contract. The Waiting Period is only applicable to a Covered Device that is classified as "Used"; which is confirmed on the Contract Purchase Receipt.</li>
                <li><strong>"Failure":</strong>themechanicalorelectricalbreakdownofYourDevicetoperformitsintended function including defects in materials or workmanship and normal wear and tear; occurring during normal use of the Device.</li>
                <li><strong>"Power Surge":</strong> damages to the Device resulting from an oversupply of voltage to Your Device while properly connected to a surge protector approved by the Underwriter’s Laboratory Inc. (UL), but not including damages resulting from the improper installation or improper connection of the Device to a power source.</li>
                <li><strong>"Deductible":</strong> the amount You are required to pay, per Claim, prior to receiving covered services under this Service Contract (if any), as indicated on Your Contract Purchase Receipt.</li>
                <li><strong>"Term":</strong> the period of time in which the provisions of this Service Contract are valid, as indicated on Your Contract Purchase Receipt.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.eligibilityOpen }
          _toggleFunc={ () => this.toggle('eligibilityOpen') }
          header='Product Eligibility'
          content={
            <React.Fragment>
              <p>In order to be eligible for coverage under this Contract, the item must be New or Used (as defined), purchased from an authorized Retailer, and not covered under any other insurance, warranty, guarantee and/or service agreement providing the same benefits as outlined herein.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.responsibilitiesOpen }
          _toggleFunc={ () => this.toggle('responsibilitiesOpen') }
          header='Your Responsibilities'
          content={
            <React.Fragment>
              <p><strong>PRODUCT PROTECTION:</strong> If damage or breakdown of the Device is suspected, You should promptly take reasonable precautions in order to protect against further damage.</p>
              <p><strong>MAINTENANCE AND INSPECTIONS:</strong> If specified in the Device manufacturer’s warranty and/or owner’s manual, You must perform all of the care, maintenance and inspections for the Device as indicated. Proof of the completion of such maintenance, care and/or inspection services may be required at time of Claim.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.productInformationOpen }
          _toggleFunc={ () => this.toggle('productInformationOpen') }
          header='Important Product Information'
          content={
            <React.Fragment>
              <p>If Your original Covered Device is ever exchanged by the manufacturer, please notify the Administrator as soon as practicable the make, model, and serial number of the new exchanged product. You can do this by either calling the Administrator at 1-888-271-1934 or by writing to the Administrator at P.O. Box 959, Bedford, TX 76095, ATTN: WCPS-Data Entry. NOTE: in the event of manufacturer or Retailer exchange, the Term of Your originally purchased Service Contract remains in effect and does not automatically extend.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.contractTermOpen }
          _toggleFunc={ () => this.toggle('contractTermOpen') }
          header='Contract Term — Effective Date Of Coverage'
          content={
            <React.Fragment>
              <ol>
                <li>
                  <strong>FOR A NEW DEVICE</strong>
                  <ol type='A'>
                    <li>Coverages for damages to Your Device resulting from defined Power Surge or if appliable to Your purchased OPTIONAL COVERAGE upgrade, Accidental Damage from Handling (ADH) and other non-Failure covered Claims applicable to Your Plan begins on Your Contract purchase date and continues for the Term shown on Your Contract Purchase Receipt.</li>
                    <li>Coverage for a defined Failure of Your Device begins upon expiration of the shortest portion of the manufacturer’s original parts and/or labor warranty and continues for the remainder of the Term shown on Your Contract Purchase Receipt.</li>
                  </ol>
                </li>
                <li>
                  <strong>FOR A USED DEVICE — NOTICE: A 30-DAY WAITING PERIOD APPLIES TO ALL USED DEVICES.</strong>
                  <ol type='A'>
                    <li>Coverage for damages to Your Device resulting from defined Power Surge or if applicable to Your purchased OPTIONAL COVERAGE upgrade, Accidental Damage from Handling (ADH) begins upon expiration of the Waiting Period and continues for the remainder of the Term shown on Your Contract Purchase Receipt.</li>
                    <li>Coverage for a defined Failure of Your Device begins upon expiration of the Waiting Period OR expiration of the shortest portion of the manufacturer’s original parts and/or labor warranty – whichever is later – and continues for the remainder of the Term shown on Your Contract Purchase Receipt.</li>
                  </ol>
                </li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coveredGeneralOpen }
          _toggleFunc={ () => this.toggle('coveredGeneralOpen') }
          header='What Is Covered — General'
          content={
            <React.Fragment>
              <p><strong>In accordance with the CONTRACT TERM-EFFECTIVE DATE OF COVERAGE provision outlined above, in the event of a covered Claim this Contract provides for the labor and/or parts necessary to repair the Covered Device, or at Our sole discretion, a replacement of the originally Covered Device or reimbursement for a replacement in lieu of such repairs, at Our sole discretion (see "About Replacements" and "About Reimbursements" bullets below for additional details).</strong></p>
              <p><strong>IMPORTANT DISCLOSURES REGARDING "WHAT IS COVERED":</strong> Coverage described in this Service Contract will not replace or provide duplicative benefits during any active manufacturer’s warranty period. During such period, anything covered under that warranty is the sole responsibility of the manufacturer and will not be considered under this Contract; regardless of the manufacturer’s ability to fulfill its obligations. ALL COVERAGE PROCLAIMED UNDER THIS SERVICE CONTRACT IS EXPRESSLY SUBJECT TO THE "LIMIT OF LIABILITY" AND "EXCLUSIONS" PROVISIONS.</p>
              <ul>
                <li><strong>About Repairs:</strong> Parts used to repair the Device may be new, used, refurbished or non-original manufacturer parts that perform to the factory specifications of the original Device.</li>
                <li><strong>About Replacements:</strong> If We determine Your original Covered Device cannot be repaired, We will make every reasonable effort to replace the defective Device with one of the same model/features; however, We reserve the right to replace the original Device with one of equal or similar features and functionality, and We do not guarantee such replacement will be the same color or brand as Your original Device. In no event will a replacement product extend Your original Contract Term. Technological advances may result in a replacement product with a lower selling price than the original Covered Device, and no reimbursement based on any replacement product cost difference will be provided. Any and all parts or units replaced under this Contract become Our property in their entirety.</li>
                <li><strong>About Reimbursements:</strong> In the event We determine to provide You with reimbursement for a replacement, such reimbursement will not exceed the amount equal to the fair market value of Your originally covered Device, based upon the age of the original Device at time of Claim, and at Our sole discretion. Reimbursements may be provided to You in the form of a check, voucher, or gift card.</li>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.baseCoverageOpen }
          _toggleFunc={ () => this.toggle('baseCoverageOpen') }
          header='Base Coverage Plan'
          content={
            <React.Fragment>
              <p>As indicated on Your Contract Purchase Receipt and applicable to You.</p>
              <ol>
                <li>
                  <strong>TABLET/NOTEBOOK/LAPTOP PLAN:</strong> If You purchased this Contract to cover Your eligible New or Used tablet/notebook/laptop Device ("Your Plan"), Your Plan provides everything outlined in the "WHAT IS COVERED-GENERAL" section above, PLUS covered Claims for:
                  <ol type='A'>
                    <li><strong>Damaged or Defective Buttons or Connectivity Ports:</strong> labor and/or parts required to repair damaged or defective buttons or connectivity ports located on the Covered Device, when such damage / defect results in significant functional impairment of the Device.</li>
                    <li><strong>Defective Pixels:</strong> labor and/or parts required to repair pixels within the display area, when such defect significantly impairs the Device’s functionality.</li>
                    <li><strong>Dust, Internal Overheating, Internal Humidity/Condensation:</strong> labor and/or parts required to repair Your Covered Device if it encounters a mechanical/electrical breakdown resulting from dust, internal overheating, internal humidity, or condensation; occurring during its routine use for which it is intended.</li>
                  </ol>
                </li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.additionalBenefitsOpen }
          _toggleFunc={ () => this.toggle('additionalBenefitsOpen') }
          header='Additional Benefits'
          content={
            <React.Fragment>
              <p>No separate election/purchase is required; coverage is limited to damages sustained to the Covered Device only.</p>
              <ol>
                <li><strong>FREE SHIPPING:</strong> for covered Claims, any/all shipping charges associated with the servicing of Your Device are covered under this Contract.</li>
                <li><strong>POWER SURGE:</strong> In addition to coverage for a defined Failure, this Service Contract also provides coverage for sustained damage to the Covered Device as a result of a defined Power Surge covered Claim. Limited to damage sustained to the Device only; see "WHAT IS COVERED-GENERAL" for how coverage will be provided.</li>
                <li><strong>NO LEMON GUARANTEE:</strong> This Service Contract has an embedded benefit that consists of the following: if, within any consecutive twelve (12) month period, Your Covered Device has three (3) repairs covered under this Contract for the same problem and a fourth (4th) repair is required for the same covered problem, We will replace Your original Device with one of like kind and quality, but not necessarily same brand or color, or provide You with reimbursement for a replacement. THIS BENEFIT IS NOT SUBJECT TO DEDUCTION OF PREVIOUSLY PAID CLAIMS; IT SUPERCEDES SUCH. HOWEVER, IF YOU RECEIVE A REPLACEMENT UNDER THIS "NO LEMON GUARANTEE", OUR OBLIGATIONS UNDER THIS CONTRACT SHALL BE CONSIDERED FULFILLED IN THEIR ENTIRETY AND YOUR COVERAGE WILL END.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.optionalCoveragesOpen }
          _toggleFunc={ () => this.toggle('optionalCoveragesOpen') }
          header='Optional Coverages'
          content={
            <React.Fragment>
              <p>As indicated on Your Contract Purchase Receipt and applicable to You.</p>
              <ol>
                <li><strong>ACCIDENTAL DAMAGE FROM HANDLING ("ADH"):</strong> if You purchased this optional coverage upgrade, in addition to coverage for that which is outlined under Your purchased Base Coverage Plan, Your Contract also provides coverage for labor and/or parts required to repair Your Covered Device if it experiences sudden and unforeseen accidental damage from handling (ADH); such as damage resulting from dropping the Covered Device, spilling liquid on it, or in association with screen breakage.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.deductibleOpen }
          _toggleFunc={ () => this.toggle('deductibleOpen') }
          header='Deductible'
          content={
            <React.Fragment>
              <p>You are required to pay the Deductible amount specified on Your Contract Purchase Receipt prior to receiving covered services under this Contract (if any).</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.howToFileOpen }
          _toggleFunc={ () => this.toggle('howToFileOpen') }
          header='How To File A Claim'
          content={
            <React.Fragment>
              <p><strong>IMPORTANT: The submission of a Claim does not automatically mean that the damage to or breakdown of the Device is covered under Your Plan(s) and this Contract. In order for a Claim to be considered, You have to contact the Administrator first for Claim approval and authorization number.</strong></p>
              <p><strong>COMPLETE THE FOLLOWING STEPS TO HAVE YOUR CLAIM CONSIDERED UNDER THIS CONTRACT:</strong></p>
              <ol>
                <li>Go online to <a href='http://www.MyProtectionPlan360.com/'>www.MyProtectionPlan360.com</a> or call toll-free 1-888-271-1934 with Your Contract Purchase Receipt readily available. Available 24/7.</li>
                <li>Explain the problem Your Device is experiencing and provide any additional information/documentation necessary in order to validate Your Claim.</li>
                <li>After confirmation of Claim eligibility under Your Plan and this Contract, the Administrator will issue a Claim authorization number to You along with additional information regarding how Your Device will be further serviced (refer to the "PLACE OF SERVICE" section below for further details). Authorization for payment of any required Deductible will be collected by the Administrator at this time. (Your applicable Deductible amount is shown on Your Contract Purchase Receipt.)</li>
              </ol>
              <p>In the event the Contract Term expires during time of an approved Claim, Your coverage will be automatically extended until the date in which the Claim in progress has been fulfilled completely in accordance with the terms and conditions of the Contract.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.placeOfServiceOpen }
          _toggleFunc={ () => this.toggle('placeOfServiceOpen') }
          header='Place Of Service'
          content={
            <React.Fragment>
              <p><strong>When You file a Claim, the Administrator will advise as to which of the following is applicable to Your Plan and will provide instructions for how to proceed for such servicing.</strong></p>
              <p>For covered Claims that are to be handled via Carry-In Service to a mobile device servicing center near Your location, the Administrator will provide You with all of the information and directions necessary to complete such servicing. NOTE: for Carry-In Service, You (or Your representative) are responsible for transporting Your impaired Device to and from the authorized servicing center. In the event the impaired Covered Device needs to be then shipped to another authorized location, We will pay for the shipping costs to and from such authorized location.</p>
              <p>For covered Claims that are to be handled via Depot Service, all shipping costs to and from Our designated Depot Center are covered by Us.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.limitOfLiabilityOpen }
          _toggleFunc={ () => this.toggle('limitOfLiabilityOpen') }
          header='Limit Of Liability'
          content={
            <React.Fragment>
              <p><strong>The total amount that We will pay for services in connection with all Claims pursuant to this Service Contract is as follows:</strong></p>
              <ol>
                <li>
                  <strong>REPAIRS LIMIT</strong> — unlimited number of Claims until the accumulated amount that We have paid equals:
                  <ol type='A'>
                    <li>For a New Covered Device, the Device Purchase Price shown on Your Device Purchase Receipt and/or Contract Purchase Receipt.</li>
                    <li>For a Used Covered Device, the current MSRP of a replacement product of equal features and functionality to the original Covered Device.</li>
                  </ol>
                </li>
                <li><strong>REPLACEMENT LIMIT</strong> — maximum of one (1) replacement product or reimbursement for replacement product, at Our sole discretion; which cumulatively, shall not exceed the current MSRP of a replacement device of equal features and functionality to the original Device.</li>
              </ol>
              <p><strong>ONCE EITHER OF THESE LIMITS HAS BEEN REACHED, OUR OBLIGATIONS UNDER THIS CONTRACT WILL BE CONSIDERED FULFILLED AND COVERAGE ENDS.</strong></p>
              <p>IN ADDITION TO THAT WHICH IS NOTED ABOVE, NEITHER WE NOR THE ADMINISTRATOR NOR THE RETAILER SHALL BE LIABLE FOR ANY INCIDENTAL OR CONSEQUENTIAL DAMAGES; INCLUDING BUT NOT LIMITED TO: PROPERTY DAMAGE, LOST TIME, LOST DATA, OR LOST INCOME/WAGES RESULTING FROM THE FAILURE OF OR DAMAGE TO ANY COVERED DEVICE, REGARDLESS OF WHETHER SUCH FAILURE OR DAMAGE IS COVERED UNDER THE PROVISIONS OF THIS CONTRACT, OR FROM DELAYS IN SERVICE OR THE INABILITY TO RENDER SERVICE, OR RESULTING FROM THE UNAVAILABILITY OF REPAIR OR REPLACEMENT PARTS/COMPONENTS/ITEMS OR INABILITY TO PROVIDE EXACT MATCH REPLACEMENT, OR FOR ANY AND ALL PRE- EXISTING CONDITIONS KNOWN TO YOU; INCLUDING ANY INHERENT DEVICE FLAWS.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.exclusionsOpen }
          _toggleFunc={ () => this.toggle('exclusionsOpen') }
          header='Exclusions (What Is Not Covered)'
          content={
            <React.Fragment>
              <p><strong>AS RELATED AND APPLICABLE TO THE COVERED DEVICE(S), THIS CONTRACT DOES NOT COVER ANY FAILURE, DAMAGE, REPAIRS OR SERVICES IN CONNECTION WITH OR RESULTING FROM:</strong></p>
              <ol type='A'>
                <li>For a Used Device only – any Claim submitted prior to the expiration of the Contract Waiting Period;</li>
                <li>A pre-existing condition known to You ("pre-existing condition" refers to a condition that within all reasonable mechanical or electrical probability, relates to the mechanical fitness of the Covered Device before this Contract was purchased, or for a Used Device only, is determined by Us to be a Failure or otherwise covered damage that occurred prior to the expiration of the Contract Waiting Period);</li>
                <li>Any Claim for service to or replacement of the Covered Device that has not been prior authorized by the Administrator;</li>
                <li>Any Claim related to cosmetic damage (meaning damages or changes to the physical M) appearance of the Covered Device that does not impede or hinder the normal operational function; such as scratches, abrasions, or changes in color, texture, or finish) or structural imperfections (when such do not impair the overall functionality of the Covered Device);</li>
                <li>Any merchandise that has been confirmed by Our authorized servicer to have removed N) or altered serial numbers;</li>
                <li>Servicing of the Covered Device in association with a non-covered Claim, and shipping O) or delivery charges associated with the initial purchase of the Covered Device;</li>
                <li>Fortuitous events; including, but not limited to: environmental conditions, exposure to P) weather conditions or perils of nature; collapse, explosion or collision of or with another object; fire, any kind of precipitation, humidity (UNLESS YOUR PLAN INCLUDES COVERAGE FOR HUMIDITY), lightning, dirt/sand, smoke, nuclear radiation, radioactive Q) contamination, riot, war or hostile action;</li>
                <li>Breakdown or damage that is covered under any other insurance, warranty, guarantee and/or service agreement providing the same benefits as outlined in this Contract;</li>
                <li>Any merchandise that has been confirmed to be used in a commercial, business, heavy industrial and/or educational institution capacity (UNLESS THE APPROPRIATE "OPTIONAL COVERAGE" UPGRADE HAS BEEN PURCHASED); S)</li>
                <li>Abuse (meaning, the intentional treatment of the Covered Device in a harmful, injurious, malicious or offensive manner which results in its damage and/or breakdown), neglect, negligence, misuse, intentional harm or malicious mischief of or T) to the Covered Device;</li>
                <li>Theft or mysterious disappearance, loss (unforeseen disappearance) or vandalism of or to the Covered Device;</li>
                <li>Rust, corrosion, warping, bending, animals, animal inhabitation or insect infestation;</li>
                <li>Accidental damage from handling ("ADH") (meaning damage resulting from unintentional dropping of the Device, or in association with screen breakage or liquid spillage) (UNLESS THE APPROPRIATE "OPTIONAL COVERAGE" UPGRADE HAS BEEN PURCHASED);</li>
                <li>Any upgrades, attachments, accessories or peripherals, or any breakdown or damage to these items;</li>
                <li>Any items that are consumer replaceable and designed to be replaced over time throughout the life of the Covered Device; including, but not limited to: fuses, batteries, and connectors;</li>
                <li>Improper removal or installation of replaceable components, modules, parts or peripherals and/or installation of incorrect parts;</li>
                <li>Routine, periodic or preventative maintenance;</li>
                <li>Lack of providing manufacturer’s recommended maintenance or operation/storage of the Covered Device in conditions outside manufacturer specifications, or use of the Covered Device in such a manner as would be voidable coverage under the manufacturer’s warranty, or use of the Device in a manner inconsistent with its design or manufacturer specifications;</li>
                <li>Adjustment, manipulation, modification, removal or unauthorized repairs of any internal component/part of a Covered Device performed by anyone other than a service center/technician authorized by the Administrator;</li>
                <li>Any kind of manufacturer recall or rework order on the Covered Device, of which the manufacturer is responsible for providing, regardless of the manufacturer’s ability to pay for such repairs; or</li>
                <li>Service or replacement outside of the United States of America, its territories, or Canada.</li>
              </ol>
              <p>IMPORTANT: RESTORATION OR TRANSFER OF SOFTWARE AND/OR DATA, AND DATA RECOVERY SERVICES ARE EXPRESSLY EXCLUDED UNDER THIS SERVICE CONTRACT. WHEN AT ALL POSSIBLE, WE STRONGLY ENCOURAGE YOU TO BACK UP ALL SOFTWARE AND DATA ON A REGULAR BASIS AND ESPECIALLY PRIOR TO SUBMITTING YOUR COVERED DEVICE FOR SERVICING PURSUANT TO THE TERMS AND CONDITIONS OF THIS CONTRACT.</p>
              <p>IF THE ADMINISTRATOR AUTHORIZES SERVICE FOR A CLAIM ON YOUR COVERED DEVICE, AND IT IS THEN DETERMINED BY OUR AUTHORIZED SERVICE CENTER/TECHNICIAN TO BE EXCLUDED UNDER THE TERMS AND CONDITIONS OF THIS CONTRACT, OR RESULTS IN A "NO PROBLEM FOUND" DIAGNOSIS, YOU MAY BE RESPONSIBLE FOR ALL REPAIR COSTS INCLUDING SHIPPING COSTS.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.recoverPaymentOpen }
          _toggleFunc={ () => this.toggle('recoverPaymentOpen') }
          header='Our Right To Recover Payment'
          content={
            <React.Fragment>
              <p>If You have a right to recover against another party for anything We have paid under this Service Contract, Your rights shall become Our rights. You shall do whatever is reasonably necessary to enable Us to enforce these rights. We shall recover only the excess after You are fully compensated for Your loss.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.cancellationOpen }
          _toggleFunc={ () => this.toggle('cancellationOpen') }
          header='Cancellation'
          content={
            <React.Fragment>
              <p>You may cancel this Service Contract at any time by informing the Administrator of cancellation request. NOTICE: The following cancellation provisions apply to the original purchaser of this Service Contract only. NO CANCELLATION FEES APPLY.</p>
              <p><strong>IF YOU CANCEL THIS CONTRACT:</strong></p>
              <ol>
                <li>Within 30 days of the Contract purchase date, You will receive a 100% refund of the full Contract Purchase Price paid by You, minus any Claims paid by Us (except in Arizona, Georgia, Missouri & Nevada where Claims deduction is prohibited). If Your refund is not paid or credited within thirty (30) days after their cancellation request to Us, We will add an extra 10% to Your due refund for every thirty (30) days the refund is not paid by Us.</li>
                <li>After 30 days from the Contract purchase date, You will receive a pro-rata refund based on 100% of the Contract Purchase Price paid by You, minus any Claims paid by Us (except in Arizona, Georgia, Missouri & Nevada where Claims deduction is prohibited). If Your refund is not paid or credited within thirty (30) days after their cancellation request to Us, We will add an extra 10% to Your due refund for every thirty (30) days the refund is not paid by Us.</li>
              </ol>
              <p><strong>WE MAY ONLY CANCEL THIS CONTRACT FOR:</strong></p>
              <ol>
                <li>Non-payment of the Contract Purchase Price/fee by You;</li>
                <li>Material misrepresentation by You; or</li>
                <li>Substantial breach of duties under this Contract by You in relation to the Covered Device or its use.</li>
              </ol>
              <p>If We cancel this Contract, We will provide written notice to You at least 15 days (30 days in Georgia) (21 days in Washington) prior to the effective date of cancellation. Such notice will be sent to Your current address in Our file (email or physical address as applicable), with the reason for and effective date of such cancellation. If We cancel this Contract, You will receive a pro-rata refund based upon the same criteria as outlined above.</p>
              <p>NOTICE: If the purchase of this Contract was financed, any outstanding balance due to the finance company will be deducted from any due refund and paid to the finance company instead of You.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.guarantyOpen }
          _toggleFunc={ () => this.toggle('guarantyOpen') }
          header='Guaranty'
          content={
            <React.Fragment>
              <p>This is not an insurance policy; it is a service contract. We have obtained an insurance policy to insure Our performance under this Service Contract. Should We fail to pay any Claim or fail to replace the Device covered under this Service Contract within sixty (60) days after the Claim has been submitted, or in the event You cancel this Service Contract, and We fail to refund any unearned portion of the Service Contract price, You are entitled to make a direct Claim against the insurer, Wesco Insurance Company, at 866-505-4048 or 59 Maiden Lane, 43rd Floor, New York, NY 10038.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.privacyOpen }
          _toggleFunc={ () => this.toggle('privacyOpen') }
          header='Privacy And Data Protection'
          content={
            <React.Fragment>
              <p>You agree that any information or data disclosed to Us under this Contract is not confidential. Furthermore, You agree that We may collect and process data on Your behalf when We provide the services contemplated under this Contract. This may include transferring Your data to affiliated companies or third party service providers in accordance with Our Customer Privacy Policy. Except for the purposes of providing services in this Contract, We will not share Your information with third parties without Your permission and We will comply with applicable privacy and data protection laws in Your specific jurisdiction.</p>
              <p>Unless specifically prohibited by Your jurisdiction’s privacy and data protection laws, We may transfer Your information to other countries and jurisdictions provided that anyone to whom We transfer Your information provides an adequate level of protection. In addition, Your information may be accessed by law enforcement agencies and other authorities to prevent and detect crime and comply with legal obligations.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.generalProvisionsOpen }
          _toggleFunc={ () => this.toggle('generalProvisionsOpen') }
          header='General Provisions'
          content={
            <React.Fragment>
              <ol>
                <li><strong>Subcontract.</strong> We may subcontract or assign performance of Ourobligations to third parties, but We shall not be relieved of Ouro bligations to You when doing so.</li>
                <li><strong>Waiver; Severability.</strong> The failure of any party to require performance by the other party of any provision hereof will not affect the full right to require such performance at any time thereafter; nor will the waiver by either party of a breach of any provision hereof be taken or held to be a waiver of the provision itself. In the event that any provision of these terms and conditions will be unenforceable or invalid under any applicable law or be so held by applicable court decision, such unenforceability or invalidity will not render these terms and conditions unenforceable or invalid as a whole and in such event, such provisions will be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or applicable court decisions.</li>
                <li><strong>Notices.</strong> You expressly consent to be contacted, for any and all purposes, at any telephone number, or physical or electronic address You provide Us. All notices or requests pertaining to this Contract will be in writing and may be sent by any reasonable means including by mail, email, facsimile, text message or recognized commercial overnight courier. Notices to You are considered delivered when sent to You by email or fax number that You provided to Us, or three (3) days after mailing to the street address You provided.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.renewabilityOpen }
          _toggleFunc={ () => this.toggle('renewabilityOpen') }
          header='Renewability'
          content={
            <React.Fragment>
              <p>This Contract is not renewable.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.transferabilityOpen }
          _toggleFunc={ () => this.toggle('transferabilityOpen') }
          header='Transferability'
          content={
            <React.Fragment>
              <p>This Contract cannot be transferred to any other party or item.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.entireAgreementOpen }
          _toggleFunc={ () => this.toggle('entireAgreementOpen') }
          header='Entire Agreement'
          content={
            <React.Fragment>
              <p>This Service Contract; including the terms, conditions, limitations, exceptions and exclusions, Your Contract Purchase Receipt and Device Purchase Receipt (if provided separately), constitute the entire agreement between Us and You and no representation, promise or condition not contained herein shall modify these items, except as required by law.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.statesOpen }
          _toggleFunc={ () => this.toggle('statesOpen') }
          header='Special State Requirements'
          content={
            <React.Fragment>
              <p><strong>Regulation of service contracts may vary widely from state to state. Any provision within this Contract that conflicts with the laws of the state where You live shall automatically be considered to be modified in conformity with applicable state laws and regulations as set forth below. The following state specific requirements apply if Your Service Contract was purchased in one of the following states and supersede any other provision within Your Service Contract terms and conditions to the contrary.</strong></p>
              <p><strong>Alabama: </strong>If You need to file a Claim under this Service Contract, You must obtain authorization by calling the Administrator at 1-888-271-1934 or by visiting <a href='http://www.MyProtectionPlan360.com/'>www.MyProtectionPlan360.com</a>. If You need authorization when the Administrator’s office is closed, You may obtain prior authorization by visiting <a href='http://www.MyProtectionPlan360.com/'>www.MyProtectionPlan360.com</a> any time. Failure to obtain prior authorization may result in non-payment. CANCELLATION is amended as follows: Any refund may be credited to any outstanding balance of Your account and the excess, if any, returned to You.</p>
              <br /><p><strong>Arizona: </strong>WAITING PERIOD – If the coverage under Your Service Contract is subject to a thirty (30) day Waiting Period, as confirmed on Your Contract Purchase Receipt, thirty (30) days will be added to the Term of Your Service Contract. WHAT IS NOT COVERED – We shall not provide coverage only for those specifically listed items in the "WHAT IS NOT COVERED" section which occurred while owned by You. "Pre-existing conditions" is deleted and replaced with: conditions that were caused by You or known by You prior to purchasing this Service Contract. WHAT IS NOT COVERED ITEM S) is amended as follows: Adjustment, manipulation, modification, removal or unauthorized repairs of any internal component/part of a Covered Device performed after the effective date of this Service Contract by anyone other than a service center/technician authorized by the Administrator or Us.</p>
              <br /><p><strong>California: </strong>Warrantech Consumer Product Services, Inc. (License No. SA-1) is the Service Contract Administrator and AMT Warranty Corp. (License No. SA-42) is the Obligor for this Service Contract. CANCELLATION: is amended as follows: This Service Contract may be canceled by the Service Contract holder for any reason, including, but not limited to, the Device covered under this Service Contract being sold, lost, stolen or destroyed. If You decide to cancel Your Service Contract, and Your cancellation notice is received by the Administrator within thirty (30) days for a home electronic, or within sixty (60) days for all other covered products, of the date You received the Service Contract, and You have made no Claims against the Service Contract, You will be refunded the full Service Contract price paid by You; or if You have made Claims against the Service Contract or Your Service Contract is canceled by written notice after thirty (30) days for a home electronic, or after sixty (60) days for all other covered products, from the date You received this Service Contract, You will be refunded a prorated amount of the Service Contract price paid by You, less any Claims paid.</p>
              <br /><p><strong>Connecticut: </strong>In the event of a dispute with Administrator, You may contact The State of Connecticut, Insurance Department, P.O. Box 816, Hartford, CT 06142-0816, Attn: Consumer Affairs. The written complaint must contain a description of the dispute, the purchase or lease price of the Product, the cost of repair of the Product and a copy of the warranty Service Contract. You may cancel Your Service Contract if the covered Product is sold, lost, stolen, or destroyed.</p>
              <br /><p><strong>Florida: </strong>This Service Contract is between the Provider, Technology Insurance Company, Inc. (License No. 03605) and You, the purchaser. The rates charged to You for this Service Contract are not subject to regulation by the Florida Office of Insurance Regulation. You may cancel Your Service Contract by informing the Administrator, WCPS of Florida, Inc. (License No. 80202) of Your cancellation request. CANCELLATION: is amended as follows: In the event the Service Contract is canceled by You, return of the premium shall be based upon ninety percent (90%) of the unearned pro rata premium less any Claims that have been paid or less the cost of repairs made on Your behalf. In the event the Service Contract is canceled by the Administrator or Provider, return of the premium shall be based upon one hundred percent (100%) of the unearned pro rata premium less any Claims that have been paid or less the cost of repairs made on Your behalf.</p>
              <br /><p><strong>Georgia: </strong>This Service Contract will be interpreted and enforced according to the laws of the state of Georgia. WHAT IS NOT COVERED ITEM S) is amended as follows: Adjustment, manipulation, modification, removal or unauthorized repairs of any internal component/part of a Covered Product performed after the effective date of this Service Contract by anyone other than a service center/technician authorized by the Administrator or Us. CANCELLATION: WE MAY ONLY CANCEL THIS CONTRACT FOR: ITEM 3. is deleted and replaced with: Fraud by You. WAITING PERIOD – If the coverage under Your Service Contract is subject to a thirty (30) day Waiting Period, as confirmed on Your Contract Purchase Receipt, thirty (30) days will be added to the Term of Your Service Contract.</p>
              <br /><p><strong>Illinois: </strong>Covered items must be in place and in good operating condition on the effective date of coverage and become inoperative due to normal wear and tear after the effective date of this Service Contract.</p>
              <br /><p><strong>Indiana: </strong>This Contract is not insurance and is not subject to Indiana insurance law. Your proof of payment to the issuing Retailer for this Service Contract shall be considered proof of payment to the insurance company which guarantees Our obligations to You. The GUARANTY section of this Contract is deleted and replaced with: If We fail to perform or make payment due under this Contract within sixty (60) days after You request the performance or payment, You may request the performance or payment directly from the insurer, Wesco Insurance Company, at 866-505-4048 or 59 Maiden Lane, 43rd Floor, New York, NY 10038., including any applicable requirement under the Contract that the Provider refund any part of the cost of the Contract upon cancellation of the Contract.</p>
              <br /><p><strong>Nevada: </strong>This Contract provides coverage that is excess over any other applicable coverage. Only unauthorized product repairs, modifications or alterations performed after the effective date of the Service Contract, or damages arising from such actions are excluded. CANCELLATION: WE MAY ONLY CANCEL THIS CONTRACT FOR: is deleted and replaced with: The Provider of this Service Agreement may cancel this Service Agreement within seventy (70) days from the date of purchase for any reason. After seventy (70) days, the Provider may only cancel this Service Agreement for fraud by You, material misrepresentation by You, nonpayment by You or a substantial breach of duties by You relating to the covered property or its use. If We cancel this Service Agreement, We shall mail a written notice to You at Your last known address at least fifteen (15) days prior to cancellation. The notice will state the effective date and the reason for the cancellation. If We cancel this Contract, You will receive a pro-rata refund based upon the same criteria as outlined above. <strong>For Used Devices Only: Coverage under Your Plan begins after a Waiting Period of thirty (30) days from the Contract purchase date.</strong></p>
              <br /><p><strong>New Hampshire: </strong>In the event You do not receive satisfaction under this Service Contract, You may contact the New Hampshire Insurance Department at 21 South Fruit Street, Suite 14, Concord, NH 03301, 603-271-2261.</p>
              <br /><p><strong>New Mexico: </strong>The Provider of this Service Contract may cancel this Service Contract within seventy (70) days from the date of purchase for any reason. After seventy (70) days, the Provider may only cancel this Service Contract for fraud, material misrepresentation, nonpayment by You, or a substantial breach of duties by You relating to the covered property or its use.</p>
              <br /><p><strong>North Carolina: </strong>CANCELLATION: WE MAY ONLY CANCEL THIS CONTRACT FOR: is deleted and replaced with: 1. Non-payment of the Contract Purchase Price/fee by You; or 2. A direct violation of the Service Contract by You. If We cancel this Contract, You will receive a pro-rata refund based upon the same criteria as outlined above.</p>
              <br /><p><strong>Oklahoma: </strong>The Service Warranty Association is AMT Warranty Corp., Oklahoma Identification #862268. This is not an insurance contract. Coverage afforded under this Service Contract is not guaranteed by the Oklahoma Insurance Guaranty Association. The CANCELLATION section of this Contract is deleted in its entirety and replaced with the following: If You cancel this Service Contract within the first thirty (30) days and no Claim has been authorized or paid within the first thirty (30) days, We will refund the entire Service Contract Purchase Price paid by You. If You cancel this service warranty after the first thirty (30) days, or have made a Claim within the first thirty (30) days, return of premium shall be based upon ninety percent (90%) of the unearned pro rata premium less the actual cost of any service provided under the Service Contract. If We cancel this Service Contract, return of premium shall be based upon one hundred percent (100%) of unearned pro rata premium less the actual cost of any service provided under the Service Contract. WAITING PERIOD – If the coverage under Your Service Contract is subject to a thirty (30) day Waiting Period, as confirmed on Your Contract Purchase Receipt, thirty (30) days will be added to the Term of Your Service Contract.</p>
              <br /><p><strong>Oregon: </strong>This Service Contract is an agreement between the Obligor/Provider, AMT Warranty Corp., 59 Maiden Lane, 43rd Floor, New York, NY 10038, (866) 327-5818 and You.</p>
              <br /><p><strong>South Carolina: </strong>If You have any questions regarding this Service Contract, or a complaint against the Obligor, You may contact the South Carolina Department of Insurance at 1201 Main Street, Suite 1000, Columbia, South Carolina 29201, (803) 737-6160.</p>
              <br /><p><strong>Texas: </strong>Warrantech Consumer Product Services, Inc., service contract administrator No. 187. If You have any questions regarding the regulation of the Service Contract Provider or a complaint against the Obligor, You may contact the Texas Department of Licensing &amp; Regulation, 920 Colorado, P.O. Box 12157, Austin, Texas 78711, (800) 803-9202. You may return this Service Contract within thirty (30) days of the date of purchase of this Service Contract. You may apply for reimbursement directly to the insurer if a refund or credit is not paid before the forty-sixth (46th) day after the date on which the Service Contract is canceled.</p>
              <br /><p><strong>Utah: </strong>The Provider/Obligor is AMT Warranty Corp., 59 Maiden Lane, 43rd Floor, New York, NY 10038, 866-327-5818. Coverage afforded under this Service Contract is not guaranteed by the Property and Casualty Guaranty Association This Service Contract or warranty is subject to limited regulation by the Utah Insurance Department. To file a complaint, contact the Utah Insurance Department. If You need to file a Claim under this Service Contract, You must obtain authorization by calling the Administrator at 1-888-271-1934 or by visiting <a href='http://www.MyProtectionPlan360.com/'>www.MyProtectionPlan360.com</a>. If authorization is needed when the Administrator’s office is closed, You may obtain prior authorization by visiting <a href='http://www.MyProtectionPlan360.com/'>www.MyProtectionPlan360.com</a> at any time. Failure to obtain prior authorization may result in non-payment. This Service Contract does not provide any coverage for any pre-existing condition. CANCELLATION: WE MAY ONLY CANCEL THIS CONTRACT FOR: is amended as follows: If We cancel this Service Contract for Items 2 or 3, such cancellation will be effective thirty (30) days after mailing of notice.</p>
              <br /><p><strong>Washington: </strong>You may file a Claim directly with Wesco Insurance Company at any time, at 59 Maiden Lane, 43rd Floor, New York, NY 10038 or 866-505-4048. The State of Washington is the jurisdiction for any civil action in connection with this Contract. WHAT IS NOT COVERED – We shall not provide coverage only for those specifically listed items in the "WHAT IS NOT COVERED" section.</p>
              <br /><p><strong>Wisconsin: </strong>
                <p><strong>THIS CONTRACT IS SUBJECT TO LIMITED REGULATION BY THE OFFICE OF THE COMMISSIONER OF INSURANCE.</strong></p>
                <p><strong>CANCELLATION</strong> is deleted and replaced as follows: You may cancel this Service Contract at any time. If this Service Contract is canceled within thirty (30) days of the date of purchase and no Claims have been paid, the Administrator shall return one hundred percent (100%) of the purchase price paid and the Service Contract shall be void. The right to void the Service Contract applies only to the original purchaser of the Service Contract. If the refund is not paid or credited within forty-five (45) days after return of the Service Contract to Us, We shall pay a ten percent (10%) per month penalty of the refund amount outstanding, which We shall add to the amount of the refund.</p>
                <p>For service Contracts canceled subsequent to the period stated in the preceding paragraph or if a Claim has been made under this service Contract within such period, We shall refund one hundred percent (100%) of the unearned pro rata Provider fee, less any Claims paid.</p>
                <p>If You request cancellation due to a total loss of Your Product which is not covered by a replacement under the terms of Your Service Contract, the Administrator shall return one hundred percent (100%) of the unearned pro-rata Service Contract Purchase Price paid, less Claims paid.</p>
                <p>We may only cancel this Service Contract for material misrepresentation by You, nonpayment by You or a substantial breach of duties by You relating to the covered property or its use. If We cancel for any reason other than nonpayment, then We shall refund one hundred percent (100%) of the unearned pro rata Provider fee, less any Claims paid.</p>
                <p>If We cancel this Service Contract, We shall mail a written notice to You at Your last known address at least five (5) days prior to cancellation. The notice shall state the effective date of the cancellation and the reason for the cancellation.</p>
                <p>Unauthorized repairs may not be covered.</p>
                <p><strong>GUARANTY</strong> is deleted and replaced as follows: Our obligations under this Service Contract are insured under a service contract reimbursement insurance policy. Should We fail to pay any Claim or fail to replace the Product covered under this Service Contract within sixty (60) days after You provide proof of loss or, in the event You cancel this Service Contract and We fail to refund the unearned portion of the Service Contract Purchase Price or, if the Provider becomes insolvent or otherwise financially impaired, You are entitled to make a direct Claim against the insurer, Wesco Insurance Company, at 1-866-505-4048 or 59 Maiden Lane, 43rd Floor, New York, NY 10038 for reimbursement, payment or provision of this Service Contract</p>
              </p>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default NotebookLaptopProtection;
