import { connect } from 'react-redux';
import { checkLogin } from '../actions/userActions';
import { sendAnalytics } from '../actions/analyticsActions';
import App from '../components/App';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _checkLogin: () => {
      dispatch(checkLogin());
    },
    _sendAnalytics: () => {
      dispatch(sendAnalytics());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
