import React from 'react';

class VefifySuccess extends React.Component {
  componentDidMount() {
    document.title = 'Clyde | Success!';
  }

  render() {
    return (
    <section className="verify-container">
      Success! Please login now <a href="/login">Login</a>
    </section>
    );
  }
}

export default VefifySuccess;
