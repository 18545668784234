import { connect } from 'react-redux';
import {
  checkout, getAvailableContracts, clearRemovedItems,
  updateOrderTax, updateOrder, inflateLocalCart,
  removeItem, resetCheckout, getCartInfo, reAddToAvailableItems,
  checkHiClydeLinkSource, setHiClydeLinkSource,
} from '../actions/checkoutActions';
import { cancelModal } from '../actions/modalActions';
import Checkout from '../components/Checkout';
import { HiClydeCartLineItem } from '../types/LineItemTypes';
import { CartInfoParams } from '../types/CartTypes';

function mapStateToProps(state: any) {
  return {
    availableItems: state.checkout.availableItems,
    cartInfo: state.checkout.cartInfo,
    customer: state.checkout.customer,
    error: state.checkout.error,
    hiClydeLinkSource: state.checkout.hiClydeLinkSource,
    isProcessing: state.checkout.isProcessing,
    isSuccess: state.checkout.isSuccess,
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    lineitems: state.checkout.lineitems,
    removedItems: state.checkout.removedItems,
    tax: state.checkout.tax,
    total: state.checkout.total,
    totalContracts: state.checkout.totalContracts,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _checkout: (order: any) => {
      dispatch(checkout(order));
    },
    _getAvailableContracts: (lineItemId: string, shouldAddAllToOrder: boolean) => {
      dispatch(getAvailableContracts(lineItemId, shouldAddAllToOrder));
    },
    _updateOrder: (lineitem: HiClydeCartLineItem) => {
      dispatch(updateOrder(lineitem));
    },
    _removeItem: (lineitemId: string) => {
      dispatch(removeItem(lineitemId));
    },
    _resetCheckout: () => {
      dispatch(resetCheckout());
    },
    _getCartInfo: (cartInfo: CartInfoParams) => {
      dispatch(getCartInfo(cartInfo));
    },
    _inflateLocalCart: () => {
      dispatch(inflateLocalCart());
    },
    _updateOrderTax: () => {
      dispatch(updateOrderTax());
    },
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _clearRemovedItems: () => {
      dispatch(clearRemovedItems());
    },
    _reAddItem: (product: any) => {
      dispatch(reAddToAvailableItems(product));
    },
    _checkHiClydeLinkSource: () => {
      dispatch(checkHiClydeLinkSource());
    },
    _setHiClydeLinkSource: (hiClydeLinkSource: string) => {
      dispatch(setHiClydeLinkSource(hiClydeLinkSource));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
