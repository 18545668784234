import React from 'react';
import {
  Button,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';

import classnames from 'classnames';

interface ClaimQuestionsProps {
  formState: {
    description:string;
    errors:any;
    issueType:string;
    stillFunctional: boolean | null;
    usedAsIntended: boolean | null;
  };

  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  stepOptions: any[],
  productParts?: any[],
}

const ClaimQuestions = (props: ClaimQuestionsProps) => {
  const {
    formState: {
      errors,
      usedAsIntended,
      stillFunctional,
    },
    iterateStep,
    updateField,
  } = props;

  const usedAsIntendedClass = classnames(
    'radio-button-container__item',
    { selected: usedAsIntended },
  );

  const usedAsIntendedClassFalse = classnames(
    'radio-button-container__item',
    { selected: usedAsIntended === false },
  );

  const stillFunctionalClass = classnames(
    'radio-button-container__item',
    { selected: stillFunctional },
  );

  const stillFunctionalClassFalse = classnames(
    'radio-button-container__item',
    { selected: stillFunctional === false },
  );

  return (
    <section className={`claim-form__step claim-form__questions`}>
      <h2>File a claim</h2>
      <h3>Usage Questions</h3>

      <FormGroup tag='fieldset'>
        <span>Were you using this product as intended?</span>
        <div className="radio-button-container" data-test='intendedUseButtonGroup'>
          <div className={ usedAsIntendedClass }>
            <Label check className='claim-form__form__additional-question-label'>
              <Input
                name='usedAsIntended'
                onChange={ (e) => { updateField(e.target.name, true); } }
                checked={ usedAsIntended === true }
                type='radio' />
              Yes
            </Label>
          </div>
          <div className={ usedAsIntendedClassFalse }>
            <Label check className='claim-form__form__additional-question-label'>
              <Input
                name='usedAsIntended'
                onChange={ (e) => { updateField(e.target.name, false); } }
                checked={ usedAsIntended === false }
                type='radio' />
              No
            </Label>
          </div>
        </div>
      </FormGroup>

      <FormGroup tag='fieldset'>
        <span>Is the product still fully functional?</span>
        <div className="radio-button-container" data-test='productFunctionalButtonGroup'>
          <div className={ stillFunctionalClass }>
            <Label check className='claim-form__form__additional-question-label'>
              <Input
                name='stillFunctional'
                onChange={ (e) => { updateField(e.target.name, true); } }
                checked={ stillFunctional === true }
                type='radio' />
              Yes
            </Label>
          </div>
          <div className={ stillFunctionalClassFalse }>
            <Label check className='claim-form__form__additional-question-label'>
              <Input
                name='stillFunctional'
                onChange={ (e) => { updateField(e.target.name, false); } }
                checked={ stillFunctional === false }
                type='radio' />
              No
            </Label>
          </div>
        </div>
      </FormGroup>

      <Button
        className='button--general claim-form__step__next-button'
        onClick={ iterateStep() }
        data-test='next'
        disabled={(
          usedAsIntended === null
          || stillFunctional === null
          || errors.includes('usageQuestions')
        )}>
        Next
      </Button>
    </section>
  );
};

export default ClaimQuestions;
