import React from 'react';
import { Button } from 'reactstrap';

const ResolutionError = ({ type, _closeModal }: { type: string, _closeModal: any }) => (
  <>
    <h3 className='claim-form__response__header'>Error processing { type }.</h3>
    <p className='claim-form__handoff__next-steps'>
      Please contact Clyde support.
    </p>
    <Button
      onClick={ _closeModal }
      className='claim-form__response__out-button button--general'>
      Close
    </Button>
  </>
);

export default ResolutionError;
