import React from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';

import { ReactComponent as SuccessIllustration } from '../../../../images/success-illustration.svg';
import { ReactComponent as ResolvedRepair } from '../../../../images/resolved-repaired.svg';
import { ReactComponent as ClaimApproved } from '../../../../images/claim-approved.svg';
import { ReactComponent as ClaimUnderReview } from '../../../../images/claim-under-review.svg';
import { ReactComponent as PromoImage } from '../../../../images/claim-promo.svg';
import { ReactComponent as PayoutImage } from '../../../../images/claim-payout.svg';
import { ReactComponent as ConfirmedImage } from '../../../../images/claim-resolution-confirmed.svg';

import { denialReasonSwitcher } from '../../../Shared/claimHelper';
import ClickCopy from '../../../Shared/ClickCopy';
import { generateShopUrls } from '../claim-utilities';

interface ClaimSuccessInfoProps {
  claimInfo:any;
  contract?:any;
  showPayoutResolution?: boolean;
  _closeModal:any;
  _showNextStep?: any;
}

const ClaimSuccessInfo = (props:ClaimSuccessInfoProps) => {
  const {
    claimInfo: {
      claimState,
      payoutType,
      denialReason,
      serviceType,
      programType,
      promoCode,
      promoCodeValue,
      resolutionCost,
      resolutionState,
    },
    contract,
    contract: {
      shopName,
      shopUrl,
    },
    showPayoutResolution,
    _closeModal,
    _showNextStep,
  } = props;

  let headerText = '';
  let paragraphText:any = '';
  let successButton: any = (
    <Button
      onClick={ _closeModal }
      className='claim-form__response__out-button button--general'
      data-test='returnToProducts'>
      Return To Products
    </Button>
  );

  const claimSVGClass = classnames(
    'claim-form-success__block__svg',
    `claim__font--${claimState}`,
    { 'claim-form-success__block__svg--underClydeReview': claimState === 'underClydeReview' },
  );

  const claimSVGContainerClass = classnames(
    'claim-form__response__body__icon',
    'svg-wrapper--clyde-blue',
    { 'claim-form__response__body__icon--underClydeReview': claimState === 'underClydeReview' },
  );

  let successImage = <SuccessIllustration className={ claimSVGClass } />;

  let adjustedState = claimState;

  if (adjustedState === 'approved') {
    if (resolutionState) {
      adjustedState = resolutionState;
    }
  }

  adjustedState = serviceType === 'payout' && !showPayoutResolution
    ? 'approved' : adjustedState;

  switch (adjustedState) {
    case 'inProcess':
    case 'underClydeReview':
    case 'underAdminReview':
      headerText = 'Your claim needs to be reviewed.';
      paragraphText = "Your claim will be reviewed by a support specialist. Hold tight, you'll receive an email with next steps soon.";
      successImage = <ClaimUnderReview className={ claimSVGClass } />;
      break;
    case 'approved':
      headerText = 'Your claim is approved!';
      paragraphText = "We've got you covered! Hold tight, you'll receive an email with next steps soon.";
      successImage = <ClaimApproved className={ claimSVGClass } />;
      break;
    case 'resolutionInProgress':
      switch (programType) {
        case 'repair':
          headerText = 'You are approved for a repair!';
          paragraphText = `Your product will be fixed in no time! ${shopName} will be reaching out with details soon.`;
          successImage = <ResolvedRepair className={ claimSVGClass } />;
          successButton = (
            <Button
              onClick={ _showNextStep(1) }
              className='claim-form__response__out-button button--general'
              data-test='enterShippingInformation'>
              Enter Shipping Information
            </Button>
          );

          break;
        case 'replacement':
          headerText = 'You are approved for a replacement!';
          paragraphText = <>
            We'll send you a replacement. No need to send back the one you have. <br />
            Click below to confirm your address so that we can ship you a replacement.
          </>;
          successImage = <ClaimApproved className={ claimSVGClass } />;
          successButton = (
            <Button
              onClick={ _showNextStep(1) }
              className='claim-form__response__out-button button--general'
              data-test='enterShippingInformation'>
              Enter Shipping Information
            </Button>
          );
          break;
        default:
          break;
      }
      break;
    case 'resolutionFulfilled':
      if (serviceType === 'promoCode') {
        headerText = 'Your claim is approved! Collect your promo code below.';
        paragraphText = <>
          You have received a promo code of <strong>${ promoCodeValue }</strong> for the { contract.name }.<br />
          Visit the retailer's store to receive a brand new replacement!
        </>;
        successImage = <PromoImage className={ claimSVGClass } />;

        const { modifiedUrl } = generateShopUrls(shopUrl);

        successButton = (
          <div className='claim-form__response__promo-code-buttons'>
            <ClickCopy copyValue={ promoCode } />
            <a href={ modifiedUrl } target='_blank' rel='noreferrer'>
              <Button className='claim-form__response__out-button button--general'>
                Visit Store
              </Button>
            </a>
          </div>
        );
      } else if (serviceType === 'payout') {
        headerText = `Congrats, you have been approved for a direct payment of $${resolutionCost}`;
        paragraphText = "Select a method of payment and we'll deposit money right into your account. It's as simple as that! Click below to choose how you'd like to receive your payment.";
        successImage = <PayoutImage className={ claimSVGClass } />;

        successButton = (
          <Button
            onClick={ _showNextStep(1) }
            className='claim-form__response__out-button button--general'>
            Continue to Select Payout Method
          </Button>
        );
      }
      break;
    case 'payoutSuccess':
      headerText = 'Payout Confirmed.';
      paragraphText = payoutType && payoutType === 'check'
        ? 'Cha-ching! Your check will typically arrive in the next 8-10 days.'
        : 'Cha-ching! Your payout will be transferred in the next 48-72 hours.';
      successImage = <ConfirmedImage className={ claimSVGClass } />;
      break;

    case 'replaceSuccess':
      headerText = 'Details Submitted.';
      paragraphText = <>
        We will keep you updated on your replacement via email.<br />
        Sit back and relax, Clyde has you covered.
      </>;
      successImage = <ConfirmedImage className={ claimSVGClass } />;
      break;

    case 'repairSuccess':
      headerText = 'Details Submitted.';
      paragraphText = <>
        We will keep you updated on your repair via email.<br />
        Sit back and relax, Clyde has you covered.
      </>;
      successImage = <ConfirmedImage className={ claimSVGClass } />;
      break;

    case 'denied':
      headerText = 'Your claim is not covered.';
      paragraphText = denialReasonSwitcher(denialReason);
      break;
    default:
      break;
  }

  return (
    <>
      <span className={ claimSVGContainerClass }>{ successImage }</span>
      <h3 className='claim-form__response__header' data-test='resolutionLabel'>{ headerText }</h3>
      <p className='claim-form__handoff__next-steps' data-test='resolutionExplanation'>{ paragraphText }</p>
      { successButton }
    </>
  );
};

export default ClaimSuccessInfo;
