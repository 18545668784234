import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import parsePhoneNumber from 'libphonenumber-js';
import validator from 'validator';

dayjs.extend(utc);

export const createIssueTypeSet = (issueTypes: any[]) => {
  const typeSet = new Set();
  for (const type of issueTypes) {
    typeSet.add(type.name);
  }

  return typeSet;
};

export const validateClaim = (state: any, props: any) => {
  const {
    step,
    email,
    phone,
    secondaryPhone,
    isBusinessClaim,
    description,
    receiptFileName,
    failureDate,
    issueTypes,
    usedAsIntended,
    stillFunctional,
    optionalStepIncluded,
    formStepsNames,
  } = state;
  const {
    contract: {
      contractSaleCreated,
      contractAdh,
      receiptFile,
      csSource,
    },
  } = props;

  // Taking advantage of the fact that in JS classes are just functions and all functions
  // have a property 'name' we can use to validate step instead of using index and math
  const stepName = formStepsNames[step];
  const stateUpdate:any = {};
  const errors = [];

  const receiptFileMissing = !receiptFile && !receiptFileName && csSource === 'checkoutWidget';
  const issueTypeSet = createIssueTypeSet(issueTypes);
  const includesAccidental = step > 0 && issueTypeSet.has('droppedOrDamaged');
  const isUncovered = issueTypeSet.has('arrivedDamaged') || issueTypeSet.has('lossOrTheft');

  let stepSwitchValue = step;

  if (optionalStepIncluded) {
    // optionalStepIncluded is more or less mobile
    // which means step 3 is parts selection
    // and we just don't care if there are any parts selected
    // N.B. on desktop this is rolled into step 2, so no one cares

    if (stepSwitchValue === 2 || stepSwitchValue === 3) {
      stepSwitchValue = 1;
    } else if (stepSwitchValue > 3) {
      stepSwitchValue -= 2;
    }
  }

  // MRCY-829: move switch variables for ease of linting:
  // considered moving all variables and their logic here but I wanted to keep scope consistency
  let parsedPhone;
  let checkPhone;
  let includesLossTheft;
  let failureDateSplit;
  let dayjsToday;
  let dayjsFailure;
  let contractSaleDop;

  switch (stepName) {
    case 'ClaimContact':
      if (!validator.isEmail(email) && !props.email) {
        errors.push('email');
      }

      parsedPhone = parsePhoneNumber(phone, 'US');
      // Marker NAS 10/1/2020: Asking for (555) 555-5555 format means we're only taking US numbers for now.
      checkPhone = parsedPhone ? parsedPhone.formatNational() : '';
      if (!validator.isMobilePhone(checkPhone, 'any') && !props.phone) {
        errors.push('phone');
      }
      if (secondaryPhone) {
        const parsedSecondaryPhone = parsePhoneNumber(secondaryPhone, 'US');
        // Marker NAS 10/1/2020: Asking for (555) 555-5555 format means we're only taking US numbers for now.
        const checkSecondaryPhone = parsedSecondaryPhone ? parsedSecondaryPhone.formatNational() : '';
        if (!validator.isMobilePhone(checkSecondaryPhone, 'any')) {
          errors.push('secondaryPhone');
        }
      }
      if (typeof isBusinessClaim !== 'boolean') {
        errors.push('isBusinessClaim');
      }

      break;
    case 'ClaimType':
      includesLossTheft = issueTypes.findIndex((type:any) => (type.name === 'lossOrTheft')) > -1;
      if (includesLossTheft) {
        stateUpdate.handoffType = 'lossOrTheft';
        stateUpdate.step = -1;
        errors.push('type');
      }

      if (includesAccidental && !contractAdh) {
        stateUpdate.step = -1;
        stateUpdate.handoffType = 'nonAdhAccident';
        errors.push('type');
      }

      if (!issueTypes.length) {
        errors.push('type');
      }

      break;
    case 'ClaimQuestions':
      if (
        !isUncovered
        && (usedAsIntended === null || stillFunctional === null)
      ) {
        errors.push('usageQuestions');
      }
      break;
    case 'ClaimDescription':
      if (!description || (description && (description.length < 100 || description.length > 250))) {
        errors.push('description');
      }
      break;
    case 'ClaimDocumentation':
      if (receiptFileMissing) {
        errors.push('fileNames');
      }
      break;
    case 'ClaimDate':
      if (validator.isEmpty(failureDate) || failureDate.length < 8) {
        errors.push('Please enter a valid date.');
        break;
      }

      failureDateSplit = failureDate.split('/');
      if (failureDateSplit.length !== 3 && failureDateSplit.length !== 1) {
        errors.push('Please enter a valid date.');
        break;
      }

      dayjsToday = dayjs.utc();
      dayjsFailure = dayjs.utc(failureDate);
      contractSaleDop = contractSaleCreated ? dayjs.utc(contractSaleCreated) : dayjs.utc('2018-06-01');

      if (dayjsFailure.isAfter(dayjsToday, 'day')) {
        errors.push('The failure date cannot be in the future.');
        break;
      }

      if (dayjsFailure.isBefore(contractSaleDop, 'day')) {
        errors.push('The failure date must be after you purchased a contract.');
        break;
      }

      break;
    default:
      break;
  }
  return { stateUpdate, errors };
};

export const checkDateBeforeSubmission = (failureDate:string, contract: any) => {
  const {
    oemWarrantyLength,
    contractSaleCreated,
    deliveryDate,
    purchaseDate,
  } = contract;

  const dateProvided = deliveryDate || purchaseDate || contractSaleCreated;
  const oemStartTime = dateProvided ? dateProvided.slice(0, 10) : '2018-06-01';

  if (oemWarrantyLength) {
    const oemMonths = oemWarrantyLength % 12;
    const oemYears = Math.floor(oemWarrantyLength / 12);
    const splitContractStart = oemStartTime.split('-');
    splitContractStart[0] = Number(splitContractStart[0]) + oemYears;
    splitContractStart[1] = Number(splitContractStart[1]) + oemMonths;

    if (splitContractStart[1] > 12) {
      splitContractStart[1] -= 12;
      splitContractStart[0] += 1;
    }

    if (splitContractStart[1] < 10) {
      splitContractStart[1] = `0${splitContractStart[1]}`;
    }

    const oemExpiry = splitContractStart.join('-');

    if (validator.isBefore(failureDate, oemExpiry)) {
      return true;
    }
  }

  return false;
};

interface claimShape {
  contractSaleID: string;
  retailerEmail: string;
  phoneNumber: string;
  secondaryPhone: string;
  failureDate: string;
  description: string;
  email: string;
  fullName: string;
  sku: string;
  productName: string;
  purchaseDate: string;
}

export const buildEmailLink = (claimObject: claimShape) => {
  const {
    retailerEmail,
    phoneNumber,
    failureDate,
    description,
    fullName,
    sku,
    productName,
    purchaseDate,
  } = claimObject;

  const mailtoString = `mailto:${retailerEmail}?subject=Issue with My Product&body=`;
  const firstName = fullName ? fullName.split(' ')[0] : '';

  const body = `Hi there!

  Clyde has advised me that the issue I'm experiencing with my product is covered by the manufacturer's limited warranty. I'm hoping you can help resolve this for me.

  Please see information about my claim below:
  My name: ${fullName}
  My phone number: ${phoneNumber}
  Product: ${productName}
  Product SKU: ${sku}
  Date of Purchase: ${purchaseDate.slice(0, 10)}
  Date my product stopped working properly: ${failureDate}
  Description of the issue: ${description}

  Thanks for your help, and I look forward to your response!

  -- ${firstName}`;

  return mailtoString + encodeURIComponent(body);
};

export const generateShopUrls = (shopUrl: string) => {
  if (!shopUrl) {
    return {
      modifiedUrl: '',
      displayUrl: '',
    };
  }

  let modifiedUrl = shopUrl;
  let displayUrl = shopUrl;

  const prefix = shopUrl.split('.')[0];
  if (prefix.slice(0, 4) !== 'http') {
    modifiedUrl = `https://${shopUrl}`;
  } else {
    displayUrl = shopUrl.split('//')[1];
  }

  return { modifiedUrl, displayUrl };
};

// Helper methods
export const insertArrayAt = (array: any[], index: number, itemToInsert: any[] | string): any[] => {
  if (typeof itemToInsert === 'string') {
    Array.prototype.splice.apply(array, [index, 0, itemToInsert]);
  } else {
    Array.prototype.splice.apply(array, [index, 0, ...itemToInsert]);
  }
  return array;
};

export const removeArrayAt = (array: any[], index: number): any[] => {
  Array.prototype.splice.apply(array, [index, 1]);
  return array;
};
