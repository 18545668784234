import React from 'react';
import queryStringParser from 'query-string';

import Templates from '../Templates';
import UserHeader from '../../../containers/UserHeader';
import LoggedOutFooter from '../../Shared/LoggedOutFooter';

interface ContractTermsPageProps {
  contractTerms: any;
  isUser: string; // String from local storage that is really boolean
  location?: any;
  _getContractTerms: any;
  _changeTermsKey:any;
  _clearContractTerms: any;
  _setTermsError: any;
}

interface ContractTermsPageState {
  errors: any;
}

class ContractTermsPage extends React.Component<ContractTermsPageProps, ContractTermsPageState> {
  constructor(props: ContractTermsPageProps) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  componentDidMount() {
    document.title = 'Clyde | Contract Terms & Conditions';
    window.scrollTo(0, 0);
    this.loadContractTerms();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadContractTerms();
    }
  }

  componentWillUnmount() {
    this.props._clearContractTerms();
  }

  loadContractTerms = () => {
    const queryString = queryStringParser.parse(this.props.location.search);
    if (queryString.pcid) {
      this.props._getContractTerms({ pcid: queryString.pcid });
    } else if (queryString.cid && queryString.pid) {
      this.props._getContractTerms({ pid: queryString.pid, cid: queryString.cid });
    } else if (queryString.cid) {
      this.props._getContractTerms({ cid: queryString.cid });
    } else {
      this.props._setTermsError('invalid query params');
    }
  };

  render() {
    const { contractTerms, _changeTermsKey } = this.props;

    return (
      <React.Fragment>
        {
          contractTerms.errors.length === 0
            ? <React.Fragment>
                <main className="app-container">
                  <UserHeader />
                  <div className="contract-terms-page">
                    <Templates contractTerms={ contractTerms } isModal={ false } _changeTermsKey={ _changeTermsKey } />
                  </div>
                  <aside className="app-underlay"></aside>
                </main>
              </React.Fragment>
            : <main className="app-container">
                <UserHeader />
                <div className="contract-terms-page--404">
                  <h1>404</h1>
                  <p>Sorry, the Terms and Conditions you are looking for do not exist.</p>
                </div>
              </main>
        }
        <LoggedOutFooter />
      </React.Fragment>
    );
  }
}

export default ContractTermsPage;
