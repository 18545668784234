import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Button, Form, FormGroup, Label, Input, FormFeedback, FormText,
} from 'reactstrap';
import { ReactComponent as LoadingBars } from '../../images/loading-bars.svg';

interface SignupFormProps {
  _createUser: any;
  _clearAuthErrors: any;
  user: any;
  error: any;
  csrf: string;
}

interface SignupFormState {
  email?: string,
  password?: string,
  passwordConfirm?: string
}

class SignupForm extends React.Component<SignupFormProps, SignupFormState> {
  constructor(props:SignupFormProps) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordConfirm: '',
    };
  }

  componentDidMount() {
    document.title = 'Clyde | Signup';
  }

  componentWillUnmount() {
    this.props._clearAuthErrors();
  }

  handleChange = (event:any) => {
    this.setState({
      [event.currentTarget.name]: event.target.value,
    });
  };

  handleSubmit = (event:any) => {
    this.props._createUser(this.state, this.props.csrf);
    event.preventDefault();
  };

  handleBackToLogin = (event:any) => {
    window.location.assign('/login');
  };

  render() {
    const {
      isProcessing, error, isLoggedIn, emailVerified,
    } = this.props.user;

    const successBlock = <React.Fragment>
      <h2 className="auth-form-container__title" >Success!</h2>
      <p>Please check your email to verify your account.</p>
      <p className="auth-form-container__success-link fake-link" onClick={ this.handleBackToLogin } >Back to
      login</p>
    </React.Fragment>;

    const formBlock = <React.Fragment>
      <h2 className="auth-form-container__title" >Create Account</h2>
      <p className="auth-form-container__copy" >
        Sign up with the email you use to purchase products online for the best experience.
      </p>
      <Form className="auth-form-container__form" >
        <FormGroup>
          <Label for="email">Email Address</Label>
          <Input
            type="email" name="email"
            invalid={ error.fields && error.fields.indexOf('email') > -1 }
            id="email" placeholder=""
            data-test="email"
            value={this.state.email} onChange={this.handleChange}
            />
            <FormFeedback>{ error.clientDesc }</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            type="password" name="password"
            invalid={ error.fields && error.fields.indexOf('password') > -1 }
            id="password" placeholder=""
            data-test="password"
            value={this.state.password} onChange={this.handleChange}
          />
          <FormFeedback>{ error.clientDesc }</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="passwordConfirm">Re-enter Password</Label>
          <Input
            type="password" name="passwordConfirm"
            invalid={ error.fields && error.fields.indexOf('passwordConfirm') > -1 }
            id="passwordConfirm" placeholder=""
            data-test="passwordConfirm"
            value={this.state.passwordConfirm} onChange={this.handleChange}
          />
          <FormFeedback>{ error.clientDesc }</FormFeedback>
        </FormGroup>
        {
          this.props.error.clientDesc && this.props.error.fields && this.props.error.fields.indexOf('form') > -1
            ? <div className="clyde-form-feedback clyde-form-feedback--error">{ this.props.error.clientDesc }</div>
            : ''
        }
        <Button
          id="signup-button"
          data-test="createAccount"
          className="button--general auth-form-container__form__button"
          onClick={ this.handleSubmit }
          type="submit"
          disabled={ !!isProcessing }
        >
          { isProcessing ? <LoadingBars /> : 'Create Account' }
        </Button>
        <FormText className="auth-form-container__already-a-user">Already a user? &nbsp; <a id="login" href="/login" data-test="login">Log in</a></FormText>
      </Form>
    </React.Fragment>;

    return (
      <section className="auth-form-container">
        {
          (isLoggedIn && emailVerified)
            ? <Redirect to="/products" />
            : ''
        }
        {
          (isLoggedIn && !emailVerified)
            ? successBlock
            : formBlock
        }
      </section>
    );
  }
}

export default SignupForm;
