import { connect } from 'react-redux';
import { getContract, resetSelectedContract } from '../actions/contractActions';
import { changeTermsKey } from '../actions/contractTermsActions';
import { showModal, cancelModal } from '../actions/modalActions';
import { createClaim, createAdminHandoffClaim, resetClaimForm } from '../actions/claimActions';
import GuestContract from '../components/GuestContract';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    selectedContract: state.guestContract.selectedContract,
    findContractError: state.guestContract.error,
    newClaim: state.claim.newClaim,
    claimFormError: state.claim.claimFormError,
    claimFormSuccess: state.claim.claimFormSuccess,
    contractTerms: state.contractTerms,
    isProcessing: state.claim.isProcessing,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _getContract: (hash: any) => {
      dispatch(getContract(hash));
    },
    _resetSelectedContract: () => {
      dispatch(resetSelectedContract());
    },
    _createClaim: (claim: any) => {
      dispatch(createClaim(claim));
    },
    _createAdminHandoffClaim: (contractSaleId:any) => {
      dispatch(createAdminHandoffClaim(contractSaleId));
    },
    _resetClaimForm: () => {
      dispatch(resetClaimForm());
    },
    _showModal: (modalID: string) => {
      dispatch(showModal(modalID));
    },
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _changeTermsKey: (termsKey:string) => {
      dispatch(changeTermsKey(termsKey));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuestContract);
