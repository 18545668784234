import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useIsFirstRender } from '../hooks/checkFirstRender';

interface ProtectedRouteProps extends RouteProps {
  isUser: boolean | null;
  history?: any;
}

const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const {
    component: Component,
    path,
    isUser,
    location,
  } = props;
  const isFirstRender = useIsFirstRender();
  const lastPath = location?.pathname;

  if (isFirstRender && !isUser) {
    // Need to pass on the first render as default props would always cause
    // this to redirect.
    localStorage.setItem('redirectPath', lastPath || '');
    return <></>;
  }

  if (!isUser) {
    return <Redirect to='/login' />;
  }
  localStorage.removeItem('redirectPath');
  return <Route component={ Component } path={ path } />;
};

export default ProtectedRoute;
