import React from 'react';
import ReactDOM from 'react-dom';
import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import store from './store';

// @ts-ignore
const { HONEYBADGER_API_KEY, NODE_ENV } = window._env_;
const config = {
  apiKey: HONEYBADGER_API_KEY,
  environment: NODE_ENV || 'production',
};

const honeybadger = Honeybadger.configure(config);

ReactDOM.render(
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <Provider store={store}>
        <Router>
          <App/>
        </Router>
      </Provider>
    </HoneybadgerErrorBoundary>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
