import React from 'react';
import TermsAccordion from '../../Shared/TermsAccordion';

import { TermsProps } from '../../../../types/TermsTypes';

class GeneralProtection extends React.Component<TermsProps, any> {
  constructor(props: TermsProps) {
    super(props);
    this.state = {
      definitions: false,
      plan: false,
      coverage: false,
    };
  }

  toggle = (section: string) => {
    this.setState((state: any) => ({ [section]: !state[section] }));
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
          <p>This Plan is a legal contract. By purchasing this Plan, You understand it is a contract and acknowledge that You had the opportunity to read the following terms and conditions and applicable state variations. Unless revised by Us with at least thirty (30) days advance written notice to You, this Plan and the State Variations, along with the Declaration of Coverage, set forth the entire contract between You and Us.  No representation, promise or condition outside of this Plan shall modify these terms. This is not a contract of insurance</p>
          <p><strong>Obligor:</strong> The Company obligated under the Plan is listed on Your Declaration of Coverage.</p>
        </div>

        <TermsAccordion
          toggle={this.state.definitions}
          _toggleFunc={() => this.toggle('definitions')}
          header="I.Definitions"
          content={
            <React.Fragment>
              <ol>
                <li>“We”, “Us” and “Our” shall mean the Obligor, which is listed on the Declaration of Coverage.</li>
                <li>“Administrator” is the entity listed as Administrator on the Declaration of Coverage.</li>
                <li>“You”, “Your” means the individual or entity who purchased this Plan or the individual or entity to whom this Plan was transferred in accordance with these Terms and Conditions.</li>
                <li><strong>Authorized Repair Technician:</strong> The service provider We assign in response to a request due to a Service Event (“Service Request”).</li>
                <li><strong>Breakdown:</strong> A mechanical or electrical failure, or accident damage (if purchased) of the Covered Product(s) under this Plan; to perform its fundamental operation(s) in normal service, as defined by the manufacturer.</li>
                <li><strong>Coverage Amount:</strong> The maximum limit of liability this Plan will pay in authorized repairs for each Covered Product and/or the Total Plan Limit of Liability, if applicable, as set forth on the Declaration of Coverage and further described in Section IX below</li>
                <li><strong>Coverage Expiration Date:</strong> The last date of coverage under the Plan.</li>
                <li><strong>Covered Product(s) or Product(s):</strong> The product(s) or type(s) of product(s) covered by this Plan.</li>
                <li><strong>Coverage Start Date:</strong> The date when coverage starts under this Plan. The Coverage Start Date is the latter of the Plan Purchase Date, or the date following the completion of any Wait Period and/or manufacturer’s warranty, unless otherwise stated.</li>
                <li><strong>Coverage Term or Term:</strong> The length of time the Covered Product(s) is eligible for claims, starting on the Coverage Start Date.</li>
                <li><strong>Declaration of Coverage:</strong> The document that summarizes Your Covered Product(s), Covered Term, Coverage Start and Expiration Dates, along with any Service Fee, Wait Period, limits, and/or other coverage terms specific to Your Plan.</li>
                <li><strong>Monthly Payment Amount:</strong> If listed on Your Declaration of Coverage, this is the monthly amount You pay of the Total Plan Price.</li>
                <li><strong>Plan:</strong> This is defined in Section II.</li>
                <li><strong>Total Plan Price:</strong> The price You paid for this Plan at time of purchase or the amount of monthly payments you have made to fully pay for the plan.</li>
                <li><strong>Plan Purchase Date:</strong> The date You purchased this Plan.</li>
                <li><strong>Plan Seller:</strong> The company You purchased the Plan from.</li>
                <li><strong>Retail Cost:</strong> The purchase price of the Product(s), exclusive of taxes and fees.</li>
                <li><strong>Service Event (“Service Request”):</strong> The uniquely identified action of diagnosis and/or performance of the work, including parts and/or labor, to repair or replace any Covered Product in accordance with the provisions set forth in this Agreement.</li>
                <li><strong>Service Fee:</strong> The applicable service charge to be paid by You, if any, for each Service Event under the Plan as defined on the Declaration of Coverage.</li>
                <li><strong>Settlement:</strong> This is the method of fulfillment of a Service Request in lieu of repair or replacement.  Examples include, but are not limited to, check, gift card, and/or store credit.</li>
                <li><strong style={{ textDecoration: 'underline' }}>WAIT PERIOD: IF LISTED ON YOUR DECLARATION OF COVERAGE, THIS IS THE AMOUNT OF TIME BETWEEN THE PLAN PURCHASE DATE AND THE COVERFAGE START DATE.  IF DURING THE WAIT PERIOD A PRE-EXISITING CONDITINO RENDERS THE ITEM INELIGIBLE FOR COVERAGE, WE WILL CANCEL YOUR PLAN AND PROVIDE YOU WITH A FULL REFUND OF THE PLAN PRICE. </strong></li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={this.state.plan}
          _toggleFunc={() => this.toggle('plan')}
          header="II. The Plan"
          content={
            <React.Fragment>
              <p>These terms and conditions, the State Variations, and the Declaration of Coverage govern the Covered Product’s mechanical and electrical services and, if applicable, accidental damage from handling coverage (“Plan”) We will provide You for the product(s) identified on Your Declaration of Coverage as having coverage under this Plan (“Covered Product(s)”).  The Covered Product(s) IWWHIC-GEN001-11212include(s) accessories contained in the original Product packaging. Coverage under this Plan is in addition to the coverage provided under any applicable manufacturer’s warranty.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={this.state.coverage}
          _toggleFunc={() => this.toggle('coverage')}
          header="III. Coverage Period"
          content={
            <React.Fragment>
              <p>Your Coverage Start Date for this Plan is listed on Your Declaration of Coverage. Your Plan expires at the end of the Coverage Term from the date on which it started, unless it is renewed or cancelled, or our obligations under the Plan become fulfilled in their entirety, in accordance with section IX hereof.</p>
              <ol type="I">
                <li>
                  <p><strong>What is Covered:</strong> This Plan covers parts and labor costs to repair Your Product(s) in the event Your Product experiences a breakdown, which is not covered by an insurance policy, manufacturer’s warranty and/or other Plan.  Depending on the Product and failure circumstances, We will, at Our discretion:</p>
                  <ul>
                    <li>Repair Your Covered Product, at Our discretion, on-site, via mail-in or at local repair service,. Non-original manufacturer’s parts may be used for repair of the product if the manufacturer’s parts are unavailable or more costly;</li>
                    <li>Provide a Settlement reflecting the cost of a replacement product of equal features and functionality up to the Coverage Amount.  Settlement may be issued in form of a check, gift card or other electronic form of payment, or</li>
                    <li>
                      Replace Your Product with a product of like kind, quality and functionality, up to the Coverage Amount.  If We choose to replace Your product:
                      <ul>
                        <li>Technological advances may result in a replacement product with a lower selling price than the original Covered Product;</li>
                        <li>Replacement products and parts may be new or refurbished, at Our discretion, which meet the manufacturer’s specifications of the Covered Product or parts; and</li>
                        <li>Covered Products and parts which are replaced become Our property except where prohibited by law.</li>
                      </ul>
                    </li>
                  </ul>

                  {/* for spacing purposes */}
                  <p></p>
                  <p className="m-b-none"><strong>For All Products:</strong></p>
                  <p className="m-b-none">(a) Failure of integral electrical and mechanical components</p>
                  <p className="m-b-none">(b) Failure of lifting, heating, or cooling mechanics</p>
                  {/* for spacing purposes */}
                  <p></p>
                  <p className="m-b-none"><strong>For Furniture Products only:</strong></p>
                  <p className="m-b-none">(a) All accidental stains attributed to a single occurrence (excluding accumulation defined as a gradual build-up of dirt, dust, body oils and perspiration)</p>
                  <p className="m-b-none">(b) Accidental rips, tears, burns, and punctures</p>
                  <p className="m-b-none">(c) Accidental scratches, gouges, chips or dents that penetrate the top coat of hard surface finishes</p>
                  <p className="m-b-none">(d) Damage caused by nail polish remover</p>
                  <p className="m-b-none">(e) Heat marks caused by brief flame or heat</p>
                  <p className="m-b-none">(f) Water or beverage marks or rings</p>
                  <p className="m-b-none">(g) Failure of, or accidental damage to, frames, panels or springs</p>
                  <p className="m-b-none">(h) Failure of, or accidental damage to, sleeper, recliner, lifting, heating and/or vibrating mechanics</p>
                  <p className="m-b-none">(i) Failure of integral electrical components</p>
                  <p className="m-b-none">(j) Lifting or incident-specific chipping of veneers or laminates exposing the substrate</p>
                  <p className="m-b-none">(k) Warping</p>
                  <p className="m-b-none">(l) Loss of silvering on mirrors</p>
                  <p className="m-b-none">(m) Checking, cracking, bubbling or peeling of finish caused by a specific incident</p>
                  <p className="m-b-none">(n) Glass or mirror chipping or breakage</p>
                  {/* for spacing purposes */}
                  <p></p>
                  <p className="m-b-none"><strong>For Luggage and Clothing Product only:</strong></p>
                  <p className="m-b-none">(a) Failures due to normal wear and tear</p>
                  <p className="m-b-none">(b) Seam/Zipper Separation</p>
                  <p className="m-b-none">(c) Shoe sole detachment due to workmanship failure</p>
                  {/* for spacing purposes */}
                  <p></p>
                  <p className="m-b-none"><strong>In addition, this Plan also covers the following:</strong></p>
                  <p><strong>Power Surge:</strong> If Your Product requires electrical power and is plugged into a power surge protection device, this Plan covers parts and labor due to failure of Your Product as a result of power surge, as determined by an Authorized Repair Technician.</p>

                  <p className="m-b-none"><strong>Optional Coverages:</strong></p>
                  <p><strong>Food Loss:</strong> If listed on Your Declaration of Coverage, and Your Covered Product is a refrigerator or freezer and You incur a covered Service Request that results in food loss, You will be reimbursed by Payment for food spoilage up to the amount listed on the Your Declaration of Coverage per Coverage Term. Documented proof of loss will be required.</p>
                  <p><strong>Accidental Damage from Handling (ADH):</strong>Except as otherwise specified, if You purchased ADH as a part of Your coverage, it will be listed on Your Declaration of Coverage providing additional protection for damage due to drops, spills or liquid damage associated with the handling and use of Your Product, as intended by the manufacturer</p>
                  <p>ADH does not provide protection against theft, loss, damage during shipment, or reckless or abusive conduct associated with handling and use of Your Product, cosmetic damage and/or other damage that does not affect the functionality of Your Product.</p>
                  <p><strong>Battery Coverage:</strong>If You purchased optional battery coverage on Your Product, it will be listed on Your Declaration of Coverage and Your Plan will include up to one (1) battery repair or replacement during the first two (2) years of the Coverage Term, when the original rechargeable battery is defective as determined by Us.  We may require You to return Your original defective battery to Us to receive a replacement battery. Battery coverage is only available for Products that are new.</p>
                </li>

                {/* for spacing purposes */}
                <p></p>

                <li>
                  <p><strong>Your Responsibilities</strong></p>
                  <ol>
                    <li>Purchase the correct Plan for Your Product(s).</li>
                    <li>Retain and provide Us or Our Plan Administrator with a complete copy of proof of purchase, if requested at time of Service Request.</li>
                    <li>Properly maintain, inspect, store, care for, including clean, and/or use Your Product according to the manufacturer instructions, and if Your Product becomes damaged, You must take necessary steps to protect it against any further damage.  If We determine that any loss or damage has occurred as a direct result of not performing any of the foregoing, Your Service Request will be denied.</li>
                    <li>You are required to pay for Your Plan in full or have paid all required payments that are due.</li>
                    <li>You will be notified by Us if You have not paid a Monthly Payment Amount that is due.  You are required to contact Us immediately to make the required Monthly Payment Amount that is due.  Your Plan may be cancelled by Us, in accordance with Section XV if you have not paid Your Monthly Payment Amount by its due date.</li>
                  </ol>
                </li>

                {/* for spacing purposes */}
                <p></p>

                <li>
                  <p><strong>To Obtain Service</strong></p>
                  <p>To request service, please call the phone number listed on Your Declaration of Coverage.</p>
                  <ol type="A">
                    <li>Notice of any Breakdown must be given to Us via Our Plan Administrator immediately upon discovery and must have occurred during the Coverage Term.</li>
                    <li>We will not pay for any services or parts provided without Our or Our Plan Administrator’s prior authorization.</li>
                    <li>You are responsible to pay a Service Fee for each Service Event, if applicable</li>
                    <li>Prior to service being dispatch, the Plan must be paid in full or You must have paid all Monthly Payment Amounts due to Us at the time You request service. If You request service during a time in which there are unpaid Monthly Payment Amounts of the Total Plan Price due from You; regardless of whether such payments are currently due or overdue, We reserve the right to require full payment of the remaining unpaid balances prior to providing any services/benefits under this Plan, at Our sole discretion.</li>
                    <li>If work performed by Our Authorized Repair Technician under this Plan fails within 30 days, We or Our Plan Administrator will make the necessary repairs for no additional Service Fee. Any past due amounts on Plans will be required.</li>
                    <li>All covered repairs will be serviced by Authorized Repair Technicians.  If We or Our Plan Administrator cannot provide an Authorized Repair Technician to You, at Our discretion, We or Our Plan Administrator may approve the use of a service provider outside of Our network, of Your choice, provided they can show proof of insurance and are fully licensed to perform such service</li>
                    <li>After the Authorized Repair Technician’s diagnosis, if it is determined that coverage under this Agreement does not apply, or no Breakdown is discovered, You are required to pay the authorized Repair Technician directly for all charges incurred, including access and diagnosis. You may then choose to have any necessary repair completed at Your expense.</li>
                  </ol>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>What’s Not Covered</strong></p>
                  <ol type="A">
                    <li>
                      <p className="m-b-none"><strong>ALL PLANS:</strong></p>
                      <ol type="a">
                        <li><strong>Normal wear and tear, unless otherwise specifically covered under Section I;</strong></li>
                        <li><strong>Any pre-existing conditions that occur prior to the Coverage Start Date of this Plan;</strong></li>
                        <li><strong>Natural flaws, inherent design defects or manufacturer’s defects;</strong></li>
                        <li><strong>Intentional damage;</strong></li>
                        <li><strong>Lost, stolen or irretrievable items;</strong></li>
                        <li><strong>Any Product that is fraudulently described or materially misrepresented;</strong></li>
                        <li><strong>Secondary or collateral damage;</strong></li>
                        <li><strong>Maintenance, service, repair or replacement necessitated by loss or damage resulting from any cause other than normal use, storage and operation of the Product in accordance with the manufacturer’s specifications and owner’s manual;</strong></li>
                        <li><strong>Damage caused by exposure to weather conditions, improper electrical/power supply, improper equipment modifications, add-on products or accessories, attachments or installation or assembly, collision with any other object, vandalism, animal or insect infestation, corrosion, battery leakage, act of nature (any accident caused or produced by any physical cause which cannot be foreseen or prevented, such as storms, perils of the sea, tornadoes, hurricanes, floods and earthquakes) or any other force majeure or peril originating from outside the Product;</strong></li>
                        <li><strong>Damage caused by “accumulation,” including, without limitation, damage from any repeated use or gradual buildup of dirt, dust, oils or similar, such as hair and body oils, perspiration or darkened bodily contact areas;</strong></li>
                        <li><strong>Damage caused by: any improper care, negligence, neglect, intentional acts, misuse or abuse of the Product; any repair, replacement or handling of the Product other than as recommended or authorized by the manufacturer and/or Us; or any failure to comply with the manufacturer’s warranty;</strong></li>
                        <li><strong>Damage caused by cleaning methods, products or materials;</strong></li>
                        <li><strong>Defects due to the installation, assembly or hookup of Your Product;</strong></li>
                        <li><strong>Damage caused by transit, delivery, redelivery, removal or reinstallation of the Product, or the Product being moved between different locations or into or out of storage, including damage caused by packing or unpacking of the Product;</strong></li>
                        <li><strong>Claims made under any improperly or incorrectly purchased Protection Plan;</strong></li>
                        <li><strong>Except as otherwise provided, “cosmetic damage,” defined as any damages or changes to the physical appearance of a Product that does not impede or hinder its normal operating function as determined by Us, such as scratches, abrasions, peelings, dents, kinks, changes in color, texture, or finish or similar conditions;</strong></li>
                        <li><strong>Television or computer monitor screen imperfections, including “burn-in”.</strong></li>
                        <li><strong>Accidental damage or liquid damage, unless ADH coverage was offered and purchased as part of Your Plan;</strong></li>
                        <li><strong>Projector or rear projection TV bulbs;</strong></li>
                        <li><strong>Consumer replaceable or consumable batteries, unless battery coverage was offered and purchased as part of Your Plan;</strong></li>
                        <li><strong>Consumer replaceable or consumable items including but not limited to toner, ribbons, ink cartridges, cables, drums, belts, printer heads, blades, strings and trim;</strong></li>
                        <li><strong>Product used for heavy commercial, educational, rental or industrial use;</strong></li>
                        <li><strong>Product(s) with removed or altered serial numbers;</strong></li>
                        <li><strong>Manufacturer defects or equipment failure, which is covered by manufacturer’s warranty, manufacturer’s recall or factory bulletins (regardless of whether or not the manufacturer is doing business as an ongoing enterprise);</strong></li>
                        <li><strong>Damage to computer hardware, software and data or loss of software or data, caused by, including, but not limited to, viruses, application programs, network drivers, source code, object code or proprietary data, or any support, configuration, installation or reinstallation of any software or data;</strong></li>
                        <li><strong>“No Problem Found” diagnosis, intermittent and non-intermittent issues that are not failures of the Product (such as poor cell phone reception);</strong></li>
                        <li><strong>Any Product that is a demonstration/in-store model, or that is sold “as-is”;</strong></li>
                        <li value="54"><strong>A Product that is no longer in Your possession; and</strong></li>
                        <li value="81"><strong>Any failure, damage, repairs or loss that is covered under any other Plan, warranty, service plan and/or insurance.</strong></li>
                      </ol>
                    </li>

                    <li>
                      <p className="m-b-none"><strong>Specific to Electronics & Appliance Plans: In addition to the exclusions listed above, this Plan only covers the operating condition of the Covered Product and does not cover:</strong></p>
                      <ol type="a">
                        <li><strong>Non-operating or external parts, e.g. protective glass, housings, insulation, conduit, frames, cabinets, knobs, dials, drawers, handles, shelves, doors, hinges, light bulbs, projection bulbs, filters, hoses;</strong></li>
                        <li><strong>Any installed accessory item, e.g., gas or electronic connectors;</strong></li>
                        <li><strong>Any antennae or antennae system, any expansion of the channel or frequency range capabilities of the Covered Product, circuit adjustments required to receive any particular station, service or adjustments due to changes in external power or water supply, water and power connectors and connections, reception or normal signal; and</strong></li>
                        <li><strong>Speakers, except surround-sound home theater, remote controls, phonograph cartridges and stylus, headphones;</strong></li>
                      </ol>
                    </li>

                    <li>
                      <p className="m-b-none"><strong>Specific to Computers and Peripheral Equipment Plans: In addition to the exclusions listed above, this Plan does not cover damage caused by or due to:</strong></p>
                      <ol type="a">
                        <li><strong>Overheating caused by accumulation of dust, vermin or fan blockage, dropping, food and beverage spills, misuse and abuse;</strong></li>
                        <li><strong>Any storage media damaged by malfunctioning parts, improper installation of computer components or peripherals, repair or replacement of upgraded computer components when repair or replacement is required due to incompatibility of parts or incorrect installation, damage caused from refilled ink cartridges; and</strong></li>
                        <li><strong>Corruption of any program; data or setup information resident on any hard drives and internal or external removable storage devices, as a result of the malfunctioning or damage of an operating part, or as a result of any repairs or replacement under this Agreement</strong></li>
                      </ol>
                    </li>

                    <li>
                      <p className="m-b-none"><strong>Specific to Jewelry and Watch Plans: In addition to the exclusions listed above, this Plan only covers the operating condition of the Covered Product and does not cover:</strong></p>
                      <ol type="a">
                        <li><strong>Natural flaws, inherent design defects or manufacturer’s defects, including, but not limited to, flaws in materials, precious or semi-precious stones or gems;</strong></li>
                        <li><strong>Introduction of foreign objects into the Product;</strong></li>
                        <li><strong>Tampering with prongs, bezels or other elements designed to secure stones;</strong></li>
                        <li><strong>Failures caused by excessive or abusive treatment; and</strong></li>
                        <li><strong>Any Product that has been resized, repaired or serviced by an unauthorized third party.</strong></li>
                      </ol>
                    </li>

                    <li>
                      <p className="m-b-none"><strong>Specific to Luggage, Clothing, Furniture, Rug and Mattress Plans: In addition to the exclusions listed above, this Plan only covers the operating condition of the Covered Product and does not cover:</strong></p>
                      <ol type="a">
                        <li><strong>Products made of “X” coded fabric, dry cleaning only fabric, non-colorfast fabric or silk fabric;</strong></li>
                        <li><strong>Natural flaws, inherent design defects or manufacturer’s defects, including, but not limited to, natural inconsistencies in wood grains, fabrics, coloring or leathers; wood stains; delamination of microfiber; manufacturer’s defects of leather or upholstery;</strong></li>
                        <li><strong>Stains caused by or from incontinence, hair and body oils, perspiration, stomach contents, saliva, paints, dyes, bleaches, flooding, rust, fire (including cigarette burns), smoke or other caustic materials as determined by Us;</strong></li>
                        <li><strong>Damage caused the application of topical treatments to the Product;</strong></li>
                        <li><strong>Damage to the Product caused by gum, mold or mildew, fading, color loss, non-stain related discoloration, dust corrosion or similar;</strong></li>
                        <li><strong>Odors, Pet or animal damage from teeth, beaks or claws, urine or feces, blood, stomach contents, saliva or gland excretions;</strong></li>
                        <li><strong>Splitting, cracking and/or peeling of aniline and protected (A&P) leather , bonded leather, bicast leather or coated fabrics;</strong></li>
                        <li><strong>Scratches of any type;</strong></li>
                        <li><strong>Loss of resiliency or elasticity; and</strong></li>
                        <li><strong>Separation of seams along mattress seam lines.</strong></li>
                        <li><strong>Defects, stains, or damages caused as a result of, abuse, misuse, physical force or furniture that is in an unserviceable condition;</strong></li>
                        <li><strong>Neglect, theft, vandalism or malicious mischief;</strong></li>
                        <li><strong>Accidents unless otherwise noted under the Coverage section;</strong></li>
                        <li><strong>Collapse or explosion;</strong></li>
                        <li><strong>Spillage of any kind unless otherwise noted under the Coverage section;</strong></li>
                        <li><strong>Sun fade or direct exposure to sunlight, bright light or extreme heat, extreme temperature or humidity changes, atmospheric conditions, any heating process, and/or drying;</strong></li>
                        <li><strong>Signs of soiling include darkened areas where the body comes into contact with the furniture (these darkened areas are signs of soil build-up, which is not covered)</strong></li>
                        <li><strong>General maintenance and overall cleaning of the furniture</strong></li>
                        <li><strong>Damage due to harsh or corrosive chemicals;</strong></li>
                        <li><strong>Acids, including without limitation, dyes and inks (except ballpoint), plant food and fertilizer and bleach, gum;</strong></li>
                        <li><strong>Any non-operating part or decorative parts such as hinges, knobs, handles, or shelves;</strong></li>
                        <li><strong>Design deficiency;</strong></li>
                        <li><strong>Odors; and</strong></li>
                        <li><strong>Wear related issues, such as, but not limited to, fading, wear, seam separation, stress tears, loss of foam resiliency, pilling or fraying of any fabric on all types of furniture.</strong></li>
                      </ol>
                    </li>

                    <li>
                      <p className="m-b-none"><strong>Specific to Fitness and Sporting Goods: In addition to the exclusions listed above, this Plan only covers the operating condition of the Covered Product and does not cover:</strong></p>
                      <li><strong>Damages such as scratches, tears, cracks, or holes;</strong></li>
                      <li><strong>Belts or chains that come off track;</strong></li>
                      <li><strong>Damage due to abuse, accident, failure to properly assemble or maintain, tampering or modification of the unit, failure to follow instructions or warnings in the Owner’s Manual, misuse, mishandling, accident, exposure to weather, or Acts of God (such as floods, tornadoes, hurricanes, power surges, etc.; and</strong></li>
                      <li><strong>Damage caused by exceeding maximum user weights as defined in the products Owner’s manual or warning label.</strong></li>
                    </li>

                  </ol>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Manufacturer’s Warranty</strong></p>
                  <p>Parts and services covered during the manufacturer’s warranty period are the responsibility of the manufacturer. Your Product(s) may have a labor and/or parts warranty from the manufacturer that may provide additional or overlapping coverage with this Plan.  Review Your manufacturer’s warranty. Nothing in the Plan will limit or discharge any manufacturer’s obligations. If Your Covered Product is replaced by the manufacturer, Your replacement device will become the Covered Product under this Plan, provided You contact us to provide the serial number and any other information requested so that We can properly identify the new product as the Covered Product.</p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>No Lemon Policy:</strong></p>
                  <p>After three (3) unique Service Events (unique claim numbers issued for each Service Event) have been completed on an individual Covered Product for the same breakdown on the same part(s) (identical part number), and that Covered Product requires a fourth (4th) repair, with a new claim number, for the same breakdown on the same part (s) (identical part number) within a rolling 12 month period, as determined by Us, We will replace it, at our discretion, with a new or remanufactured product of like kind and quality that performs to the factory specifications of the original product or provide You a Settlement with a value equivalent to the original purchase price of the product,. This Plan shall be satisfied upon replacement of Your Covered Product or issuance of Your Settlement.</p>
                  <ol>
                    <li>You must complete the transaction within 90 days from the date of our No-Lemon Benefit authorization.</li>
                    <li>The following services do not qualify as a repair for purposes of determining the No-Lemon Benefit: ADH Coverage repairs and replacements; parts and service covered under the manufacturer’s warranty or by a manufacturer’s recall; preventative maintenance; cleanings; troubleshooting and diagnosis; any technical support services in relation to computer software-related issues; virus and/or spyware damage/removal; a valid no-fault-found determination; customer education; or accessory repairs/replacements (e.g., ice makers, chargers, disposable batteries).</li>
                  </ol>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong style={{ textDecoration: 'underline' }}>WAIT PERIOD</strong></p>
                  <p><strong style={{ textDecoration: 'underline' }}>A WAIT PERIOD APPLIES TO THIS PLAN IF LISTED ON YOUR DECLARATION OF COVERAGE PAGE.</strong></p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Service Fee:</strong></p>
                  <p><strong>A Service Fee applies to this Plan if listed on Your Declaration of Coverage.  The Service Fee will be required to be paid prior to any Service being provided.</strong></p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Limit of Liability</strong></p>
                  <p>The Limit of Liability is the maximum amount We will pay during the Term of this Plan. The Limit of Liability for each Covered Product is shown on the Declaration of Coverage. Should the amount paid by Us for authorized repairs, parts, and other coverage and benefits (e.g., Food Loss, Power Surge, etc.) for any individual Covered Product reach the Limit of Liability shown on the Declaration of Coverage during the Term, or if We replace Your Covered Product or provide You with a Settlement reflecting the cost of a replacement Product during the Term, no further coverage is provided for that Covered Product for the remainder of the Term, and the obligations of the Obligor, Plan Seller, and Plan Administrator under this Plan will be deemed fully satisfied by Us for that Covered Product.</p>
                  <p>This Plan may also be subject to a Total Plan Limit of Liability as shown on the Declaration of Coverage. Should the total amount paid by Us for authorized repairs, parts, and other coverage and benefits, Covered Product replacement, and/or Settlement for all Covered Product(s) reach the Total Plan Limit of Liability during the Term, no further coverage is provided for any Covered Product for the remainder of the Term, and the obligations of the Obligor, Plan Seller, and Plan Administrator under this Plan will be deemed fully satisfied by Us. <strong>THE TOTAL LIABILITY UNDER THIS PLAN WILL NOT EXCEED THE LIMIT OF LIABILITY FOR EACH COVERED PRODUCT OR THE TOTAL PLAN LIMIT OF LIABILITY, IF APPLICABLE, UNDER ANY CIRCUMSTANCES.</strong></p>
                  <p>In the event Your Plan is renewed, the Limit of Liability and Total Plan Limit of Liability, as applicable, will reset at the Coverage Start Date of each renewed Plan for the remainder of that renewal Term.</p>
                  <p><strong>IN NO EVENT WILL THE OBLIGOR, PLAN SELLER, OR PLAN ADMINISTRATOR BE LIABLE FOR SPECIAL, INDIRECT, CONSEQUENTIAL, OR INCIDENTAL DAMAGES, INCLUDING, BUT NOT LIMITED TO, PROPERTY DAMAGE, LOST TIME OR LOST DATA RESULTING FROM THE FAILURE OF ANY PRODUCT OR EQUIPMENT OR FROM DELAYS IN SERVICE OR THE INABILITY TO RENDER SERVICE, LOSS OF USE, LOSS OF BUSINESS, LOSS OF PROFITS, LOSS OF DATA, DOWN-TIME, AND CHARGES FOR TIME AND EFFORT RELATING DIRECTLY OR INDIRECTLY TO THIS PLAN.</strong></p>
                  <p>The Insurer and Obligor shall not be deemed to provide cover and neither the Insurer nor Obligor shall be liable to pay any claim or provide any benefit hereunder to the extent that the provision of such cover, payment of such claim or provision of such benefit would expose the Obligor or Insurer, their parent companies or their ultimate controlling entities to any sanction, prohibition or restriction under United Nations resolutions or the trade or economic sanctions, laws or regulations of the European Union or the United States of America.</p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Transfer of Plan</strong></p>
                  <p>This Plan may be transferred to a subsequent owner of the Product at no additional charge. To transfer, call the number listed on Your Declaration of Coverage. Proof of purchase receipt, as well as any service repair receipts must be transferred to the new owner.  The remaining Limit of Liability applies to the new owner.</p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Renewal of Plan</strong></p>
                  <p>At our discretion, We may offer You a renewal of this Plan.  The renewal term and price may vary based on the age, condition of the Covered Product, and current service costs at the time of the renewal.  If We elect to renew, the Limits of Liability applicable to each Covered Product and the Total Plan Limit of Liability, if applicable, set forth on the Declaration of Coverage and as further described in Section IX herein shall reset.  If We elect to offer You a new service plan, it may contain different coverage and benefits as compared to Your original Plan.  We are not responsible for giving You notice of the expiration of Your Plan. Therefore, You may not receive any communication from Us prior to Your Plan expiring unless We offer You a renewal of this Plan.</p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Cancellation of Plan</strong></p>
                  <p>You may cancel this Plan for any reason at any time.  To cancel the Plan, contact the Plan Seller by calling the number listed on Your Declaration of Coverage or mailing Your cancellation request to the Obligor listed on Your Declaration of Coverage.</p>
                  <ol>
                    <li>If You cancel this Plan within 30 days of the Contract Start Date, You will receive a 100% refund of the Total Plan Price paid less the actual cost of any service, labor, payments, reimbursements, replacements, parts, coverages and/or benefits received.</li>
                    <li>
                      <p className="m-b-none">After 30 days from the Contract Start Date:</p>
                      <ol type="a">
                        <li>If You cancel after the first thirty (30) days from purchase of this Plan, You will receive a pro rata refund of the Payment Amount paid by You, less the actual cost of any service, labor, payments, reimbursements, replacements, parts, coverages and/or benefits received and less a cancellation fee of twenty-five dollars ($25.00) or ten percent (10%) of the Plan Price, whichever is less. The cancellation of a Plan containing multiple Products, cancels coverage on ALL Products previously covered by the Plan.</li>
                        <li>If You are paying on a monthly basis or have a month-to-month Plan, Your Plan will run out through the end of the month paid and You will not be charged again; and You will not receive a refund.</li>
                      </ol>
                    </li>
                  </ol>
                  <p>NOTICE: If You cancel this Plan during a time in which there are unpaid Monthly Payment Amounts of the Total Plan Price due from You; regardless of whether such Monthly Payment Amounts are currently due or overdue, We reserve the right to deduct any or the entire portion of any such unpaid amounts from Your refund, if any.  If Your calculated refund results in You owing Us payment for services provided in advance of Our receipt of Your due Total Plan Price, We may bill You for the lesser of the net amount due to Us or the remaining unpaid Total Plan Price.  We will bill You any balance owed to Us through the same mechanism as any previous installment billings, or We will direct bill.</p>
                  <p>If We fail to pay the cancellation refund within 45 days of Your written request We will pay You a penalty of ten percent (10%) of the purchase price for each thirty (30) day period or portion thereof that the refund and any accrued penalties remain unpaid.</p>
                  <p>We reserve the right to cancel this Plan at any time and without prior written notice in the event of non-payment, material misrepresentation by You, or a substantial breach of duties by You.  If You are paying the Total Plan Price in monthly payments and have not paid a Monthly Payment Amount that is due, Your Plan may be cancelled by Us effective as of the last day of the month in which the last Monthly Payment Amount was paid. If We cancel this Plan for any other reason, written notice which includes the effective date of cancellation and reason for cancellation will be mailed to You at least thirty (30) days prior to termination. If this Plan was inadvertently sold to You on a product which was not intended to be covered by this Plan, We will cancel this Plan and return the full Total Plan Price or Monthly Payment Amount(s) paid by You.</p>
                </li>

                {/* for spacing purposes */}
                <p></p>
                <li>
                  <p><strong>Insurance</strong></p>
                  <p>This Plan is not a contract of insurance, but it is secured by an insurance policy provided by the Insurer listed on Your Declaration of Coverage. If, within 60 days, We have not paid a claim, provided You with a refund, or if You are otherwise dissatisfied, or We are no longer a going concern, You may make a claim directly to the Insurer by contacting the Insurer at the address or phone number listed above. Please enclose a copy of Your Plan when sending correspondence to the Insurer.</p>
                </li>

              </ol>

            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default GeneralProtection;
