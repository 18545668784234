import React from 'react';
import {
  Button, Form, FormGroup,
  Label, Input, FormFeedback,
} from 'reactstrap';
import validator from 'validator';
import applyMask from '../Shared/phoneMask';

interface AccountProfileFormProps {
  _changeProfile: any;
  _clearAccountMessages: any;
  userDetails: any;
  error: any;
  changeSuccess: any;
}
class AccountProfileForm extends React.Component<AccountProfileFormProps, { phone: string, isInvalidNumber: boolean }> {
  private email: React.RefObject<HTMLInputElement>;

  private fullName: React.RefObject<HTMLInputElement>;

  private phoneNumber: React.RefObject<HTMLInputElement>;

  constructor(props: AccountProfileFormProps) {
    super(props);
    this.email = React.createRef();
    this.fullName = React.createRef();
    this.phoneNumber = React.createRef();
    this.state = { phone: '', isInvalidNumber: true };
  }

  componentWillReceiveProps(nextProps: AccountProfileFormProps) {
    if (nextProps.userDetails !== this.props.userDetails) {
      const numberToSet = nextProps.userDetails ? nextProps.userDetails.phoneNumber : '';
      const validPhoneBoolToSet = !validator.isMobilePhone(`${numberToSet}`, 'any');
      this.setState({ phone: numberToSet, isInvalidNumber: validPhoneBoolToSet });
    }
  }

  componentWillUnmount() {
    this.props._clearAccountMessages();
  }

  handleChange = (event: any) => {
    if (event.target.name === 'phoneNumber') {
      const maskedPhoneNumberInput = applyMask(event.target.value);
      const validBool = !validator.isMobilePhone(`${maskedPhoneNumberInput}`, 'any');
      this.setState({
        phone: applyMask(maskedPhoneNumberInput),
        isInvalidNumber: validBool,
      });
    }
  };

  handleSubmit = (event: any) => {
    const changeObj: any = {};
    if (this.fullName.current && this.fullName.current.value) {
      changeObj.fullName = this.fullName.current.value;
    }

    if (this.phoneNumber.current && this.phoneNumber.current.value) {
      changeObj.phoneNumber = this.phoneNumber.current.value;
    }
    this.props._changeProfile(changeObj);
    event.preventDefault();
  };

  render() {
    return (
      <section className="profile-form-container clyde-shadow" data-test="profileCard">
        <h3 className="profile-form-container__title">Profile</h3>
        <Form className="profile-form-container__form" onSubmit={ (e) => this.handleSubmit(e) }>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              type="email" name="email"
              id="email" placeholder=""
              data-test="email"
              innerRef={this.email}
              disabled={ true }
              invalid={ this.props.error.fields && this.props.error.fields.indexOf('email') > -1 }
              defaultValue={this.props.userDetails ? this.props.userDetails.email : ''}
              onChange={ this.handleChange } />
            <FormFeedback>{ this.props.error.clientDesc }</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="fullName">Full Name</Label>
            <Input
              type="text" name="fullName"
              id="fullName" placeholder=""
              data-test="fullName"
              innerRef={ this.fullName }
              invalid={ this.props.error.fields && this.props.error.fields.indexOf('fullName') > -1 }
              defaultValue={ this.props.userDetails ? this.props.userDetails.fullName : '' }
              onChange={ this.handleChange } />
              <FormFeedback>{ this.props.error.clientDesc }</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="phoneNumber">Phone Number</Label>
            <Input
              type="text" name="phoneNumber"
              id="phoneNumber" placeholder=""
              data-test="phoneNumber"
              innerRef={ this.phoneNumber }
              invalid={
                (this.props.error.fields && this.props.error.fields.indexOf('phoneNumber') > -1)
                || (this.state.phone !== '' && this.state.isInvalidNumber)
              }
              value={ this.state.phone }
              onChange={ this.handleChange } />
              {
                this.props.error.clientDesc
                && <FormFeedback>{ this.props.error.clientDesc }</FormFeedback>
              }
              {
                this.state.isInvalidNumber
                && <FormFeedback data-test="formFeedback">Please input a valid phone number</FormFeedback>
              }
          </FormGroup>
          <Button
          className="button--general profile-form-container__form__button"
          disabled={Boolean(Object.keys(this.props.error).length) || this.state.isInvalidNumber}
          data-test="save"
          >
            Save
          </Button>
          {
            this.props.changeSuccess && this.props.changeSuccess.details
              ? <div className="clyde-form-feedback clyde-form-feedback--spacing clyde-form-feedback--success" data-test="formFeedback">
                  Profile successfully updated!
                </div>
              : ''
          }
        </Form>
      </section>
    );
  }
}

export default AccountProfileForm;
