import { connect } from 'react-redux';
import {
  getProducts, getProduct, markProductResolutionRead, clearProductError,
} from '../actions/productActions';
import { addToAvailableItems, resetCheckout } from '../actions/checkoutActions';
import { showModal, cancelModal } from '../actions/modalActions';
import Product from '../components/Product';
import { setError } from '../actions/errorActions';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    products: state.product.products,
    loadingProducts: state.product.loadingProducts,
    resolutionAlerts: state.product.resolutionAlerts,
    cart: state.checkout,
    isCheckoutSuccess: state.checkout.isSuccess,
    error: state.product.error,
    transferSuccess: state.product.transferSuccess,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _getProduct: (ID: any) => {
      dispatch(getProduct(ID));
    },
    _getProducts: (page: number, opts: any) => {
      dispatch(getProducts());
    },
    _addToAvailableItems: (product: any) => {
      dispatch(addToAvailableItems(product));
    },
    _resetCheckout: () => {
      dispatch(resetCheckout());
    },
    _markAsRead: (resolutionId: string) => {
      dispatch(markProductResolutionRead(resolutionId));
    },
    _showModal: (modalId: string) => {
      dispatch(showModal(modalId));
    },
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _setError: (error: string) => {
      dispatch(setError(error));
    },
    _clearError: () => {
      dispatch(clearProductError());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Product);
