import React from 'react';
import {
  Button, Form, FormGroup, Label, Input,
} from 'reactstrap';

interface AccountPasswordFormProps {
  _changePasswordLoggedIn: any;
  _clearAccountMessages: any;
  error: any;
  changeSuccess: any;
}

interface AccountPasswordFormState {
  password?: string;
  passwordConfirm?: string;
}

class AccountPasswordForm extends React.Component<AccountPasswordFormProps, AccountPasswordFormState> {
  constructor(props:AccountPasswordFormProps) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
    };
  }

  componentDidUpdate() {
    if (this.checkPWChangeSuccess()) {
      setTimeout(() => { this.props._clearAccountMessages(); }, 4000);
    }
  }

  componentWillUnmount() {
    this.props._clearAccountMessages();
  }

  checkPWChangeSuccess = () => !this.props.error.clientDesc && this.props.changeSuccess && this.props.changeSuccess.pw;

  handleChange = (event: any) => {
    this.setState({
      [event.currentTarget.name]: event.target.value,
    });
  };

  handleSubmit = (event: any) => {
    if (!this.checkPWChangeSuccess()) {
      this.props._changePasswordLoggedIn(this.state);
    }
    event.preventDefault();
  };

  render() {
    let saveButtonClassName = 'button--general account-password-form-container__form__button';
    saveButtonClassName += this.checkPWChangeSuccess() ? ' account-password-form-container__form__button--success' : '';
    return (
    <section className="account-password-form-container clyde-shadow" data-test="passwordCard">
      <h3 className="account-password-form-container__title">Password</h3>
      <Form className="account-password-form-container__form" onSubmit={ (e) => this.handleSubmit(e) }>
        <FormGroup>
          <Label for="password">New Password</Label>
          <Input
            type="password" name="password"
            id="password" placeholder="Enter new password"
            data-test="newPassword"
            value={ this.state.password }
            onChange={ this.handleChange } />
        </FormGroup>
        <FormGroup>
          <Label for="passwordConfirm">Confirm Password</Label>
          <Input
            type="password" name="passwordConfirm"
            id="passwordConfirm" placeholder="Re-enter new password"
            data-test="confirmPassword"
            value={ this.state.passwordConfirm }
            onChange={ this.handleChange } />
        </FormGroup>
        {
          this.props.error.clientDesc && this.props.changeSuccess && !this.props.changeSuccess.pw
            ? <div className="clyde-form-feedback clyde-form-feedback--error" data-test="formFeedback">{ this.props.error.clientDesc }</div>
            : ''
        }
        {
          this.checkPWChangeSuccess()
            ? <div className="clyde-form-feedback" data-test="formFeedback">Password successfully updated!</div>
            : ''
        }
        <Button className={ saveButtonClassName } data-test="save">Save</Button>
      </Form>
    </section>
    );
  }
}

export default AccountPasswordForm;
