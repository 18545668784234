const defaultState = {
  status: 200,
  error: '',
};

const appReducer = function user(state = defaultState, action:any) {
  const isFail = action.type.includes('FAIL_');
  if (isFail) {
    switch (action.status) {
      case 503:
        return {
          status: action.status,
          error: action.response,
        };
      default:
        return {
          status: action.status,
          error: action.response,
        };
    }
  } else {
    return state;
  }
};

export default appReducer;
