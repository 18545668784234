import React from 'react';
import classnames from 'classnames';
import ContractTransferFormInput from './ContractTransferFormInput';
import ContractTransferReview from './ContractTransferReview';
import { TransferOrderDataI } from '../../../types/ProductTypes';

interface ContractTransferBodyProps {
  step: number;
  transferOrderData: TransferOrderDataI;
  formErrors: any;
  setTransferOrderData: any;
  setStep: any;
}

const setContentForStep = (step: number, transferOrderData: TransferOrderDataI, setTransferOrderData: any, setStep: any, formErrors:any) => {
  let component;
  switch (step) {
    case 0:
      component = <ContractTransferFormInput transferOrderData={ transferOrderData } onInputChange={ setTransferOrderData } formErrors={ formErrors } />;
      break;
    case 1:
      component = <ContractTransferReview transferOrderData={ transferOrderData } setStep={ setStep }/>;
      break;
    case 2:
      component = <></>;
      break;
    default:
      break;
  }
  return component;
};

const ContractTransferBody = ({
  step,
  transferOrderData,
  setTransferOrderData,
  setStep,
  formErrors,
}: ContractTransferBodyProps) => {
  const content = setContentForStep(step, transferOrderData, setTransferOrderData, setStep, formErrors);
  const contractBodyTransferClass = classnames('contract-transfer-body', {
    'mb-0': step === 2,
  });
  return (
    <div className={ contractBodyTransferClass }>
      { content }
    </div>
  );
};

export default ContractTransferBody;
