import React, { Component } from 'react';
import { Button } from 'reactstrap';

interface clickCopyProps {
  copyValue: string;
  className?: string;
  prefix?: string;
  guideline?: string;
  _onClick?: any;
}

class ClickCopy extends Component<clickCopyProps, any> {
  state = { tipText: 'copy' };

  tipInterval:any = null;

  buttonRef:any = null;

  copyText = () => {
    if (typeof this.props._onClick === 'function') {
      this.props._onClick();
    }

    const element = this.buttonRef;
    element.select();
    document.execCommand('copy');
    this.setState({ tipText: 'copied!' });

    if (this.tipInterval) {
      clearInterval(this.tipInterval);
    }

    this.tipInterval = setInterval(() => {
      this.setState({ tipText: 'copy' });
    }, 3000);
  };

  render() {
    const {
      props: {
        copyValue, className, prefix, guideline,
      },
      state: { tipText },
      copyText,
    } = this;

    let buttonClass = 'button--general button--click-copy';

    if (typeof className === 'string') {
      buttonClass += ` ${className}`;
    }

    return (
      <Button
        className={ buttonClass }
        onClick={copyText}
        data-test='copy'
      >
        <div>
          { prefix && <span className='button--click-copy__prefix'>{prefix}:&nbsp;</span> }
          <div className='button--click-copy__input__wrapper'>
            <input
              className='button--click-copy__input'
              readOnly
              ref={ (node) => { this.buttonRef = node; } }
              value={ copyValue }
            />
            <span className='button--click-copy__copy-text' data-test='promoCode'>{ copyValue }</span>
          </div>
        </div>
        <span className='button--click-copy__tip'>{ tipText }</span>
        { guideline && <span className='button--click-copy__guideline' onClick={ copyText }>{ guideline }</span> }
      </Button>
    );
  }
}

export default ClickCopy;
