import React from 'react';
import UserHeader from '../../containers/UserHeader';
import LoggedOutFooter from '../Shared/LoggedOutFooter';

class UnsubSuccess extends React.Component {
  componentDidMount() {
    document.title = 'Clyde | Unsub';
  }

  render() {
    return (
      <React.Fragment>
        <main className="app-container">
          <UserHeader />
          <section className="unsub-container">
            You have been unsubscribed from our list.
          </section>
        </main>
        <LoggedOutFooter />
      </React.Fragment>
    );
  }
}

export default UnsubSuccess;
