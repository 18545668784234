import React from 'react';
import { Link } from 'react-router-dom';
import ProductContractDetailCard from '../Product/PDP/ProductContractDetailCard';
import { ReactComponent as BigRightArrow } from '../../images/big-right-arrow.svg';
import { ReactComponent as LeftArrow } from '../../images/left-arrow.svg';
import showPdpModal from '../Shared/pdpShowModal';

interface GuestProductContractProps {
  product?: any;
  contractTerms?: any;
  newClaim?: any;
  _resetSelectedContract: any;
  _showModal: any;
  _cancelModal: any;
  _changeTermsKey:any;
  _createAdminHandoffClaim:any;
}

const GuestProductContract: React.FC<GuestProductContractProps> = (props) => (
    <section className="guest-contract-container">
      <div onClick={ props._resetSelectedContract } className="fake-link pdp-back-link" >
        <span><LeftArrow /></span>
        <span>Back to Guest Access</span>
      </div>
      <ProductContractDetailCard
        newClaim={ props.newClaim }
        contractTerms={ props.contractTerms }
        product={ props.product }
        _showModal={ (modalID:string) => { showPdpModal(props._showModal, modalID); } }
        _cancelModal={ props._cancelModal }
        _changeTermsKey={ props._changeTermsKey }
        _createAdminHandoffClaim={ props._createAdminHandoffClaim }
        isGuest
      />
      <div className="guest-contract-container__cta" >
        <BigRightArrow />
        <p>
          Filed a claim before?<br />
          <Link to="/signup">Register with your purchase email</Link> to view your claim history
        </p>
      </div>
    </section>
);

export default GuestProductContract;
