import { HiClydeCartLineItem, AvailableItemType } from '../types/LineItemTypes';

const defaultState = {
  availableItems: [], // Meta data about the order products and contracts
  cartInfo: {}, // General cart info
  customer: null, // Customer info
  error: {},
  hiClydeLinkSource: null,
  isProcessing: false,
  isSuccess: false,
  lineitems: [],
  removedItems: [],
  tax: 0,
  total: 0, // Order total
  totalContracts: 0,
};

const convertAvailableItemsToLineItems = (availableItems: AvailableItemType[]) => {
  let total = 0;
  let tax = 0;
  let totalContracts = 0;
  const newLineitems = [];

  for (const product of availableItems) {
    if (product.contracts[0]) {
      const contract = product.contracts[0];
      const lineitemTotal = product.quantityAvailable
        * (contract.backendFee + contract.platformFee + contract.baseCost + contract.markup);
      const taxTotal = product.taxRate ? lineitemTotal * product.taxRate : 0;
      const newlineitem: HiClydeCartLineItem = {
        contractId: contract.contractId,
        lineitemId: product.lineitemId,
        quantity: product.quantityAvailable,
        lineitemTotal: lineitemTotal + taxTotal,
        tax: taxTotal,
      };
      total += newlineitem.lineitemTotal;
      tax += taxTotal;
      totalContracts += parseInt(newlineitem.quantity as string, 10);
      newLineitems.push(newlineitem);
    }
  }
  return {
    total,
    tax,
    totalContracts,
    newLineitems,
  };
};

const checkoutReducer = function user(state:any = defaultState, action:any) {
  let newLineitems:HiClydeCartLineItem[] = [];
  let newAvailableItems = [];
  let total = 0;
  let tax = 0;
  let totalContracts = 0;
  let newCart;
  let removedItems = [];

  switch (action.type) {
    case 'RE_ADD_TO_AVAILABLE_ITEMS':
      newAvailableItems = state.availableItems.concat(action.product);
      removedItems = state.removedItems.filter((li:any) => li.lineitemId !== action.product.lineitemId);
      newCart = convertAvailableItemsToLineItems(newAvailableItems);
      return {
        ...state,
        availableItems: newAvailableItems,
        removedItems,
        lineitems: newCart.newLineitems,
        total: newCart.total,
        tax: newCart.tax,
        totalContracts: newCart.totalContracts,
      };
    case 'CLEAR_REMOVED_ITEMS':
      return {
        ...state,
        removedItems: [],
      };
    case 'ADD_TO_AVAILABLE_ITEMS':
      return state.availableItems.find((item: any) => item.lineitemId === action.product.lineitemId)
        ? state
        : {
          ...state,
          availableItems: state.availableItems.concat(action.product),
        }; // If product is not in cart, add to cart
    case 'REMOVE_ITEM':
      total = 0;
      tax = 0;
      totalContracts = 0;
      removedItems = state.removedItems.concat(state.availableItems.filter((li:any) => (
        li.lineitemId === action.lineitemId
      )));
      newAvailableItems = state.availableItems.filter((li:any) => li.lineitemId !== action.lineitemId);
      newCart = convertAvailableItemsToLineItems(newAvailableItems);
      localStorage.setItem('localCart', JSON.stringify(newAvailableItems)); // Would rather this not here, but works
      return {
        ...state,
        availableItems: newAvailableItems,
        removedItems,
        lineitems: newCart.newLineitems,
        total: newCart.total,
        tax: newCart.tax,
        totalContracts: newCart.totalContracts,
      };
    case 'ADD_ALL_TO_ORDER':
      newCart = convertAvailableItemsToLineItems(state.availableItems);
      return {
        ...state,
        lineitems: newCart.newLineitems,
        total: newCart.total,
        tax: newCart.tax,
        totalContracts: newCart.totalContracts,
      };
    case 'UPDATE_ORDER':
      newLineitems = [];
      total = 0;
      tax = 0;
      totalContracts = 0;
      for (let lineitem of state.lineitems) {
        if (lineitem.lineitemId === action.lineitem.lineitemId) {
          const actionLineitem = action.lineitem;
          lineitem = actionLineitem;
        }
        total += lineitem.lineitemTotal;
        tax += lineitem.tax * lineitem.quantity;
        totalContracts += parseInt(lineitem.quantity, 10);
        newLineitems.push(lineitem);
      }
      return {
        ...state,
        lineitems: newLineitems,
        total,
        tax,
        totalContracts,
      };
    case 'ADD_TO_ORDER':
      // eslint-disable-next-line no-case-declarations
      const {
        contractId, lineitemId, quantity, lineitemTotal,
      } = action.lineitem;
      // eslint-disable-next-line no-case-declarations
      const lineitems = state.lineitems.push({
        contractId,
        lineitemId,
        quantity,
        lineitemTotal,
      });
      return {
        ...state,
        lineitems,
      };
    case 'REMOVE_FROM_ORDER':
      return {
        ...state,
      };
    case 'LOADING_GET_AVAILABLE_CONTRACTS':
      return {
        ...state,
        isProcessing: true,
      };
    case 'FAIL_GET_AVAILABLE_CONTRACTS':
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };
    case 'UPDATE_GET_AVAILABLE_CONTRACTS':
      newLineitems = [];
      for (const product of state.availableItems) {
        if (product.contracts) {
          continue;
        }
        // Need to reset the contracts to an empty array here when there are no contracts to register change
        product.contracts = [];
        for (const contract of action.contracts) {
          contract.recommendedPrice = contract.baseCost + contract.backendFee + contract.platformFee + contract.markup;
          if (
            contract.productId === product.productId
            && contract.allowedTerms
            && contract.allowedTerms.indexOf(contract.term) > -1
          ) {
            product.contracts.push(contract);
          }
        }
        newLineitems.push(product);
      }
      return {
        ...state,
        orderItems: newLineitems,
        isProcessing: false,
      };
    case 'LOADING_GET_CART_INFO':
      return {
        ...state,
        isProcessing: true,
      };
    case 'FAIL_GET_CART_INFO':
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };
    case 'UPDATE_GET_CART_INFO':
      newAvailableItems = action.cart;
      for (const item of newAvailableItems) {
        const newContracts = [];
        for (const contract of item.contracts) {
          if (item.allowedTerms.indexOf(contract.term) > -1) {
            newContracts.push(contract);
          }
        }
        item.contracts = newContracts;
      }
      newCart = convertAvailableItemsToLineItems(newAvailableItems);
      return {
        ...state,
        cartInfo: {
          shopLogo: action.cart[0].shopLogo,
          cartID: action.cart[0].cartID,
        },
        availableItems: newAvailableItems,
        lineitems: newCart.newLineitems,
        total: newCart.total,
        tax: newCart.tax,
        totalContracts: newCart.totalContracts,
        isProcessing: false,
      };
    case 'LOADING_CHECKOUT':
      return {
        ...state,
        isProcessing: true,
        isSuccess: false,
      };
    case 'FAIL_CHECKOUT':
      return {
        ...state,
        isProcessing: false,
        isSuccess: false,
        error: action.error,
      };
    case 'UPDATE_CHECKOUT':
      return {
        ...state,
        total: 0, // Extra paranoid setting of this to 0 in case of double charge
        tax: 0,
        isProcessing: false,
        isSuccess: true,
      };
    case 'STORE_CART_LOCAL':
      if (state.availableItems.length > 0) {
        localStorage.setItem('localCart', JSON.stringify(state.availableItems));
      }
      return {
        ...state,
      };
    case 'INFLATE_LOCAL_CART':
      try {
        newAvailableItems = JSON.parse(localStorage.getItem('localCart') || '[]');
        if (newAvailableItems.length === 0) {
          throw new Error('Trying to inflate empty cart');
        }
        newCart = convertAvailableItemsToLineItems(newAvailableItems);
        return {
          ...state,
          availableItems: newAvailableItems || [],
          lineitems: newCart.newLineitems,
          total: newCart.total,
          tax: newCart.tax,
          totalContracts: newCart.totalContracts,
          isProcessing: false,
        };
      } catch (e) {
        localStorage.setItem('localCart', '');
        return {
          ...state,
        };
      }
    case 'RESET_CHECKOUT':
      localStorage.setItem('localCart', '');
      return {
        ...defaultState,
        isSuccess: false,
      };
    case 'CHECK_HI_CLYDE_LINK_SOURCE':
      return {
        ...state,
        hiClydeLinkSource: sessionStorage.getItem('hiClydeLinkSource') || null,
      };
    case 'SET_HI_CLYDE_LINK_SOURCE':
      sessionStorage.setItem('hiClydeLinkSource', action.hiClydeLinkSource);
      return {
        ...state,
        hiClydeLinkSource: action.hiClydeLinkSource,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
