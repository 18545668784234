import React from 'react';
import TermsAccordion from '../../Shared/TermsAccordion';

import { TermsProps, TermsState } from '../../../../types/TermsTypes';

class Bike extends React.Component<TermsProps, TermsState> {
  constructor(props: TermsProps) {
    super(props);
    this.state = {
      cancellationOpen: false,
      coveredComponentsOne: false,
      coveredComponentsTwo: false,
      coveredOpen: false,
      definitionsOpen: false,
      exchangedGoodsOpen: false,
      exclusionsOpen: false,
      howServiceIsProvidedOpen: false,
      liabilityOpen: false,
      majorPlanCoveragesOpen: false,
      majorPlanExclusionsOpen: false,
      planTermsOpen: false,
      purchaserRecordsOpen: false,
      renewOpen: false,
      repeatServiceOpen: false,
      responsibilitiesOpen: false,
      transferabilityOpen: false,
    };
  }

  toggle = (section: string) => {
    this.setState((state: any) => ({ [section]: !state[section] }));
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
          <p><strong>This is a legal contract referred to hereinafter as the “Service Contract” and indicates the terms and conditions, limitations, exceptions and exclusions included herein and Your purchase receipt and constitute the entire agreement. By purchasing it, You understand that it is such a contract and acknowledge that You have had the opportunity to read the terms and conditions set forth herein. This is not a contract of insurance. Please read these terms and conditions carefully so that You fully understand Your coverage under this Service Contract.</strong></p>
        </div>

        <TermsAccordion
          toggle={ this.state.majorPlanCoveragesOpen }
          _toggleFunc={ () => this.toggle('majorPlanCoveragesOpen') }
          header="Major Plan Coverages"
          content={
            <React.Fragment>
              <ul>
                <li>Mechanical and/or Electrical Failure</li>
                <li>No Deductibles</li>
                <li>24/7 Customer Service Availability</li>
                <li>Plan Ownership Transferability</li>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.majorPlanExclusionsOpen }
          _toggleFunc={ () => this.toggle('majorPlanExclusionsOpen') }
          header="Major Plan Exclusions"
          content={
            <React.Fragment>
              <ul>
                <li>Any Physical or Accidental Damage</li>
                <li>Unauthorized Repairs, Consumer Replaceable Items, Add-On Items, and/or Non-Operational Components</li>
                <li>Service for No Problem Found, Customer Education or Non-Failures</li>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.definitionsOpen }
          _toggleFunc={ () => this.toggle('definitionsOpen') }
          header="Definitions"
          content={
            <React.Fragment>
              <p>“You” and “Your” indicates the purchaser of this Service Contract or the person to whom it was properly transferred. “We”, “Us”, and “Our” indicate the Obligor/Provider/Administrator of this Service Contract. Unless indicated below or specified in the individual state disclosure, Bankers Warranty Group, Inc., 11101 Roosevelt Blvd. N, St. Petersburg, FL 33716 is the Obligor/Provider/Administrator of this Service Contract.<strong>If You live in UT, Bankers Warranty Group, Inc. d/b/a BWG Protection Plans, Inc., 11101 Roosevelt Blvd. N, St. Petersburg, FL 33716 is the Obligor/Provider/Administrator of this Service Contract. If You live in HI or NY, Bankers Warranty Group, Inc. d/b/a BWG Protection Plans, 11101 Roosevelt Blvd. N, St. Petersburg, FL 33716 is the Obligor/Provider/Administrator of this Service Contract. If You live in FL, Bankers Warranty Group of Florida, Inc., 11101 Roosevelt Blvd. N, St. Petersburg, FL 33716, is the Obligor/Provider/Administrator of this Service Contract. “Bicycle”</strong>means the Bicycle(s) which You purchased and is/are covered by this Service Contract. “<strong>Failure</strong>” means the inability of the covered Bicycle to perform its originally intended function as a result of defects in materials or workmanship or a mechanical breakdown as a result of normal use and operation in accordance with the manufacturer’s specifications.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.purchaserRecordsOpen }
          _toggleFunc={ () => this.toggle('purchaserRecordsOpen') }
          header="Purchaser Records"
          content={
            <React.Fragment>
              <p>Your sales receipt and these terms and conditions including the provisions, limitations, definitions, and exclusions constitute the entire Service Contract. Please keep this Service Contract and applicable sales receipts as you may be required to produce them at any time during the term to obtain service or replacement.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.planTermsOpen }
          _toggleFunc={ () => this.toggle('planTermsOpen') }
          header="Term"
          content={
            <React.Fragment>
              <p>The term of the Service Contract commences at the date indicated on the face of this Service Contract as the effective date. This Service Contract is not inclusive of the manufacturer’s warranty or the dealer’s limited warranty. We are not responsible for providing service for failures that occur during the manufacturer’s and/or dealer’s warranty period and that are covered under the manufacturer’s and/or dealer’s warranty, unless specifically stated otherwise, regardless of when such failures are reported to Us, the manufacturer, the dealer, or any other entity. We are not responsible for providing service for failures that the manufacturer and/or dealer has evaluated and denied coverage for, unless specifically stated to the contrary herein.<strong>This Service Contract does not have a deductible. ALL CLAIMS MUST BE REPORTED PRIOR TO THE EXPIRATION DATE OF THE SERVICE CONTRACT.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coveredOpen }
          _toggleFunc={ () => this.toggle('coveredOpen') }
          header="Service And Coverage"
          content={
            <React.Fragment>
              <p>To arrange for service, call the toll free number listed on this Service Contract. You must call prior to receiving service; all repairs must be authorized in advance. Unauthorized repairs may not be covered. This Service Contract covers the Bicycle(s) purchased as new or factory- refurbished from an authorized dealer in the United States; which at the time of purchase included a manufacturer’s original or factory-refurbished warranty valid in the United States. Coverage only applies to Bicycles used non-commercially. Accessories and/or add-on options purchased separately and not essential to the basic function of the Bicycle are not eligible for coverage. Replacement parts will be, at our discretion, new, rebuilt or non-original manufacturer’s parts that perform to the factory specifications of Your Bicycle. We are not responsible for delays caused by factors beyond Our control, including but not limited to manufacturer’s delays, shipping to a regional service facility, or Acts of Nature.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.howServiceIsProvidedOpen }
          _toggleFunc={ () => this.toggle('howServiceIsProvidedOpen') }
          header="How We Will Provide Service For Your Bicycle"
          content={
            <React.Fragment>
              <p>We will furnish labor and/or parts required to repair or replace the following Covered Components on Your Bicycle(s) in the event of a Failure, as defined, in accordance with the terms and conditions stated herein, as long as the Bicycle is not covered under any insurance, or other warranty, guarantee and/or service</p>
              <p>contract. In lieu of repairing Your Bicycle’s components, We reserve the right, at Our sole discretion, to replace the components with a product of equal value, similar features and functionality, not necessarily the same model but of a similar and equal quality. Any and all parts or units replaced under this Service Contract become Our property in their entirety.</p>
              <p>Technological advances may result in a replacement product with a lower retail price than the original product purchase price; you are not entitled to a refund in the difference in price in such a case. The Administrator will not be responsible for upgrades, matching brand or color or for any modifications or construction that may be necessary as a condition of service.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coveredComponentsOne }
          _toggleFunc={ () => this.toggle('coveredComponentsOne') }
          header="Covered Components: Specifically, With Respect To Bicycles And Wheels"
          content={
            <React.Fragment>
              <ol>
                <li>Components attached to the frame including, but not limited to:</li>
                <li>Fork, including skewers;</li>
                <li>Wheels, including spokes, rims, hubs and valve stems; <strong>EXCLUDES TIRES/TUBES</strong></li>
                <li>Crank assembly, includes crank arms and bottom bracket;</li>
                <li>Brakes including levers, cables, hoses, fluid reservoirs, calipers and rotors; <strong>EXCLUDES BRAKE PADS</strong></li>
                <li>Drivetrain, includes front and rear derailleurs, shifters, cables, freewheel/cassette, chain and chain rings;</li>
                <li>Suspension, includes front and rear components; and</li>
                <li>Saddle, seat post, pedals, handlebar, handlebar grips, stem and headset.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.coveredComponentsTwo }
          _toggleFunc={ () => this.toggle('coveredComponentsTwo') }
          header="Covered Components: Specifically, With Respect To Electrical Bicycles, And In Addition To The Above"
          content={
            <React.Fragment>
              <ol>
                <li>Motor; EXCLUDES BATTERIES</li>
                <li>Controller;</li>
                <li>Electrical bicycle cabling.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.serviceLocationOpen }
          _toggleFunc={ () => this.toggle('serviceLocationOpen') }
          header="Service Location"
          content={
            <React.Fragment>
              <p><strong>Carry-In Service:</strong> You are responsible for transporting Your Bicycle to and from the designated service center. We will not be responsible for reimbursement for any costs associated with such transportation.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.liabilityOpen }
          _toggleFunc={ () => this.toggle('liabilityOpen') }
          header="Service Contract Limits Of Liability; Aggregate Limit"
          content={
            <React.Fragment>
              <p><strong>The total amount We will pay for repairs made in connection with all claims that You make pursuant to this Service Contract shall not exceed the purchase price of the Bicycle, excluding taxes. In the event that We make payments for repairs and/or a cash settlement, which in the aggregate, are equal to the purchase price or We replace the Bicycle We will have no further obligations under this Service Contract. Technological advances may result in a current retail cost of a replacement product with a lower retail price than the original purchase price of the product. In no event shall We be liable for any damages as a result of the unavailability of repair parts or delays in service.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.responsibilitiesOpen }
          _toggleFunc={ () => this.toggle('responsibilitiesOpen') }
          header="Your Responsibilities"
          content={
            <React.Fragment>
              <ol>
                <li>Maintain, store and use Your covered product according to the requirements set forth by the manufacturer's specifications, including maintenance and cleaning;</li>
                <li>Provide proper electrical requirements as specified by the manufacturer;</li>
                <li>Assure full cooperation with the Administrator and authorized service provider during any telephone diagnosis and repair of the covered product including accessibility of the covered product.</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.exclusionsOpen }
          _toggleFunc={ () => this.toggle('exclusionsOpen') }
          header="General Exclusions"
          content={
            <React.Fragment>
              <ol>
                <li>Bicycles sold without a manufacturer’s parts and labor limited warranty or “as is”. Products or parts with a lifetime warranty.</li>
                <li>Consumer replaceable items including but not limited to: lamps, bulbs and housings, fuses, replaceable fluids, hoses, belts, bags, tires, tubes, brake pads, cords, dials, drive belts, handles, hinges, keypads, keys, knobs, latches, LED’s, LCD’s, bolts, batteries or chargers, fuel of any kind, or any other parts or materials which are designed to be consumed during the life of the product; all adaptors and remote controls.</li>
                <li>Any&nbsp; add-on accessories and/or attachments that were purchased in addition to and/or separate from the original sale of the covered product.</li>
                <li>Improper installation of components, upgrades, attachments or peripherals, whether consumer replaceable or not; improper or inadequatestorage.</li>
                <li>Any batteries such as but not limited to: internal batteries, external batteries, GPS batteries and rechargeable batteries whether consumer replaceable or not; any battery leakage.</li>
                <li>Non-operational components, decorative finishing, paint, plastic body or molding, bicycle frame, supports, switches, wiring and cosmetic damage that does not impede the functionality of the product.</li>
                <li>Damage resulting from unauthorized repair, modifications, adjustments, alterations, or manipulations; inadequate or improper electrical wiringand connections; interruption of electrical service, loss of power, “brown-outs”.</li>
                <li>Damage caused during delivery, transit, improper packaging, including failure to properly secure the bicycle during transportation; user- facilitated minor adjustments and settings outlined in the product’s owner’s manual; inaccessible products or parts; negligence, misuse orabuse or intentional damage.</li>
                <li>Damage from heat sources, warping, scratching, denting, chipping, sun fading, tarnishing, pitting, staining, punctures, tears, cleaning stains caused by dyes, acids and corrosives.</li>
                <li>Failures due to corrosion, rust, dust, animal/insect infestation or damage; Acts of Nature; exposure to weather, freezing or overheating, moisture, and other environmental conditions; civil disorders; riot; nuclear accident; radioactive contamination, malicious mischief; theft, mysterious disappearance, loss or vandalism.</li>
                <li>Special, indirect, consequential or incidental damages, including but not limited to, delays in rendering service, loss of use, loss of business, loss of profits, loss of data, down-time and charges for time and effort.</li>
                <li>“No problem found” diagnosis or failure to follow the manufacturer’s instructions; cleanings and alignments; non-failure problems, including but not limited to: noises and squeaks.</li>
                <li>Failure, inoperability, or disruption of any product or product functions due to any manufacturer recall, warranty or rework to repair designor component deficiencies, improper construction, manufacturer error; regardless of the manufacturer’s ability to pay for such repair.</li>
                <li>All bikes used in professional fitness centers or offered on a rental basis; products utilized for commercial operations; or used in activitiesnot standard for the bike sold (i.e.no off-road mountain trail usage for a standard street bike).</li>
                <li>Utilization of equipment that is inconsistent with either the design of the product or the way the manufacturer intended the product to be used; any and all cases in which the manufacturer of the product would not honor any warranty regarding the product.</li>
                <li>Products with safety feature(s) removed, bypassed, disabled or altered; products with altered, removed or missing serial numbers. Q. Conditions, which existed prior to Your purchase and delivery of the product or the Service Contract.</li>
                <li>Cost of routine or preventative maintenance, or damages caused by improper routine or preventative maintenance.</li>
                <li>Any service request or situation which may pose a health risk to Our technicians or service providers, including but not limited to animal/insect infestation, mold, or fungus; whether or not such circumstances were a result of a covered failure.</li>
                <li>Any software, media or virus related issues; loss or damage to stored data, loss or damage due to computer viruses, items left in Your product,such as but not limited to, computer media, personal items and batteries and computer hardware or software that is added after the original purchase date as indicated on Your purchase receipt.</li>
                <li>Liability or damage to property, or injury, or death to any person arising out of the operation, maintenance, or use of the covered Bicycle; orservice or replacement outside of the United States of America, its territories or Canada.</li>
                <li>Product failures due to an unexpected, accidental and/or unintentional external event (falls, collisions, explosions, or collapse) that arises</li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.repeatServiceOpen }
          _toggleFunc={ () => this.toggle('repeatServiceOpen') }
          header="Repeat Service"
          content={
            <React.Fragment>
              <p>If Your covered product should require service more than once within a sixty (60) day period, the service must be performed by the original authorized service provider.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.renewOpen }
          _toggleFunc={ () => this.toggle('renewOpen') }
          header="Renewals"
          content={
            <React.Fragment>
              <p>This Service Contract is not renewable.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.exchangedGoodsOpen }
          _toggleFunc={ () => this.toggle('exchangedGoodsOpen') }
          header="Exchanged Bicycles"
          content={
            <React.Fragment>
              <p>If Your Bicycle is exchanged by the manufacturer or authorized dealer, You must advise Us in writing at the address listed in this Service Contract with the date of exchange, make, model, and serial number of the replacement Bicycle within 10 days of the exchange. In the event of such exchange, the coverage period shall not exceed the original Service Contract expiration date.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.transferabilityOpen }
          _toggleFunc={ () => this.toggle('transferabilityOpen') }
          header="Transfer"
          content={
            <React.Fragment>
              <p>This Service Contract may be transferred to an eligible party to whom You sell or give the equipment while this Service Contract is in force. This may be accomplished only if You notify the Administrator by mail with the name and address of the new owner within 15 days of the change of ownership.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.cancellationOpen }
          _toggleFunc={ () => this.toggle('cancellationOpen') }
          header="Cancellation"
          content={
            <React.Fragment>
              <p>You may cancel this contract for any reason at any time. To cancel Your Service Contract, contact the retailer from which You purchased the Service Contract. If You cancel this Service Contract within the first thirty (30) days after You purchase this Service Contract You will receive a full refund, less any claims paid, where allowed by law. If You cancel after the first thirty (30) days from purchase of this Service Contract, You will receive a pro rata refund based on the time remaining on Your Service Contract, less an administrative fee, not to exceed ten percent (10%) of the price of the Service Contract or twenty-five dollars ($25.00), whichever is less, and less any claims paid, where allowed by law. If the Administrator cancels the Service Contract, You will be refunded the unearned pro rata purchase price of the Service Contract, less any claims paid, where allowed by law. If this Service Contract was inadvertently sold to You on a product which was not intended to be covered by this Service Contract, Your Service Contract will be cancelled and You will receive the full purchase price of the Service Contract.<strong>We may cancel this Service Contract at our option on the basis of fraud or misrepresentation.</strong></p>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default Bike;
