import React from 'react';
import { ClydeTextInput, ClydeSelect } from '@joinclyde/clyde-react-components';
import { countriesAndProvincesList } from '../../../components/Shared/regionCodes';
import { TransferOrderDataI } from '../../../types/ProductTypes';
import applyMask from '../../Shared/phoneMask';

interface ContractTransferFormInputProps {
  transferOrderData: TransferOrderDataI;
  onInputChange: any;
  formErrors: any;
}

const ContractTransferFormInput = ({
  transferOrderData,
  onInputChange,
  formErrors,
}: ContractTransferFormInputProps) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    address1,
    address2,
    city,
    province,
    zip,
  } = transferOrderData;

  const updateField = (e: any) => {
    const fieldWithFormat = e.target.name === 'phone'
      ? applyMask(e.target.value)
      : e.target.value;
    onInputChange({ ...transferOrderData, [e.target.name]: fieldWithFormat });
  };

  const parsedStateList = countriesAndProvincesList.US.map((listProvince) => ({
    text: listProvince.name,
    value: listProvince.value,
  }));

  return (
    <div className='contract-transfer-form'>
      <div className='top-input-fields'>
        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-first-name'>First name</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.firstName ? 'transfer-error-field' : ''}`}
            id='transfer-first-name'
            name='firstName'
            dataTestValue='firstName'
            onChange={ updateField }
            value={ firstName } />
          <span className='transfer-error-text'>{formErrors.firstName ? 'First name is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-last-name'>Last name</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.lastName ? 'transfer-error-field' : ''}`}
            id='transfer-last-name'
            name='lastName'
            dataTestValue='lastName'
            onChange={ updateField }
            value={ lastName } />
            <span className='transfer-error-text'>{formErrors.lastName ? 'Last name is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-email'>Email address</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.email ? 'transfer-error-field' : ''}`}
            id='transfer-email'
            name='email'
            dataTestValue='emailAddress'
            onChange={ updateField }
            value={ email } />
            <span className='transfer-error-text'>{formErrors.email ? 'Email address is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-phone'>Phone number (optional)</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.phone ? 'transfer-error-field' : ''}`}
            id='transfer-phone'
            name='phone'
            dataTestValue='phoneNumber'
            onChange={ updateField }
            value={ phone } />
            <span className='transfer-error-text'>{formErrors.phone ? 'Phone is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-address-one'>Address</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.address1 ? 'transfer-error-field' : ''}`}
            id='transfer-address-one'
            name='address1'
            dataTestValue='address'
            onChange={ updateField }
            value={ address1 } />
            <span className='transfer-error-text'>{formErrors.address1 ? 'Address is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-address-two'>Apartment, suite, etc. (optional)</label>
          <ClydeTextInput
            className='transfer-input'
            id='transfer-address-two'
            name='address2'
            dataTestValue='apartmentSuite'
            onChange={ updateField }
            value={ address2 } />
        </div>

      </div>

      <div className='bottom-input-fields'>
        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-city'>City</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.city ? 'transfer-error-field' : ''}`}
            id='transfer-city'
            name='city'
            dataTestValue='city'
            onChange={ updateField }
            value={ city } />
            <span className='transfer-error-text'>{formErrors.city ? 'City is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-state'>State</label>
          <ClydeSelect
            className={`transfer-input ${formErrors.province ? 'transfer-error-field' : ''}`}
            multiple={ false }
            id='transfer-state'
            name='province'
            data-test='state'
            defaultValue={ province }
            onChange={ updateField }
            options={ parsedStateList }
          />
          <span className='transfer-error-text'>{formErrors.province ? 'State is required' : ''}</span>
        </div>

        <div className='contract-transfer-form-input'>
          <label htmlFor='transfer-zip'>Zip code</label>
          <ClydeTextInput
            className={`transfer-input ${formErrors.zip ? 'transfer-error-field' : ''}`}
            id='transfer-zip'
            name='zip'
            dataTestValue='zipCode'
            onChange={ updateField }
            value={ zip } />
          <span className='transfer-error-text'>{formErrors.zip ? 'Zip code is required' : ''}</span>
        </div>
      </div>
    </div>
  );
};

export default ContractTransferFormInput;
