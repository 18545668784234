import React from 'react';
import { CSSTransition } from 'react-transition-group';
import SignupForm from './SignupForm';
import LoginForm from './LoginForm';
import RequestPasswordForm from './RequestPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import HowItWorks from '../Shared/HowItWorks';
import { ReactComponent as LogoXCover } from '../../images/logo-clyde-xcover.svg';
import gradientGlow from '../../images/gradient-glow.png';

interface AuthProps {
  _authUser: any;
  _createUser: any;
  _requestPassword: any;
  _changePassword: any;
  _getCSRF: any;
  _clearAuthErrors: any;
  _resendVerify: any;
  _setPasswordResetSuccessFalse: any;
  _setPasswordChangeSuccessFalse: any;
  _googleOauthUser: any;
  _failGoogleOauthUser: any;
  location: any;
  user: any;
  passwordResetSuccess: boolean;
  changeSuccess: any;
  isProcessing: boolean;
  error: any;
  csrf: string;
}

interface AuthState {
  showFlash: boolean;
  flashClass: string;
  flashMessage?: string;
}

class Auth extends React.Component<AuthProps, AuthState> {
  constructor(props:AuthProps) {
    super(props);
    this.state = {
      showFlash: false,
      flashClass: 'clyde-flash--pro',
    };
  }

  componentDidMount() {
    this.props._getCSRF();
    if (this.props.location.search && this.props.location.search === '?flash=pro') {
      this.toggleFlash(true);
      this.setState({
        flashMessage: 'Email Verified! Log in to manage your account.',
        flashClass: 'clyde-flash--pro',
      });
      setTimeout(() => this.toggleFlash(false), 6000);
    }
    if (this.props.location.search && this.props.location.search === '?flash=con') {
      this.toggleFlash(true);
      this.setState({
        flashMessage: 'Something went wrong with email verification.',
        flashClass: 'clyde-flash--con',
      });
      setTimeout(() => this.toggleFlash(false), 6000);
    }
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  toggleFlash(showFlash: boolean) {
    this.setState({
      showFlash,
    });
  }

  render() {
    let Displayed:any = '';

    switch (this.props.location.pathname) {
      case '/login':
        Displayed = <LoginForm
          user={this.props.user}
          _authUser={ this.props._authUser}
          _googleOauthUser={ this.props._googleOauthUser }
          _failGoogleOauthUser={ this.props._failGoogleOauthUser }
          error={ this.props.error }
          csrf={ this.props.csrf }
          _clearAuthErrors={ this.props._clearAuthErrors}
          _resendVerify={ this.props._resendVerify }
          changeSuccess={ this.props.changeSuccess }
          isProcessing={ this.props.isProcessing }
          />;
        break;
      case '/request-pw':
        Displayed = <RequestPasswordForm
          user={this.props.user} csrf={ this.props.csrf }
          passwordResetSuccess={ this.props.passwordResetSuccess }
          _requestPassword={ this.props._requestPassword } error={ this.props.error }
          _clearAuthErrors={ this.props._clearAuthErrors}
          _setPasswordResetSuccessFalse={ this.props._setPasswordResetSuccessFalse } />;
        break;
      case '/change-pw':
        Displayed = <ChangePasswordForm csrf={ this.props.csrf }
          user={this.props.user} error={ this.props.error }
          _changePassword={ this.props._changePassword }
          _clearAuthErrors={ this.props._clearAuthErrors}
          _setPasswordChangeSuccessFalse={ this.props._setPasswordChangeSuccessFalse } />;
        break;
      case '/signup':
      default:
        Displayed = <SignupForm csrf={ this.props.csrf }
        user={this.props.user} error={ this.props.error }
        _createUser={ this.props._createUser }
        _clearAuthErrors={ this.props._clearAuthErrors} />;
    }

    return (
      <main className='auth-main'>
        <CSSTransition
          in={ this.state.showFlash }
          timeout={ 6000 }
          classNames='clyde-flash'>
          <div className={ `clyde-flash ${this.state.flashClass}` }>
            { this.state.flashMessage }
          </div>
        </CSSTransition>
        <section className='auth-main__left'>
          <div className='auth-main__left--padded'>
            <LogoXCover className='auth-main__logo' data-test='clydeLogo'/>
            { Displayed }
          </div>
          <footer className='auth-main__footer'>
            <a
              className='auth-main__footer__link'
              target='_blank'
              rel='noopener noreferrer'
              data-test='privacyPolicy'
              href='https://www.joinclyde.com/legal/privacy-policy'>
                Privacy Policy
            </a>
            <a
              className='auth-main__footer__link'
              target='_blank'
              rel='noopener noreferrer'
              data-test='termsOfService'
              href='https://www.joinclyde.com/legal/terms-of-use'>
              Terms of Service
            </a>
            <a
              className='auth-main__footer__link'
              target='_blank'
              rel='noopener noreferrer'
              data-test='joinClyde'
              href='https://www.joinclyde.com/'>
              joinclyde.com
            </a>
          </footer>
        </section>
        <section className='auth-main__right'>
          <HowItWorks />
          <img src={ gradientGlow } className='brandon-glow' alt=''/>
        </section>
      </main>
    );
  }
}

export default Auth;
