import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ProductPlaceholder from '../../../images/product-placeholder.svg';
import { ProductPurchaseType } from '../../../types/ProductTypes';
import ProductListItemCTA from './ProductListItemCTA';
import { ReactComponent as MenuDotsInactive } from '../../../images/menu-dots--inactive.svg';
import { ReactComponent as MenuDotsActive } from '../../../images/menu-dots--active.svg';

interface ProductListItemProps {
  product: ProductPurchaseType,
  _addToAvailableItems: any;
  _handleShowModal: any;
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product, _addToAvailableItems, _handleShowModal }) => {
  const { state } = product;
  const name = product.name.length > 38 ? `${product.name.slice(0, 35).trim()}...` : product.name;
  const [showMenu, setShowMenu] = useState(false);
  const { isEligibleForTransfer } = product;
  const displayMenu = () => {
    setShowMenu(!showMenu);
  };

  const displayMenuIcon = (itemState: string) => {
    if (itemState === 'active') {
      return <MenuDotsActive className='dot-menu-active' onClick={ displayMenu } data-test='menuDots' />;
    }

    return <MenuDotsInactive className='dot-menu-disabled' data-test='inactiveMenuDots' />;
  };

  return (
    <div className={`purchase-list-item ${state}`} data-test='purchaseListItem'>
      <div className='purchase-list-item__top-container'>
        <h4 className={`purchase-list-item__status ${state}`} data-test='itemStatus'>{ state }</h4>
        {
          displayMenuIcon(state)
        }
      </div>
      <div className={ `purchase-list-item__details ${state}` }>
        <div
          className={`purchase-list-item__img ${state}`}
          style={{ backgroundImage: `url(${product.imageLink || ProductPlaceholder})` }}>
        </div>
        <h5 className='purchase-list-item__name' data-test='itemName'>{ name }</h5>
        { product.manufacturer && <h6 className='purchase-list-item__manufacturer' data-test='itemManufacturer'>{ product.manufacturer }</h6> }
      </div>
      <ProductListItemCTA _addToAvailableItems={ _addToAvailableItems } product={ product } />
      <ul className={`purchase-list-item__menu ${showMenu ? 'active' : ''}`} data-test='menuCard'>
        { isEligibleForTransfer && <li onClick={ () => _handleShowModal(product) } data-test='changePlanOwner'>Change plan owner</li> }
        {
          state === 'active'
          && <li><Link to={ `/products/${product.contractSaleId}` } data-test='viewProduct'>View product</Link></li>
        }
      </ul>
    </div>
  );
};

export default ProductListItem;
