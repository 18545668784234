import { connect } from 'react-redux';
import {
  getUserDetails,
  changeProfile,
  submitUserBilling,
  changePassword,
  changePasswordLoggedIn,
  clearAccountMessages,
} from '../actions/userActions';
import Account from '../components/Account';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    userDetails: state.user.details,
    error: state.user.error,
    changeSuccess: state.user.changeSuccess,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _getUserDetails: () => {
      dispatch(getUserDetails());
    },
    _changeProfile: (user: any) => {
      dispatch(changeProfile(user));
    },
    _submitUserBilling: (billingInfo: any) => {
      dispatch(submitUserBilling(billingInfo));
    },
    _changePassword: (pwDetails: any, csrf:string) => {
      dispatch(changePassword(pwDetails, csrf));
    },
    _changePasswordLoggedIn: (pwDetails: any) => {
      dispatch(changePasswordLoggedIn(pwDetails));
    },
    _clearAccountMessages: () => {
      dispatch(clearAccountMessages());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
