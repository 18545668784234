import React from 'react';

import AllProduct from './AllProduct';
import AllProdNonAdh from './AllProdNonAdh';
import AllProdClydeAdmin from './AllProdClydeAdmin';
import ConsumerElectronics from './ConsumerElectronics';
import ETransport from './ETransport';
import FurniturePlus from './FurniturePlus';
import FurniturePreferred from './FurniturePreferred';
import FurniturePreferredLTO from './FurniturePreferredLTO';
import Jewelry from './Jewelry';
import LightsAndFans from './LightsAndFans';
import PoolAndSpa from './PoolAndSpa';
import Optical from './Optical';
import OpticalClyde from './OpticalClyde';
import FurniturePlus4WC from './FurniturePlus4WC';

import { UnderwriterProps } from '../../../../types/TermsTypes';
import PDFOnlyTerms from '../PDFOnlyTerms';

const Fortegra: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'allProd':
      Terms = <AllProduct contractTerms={ contractTerms } />;
      break;
    case 'allProdNonAdh':
      Terms = <AllProdNonAdh contractTerms={ contractTerms } />;
      break;
    case 'allProdClydeAdmin':
      Terms = <AllProdClydeAdmin contractTerms={ contractTerms } />;
      break;
    case 'consumerElectronics':
      Terms = <ConsumerElectronics contractTerms={ contractTerms } />;
      break;
    case 'eTransport':
      Terms = <ETransport contractTerms={ contractTerms } />;
      break;
    case 'furniturePlus':
      Terms = <FurniturePlus contractTerms={ contractTerms } />;
      break;
    case 'furniturePreferred':
      Terms = <FurniturePreferred contractTerms={ contractTerms } />;
      break;
    case 'furniturePreferredLTO':
      Terms = <FurniturePreferredLTO contractTerms={ contractTerms } />;
      break;
    case 'jewelry':
      Terms = <Jewelry contractTerms={ contractTerms } />;
      break;
    case 'lightsAndFans':
      Terms = <LightsAndFans contractTerms={ contractTerms } />;
      break;
    case 'poolAndSpa':
      Terms = <PoolAndSpa contractTerms={ contractTerms } />;
      break;
    case 'optical':
      Terms = <Optical contractTerms={ contractTerms } />;
      break;
    case 'opticalClyde':
      Terms = <OpticalClyde contractTerms={ contractTerms }></OpticalClyde>;
      break;
    case 'furniturePlus4WC':
      Terms = <FurniturePlus4WC contractTerms={ contractTerms }></FurniturePlus4WC>;
      break;
    default:
      Terms = <PDFOnlyTerms contractTerms={ contractTerms } />;
      break;
  }

  return Terms;
};

export default Fortegra;
