import React from 'react';

import GeneralProtection from './GeneralProtection';

import { UnderwriterProps } from '../../../../types/TermsTypes';
import PDFOnlyTerms from '../PDFOnlyTerms';

const Hornbeam: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'dopConsumer':
      Terms = <GeneralProtection contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <PDFOnlyTerms contractTerms={ contractTerms } />;
      break;
  }

  return Terms;
};

export default Hornbeam;
