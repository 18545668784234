import Axios, { AxiosError } from 'axios';

// See axios docs for usage, https://www.npmjs.com/package/axios
// or if you really hate it just swap it out here
const http = Axios.create({
  // @ts-ignore
  baseURL: window._env_.REACT_APP_HICLYDE_BE_HOST,
  timeout: 15000,
  withCredentials: true,
});

export default http;

export enum HttpError {
  CLIENT = 'Client Error',
  SERVER = 'Server Error',
  NETWORK = 'Network Error',
  UNKNOWN = 'Unknown Error',
}

export type ServiceError = Error | AxiosError;

export function handleServiceError(err: ServiceError) {
  if (Axios.isAxiosError(err)) {
    const { response } = err;
    if (response) {
      // NOTE: we don't (usually) show the real error to end users,
      // but it's nice to differentiate between client-side and server-side errors.
      return {
        error: response.status >= 500 ? HttpError.SERVER : HttpError.CLIENT,
        status: response.status,
      };
    }

    return {
      error: HttpError.NETWORK,
      status: 503, // Not actually an http-level error
    };
  }

  return {
    error: HttpError.UNKNOWN,
    status: 500, // Not actually an http-level error
  };
}
