import { connect } from 'react-redux';
import { clearClaimError, createClaim } from '../actions/claimActions';
import { setError } from '../actions/errorActions';
import { cancelModal } from '../actions/modalActions';
import ClaimForm from '../components/Claim/ClaimForm';

function mapStateToProps(state: any) {
  return {
    customerName: state.user.details.fullName,
    email: state.user.details.email,
    phone: state.user.details.phoneNumber,
    error: state.claim.error,
    claimFormSuccess: state.claim.claimFormSuccess,
    newClaim: state.claim.newClaim,
    issueTypes: state.claim.issueTypes,
    isProcessing: state.claim.isProcessing,
    isOpen: Boolean(state.modal.visibleDict['claim-form']),
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _createClaim: (claim: any) => {
      dispatch(createClaim(claim));
    },
    _setError: (error: string) => {
      dispatch(setError(error));
    },
    _clearError: () => {
      dispatch(clearClaimError());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimForm);
