import React from 'react';
import { Collapse } from 'reactstrap';

import termsArrow from '../../../images/caret-down.svg';

interface TermsAccordionProps {
  header: string;
  content: any;
  toggle: any;
  _toggleFunc: any;
}

const TermsAccordion: React.FC<TermsAccordionProps> = (props) => {
  const {
    content, header, toggle, _toggleFunc,
  } = props;
  return (
    <div className="terms-section">
      <div className="terms-section-toggle" onClick={ _toggleFunc }>
        <h5 className="terms-section-header">{ header }</h5>
        <img
          className={ `terms-section-arrow terms-section-arrow--${toggle ? 'open' : ''}` }
          alt="Accordion arrow"
          src={ termsArrow } />
      </div>
      <Collapse className="terms-section-collapse" isOpen={ toggle }>
        <div className="collapse-padding-wrapper">
          <div className="terms-section-content">
            { content }
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default TermsAccordion;
