import React from 'react';
import classnames from 'classnames';

import { Button } from 'reactstrap';
import { ReactComponent as ArrivedDamaged } from '../../../images/arrived-damaged.svg';
import { ReactComponent as LossOrTheft } from '../../../images/loss-or-theft.svg';
import { ReactComponent as AccidentalDamage } from '../../../images/accidental-damage.svg';
import { ReactComponent as StoppedWorking } from '../../../images/extended-warranty.svg';
import { ReactComponent as ExclamationCircle } from '../../../images/exclamation-circle.svg';
import { ReactComponent as UsageQuestion } from '../../../images/claim-under-review.svg';
import { ReactComponent as BusinessClaim } from '../../../images/business-claim.svg';

import ClickCopy from '../../Shared/ClickCopy';
import { generateShopUrls } from './claim-utilities';
import CloseModalButton from '../../Shared/CloseModalButton';

interface ClaimRejectionProps {
  handoffType: string;
  shopName: string;
  email: string;
  supportLink: string;
  pid: string;
  cid: string;
  closeModal: (e: any) => any;
  newClaim: any;
  shopUrl: string;

  mailToLink?: string;
}

const ClaimRejection = ({
  handoffType,
  shopName,
  email,
  supportLink,
  closeModal,
  pid,
  cid,
  mailToLink,
  newClaim,
  shopUrl,
} : ClaimRejectionProps) => {
  if (!handoffType) return null;

  const { discountPromoCode, rejectionDiscount } = newClaim;
  const { modifiedUrl } = generateShopUrls(shopUrl);

  const simpleEmailLink = email && `mailto:${email}`;
  const outButtonClass = discountPromoCode ? 'claim-form__response__out-button--subdued' : 'claim-form__response__out-button button--general';
  const outLinkClass = discountPromoCode ? 'claim-form__response__out-link--subdued' : 'claim-form__response__out-link';

  let handoffImg;
  let headerText = '';
  let explanation = '';
  let nextSteps:any = '';
  let handoffButton;

  switch (handoffType) {
    case 'arrivedDamaged':
      handoffImg = <ArrivedDamaged height='50px' width='50px' />;
      headerText = `Please contact ${shopName} for further support.`;
      explanation = 'Products that arrive damaged are not covered by Clyde.';
      nextSteps = <>
        In order to help you resolve the issue, you'll need to contact the retailer. <br />
        { discountPromoCode
          ? `In the meantime, we'd like to offer you ${rejectionDiscount}% off any product in the store.`
          : 'Please reach out to their team to get assistance.'
        }
      </>;
      handoffButton = (
        <a href={ mailToLink || simpleEmailLink || supportLink } target='_blank' rel='noopener noreferrer' className={ outLinkClass }>
          <Button className={ outButtonClass }>
            Contact { shopName }
          </Button>
        </a>
      );
      break;

    case 'lossOrTheft':
      handoffImg = <LossOrTheft height='50px' width='50px' />;
      headerText = 'Unfortunately, your Clyde protection plan does not provide coverage for loss and theft.';
      explanation = 'If you have homeowners or renters insurance, you may be able to file a claim with that policy.';
      nextSteps = <>
        Insurance regulation prohibits us from selling contracts with this type of protection.<br />
        { discountPromoCode
          ? `As a token of gratitude, we’d like to offer you ${rejectionDiscount}% off any product in the store.`
          : 'We sincerely apologize for the inconvenience.'
        }
      </>;
      handoffButton = (
        <a href={`/terms?pid=${pid}&cid=${cid}`} target='_blank' rel='noopener noreferrer' className={ outLinkClass }>
          <Button className={ outButtonClass }>
            View Warranty Terms
          </Button>
        </a>
      );
      break;

    case 'nonAdhAccident':
      handoffImg = <AccidentalDamage height='50px' width='50px' />;
      headerText = "Your protection plan doesn't cover accidental damage from handling.";
      explanation = 'Clyde extends the manfacture warranty after it expires.';
      nextSteps = <>
        Your current plan is an extended warranty that doesn't cover accidental damage. <br />
        { discountPromoCode
          ? `As a token of gratitude, we'd like to offer you ${rejectionDiscount}% off any product in the store.`
          : "Feel free to review warranty terms below to see the details of what's covered by your contract."
        }
      </>;
      handoffButton = (
        <a href={`/terms?pid=${pid}&cid=${cid}`} target='_blank' rel='noopener noreferrer' className={ outLinkClass }>
          <Button className={ outButtonClass }>
            View Warranty Terms
          </Button>
        </a>
      );
      break;

    case 'denied':
      handoffImg = <UsageQuestion height='75px' width='75px' />;
      headerText = "Product wasn't used as intended.";
      explanation = 'Clyde warranties only cover issues when product is used as intended.';
      nextSteps = <>
        In order to help you resolve the issue, you'll need to contact the retailer. <br />
        { discountPromoCode
          ? `In the meantime, we'd like to offer you ${rejectionDiscount}% off any product in the store.`
          : 'Please reach out to their team to get assistance with your issue.'
        }
      </>;
      handoffButton = (
        <a href={ mailToLink || simpleEmailLink || supportLink } target='_blank' rel='noopener noreferrer' className={ outLinkClass }>
          <Button className={ outButtonClass }>
            Contact { shopName }
          </Button>
        </a>
      );
      break;

    case 'invalidBusinessClaim':
      handoffImg = <BusinessClaim height='50px' width='50px' />;
      headerText = 'Plan does not cover commercial use.';
      explanation = 'Product is only covered for individual use.';
      nextSteps = <>
        Your protection plan doesn't cover issues that occur in a business setting.
        Please check terms and conditions.
        <br />
      </>;
      handoffButton = (
        <a href={`/terms?pid=${pid}&cid=${cid}`} target='_blank' rel='noopener noreferrer' className={ outLinkClass }>
          <Button className={ outButtonClass }>
            View Warranty Terms
          </Button>
        </a>
      );
      break;

    default:
      handoffImg = <StoppedWorking height='50px' width='50px' />;
      headerText = `Your product is still covered by ${shopName}.`;
      explanation = 'Clyde only covers defects that occur after the limited warranty expires.';
      nextSteps = <>
        In order to help you resolve the issue, you'll need to contact the retailer. <br />
        { discountPromoCode
          ? `In the meantime, we'd like to offer you ${rejectionDiscount}% off any product in the store.`
          : 'Please reach out to their team to get assistance.'
        }
      </>;
      handoffButton = (
        <a href={ mailToLink || simpleEmailLink || supportLink } target='_blank' rel='noopener noreferrer' className={ outLinkClass }>
          <Button className={ outButtonClass }>
            Contact { shopName }
          </Button>
        </a>
      );
      break;
  }

  const isExternalCovered = handoffType === 'arrivedDamaged' || handoffType === 'inOemTerm';

  return (
    <div
      className={classnames(
        'claim-form__response__container',
        { 'claim-form__response__limited-warranty': isExternalCovered },
      )}
    >
      <section className='claim-form__response__body'>
        <CloseModalButton onClick={ closeModal } className='clyde-modal__close'/>
        <span
          className={classnames(
            'claim-form__response__body__icon',
            'svg-wrapper--clyde-black',
            { 'claim-form__response__body__icon--usage-question': handoffType === 'denied' },
          )}
        >
          { handoffImg }
        </span>
        <h3 className='claim-form__response__header'>{ headerText }</h3>
        <p className='claim-form__handoff__explanation claim-form__response__purple-text'>
          <ExclamationCircle /> { explanation }
        </p>
        <p className='claim-form__handoff__next-steps'>{ nextSteps }</p>
        { discountPromoCode
          && <div className='claim-form__response__promo-code-buttons'>
            <ClickCopy copyValue={ discountPromoCode } />
            <a href={ modifiedUrl } target='_blank' rel='noreferrer'>
              <Button className='claim-form__response__out-button button--general'>
                Visit Store
              </Button>
            </a>
          </div>
        }
        { handoffButton }
      </section>

    </div>
  );
};

export default ClaimRejection;
