import React from 'react';

class VefifyError extends React.Component {
  render() {
    return (
    <section className="verify-container">
      An error has occurred - the link you used to get here is probably expired.
      Please try logging in here <a href="/login">Login</a>.
    </section>
    );
  }
}

export default VefifyError;
