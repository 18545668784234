import React, { Component } from 'react';
import { Button } from 'reactstrap';
import plaidConfig from '../../config/plaid';

interface PlaidModalProps {
  hasBankAccountConnected: boolean;
  _onPlaidSuccess: any;
}

class PlaidModalButton extends Component<PlaidModalProps> {
  createPlaidLinkHandler = () => {
    const { _onPlaidSuccess } = this.props;

    // @ts-ignore
    window.Plaid.create({
      env: plaidConfig.PLAID_ENV,
      clientName: 'Clyde',
      key: plaidConfig.PLAID_PK,
      product: ['auth'],
      selectAccount: true,
      // @ts-ignore
      // eslint-disable-next-line camelcase
      onSuccess: (public_token, metadata) => {
        // @ts-ignore Send the public_token and account ID to your app server.
        // eslint-disable-next-line camelcase
        _onPlaidSuccess({ plaidToken: public_token, metadata });
      },
      // @ts-ignore
      onExit: (err, metadata) => {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error prior to exiting.
        }
      },
    }).open();
  };

  render() {
    const { hasBankAccountConnected } = this.props;

    return (
      <Button
        onClick={ () => this.createPlaidLinkHandler() }
        className='button--general'
        id='connect-with-plaid-button'>
        { hasBankAccountConnected ? 'Change Billing Account' : 'Connect with Plaid' }
      </Button>
    );
  }
}

export default PlaidModalButton;
