import React from 'react';
import { Link } from 'react-router-dom';

type ButtonMailToProps = {
  mailto: any;
  label: string;
}

export const ButtonMailto = ({ mailto, label }: ButtonMailToProps) => (
    <Link
      to='#'
      onClick={(e) => {
        window.location = mailto;
        e.preventDefault();
      }}
    >
      { label }
    </Link>
);
