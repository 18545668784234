/* eslint-disable camelcase */
import React from 'react';

import AdjustableBedBase from './AdjustableBedBase';
import AreaRug from './AreaRug';
import CompleteMattress from './CompleteMattress';
import CompletePlusFurniture from './CompletePlusFurniture';
import CompletePlusFurnitureAreaRug750 from './CompletePlusFurnitureAreaRug750';
import CompletePlusFurnitureAreaRug30000 from './CompletePlusFurnitureAreaRug30000';
import EliteCompleteFurniture_2yr from './EliteCompleteFurniture_2yr';
import EliteCompleteFurniture_3yr from './EliteCompleteFurniture_3yr';
import EliteCompleteFurniture_5yr from './EliteCompleteFurniture_5yr';
import ClassicMattress_3yr from './ClassicMattress_3yr';
import ClassicMattress_5yr from './ClassicMattress_5yr';
import ClassicMattress_10yr from './ClassicMattress_10yr';
import GameTables_5yr from './GameTables_5yr';
import ElectricFireplace_5yr from './ElectricFireplace_5yr';
import UltimateDefenseOutdoorFurniture from './UltimateDefenseOutdoorFurniture';
import UltimateDefenseOutdoorFurnitureCK from './UltimateDefenseOutdoorFurnitureCK';
import PrimePlusFurniture_5yr from './PrimePlusFurniture_5yr';
import LightingAndCeilingFan from './LightingAndCeilingFan';
import PremierLightingAndCeilingFan from './PremierLightingAndCeilingFan';
import CompleteMattressWithBoxSpring_3yr from './CompleteMattressWithBoxSpring_3yr';
import CompleteMattressWithBoxSpring_5yr from './CompleteMattressWithBoxSpring_5yr';
import CompleteMattressWithBoxSpring_10yr from './CompleteMattressWithBoxSpring_10yr';
import Test from '../../../../containers/TestTerms';

import { UnderwriterProps } from '../../../../types/TermsTypes';
import PDFOnlyTerms from '../PDFOnlyTerms';

const Guardsman: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms, _changeTermsKey } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'adjustableBedBase':
      Terms = <AdjustableBedBase contractTerms={ contractTerms } />;
      break;
    case 'areaRug':
      Terms = <AreaRug contractTerms={ contractTerms } />;
      break;
    case 'completeMattress':
      Terms = <CompleteMattress contractTerms={ contractTerms } />;
      break;
    case 'completeMattressWithBoxSpring':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}yr`);
      Terms = <Test />;
      break;
    case 'completeMattressWithBoxSpring_3yr':
      Terms = <CompleteMattressWithBoxSpring_3yr contractTerms={ contractTerms } />;
      break;
    case 'completeMattressWithBoxSpring_5yr':
      Terms = <CompleteMattressWithBoxSpring_5yr contractTerms={ contractTerms } />;
      break;
    case 'completeMattressWithBoxSpring_10yr':
      Terms = <CompleteMattressWithBoxSpring_10yr contractTerms={ contractTerms } />;
      break;
    case 'completePlusFurniture':
      Terms = <CompletePlusFurniture contractTerms={ contractTerms } />;
      break;
    case 'completePlusFurnitureAreaRug750':
      Terms = <CompletePlusFurnitureAreaRug750 contractTerms={ contractTerms } />;
      break;
    case 'completePlusFurnitureAreaRug30000':
      Terms = <CompletePlusFurnitureAreaRug30000 contractTerms={ contractTerms } />;
      break;
    case 'eliteCompleteFurniture':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}yr`);
      Terms = <Test />;
      break;
    case 'eliteCompleteFurniture_2yr':
      Terms = <EliteCompleteFurniture_2yr contractTerms={ contractTerms } />;
      break;
    case 'eliteCompleteFurniture_3yr':
      Terms = <EliteCompleteFurniture_3yr contractTerms={ contractTerms } />;
      break;
    case 'eliteCompleteFurniture_5yr':
      Terms = <EliteCompleteFurniture_5yr contractTerms={ contractTerms } />;
      break;
    case 'classicMattress':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}yr`);
      Terms = <Test />;
      break;
    case 'classicMattress_3yr':
      Terms = <ClassicMattress_3yr contractTerms={ contractTerms } />;
      break;
    case 'classicMattress_5yr':
      Terms = <ClassicMattress_5yr contractTerms={ contractTerms } />;
      break;
    case 'classicMattress_10yr':
      Terms = <ClassicMattress_10yr contractTerms={ contractTerms } />;
      break;
    case 'gameTables':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}yr`);
      Terms = <Test />;
      break;
    case 'gameTables_5yr':
      Terms = <GameTables_5yr contractTerms={ contractTerms } />;
      break;
    case 'electricFireplace':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}yr`);
      Terms = <Test />;
      break;
    case 'electricFireplace_5yr':
      Terms = <ElectricFireplace_5yr contractTerms={ contractTerms } />;
      break;
    case 'ultimateDefenseOutdoorFurniture':
      Terms = <UltimateDefenseOutdoorFurniture contractTerms={ contractTerms } />;
      break;
    case 'ultimateDefenseOutdoorFurnitureCK':
      Terms = <UltimateDefenseOutdoorFurnitureCK contractTerms={ contractTerms } />;
      break;
    case 'primePlusFurniture':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}yr`);
      Terms = <Test />;
      break;
    case 'primePlusFurniture_5yr':
      Terms = <PrimePlusFurniture_5yr contractTerms={ contractTerms } />;
      break;
    case 'lightingAndCeilingFan':
      Terms = <LightingAndCeilingFan contractTerms={ contractTerms } />;
      break;
    case 'premierLightingAndCeilingFan':
      Terms = <PremierLightingAndCeilingFan contractTerms={ contractTerms } />;
      break;
    case 'adjustibleBedWA':
    case 'areaRugWA':
    case 'classicMattressWA':
    case 'completeMattressWA':
    case 'eliteCompleteFurnitureWA':
    case 'goldCompleteFurnitureWA':
    case 'goldCompletePlusFurnitureWA':
    case 'ultimateOutdoorFurnitureWA':
      _changeTermsKey(`${contractTerms.termsKey}_${contractTerms.contractTerm}y`);
      Terms = <PDFOnlyTerms contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <PDFOnlyTerms contractTerms={ contractTerms } />;
      break;
  }

  return Terms;
};

export default Guardsman;
