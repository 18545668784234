import { connect } from 'react-redux';
import { getTestContracts } from '../actions/contractTermsActions';
import TestTerms from '../components/ContractTerms/Templates/Test/TestTerms';

function mapStateToProps(state: any) {
  return {
    testTerms: state.contractTerms.testTerms,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    _getTestContracts: () => {
      dispatch(getTestContracts());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestTerms);
