import React from 'react';
import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

import { HttpError } from '../../config/http';

export interface ErrorsProps {
  errors: string[];
  _clearError?: any;
}

function hasErrorType(errors: string[], type: string) {
  return errors.find((e) => e === type) !== undefined;
}

class Errors extends React.Component<ErrorsProps> {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: ErrorsProps) {
    if (nextProps.errors && nextProps.errors.length > 0) {
      // Default to a generic server error
      let errorMessage = 'HiClyde is currently experiencing an issue. Please try again later.';

      // Show slightly different error messages so we can tell the class of error from the support ticket
      if (hasErrorType(nextProps.errors, HttpError.NETWORK)) {
        errorMessage = `We're having trouble connecting. Please check your network stability and try again.`;
      } else if (hasErrorType(nextProps.errors, HttpError.CLIENT)) {
        errorMessage = `We're having trouble processing your claim. Please try again later.`;
      } else if (hasErrorType(nextProps.errors, HttpError.UNKNOWN)) {
        errorMessage = `Something unexpected went wrong. Please try again later.`;
      }

      Alert.error(errorMessage, {
        position: 'top',
        effect: 'stackslide',
        timeout: 3000,
      });
      this.props._clearError();
    }
  }

  render() {
    return <Alert stack={ { limit: 1 } } />;
  }
}

export default Errors;
