import React from 'react';

interface SummaryTileProps {
  Image: any;
  title: string;
  text: string;
}

const Tile: React.FC<SummaryTileProps> = (props) => {
  const { Image, title, text } = props;
  return (
    <div className="terms-summary-tile" data-test="termsSummaryTile">
      <div className="terms-summary-tile__image">
        <Image />
      </div>
      <div className="terms-summary-tile__title" data-test='title'>
        <h5>{ title }</h5>
      </div>
      <div className="terms-summary-tile__text" data-test='description'>
        <p>{ text }</p>
      </div>
    </div>
  );
};

export default Tile;
