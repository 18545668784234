import React from 'react';

interface ContractTransferFooterProps {
  step: number;
  setStep: any;
  _closeModal: any;
  _onSubmitTransfer: any;
  _redirectToProducts: any;
  disableTransferButton: boolean;
}

const setContentForStep = (
  step: number,
  setStep: any,
  _closeModal: any,
  _onSubmitTransfer: any,
  _redirectToProducts: any,
  disableTransferButton: boolean,
) => {
  let component;
  switch (step) {
    case 0:
      component = <button className='contract-transfer-btn-primary' onClick={ () => setStep(1) } data-test='reviewInformation'>Review Information</button>;
      break;
    case 1:
      component = <>
        <button className='contract-transfer-btn-primary' onClick={ _onSubmitTransfer } disabled={ disableTransferButton } data-test='transferContract'>Transfer Contract</button>
        <button className='contract-transfer-btn-secondary' onClick={ _closeModal } data-test='cancelTransfer'>Cancel Transfer</button>
      </>;
      break;
    case 2:
      component = <button className='contract-transfer-btn-primary' onClick={ _redirectToProducts } data-test='returnToProducts'>Return to Products</button>;
      break;
    default:
      break;
  }
  return component;
};

const ContractTransferFooter = ({
  step,
  setStep,
  _closeModal,
  _onSubmitTransfer,
  _redirectToProducts,
  disableTransferButton,
}: ContractTransferFooterProps) => {
  const content = setContentForStep(
    step,
    setStep,
    _closeModal,
    _onSubmitTransfer,
    _redirectToProducts,
    disableTransferButton,
  );

  return (
    <div className='contract-transfer-footer'>
      { content }
    </div>
  );
};

export default ContractTransferFooter;
