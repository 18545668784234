const defaultState = {
  isDepotRepairEnabled: false,
};

const checkoutReducer = function user(state:any = defaultState, action:any) {
  switch (action.type) {
    case 'LOADING_GET_MERCHANT_SETTINGS':
      return {
        ...state,
      };
    case 'UPDATE_GET_MERCHANT_SETTINGS':
      return {
        ...state,
        ...action.settings,
      };
    default:
      return state;
  }
};

export default checkoutReducer;
