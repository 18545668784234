import React from 'react';

interface CloseModalButtonProps {
  onClick: any;
  className?: string;
}

const CloseModalButton = ({ onClick, className } : CloseModalButtonProps) => (
    <button
      onClick={ onClick }
      title='close'
      data-test='close'
      className={`clyde--x-circle clyde-modal__higher-z-index ${className || ''}`}>
        &times;
    </button>
);

export default CloseModalButton;
