import { connect } from 'react-redux';
import { changeTermsKey } from '../actions/contractTermsActions';

import {
  getProductHistory,
  getProductTermsInfo,
  getProduct,
  setSelectedProduct,
  uploadReceiptPicture,
  clearProductError,
} from '../actions/productActions';

import { getMerchantSettings } from '../actions/merchantActions';
import { showModal, cancelModal } from '../actions/modalActions';
import {
  createAdminHandoffClaim,
  resetProductResolutionSuccess,
  getIssueTypes,
} from '../actions/claimActions';

import { getUserDetails } from '../actions/userActions';
import PDP from '../components/Product/PDP';
import { setError } from '../actions/errorActions';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    products: state.product.products,
    selectedProduct: state.product.selectedProduct,
    historyLog: state.product.selectedProductHistory,
    contractTerms: state.contractTerms,
    userDetails: state.user.details,
    newClaim: state.claim.newClaim,
    issueTypes: state.claim.issueTypes,
    issueTypesProcessing: state.claim.issueTypesProcessing,
    receiptUploadProcessing: state.product.receiptUploadProcessing,
    receiptUploadError: state.product.receiptUploadError,
    error: state.product.error,
    transferSuccess: state.product.transferSuccess,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _changeTermsKey: (termsKey:string) => {
      dispatch(changeTermsKey(termsKey));
    },
    _getProduct: (contractSaleId: string) => {
      dispatch(getProduct(contractSaleId));
    },
    _setSelectedProduct: (contractSaleId: string) => {
      dispatch(setSelectedProduct(contractSaleId));
    },
    _getProductHistory: (lineitemId: string) => {
      dispatch(getProductHistory(lineitemId));
    },
    _getProductTermsInfo: (product: any) => {
      dispatch(getProductTermsInfo(product));
    },
    _showModal: (modalID: string) => {
      dispatch(showModal(modalID));
    },
    _getUserDetails: () => {
      dispatch(getUserDetails());
    },
    _getMerchantSettings: (contractSaleId:string) => {
      dispatch(getMerchantSettings(contractSaleId));
    },
    _createAdminHandoffClaim: (contractSaleId:string) => {
      dispatch(createAdminHandoffClaim(contractSaleId));
    },
    _uploadReceiptPicture: (contractSaleId:string, file: any) => {
      dispatch(uploadReceiptPicture(contractSaleId, file));
    },
    _resetProductResolutionSuccess: () => {
      dispatch(resetProductResolutionSuccess());
    },
    _getIssueTypes: () => {
      dispatch(getIssueTypes());
    },
    _setError: (error: string) => {
      dispatch(setError(error));
    },
    _clearError: () => {
      dispatch(clearProductError());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PDP);
