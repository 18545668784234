import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { ReactComponent as LogoXCover } from '../../images/logo-clyde-xcover.svg';
import { ReactComponent as LogInIcon } from '../../images/log-in-icon.svg';

import { ReactComponent as AccountImg } from '../../images/nav/account.svg';
// import { ReactComponent as ClaimsImg } from '../../images/nav/claims.svg';
import { ReactComponent as LogOutImg } from '../../images/nav/log-out.svg';
import { ReactComponent as ProductsImg } from '../../images/nav/products.svg';

interface headerProps {
  isUser: string | boolean;
  logoutFlash: boolean;
  activeTab?: string;
  _logout: any;
  _clearLogoutFlash: any;
}

interface NavigationType {
  isLink: boolean;
  name: string;
  to?: string;
  click?: any;
  img?: any;
}

class UserHeader extends React.Component<headerProps> {
  componentDidUpdate(prevProps: any) {
    if (this.props.logoutFlash && !prevProps.logoutFlash) {
      setTimeout(() => {
        this.props._clearLogoutFlash();
      }, 3000);
    }
  }

  render() {
    const Navigation: NavigationType[] = this.props.isUser
      ? [ // Logged in users
        {
          isLink: true,
          name: 'products',
          to: '/products',
          img: <ProductsImg />,
        },
        {
          isLink: true,
          name: 'account',
          to: '/account',
          img: <AccountImg />,
        },
        {
          isLink: false,
          name: 'log out',
          click: this.props._logout,
          img: <LogOutImg />,
        },
      ]
      : [ // Logged out users
        { isLink: true, name: 'sign in', to: '/login' },
        { isLink: true, name: 'create account', to: '/signup' },
      ];

    return (
      <>
        <header className="user-header">
          <CSSTransition
            in={this.props.logoutFlash}
            timeout={ 6000 }
            classNames="clyde-flash"
            >
            <div className="clyde-flash clyde-flash--pro">
              Successfully logged out!
            </div>
          </CSSTransition>
          <Link to="/products">
            <LogoXCover className="user-header__logo" />
          </Link>
          {
            !this.props.isUser
            && <Link to="/login">
              <LogInIcon className="user-header__login" />
            </Link>
          }
        </header>

        <nav data-test="menuItems" className={ `user-nav ${this.props.isUser ? 'user-nav--logged-in' : 'user-nav--logged-out'}` }>
            {
              Navigation.map((nav: any, idx: number) => (
                nav.isLink
                  ? <Link
                      key={ idx }
                      data-test={`${nav.name.toLowerCase()}Item`}
                      className={ `user-nav__nav-item ${this.props.activeTab === nav.name ? 'active' : ''}` }
                      to={ nav.to }>
                      <div className={
                        `user-nav__nav-item__link ${
                          this.props.activeTab === nav.name
                            ? 'user-nav__nav-item__link--active'
                            : 'user-nav__nav-item__link--inactive'}` } >
                        { nav.img }
                        { nav.name }
                      </div>
                      <div className="link-underline" data-test="linkUnderline"></div>
                    </Link>
                  : <button
                      key={ idx }
                      data-test="logoutItem"
                      className={ `user-nav__nav-item user-nav__nav-item__logout` }
                      onClick={ nav.click } >
                      { nav.img }
                      { nav.name }
                    </button>
              ))
            }
        </nav>
      </>
    );
  }
}

export default UserHeader;
