import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { ProductPurchaseType, purchaseStates } from '../../../types/ProductTypes';
import ClickCopy from '../../Shared/ClickCopy';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

class ProductListItemCTA extends React.Component<any> {
  addToAvailableItems = (product:ProductPurchaseType) => {
    this.props._addToAvailableItems(product);
    this.props.history.push('/checkout');
  };

  render() {
    const { product } = this.props;
    let cta = <div></div>;
    let expiresText:JSX.Element|null = <div></div>;
    switch (product.state) {
      case purchaseStates.active:
        // eslint-disable-next-line no-case-declarations
        const expiresInfo:string = product.coverageStartType === 'dop'
          ? `Expires on ${dayjs(product.expiresDate).utc().local().format('MMMM Do, YYYY')}`
          : `Expires ${product.termLabelPlural.toLowerCase()} after limited warranty`;
        cta = <div className='purchase-list-item__ctas'>
          <Link className='clyde-link' to={ `/products/${product.contractSaleId}` } data-test='viewProduct'>View Product</Link>
        </div>;
        expiresText = <p className='purchase-list-item__expires-text clyde--muted-text' >
          { expiresInfo }
        </p>;
        break;
      case purchaseStates.eligible:
        cta = <div className='purchase-list-item__ctas'>
          <Button
            onClick={ () => this.addToAvailableItems(product) }
            className='button--general purchase-list-item__button'
            data-test='buyCoverageButton'>Buy Coverage</Button>
        </div>;
        expiresText = product.daysLeft <= 31
          ? <p className='purchase-list-item__expires-text clyde--warning' >
              {
                product.daysLeft === 1
                  ? `${product.daysLeft} day left to purchase`
                  : `${product.daysLeft} days left to purchase`
              }
            </p>
          : null;
        break;
      case purchaseStates.fulfilled:
        cta = (
          <div className='purchase-list-item__ctas'>
            <Link to={ `/products/${product.contractSaleId}` } data-test='viewProduct'>View Product</Link>
          </div>
        );
        expiresText = (
          <p className='purchase-list-item__expiresText clyde--muted-text' >
            Fulfilled on { product.expiresDate }
          </p>
        );
        break;
      case purchaseStates.expired:
        cta = <div className='purchase-list-item__ctas'>
          <Link to={ `/products/${product.contractSaleId}` } data-test='viewProduct'>View Product</Link>
        </div>;
        expiresText = <p className='purchase-list-item__expiresText clyde--muted-text' >
          Expired on { dayjs(product.expiresDate).utc().local().format('MMMM Do, YYYY') }
        </p>;
        break;
      case purchaseStates.ineligible:
        cta = <div></div>;
        break;
      default:
        cta = <div className='purchase-list-item__ctas'>
          <Link to={ `/products/${product.lineitemId}` } data-test='viewProduct'>View Product</Link>
        </div>;
        break;
    }

    if (product.resolutionPromoCode) {
      expiresText = <>
        <ClickCopy
          copyValue={product.resolutionPromoCode}
          className='purchase-list__promo-code'
          prefix='Code'
        />
      </>;
      cta = (
        <div className='purchase-list-item__ctas'>
          <Link to={ `/products/${product.contractSaleId}` } data-test='viewProduct'>View Product</Link>
        </div>
      );
    }

    return (
      <div className='purchase-list-item__cta-wrapper'>
        { expiresText }
        { cta }
      </div>
    );
  }
}

export default withRouter(ProductListItemCTA);
