import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import Errors from '../components/Errors';

import { clearError, setError } from '../actions/errorActions';
import { clearProductError } from '../actions/productActions';
import { clearClaimError } from '../actions/claimActions';

const mapStateToProps = (state: any) => ({ errors: state.error.errors });

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  _clearError: clearError,
  _setError: setError,
  _clearProductError: clearProductError,
  _clearClaimError: clearClaimError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Errors);
