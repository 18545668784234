import http, { handleServiceError, ServiceError } from '../config/http';

const CLAIM_URL = '/claim';

const CLAIM_RESOLUTION_URL = '/claim/resolution';

export const updateCreateClaim = (data:any) => {
  const { claim, claimHistory, claimInfo } = data;
  return {
    type: 'UPDATE_CREATE_CLAIM',
    claim,
    claimHistory,
    claimInfo,
  };
};

export const loadingCreateClaim = () => ({
  type: 'LOADING_CREATE_CLAIM',
});

export const failedCreateClaim = (err: ServiceError) => ({
  type: 'FAIL_CREATE_CLAIM',
  ...handleServiceError(err),
});

export const createClaim = (claim:any) => (dispatch:any) => {
  dispatch(loadingCreateClaim());
  return http.post(CLAIM_URL, claim)
    .then((response) => {
      dispatch(updateCreateClaim(response.data));
    })
    .catch((err) => {
      dispatch(failedCreateClaim(err));
    });
};

export const createAdminHandoffClaim = (contractSaleId:any) => (dispatch:any) => {
  dispatch(loadingCreateClaim());
  return http.post(`${CLAIM_URL}/admin-handoff`, contractSaleId)
    .then((response) => {
      dispatch(updateCreateClaim(response.data));
    })
    .catch((err) => {
      dispatch(failedCreateClaim(err));
    });
};

export const updateGetClaim = (claim: any) => ({
  type: 'UPDATE_GET_CLAIM',
  claim,
});

export const loadingGetClaims = () => ({
  type: 'LOADING_GET_CLAIMS',
});

export const failedGetClaims = (err:any) => ({
  type: 'FAIL_GET_CLAIM',
  error: err.response ? err.response.data : 'Network Error',
  status: err.response ? err.response.status : 503,
});

export const resetClaimForm = () => ({
  type: 'RESET_CLAIM_FORM',
});

export const resetSelectedClaim = () => ({
  type: 'RESET_SELECTED_CLAIM',
});

interface resolutionInfoType {
  resolutionType: string;
  claimId: string;
  data: any;
}

export const submitClaimResolution = (resolutionInfo: resolutionInfoType) => (dispatch:any) => {
  dispatch(loadingClaimResolution());
  return http.post(CLAIM_RESOLUTION_URL, { resolutionInfo })
    .then((response) => {
      dispatch(updateClaimResolution(response.data));
    })
    .catch((err) => {
      dispatch(failedClaimResolution(err));
    });
};

export const loadingClaimResolution = () => ({
  type: 'CLAIM_RESOLUTION_PENDING',
});

export const failedClaimResolution = (err:any) => ({
  type: 'CLAIM_RESOLUTION_FAILURE',
  error: err,
});

export const updateClaimResolution = (data:any) => ({
  type: 'CLAIM_RESOLUTION_SUCCESS',
  data,
});

export const resetProductResolutionSuccess = () => ({
  type: 'RESET_CLAIM_RESOLUTION_SUCCESS',
});

export const updateGetIssueTypes = (issueTypes:any) => ({
  type: 'UPDATE_GET_ISSUE_TYPES',
  issueTypes,
});

export const loadingGetIssueTypes = () => ({
  type: 'LOADING_GET_ISSUE_TYPES',
});

export const failedGetIssueTypes = (err:any) => ({
  type: 'FAIL_GET_ISSUE_TYPES',
  error: err.response ? err.response.data : 'Network Error',
  status: err.response ? err.response.status : 503,
});

export const getIssueTypes = () => (dispatch:any) => {
  dispatch(loadingGetIssueTypes());
  return http.get(`${CLAIM_URL}/issue-types`)
    .then((response) => {
      dispatch(updateGetIssueTypes(response.data.issueTypes));
    })
    .catch((err) => {
      dispatch(failedGetIssueTypes(err));
    });
};
export const clearClaimError = () => ({
  type: 'CLEAR_CLAIM_ERROR',
});

export const getClaim = (claimId: string) => (dispatch:any) => {
  dispatch(loadingGetClaims());
  return http.get(`${CLAIM_URL}/${claimId}`)
    .then((response) => {
      dispatch(updateGetClaim(response.data));
    })
    .catch((err) => {
      dispatch(failedGetClaims(err));
    });
};
