import React, { useState } from 'react';
import { FormGroup, Button } from 'reactstrap';
import { ReactComponent as LoadingBars } from '../../../../images/loading-bars.svg';
import StepsList from './StepsList';

interface SummaryProps {
  isProcessing?: boolean;
  isResolution?: boolean;
  formState: any;
  imageLink: string;
  contractName: string;
  contractId: string;
  contractExpiry: string;
  contractTerm: string;
  contractAdh: boolean;
  propsEmail: string;
  propsPhone: string;
  pid: string;
  cid: string;
  optionalStepIncluded?: boolean;
  selectStep: (step: number) => any;
  selectPrevStep: (step: number) => any;
  submitClaim: (e: any) => any;
  currentStep: any;
  navigateToStep: (stepName: string) => any;
  partsStepIncluded: boolean;
}

const Summary = ({
  propsEmail,
  propsPhone,
  isProcessing,
  isResolution,
  formState: {
    step,
    completed,
    handoffType,
    email,
    phone,
    secondaryPhone,
    issueTypes,
    affectedParts,
    description,
    fileNames,
    failureDate,
  },
  submitClaim,
  optionalStepIncluded,
  navigateToStep,
  partsStepIncluded,
} : SummaryProps) => {
  const [checked, toggleCheck] = useState(false);

  const formattedDate = failureDate ? failureDate.split('-') : [];
  formattedDate.push(formattedDate[0]);
  formattedDate.shift();

  const issueLabel = issueTypes.map((it: any) => (it.label)).join(', ');
  const partsInfo = affectedParts.map((part: any) => (part.name)).join(', ');
  const filesProvided = Boolean(fileNames.join(''));
  const isReview = step < 0 && !handoffType;

  return (
    <section className="claim-form__step claim-form__summary">
      <div className='claim-form__nav__review-header'>
        <h2>File a claim</h2>
        <h3>Review your claim</h3>
      </div>

      <StepsList
        completed={ completed }
        step={ step }
        propsEmail={ propsEmail }
        email={ email }
        propsPhone={ propsPhone }
        phone={ phone }
        secondaryPhone={ secondaryPhone }
        issueLabel={ issueLabel }
        description={ description }
        isReview={ isReview }
        isResolution={ isResolution }
        isSummary={ true }
        optionalStepIncluded={ optionalStepIncluded }
        filesProvided={ filesProvided }
        affectedParts={ affectedParts }
        partsInfo={ partsInfo }
        fileNames={ fileNames }
        formattedDate={ formattedDate }
        navigateToStep={ navigateToStep }
        partsStepIncluded={ partsStepIncluded }
        />

      <FormGroup>
        <div className='claim-form__nav__confirmation__check' data-test='confirmInformation'>
          <input
            id='verify-claim-form-info'
            type='checkbox'
            className='claim-form__nav__confirmation__check__input'
            checked={ checked }
            onChange={ () => toggleCheck(!checked) }/>
          <label htmlFor="verify-claim-form-info">
            I confirm that the information I’ve provided is accurate and truthful.
          </label>
        </div>
        <Button
          className='button--general claim-form__step__next-button'
          data-test='submitClaim'
          onClick={() => submitClaim(false)}
          disabled={ !checked || isProcessing }
        >
          { isProcessing ? <LoadingBars /> : 'Submit Claim' }
        </Button>
      </FormGroup>

    </section>
  );
};

export default Summary;
