import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import CloseModalButton from '../../../Shared/CloseModalButton';

import Card from './Card';
import CoBranding from '../../../Shared/CoBranding';
import { ReactComponent as Partner } from '../../../../images/partner.svg';
import { ReactComponent as ArrowDoubleRight } from '../../../../images/ArrowDoubleRight.svg';
import { ReactComponent as Clipboard } from '../../../../images/Clipboard.svg';

const XClaimHandoff = (props:any) => {
  const {
    claimsOutlink,
    cobrandedLogo,
    bookingId,
    _closeModal,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList.add('overflow-off');

    return () => {
      document.body.classList.remove('overflow-off');
    };
  }, []);

  const [activeDot, setDot] = useState(0);

  const handleFileClaim = () => {
    _closeModal();
  };

  const handleScroll = (e: any) => {
    const { scrollLeft, offsetWidth } = e.target;
    const cardWidth = e.target.children[0].offsetWidth;
    const maxScroll = ((cardWidth + 50) * 3) - offsetWidth;
    const percentScrolled = scrollLeft / maxScroll;
    setDot(maxScroll === scrollLeft ? 2 : Math.floor(percentScrolled * 3));
  };

  // generates an range array 0-2
  const range = Array.from(Array(3).keys());

  return (
    <div className='file-claim-handoff' onClick={ _closeModal }>
      <div
        className='file-claim-handoff__modal'
        data-test='handoffModal'
        onClick={ (e) => { e.stopPropagation(); } }>
        <CloseModalButton
          className='clyde-modal__close'
          onClick={ _closeModal }/>

        <CoBranding imgSrc={ cobrandedLogo } />

        <div className='file-claim-handoff__modal__title'>
          <p>Your claim will be managed through the XCover portal!</p>
          <p className='clyde-muted-text'>Booking ID: {bookingId}</p>
        </div>

        <div className='file-claim-handoff__modal__cards' onScroll={ handleScroll }>
          <Card
            Image={ Partner }
            title='XCover'
            text="Your time is precious. We make sure you don’t have to spend it on lengthy claims processes." />
          <Card
            Image={ ArrowDoubleRight }
            title='Next Steps'
            text='File a claims form, using your Booking ID. XCover will keep you up to date on your request status.' />
          <Card
            Image={ Clipboard }
            title='Claim Status'
            text='All updates to the claim status will be accessible via the XCover portal.' />
          <div className='file-claim-handoff__modal__cards__spacer' />
        </div>

        <div className='file-claim-handoff__modal__card-indicator'>
          { range.map((n, i) => <div key={i} className={classnames(
            'file-claim-handoff__modal__card-indicator__dot',
            { 'file-claim-handoff__modal__card-indicator__dot--active': i === activeDot },
          )}>
              <div className='file-claim-handoff__modal__card-indicator__dot__inner' />
            </div>)}
        </div>

        <a target='_blank' rel='noopener noreferrer' href={ claimsOutlink }>
          <Button
            className='button--general'
            onClick={ handleFileClaim }
            data-test='continueToFileClaim'
            >
            Continue To File Claim
          </Button>
        </a>
      </div>
    </div>
  );
};

export default XClaimHandoff;
