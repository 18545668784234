import React, { Component } from 'react';
import classnames from 'classnames';

import { ReactComponent as Checkmark } from '../../../images/checkmark.svg';
import { ReactComponent as ExclamationCircle } from '../../../images/exclamation-circle.svg';
import { ReactComponent as RightArrow } from '../../../images/right-arrow.svg';

interface ReceiptUploadProps {
  receiptUploadProcessing: boolean;
  receiptUploadError: boolean;
  receiptFile: string;
  propsTime: number;
  csSource: string;
  _uploadReceipt: any;
}

class ReceiptUpload extends Component<ReceiptUploadProps, any> {
  state = {
    secondClass: '',
    showUpload: false,
    labelText: 'Upload a receipt to process your claims faster',
  };

  componentDidUpdate = (prevProps: ReceiptUploadProps) => {
    const {
      receiptFile: prevReceipt,
      propsTime: prevTime,
    } = prevProps;

    const {
      receiptFile: currentReceipt,
      propsTime: currentTime,
      receiptUploadProcessing,
      receiptUploadError,
    } = this.props;

    if (prevTime === currentTime) {
      // prevent continous state updates
      return;
    }

    let {
      showUpload,
      secondClass,
      labelText,
    } = this.state;

    if (prevReceipt === 'placeholder' && currentReceipt) {
      return;
    }

    if (!currentReceipt) {
      showUpload = true;
    }

    if (receiptUploadProcessing) {
      secondClass = 'pdp-receipt-upload__background--progress--processing';
    }

    if (currentReceipt && !prevReceipt) {
      secondClass = 'pdp-receipt-upload__background--progress--finished';
      setTimeout(() => {
        this.setState({ labelText: 'Success' });
      }, 500);
    }

    if (receiptUploadError) {
      secondClass = 'pdp-receipt-upload__background--progress--error';
      labelText = 'Something went wrong.  Please refresh the page.';
    }

    this.setState({ showUpload, secondClass, labelText });
  };

  handleReceiptUpload = (e: any) => {
    const { _uploadReceipt } = this.props;
    const uploadInfo = new FormData();
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    uploadInfo.append('receiptFile', file);
    _uploadReceipt(uploadInfo);
  };

  render() {
    const {
      props: {
        receiptUploadError, receiptUploadProcessing, receiptFile, csSource,
      },
      state: { showUpload, secondClass, labelText },
      handleReceiptUpload,
    } = this;

    if (!showUpload || csSource !== 'checkoutWidget') {
      return null;
    }

    if (labelText === 'Success') {
      setTimeout(() => this.setState({ showUpload: false }), 1500);
    }

    const progressClass = classnames('pdp-receipt-upload__background--progress', secondClass);
    const uploadClass = classnames('pdp-receipt-upload', {
      'pdp-receipt-upload--finished': labelText === 'Success',
      'pdp-receipt-upload--errored': receiptUploadError,
    });

    return (
      <div className={ uploadClass }>
        <div className='pdp-receipt-upload__background' />
        <div className={ progressClass } />
        { labelText === 'Success'
          ? <Checkmark />
          : <ExclamationCircle />
        }
        <label
          htmlFor='pdp-receipt-upload__input'
          className='pdp-receipt-upload__label'
        >
          { labelText }
        </label>
        { labelText !== 'Success' && <RightArrow /> }
        <input
          id='pdp-receipt-upload__input'
          className='pdp-receipt-upload__input'
          type='file'
          onChange={ handleReceiptUpload }
          accept='image/*, .pdf'
          disabled={ receiptUploadError || receiptUploadProcessing || Boolean(receiptFile) }
        />
      </div>
    );
  }
}

export default ReceiptUpload;
