interface ErrorState {
  errors: string[]; // TODO: maybe we could use objects instead of strings? easier to handle with instanceof checks..
}

const defaultState: ErrorState = {
  errors: [],
};

const errorReducer = function user(state: ErrorState = defaultState, action: any) {
  switch (action.type) {
    case 'SET_ERROR':
      return { errors: [action.payload.name, ...state.errors] };
    case 'CLEAR_ERROR':
      return { errors: state.errors.slice(1) };
    default:
      return state;
  }
};

export default errorReducer;
