import React, { Component } from 'react';
import {
  FormGroup, Button, Label, Input, FormFeedback,
} from 'reactstrap';
import validator from 'validator';
import classnames from 'classnames';

import { getProvincesByCountry, countryCodes } from '../../../Shared/regionCodes';

import ClaimSuccessInfo from './ClaimSuccessInfo';
import ResolutionError from './ResolutionError';

interface replaceProps {
  resolutionError: any;
  listedAddress: any;
  resolutionSuccess: boolean;
  resolutionType: string;
  _closeModal: any;
  _submitResolution: any;
}

class ReplaceSnippet extends Component<replaceProps, any> {
  state: any = {
    customerName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    addressReason: '',
    checked: false,
    addressConfirmed: null,
    errors: [],
  };

  componentDidMount = () => {
    if (!this.props.listedAddress || !this.props.listedAddress.address1) {
      this.setState({ addressConfirmed: false });
    }
  };

  buildHumanFriendlyAddress = () => {
    const { listedAddress } = this.props;
    let buildingAddress = listedAddress.address1;
    if (listedAddress.address2) {
      buildingAddress += ` ${listedAddress.address2}`;
    }
    buildingAddress += `, ${listedAddress.city}`;
    buildingAddress += `, ${listedAddress.province}`;
    buildingAddress += `, ${listedAddress.zip}`;
    return buildingAddress;
  };

  submitAddress = () => {
    const {
      state: {
        customerName,
        addressConfirmed,
        address1,
        address2,
        city,
        state,
        zip,
        addressReason,
      },
      props: { _submitResolution },
    } = this;

    const errors = [...this.state.errors];
    const country = this.state.country || 'US';

    if (addressConfirmed) {
      _submitResolution({ addressConfirmed });
    }

    if (customerName.length < 3) {
      errors.push('customerName');
    }

    if (address1.length < 4) {
      errors.push('address1');
    }

    if (city.length < 3) {
      errors.push('city');
    }

    if (countryCodes.indexOf(country) < 0) {
      errors.push('country', 'state', 'zip');
    } else { // validator will error on invalid country
      if (getProvincesByCountry(country).indexOf(state.toUpperCase()) < 0) {
        errors.push('state');
      }

      if (!validator.isPostalCode(zip, country) || !zip) {
        errors.push('zip');
      }
    }

    if (addressReason.length < 5) {
      errors.push('addressReason');
    }

    if (!errors.length) {
      _submitResolution({
        addressConfirmed,
        customerName,
        address1,
        address2,
        city,
        state,
        zip,
        country,
        addressReason,
      });
      return;
    }

    this.setState({ errors });
  };

  updateField = (e: any) => {
    const { name } = e.currentTarget;
    let { value } = e.currentTarget;
    const errors = this.state.errors.filter((error: string) => error !== name);

    if (name === 'country' || name === 'state') {
      value = value.toUpperCase();
    }

    this.setState({ [name]: value, errors });
  };

  render = () => {
    const {
      props: {
        resolutionSuccess,
        resolutionError,
        listedAddress,
        resolutionType,
        _closeModal,
      },
      state: {
        customerName,
        address1,
        address2,
        city,
        state,
        zip,
        country,
        addressReason,
        checked,
        addressConfirmed,
        errors,
      },
      buildHumanFriendlyAddress,
      updateField,
      submitAddress,
    } = this;

    const address = buildHumanFriendlyAddress();

    if (resolutionSuccess) {
      const claimState = resolutionType === 'replacement' ? 'replaceSuccess' : 'repairSuccess';
      return (
        <ClaimSuccessInfo
          claimInfo={{ claimState }}
          contract={{}}
          _closeModal={ _closeModal }
        />
      );
    }

    if (resolutionError.message && !resolutionSuccess) {
      const errorType = resolutionType === 'replacement' ? 'replacement' : 'repair';
      return (
        <ResolutionError type={ errorType } _closeModal={ _closeModal } />
      );
    }

    return (
      <section className='claim-form__step claim-form__step--approved-step'>
        <h4>Shipping information</h4>
        <FormGroup>
          { listedAddress && listedAddress.address1
            && <>
              <Label>Is this the correct address?</Label>
              <p>
                { listedAddress.firstName } { listedAddress.lastName } <br />
                { address }
              </p>
              <Button
                onClick={() => this.setState({ addressConfirmed: true })}
                className={classnames(
                  'claim-form__step--replacement__address-button',
                  { 'claim-form__step--replacement__address-button--selected': addressConfirmed },
                )}
                data-test='yes'
              >
                Yes
              </Button>
              <Button
                onClick={() => this.setState({ addressConfirmed: false })}
                className={classnames(
                  'claim-form__step--replacement__address-button',
                  { 'claim-form__step--replacement__address-button--selected': addressConfirmed === false },
                )}
                data-test='no'
              >
                No
              </Button>
            </>
        }
        </FormGroup>

        <div className={classnames(
          'claim-form__step--replacement__address-form',
          { 'claim-form__step--replacement__address-form--expanded': addressConfirmed === false },
        )}>
          <FormGroup>
            <Label>Who should this be addressed to?</Label>
            <Input
              name='customerName'
              data-test='name'
              placeholder='Your name'
              value={ customerName }
              onChange={ updateField }
              invalid={ errors.indexOf('customerName') >= 0 }
            />
            <FormFeedback>Please enter a name</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>What address should we send it to?</Label>
            <div className='claim-form__replacement__address__row'>
              <div className='replacement__address__row__cell--large'>
                <Label>Address 1</Label>
                <Input
                  name='address1'
                  data-test='addressLine1'
                  placeholder='123 Main St'
                  value={ address1 }
                  onChange={ updateField }
                  invalid={ errors.indexOf('address1') >= 0 }
                />
                <FormFeedback>Please enter an address</FormFeedback>
              </div>
              <div>
                <Label>Address 2</Label>
                <Input
                  name='address2'
                  data-test='addressLine2'
                  placeholder='Apt #'
                  value={ address2 }
                  onChange={ updateField }
                />
              </div>
            </div>

            <div>
              <Label>City</Label>
              <Input
                name='city'
                data-test='city'
                placeholder='Your town'
                value={ city }
                onChange={ updateField }
                invalid={ errors.indexOf('city') >= 0 }
              />
              <FormFeedback>Please enter your city</FormFeedback>
            </div>

            <div className='claim-form__replacement__address__row'>
              <div>
                <Label>State</Label>
                <Input
                  name='state'
                  data-test='state'
                  placeholder='AA'
                  value={ state }
                  onChange={ updateField }
                  maxLength={ 2 }
                  invalid={ errors.indexOf('state') >= 0 }
                />
                <FormFeedback>Please enter a 2-digit state code</FormFeedback>
              </div>
              <div>
                <Label>Postal Code</Label>
                <Input
                  name='zip'
                  data-test='zipCode'
                  placeholder='#####'
                  value={ zip }
                  onChange={ updateField }
                  invalid={ errors.indexOf('zip') >= 0 }
                />
                <FormFeedback>Please enter a valid zip</FormFeedback>
              </div>
              <div>
                <Label>Country Code</Label>
                <Input
                  name='country'
                  data-test='country'
                  placeholder='US'
                  value={ country }
                  onChange={ updateField }
                  invalid={ errors.indexOf('country') >= 0 }
                  maxLength={ 2 }
                />
                <FormFeedback>Only US / CA are supported</FormFeedback>
              </div>
            </div>

            <Label>Why is the address different?</Label>
            <Input
              name='addressReason'
              placeholder='It is different because...'
              value={ addressReason }
              onChange={ updateField }
              invalid={ errors.indexOf('addressReason') >= 0 }
              data-test='addressReason'
            />
            <FormFeedback>Please provide a reason</FormFeedback>
          </FormGroup>
          <p>
            Changing shipping addresses can cause a delay in fulfillment, as we want to be sure that you're shipping it to yourself.
          </p>
          <div className='claim-form__replacement__check' data-test='confirmationCheckbox'>
            <input
              id='verify-claim-form-info'
              type='checkbox'
              className='claim-form__replacement__check__input'
              checked={ checked }
              onChange={ () => this.setState({ checked: !checked }) }/>
            <label htmlFor="verify-claim-form-info">
              I confirm that the information I am providing is correct.
            </label>
          </div>

        </div>

        <p>Shipping times can vary. We'll send tracking details over email once ready.</p>

        <FormGroup>
          <Button
            className='button--general claim-form__step__payout-button'
            onClick={ submitAddress }
            disabled={ !addressConfirmed && !checked }
            data-test='submit'
          >
            Submit
          </Button>
        </FormGroup>
      </section>
    );
  };
}

export default ReplaceSnippet;
