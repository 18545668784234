export const getLocalTimeFormatFromDate = (time:any) => {
  // Set time in UTC since date format(ie YYYY-MM-DD) doesn't have that information
  const dateInUTCFormat = new Date(time);

  // Adjust to local time
  const adjustedForLocalTime = dateInUTCFormat.setMinutes(
    dateInUTCFormat.getMinutes() + dateInUTCFormat.getTimezoneOffset(),
  );
  return new Date(adjustedForLocalTime).toLocaleDateString(undefined, { year: 'numeric', month: '2-digit', day: '2-digit' });
};
