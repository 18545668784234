import React from 'react';
import ProductClaims from '../../Claim/ProductClaims';
import ClydeModal from '../../../containers/Modal';
import ClaimApprovedForm from '../../../containers/ClaimApprovedForm';
import ClaimHistory from '../../Claim/ClaimHistory';

interface ProductHistoryProps {
  history?: any,
  product?: any,
  hashedSaleID?: any;
  _showModal?: any;
  _closeModal?: any;
}

class ProductHistory extends React.Component<ProductHistoryProps> {
  render() {
    const {
      product,
      history,
      hashedSaleID,
      _showModal,
      _closeModal,
    } = this.props;

    return history?.claimHistory?.length > 0 ? (
      <>
        <ProductClaims
          claimHistory={ history.claimHistory }
          claimInfo={ history.claimInfo }
          claims={ history.claims }
          hashedSaleID={ hashedSaleID }
          shopUrl={ product.shopUrl }
          externalShopId={ product.externalShopId }
          orderId={ product.orderID }
          _showModal={ _showModal }
        />
        <ClydeModal modalID="claim-resolution-form" customClass="clyde-modal--full claim-modal clyde-modal--hide-close" >
          <ClaimApprovedForm contract={ product } claimInfo={ history.claimInfo } />
        </ClydeModal>
        <ClydeModal modalID="claim-history" customClass="clyde-modal--full history-modal clyde-modal--hide-close" >
          <ClaimHistory
            _closeModal={ _closeModal }
            claimHistory={ history.claimHistory }
            claimInfo={ history.claimInfo } />
        </ClydeModal>
      </>
    ) : null;
  }
}

export default ProductHistory;
