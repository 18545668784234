import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Form, FormGroup, Input, FormText,
} from 'reactstrap';
import { ReactComponent as BigRightArrow } from '../../images/big-right-arrow.svg';
import { ReactComponent as ExclamationCircle } from '../../images/exclamation-circle.svg';

interface FindContractFormProps {
  _getContract: any;
  error?: string;
}

interface FindContractFormState {
  contractId?: string,
}

class FindContractForm extends React.Component<FindContractFormProps, FindContractFormState> {
  state = {
    contractId: '',
  };

  handleChange = (event: any) => {
    this.setState({
      [event.currentTarget.name]: event.target.value,
    });
  };

  handleSubmit = (event: any) => {
    if (this.state.contractId && this.state.contractId.length > 3) {
      this.props._getContract(this.state.contractId);
    }
    event.preventDefault();
  };

  render() {
    const { error } = this.props;
    return (
      <section className="find-contract-container clyde-big-shadow" data-test="findYourContract">

        <div className="find-contract-container__top">
        <h2 className="find-contract-container__title">Find Your Contract</h2>
        <h4 className="find-contract-container__sub-title">File a Claim or Review Your Terms &amp; Conditions</h4>
        <Form inline onSubmit={ (e) => this.handleSubmit(e) }>
          <FormGroup>
            <Input
              type="text" name="contractId"
              id="contractId" placeholder=""
              data-test="contractId"
              className="find-contract-container__input"
              value={ this.state.contractId }
              onChange={ this.handleChange } />
          </FormGroup>
          <Button className="button--general find-contract-container__submit" data-test="submit">
            Submit
          </Button>
        </Form>
        { error
          && <FormText className="find-contract-container__help find-contract-container__help--error">
            <ExclamationCircle /> Incorrect Plan ID submitted. Try again to find your contract.
          </FormText>
        }
        <FormText className="find-contract-container__help" color="muted">
          You can find your Plan ID in your confirmation email
        </FormText>
        </div>

        <div className="find-contract-container__bottom clyde-big-shadow">
          <div className="find-contract-container__bottom__left">
            <BigRightArrow />
            <p>
              Not sure where your Plan ID is?<br />
              <Link to="/signup" data-test='signUp'>Register with your purchase email</Link> to locate your information
            </p>
          </div>
          <Link to="/login">
            <Button className="button--inverted find-contract-container__bottom__button" data-test="signIn">Sign in</Button>
          </Link>
        </div>

      </section>
    );
  }
}

export default FindContractForm;
