import React from 'react';
import { Button } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

interface ClaimButtonProps {
  product: any;
  isGuest: boolean;
  shouldDisableClaimBtn: boolean;
  _showModal: any;
  _showUnderwriterClaimHandoffModal: any;
  _showXCoverHandoffModal: any;
  showAsLink?: boolean;
}

const ClaimButton = (props: ClaimButtonProps) => {
  const {
    isGuest,
    product,
    product: {
      customClaimLink,
      adminHandoff,
      underwriterClaimsOutlink,
      state,
      coverageStartType,
      expiresDate,
    },
    shouldDisableClaimBtn,
    _showModal,
    _showUnderwriterClaimHandoffModal,
    _showXCoverHandoffModal,
    showAsLink,
  } = props;

  let buttonAction:any = () => { _showModal('claim-form'); };
  let buttonClass = 'button--general pdp-detail__ctas__button';
  let buttonText = 'File a Claim';

  if (showAsLink) {
    buttonClass = 'pdp-btn-as-link';
  }

  if (!isGuest) {
    if (product && customClaimLink) {
      // If claim form is external, we link to shop's external claim form
      return (
        <>
          <a className='claim-link--mobile' target='_blank' rel='noopener noreferrer' href={ customClaimLink }>
            <Button className={ buttonClass }>{ buttonText }</Button>
          </a>
          <Button
            className={ `claim-link--desktop ${buttonClass}` }
            onClick={ () => _showModal('claim-link') }
            data-test='fileAClaim'>
            { buttonText }
          </Button>
        </>
      );
    }

    if (product && adminHandoff && underwriterClaimsOutlink) {
      // If AdminHandoff === true AND Underwriter has a ClaimsOutlink, we send customer to underwriter's claim portal
      return (
        <Button className={ buttonClass } onClick={ _showUnderwriterClaimHandoffModal } data-test='fileAClaim'>
          { buttonText }
        </Button>
      );
    }

    if (product && product.quotePackageId) {
      return (
        <Button className={ buttonClass } onClick={ _showXCoverHandoffModal } data-test='fileAClaim'>
          { buttonText }
        </Button>
      );
    }
  }

  const expiredClassName = ' pdp-detail__ctas__button--expired';
  if (isGuest) {
    buttonAction = null;
    buttonClass += expiredClassName;
  } else if (product && state === 'inactive') {
    buttonAction = null;
    buttonClass += expiredClassName;
    buttonText = 'Contract Inactive';
  } else if (product && state === 'expired') {
    buttonAction = null;
    buttonClass += expiredClassName;
    buttonText = 'Contract Expired';
  } else if (product && state === 'fulfilled') {
    buttonAction = null;
    buttonClass += expiredClassName;
    buttonText = 'Fulfilled';
  } else if (shouldDisableClaimBtn) {
    buttonAction = null;
    buttonClass += expiredClassName;
    buttonText = 'Opened Claim';
  }

  // Check if contract is dop, and, if it is, if it's expired
  if (coverageStartType === 'dop' && state !== 'fulfilled') {
    const today = dayjs();
    const daysjsExpiresDate = dayjs(expiresDate, 'MMMM Do YYYY');
    if (today.isAfter(daysjsExpiresDate)) {
      buttonAction = null;
      buttonClass += expiredClassName;
      buttonText = 'Contract Expired';
    }
  }

  return (
    <Button
      className={ buttonClass }
      disabled={ buttonAction === null }
      onClick={ buttonAction }
      data-test='fileAClaim'>
      { buttonText }
    </Button>
  );
};

export default ClaimButton;
