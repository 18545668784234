import React from 'react';
import { ReactComponent as IconTransfer } from '../../../images/icon-transfer.svg';
import { ReactComponent as Exclamation } from '../../../images/exclamation-circle--grey-bg.svg';
import { ReactComponent as Check } from '../../../images/check--black.svg';

interface ContractTransferHeaderProps {
  step: number;
  name: string;
  _closeModal: any;
}

interface infoObjectProps {
  icon: any;
  header: string;
  p1: any;
  p2: string;
}

const setInfoForDisplay = (step: number, name: string) => {
  const infoObject: infoObjectProps = {
    icon: '',
    header: '',
    p1: '',
    p2: '',
  };
  switch (step) {
    case 0:
      infoObject.icon = <IconTransfer className='icon-transfer' />;
      infoObject.header = 'Change plan ownership';
      infoObject.p1 = 'Fill out information of new plan owner below.';
      infoObject.p2 = '(Please note: the new owner must live in the same country as you do.)';
      break;
    case 1:
      infoObject.icon = <Exclamation className='icon-transfer' />;
      infoObject.header = 'Review transfer information';
      infoObject.p1 = 'Are you sure you’d like to transfer this contract?';
      infoObject.p2 = '(Please note: this action is irreversible.)';
      break;
    case 2:
      infoObject.icon = <Check className='icon-transfer' />;
      infoObject.header = 'Transfer successful!';
      infoObject.p1 = <span>Protection plan has been transferred to <h6 className='transfer-name'>{ name }</h6>, they should be receiving an email shortly.</span>;
      infoObject.p2 = '';
      break;
    default:
      break;
  }
  return infoObject;
};

const ContractTransferHeader = ({
  step,
  name,
  _closeModal,
}: ContractTransferHeaderProps) => {
  const {
    icon,
    header,
    p1,
    p2,
  } = setInfoForDisplay(step, name);

  return (
    <div className={`contract-transfer-header ${step === 2 ? 'transfer-mb-xl' : ''}`}>
      <div className='contract-transfer-header__close'>
        {
          step < 2
          && <button
              onClick={ _closeModal }
              title='close'
              data-test='close'
              className='clyde--x-circle clyde-modal__higher-z-index'>
                &times;
            </button>
        }
      </div>
      <div className='contract-transfer-header__image'>
        { icon }
      </div>
      <div className='contract-transfer-header__copy'>
        <h2>{ header }</h2>
        <p>{ p1 }</p>
        <p>{ p2 }</p>
      </div>
    </div>
  );
};

export default ContractTransferHeader;
