import { LoadingDots } from '@joinclyde/clyde-react-components';
import React from 'react';
import Img from 'react-image';
import { Link } from 'react-router-dom';
import { FormGroup, Input } from 'reactstrap';
import ProductPlaceholder from '../../images/product-placeholder.svg';
import { AvailableItemType } from '../../types/LineItemTypes';
import CloseModalButton from '../Shared/CloseModalButton';

interface CartItemProps {
  item: AvailableItemType;
  index: number;
  _updateOrder: any;
  _removeItem: any;
}

class CartItem extends React.Component<CartItemProps, any> {
  constructor(props:CartItemProps) {
    super(props);
    const quants = [];
    for (let i = 1; i <= this.props.item.quantityAvailable; i++) {
      quants.push(i);
    }

    this.state = {
      quantsAvailable: quants,
      selectedContractIndex: 0,
      selectedQuantity: quants[quants.length - 1],
      lineitemId: props.item.lineitemId,
    };
  }

  static getDerivedStateFromProps(nextProps:any, prevState:any) {
    // If it's the same line item id, don't reset quants
    if (nextProps.item.lineitemId === prevState.lineitemId) {
      return null;
    }

    const quants = [];
    for (let i = 1; i <= nextProps.item.quantityAvailable; i++) {
      quants.push(i);
    }

    return {
      quantsAvailable: quants,
      selectedContractIndex: 0,
      selectedQuantity: quants[0],
      lineitemId: nextProps.item.lineitemId,
    };
  }

  changeQty = (e:any) => {
    const taxRate = this.props.item.taxRate ? this.props.item.taxRate : 0;
    const contract = this.props.item.contracts ? this.props.item.contracts[this.state.selectedContractIndex] : {};
    const unitPrice = (contract.backendFee + contract.platformFee + contract.baseCost + contract.markup);
    const tax = unitPrice * taxRate;
    const lineitemTotal = e.target.value * (unitPrice + tax);

    this.setState({
      selectedQuantity: e.target.value,
      total: lineitemTotal,
    });
    this.props._updateOrder({
      contractId: this.props.item.contracts ? this.props.item.contracts[this.state.selectedContractIndex].contractId : '',
      lineitemId: this.props.item.lineitemId,
      quantity: e.target.value,
      lineitemTotal,
      tax,
    });
  };

  changeSelectedContract = (e:any) => {
    const taxRate = this.props.item.taxRate ? this.props.item.taxRate : 0;
    const contract = this.props.item.contracts ? this.props.item.contracts[e.target.value] : 0;
    const unitPrice = (contract.backendFee + contract.platformFee + contract.baseCost + contract.markup);
    const tax = unitPrice * taxRate;
    const lineitemTotal = this.state.selectedQuantity * (unitPrice + tax);
    this.setState({
      selectedContractIndex: e.target.value,
      total: lineitemTotal,
    });
    this.props._updateOrder({
      contractId: this.props.item.contracts ? this.props.item.contracts[e.target.value].contractId : '',
      lineitemId: this.props.item.lineitemId,
      quantity: this.state.selectedQuantity,
      lineitemTotal,
      tax,
    });
  };

  removeItem = () => {
    this.props._removeItem(this.props.item.lineitemId);
  };

  render() {
    const { item } = this.props;
    const { selectedQuantity } = this.state;

    if (!item.contracts) {
      return <LoadingDots dotColor='clydeBlack'/>;
    }

    const { productId } = item;
    const taxRate = item.taxRate ? item.taxRate : 0;
    const contract = item.contracts[this.state.selectedContractIndex]; // Wondering if this could be undefined, don't thinks so
    const unitPrice = contract ? contract.backendFee + contract.platformFee + contract.baseCost + contract.markup : 0;
    const tax = unitPrice * taxRate * this.state.selectedQuantity;
    const total = contract ? (this.state.selectedQuantity * unitPrice) + tax : 0;

    return (
      <section className="cart-item clyde-shadow" data-test="cartItem">
        <CloseModalButton onClick={ this.removeItem } className='clyde--x-circle--checkout'/>
        <div className="cart-item__top">
          <Img
            alt="Default product image"
            className="cart-item__top__img"
            src={ item.imageLink || ProductPlaceholder } />

          <div className="cart-item__top__product-info">
            <span className="clyde--bold-small">{ item.name }</span>
            <Link
              to={ `/terms?pid=${productId}&cid=${contract && contract.contractId}` }
              target='blank'
              className='clyde-link-secondary'
            >
              View Terms
            </Link>
          </div>

          {
            item.daysLeft <= 31
              && <div className="cart-item__top__cta clyde--warning">
                {
                  item.daysLeft === 1
                    ? `${item.daysLeft} Day left to Buy Coverage`
                    : `${item.daysLeft} Days left to Buy Coverage`
                }
              </div>
          }
        </div>

        <div className="cart-item__bottom">
          <div className="cart-item__bottom__input">
            <div className="cart-item__bottom__input__quantity">
              <span className="cart-item-header clyde--bold-small">Quantity</span>
              <FormGroup className="cart-item__table__row__cart-form">
                <Input
                  className="contract-quantity"
                  data-test="quantity"
                  type="select"
                  name="select"
                  onChange={ this.changeQty }>
                  {
                    this.state.quantsAvailable.map((quantity: any, i: number) => (
                      <option key={ i } value={ quantity } selected={ selectedQuantity === quantity }>{ quantity }</option>
                    ))
                  }
                </Input>
              </FormGroup>
            </div>

            <div className="cart-item__bottom__input__warranty">
              <span className="cart-item-header clyde--bold-small">Warranty</span>
              <FormGroup className="cart-item__table__row__cart-form">
                <Input
                  className="contract-selector"
                  data-test="warranty"
                  type="select"
                  name="select"
                  onChange={ this.changeSelectedContract }>
                  {
                    item.contracts
                      ? item.contracts.map((itemContract: any, i: number) => {
                        const contractPrice = itemContract.recommendedPrice
                          ? itemContract.recommendedPrice.toFixed(2)
                          : (itemContract.markup + itemContract.baseCost + itemContract.backendFee + itemContract.platformFee).toFixed(2);
                        return (
                            <option key={ i } value={ i } >
                              { itemContract.termLabelPlural } (${ contractPrice })
                            </option>
                        );
                      })
                      : ''
                  }
                </Input>
              </FormGroup>
            </div>
          </div>

          <div className="cart-item__bottom__output">
            <div className="cart-item__bottom__output__tax">
              <span className="cart-item-header clyde--bold-small">Tax</span>
              <span className="cart-item-content">${ tax.toFixed(2) }</span>
            </div>

            <div className="cart-item__bottom__output__total">
              <span className="cart-item-header clyde--bold-small">Total</span>
              <span className="cart-item-content">${ total.toFixed(2) }</span>
            </div>
          </div>

        </div>

      </section>
    );
  }
}

export default CartItem;
