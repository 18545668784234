// Claim status objects
const inProcess = { value: 'inProcess', label: 'Submitted' };
const underClydeReview = { value: 'underClydeReview', label: 'Under Review' };
const underAdminReview = { value: 'underAdminReview', label: 'Under Review' };
const hiClydeHandoff = { value: 'hiClydeHandoff', label: 'Clyde Partner Managing Claim' };
const needMoreInfo = { value: 'needMoreInfo', label: 'More Info Requested' };
const customerWithdrawn = { value: 'customerWithdrawn', label: 'Customer Withdrawn' };
const approved = { value: 'approved', label: 'Approved' };
const denied = { value: 'denied', label: 'Not Covered' };
const resolutionInitiated = { value: 'resolutionInitiated', label: 'Resolution Initiated' };
const resolutionInProgress = { value: 'resolutionInProgress', label: 'Resolution In Progress' };
const resolutionOrdered = { value: 'resolutionOrdered', label: 'Resolution Ordered' };
const resolutionDelayed = { value: 'resolutionDelayed', label: 'Resolution Delayed' };
const resolutionFulfilled = { value: 'resolutionFulfilled', label: 'Resolution Fulfilled' };

export const newClaimStatuses = [
  inProcess,
  underClydeReview,
];
export const pendingClaimStatuses = [
  inProcess,
  underClydeReview,
  needMoreInfo,
];
export const needsAttentionClaimStatuses = [
  underAdminReview,
  needMoreInfo,
  approved,
];
export const nonResolvedClaimStatuses = [
  inProcess,
  underClydeReview,
  underAdminReview,
  hiClydeHandoff,
  needMoreInfo,
  customerWithdrawn,
  approved,
  denied,
];
export const openClaimStatuses = [
  inProcess,
  underClydeReview,
  underAdminReview,
  needMoreInfo,
  approved,
]; // "Open" here denoting the claim is still being processed in some way
export const closedClaimStatuses = [
  hiClydeHandoff,
  customerWithdrawn,
  denied,
  resolutionFulfilled,
]; // "Closed" here denoting that Clyde cannot / does not need to take any more action on claim
export const allClaimStatuses = [
  inProcess,
  underClydeReview,
  underAdminReview,
  hiClydeHandoff,
  needMoreInfo,
  customerWithdrawn,
  approved,
  denied,
  resolutionInitiated,
  resolutionInProgress,
  resolutionOrdered,
  resolutionDelayed,
  resolutionFulfilled,
];

const findClaimStatus = (claims:any[], target:string) => claims.find((claim) => claim.value === target);

export const claimStatusSwitcher = (claimStatusEnum: string, serviceType: string) => {
  if (claimStatusEnum === 'resolutionFulfilled' && serviceType) {
    return `Resolved: ${resolutionStatusSwitcher(serviceType)}`;
  } if (claimStatusEnum === 'resolutionInitiated') {
    return approved.label;
  } if (claimStatusEnum.indexOf('resolution') > -1) {
    // If claim is resolved but resolution isn't fulfilled, resolution exists but is incomplete
    return 'Resolution Initiated';
  }

  const foundStatus = findClaimStatus(allClaimStatuses, claimStatusEnum);
  return foundStatus ? foundStatus.label : 'None';
};

export const isNewClaim = (claimStatusEnum:string) => !!findClaimStatus(newClaimStatuses, claimStatusEnum);
export const isPendingClaim = (claimStatusEnum:string) => !!findClaimStatus(pendingClaimStatuses, claimStatusEnum);
export const isOpenClaim = (claimStatusEnum:string) => !!findClaimStatus(openClaimStatuses, claimStatusEnum);
export const isClosedClaim = (claimStatusEnum:string) => !!findClaimStatus(closedClaimStatuses, claimStatusEnum);

export const isApprovedClaim = (claimStatusEnum:string) => claimStatusEnum === approved.value;
export const isDeniedClaim = (claimStatusEnum:string) => claimStatusEnum === denied.value;
export const isResolvedClaim = (claimStatusEnum:string) => claimStatusEnum === resolutionFulfilled.value;

// Denial reason objects
const arrivedDamaged = {
  value: 'arrivedDamaged',
  label: 'Your product is still covered by the manufacturer\'s warranty.',
  explanation: 'The claim is not covered because it arrived damaged.',
};
const contractFullyUtilized = {
  value: 'contractFullyUtilized',
  label: 'Your Clyde contract has been fully utilized.',
  explanation: 'The claim is not covered because the contract has been fully utilized.',
};
const failedUsageQuestion = {
  value: 'failedUsageQuestion',
  label: 'Your Clyde contract doesn\'t cover that particular issue.',
  explanation: 'The claim is not covered because customer failed a usage question.',
};
const lossOrTheft = {
  value: 'lossOrTheft',
  label: 'Your Clyde contract doesn\'t cover lost and theft.',
  explanation: 'Lost and theft are not covered.',
};
const manualDenial = {
  value: 'manualDenial',
  label: 'Not covered after clyde review',
  explanation: 'The claim is not covered after being reviewed by Clyde.',
};
const nonAdhContract = {
  value: 'nonAdhContract',
  label: 'Your Clyde contract doesn\'t cover accidental damage.',
  explanation: 'Accidental damage is not covered.',
};
const withinLimitedWarranty = {
  value: 'withinLimitedWarranty',
  label: 'Your product is still covered by the manufacturer\'s warranty.',
  explanation: 'The claim is not covered because it was filed within limited warranty period.',
};

export const allDenialReasons = [
  arrivedDamaged,
  contractFullyUtilized,
  failedUsageQuestion,
  lossOrTheft,
  manualDenial,
  nonAdhContract,
  withinLimitedWarranty,
];

export const denialReasonSwitcher = (denialReasonEnum:string, returnExplanation?:boolean) => {
  const foundDenialReason = allDenialReasons.find((status:any) => status.value === denialReasonEnum);
  if (!foundDenialReason) {
    return 'None';
  } if (returnExplanation) {
    return foundDenialReason.explanation;
  }
  return foundDenialReason.label;
};

// Resolution types
export const resolutionStatusSwitcher = (status:string) => {
  switch (status) {
    case 'carryIn':
      return 'Carry in';
    case 'inHome':
      return 'In home repair';
    case 'depot':
      return 'Depot repair';
    case 'customerInstall':
      return 'Customer Install';
    case 'promoCode':
      return 'Promo Code';
    case 'payout':
      return 'Payout';
    case 'retailerReplace':
    case 'clydeReplace':
      return 'Replace';
    default:
      return 'None';
  }
};

// Claim types
export const claimTypeSwitcher = (status:string) => {
  switch (status) {
    case 'arrivedDamaged':
      return 'Arrived Damaged';
    case 'lossOrTheft':
      return 'Loss Or Theft';
    case 'accidentalDamage':
      return 'Accidental Damage';
    case 'stoppedWorking':
      return 'Stopped Working';
    default:
      return 'None';
  }
};

export const displayResolutionButton = (historyEvent: any, claimInfo: any) => {
  // @ts-ignore
  const showPayoutResolution = window._env_.SHOW_CLAIM_PAYOUTS === 'true';
  const { serviceType, programType } = claimInfo;
  return (
    (
      // the claim is in progress and we can show either a form for an address input or a simple modal saying repair is underway
      historyEvent.claimState === 'resolutionInProgress'
      && (
        !claimInfo.resolutionAddressId
        && (
          (serviceType === 'clydeReplace' || serviceType === 'retailerReplace') || programType === 'repair'
        )
      )
    ) || (
      historyEvent.claimState === 'resolutionFulfilled'
      // claim is resolved in a payout and the releveant information is present for the user to collect
      && claimInfo.serviceType === 'payout'
      && showPayoutResolution
      && !claimInfo.payoutDate
      && claimInfo.hiClydeEnabled
    )
  );
};
