const defaultState = {
  contractAdh: false,
  category: '',
  errors: [],
  shopLogo: '',
  summaryOptions: {
    extendedWarranty: false,
    jewelry: false,
    wearTear: false,
    allStains: false,
    defects: false,
    powerSurge: false,
  },
  termsKey: '',
  testTerms: [],
  type: '',
  underwriterName: '',
  underwriterClaimsOutlink: '',
};

const contractReducer = function user(state = defaultState, action:any) {
  switch (action.type) {
    case 'SUCCESS_GET_CONTRACT_TERMS':
      return {
        ...state,
        ...action.contractTerms,
        errors: [],
      };
    case 'FAILURE_GET_CONTRACT_TERMS':
      return {
        ...state,
        errors: [action.error.message],
      };
    case 'GET_PRODUCT_TERMS_INFO':
      return {
        ...state,
        contractAdh: action.product.contractAdh,
        contractTerm: action.product.term,
        category: action.product.contractCategory,
        shopLogo: action.product.shopLogo,
        summaryOptions: action.product.summaryOptions,
        termsKey: action.product.termsKey,
        underwriterName: action.product.underwriterName,
        underwriterClaimsOutlink: action.product.underwriterClaimsOutlink,
        pdfUrl: action.product.pdfUrl,
      };
    case 'UPDATE_GET_CONTRACT':
      return {
        ...state,
        contractAdh: action.contract.contractAdh,
        contractTerm: action.contract.term,
        category: action.contract.category,
        shopLogo: action.contract.shopLogo,
        summaryOptions: action.contract.summaryOptions,
        termsKey: action.contract.termsKey,
        underwriterName: action.contract.underwriterName,
        underwriterClaimsOutlink: action.contract.underwriterClaimsOutlink,
      };
    case 'SUCCESS_GET_TEST_CONTRACTS':
      return {
        ...state,
        testTerms: action.testTerms,
      };
    case 'CHANGE_TERMS_KEY':
      return {
        ...state,
        termsKey: action.termsKey,
      };
    case 'CLEAR_CONTRACT_TERMS':
      return defaultState;
    case 'SET_TERMS_ERROR':
      return {
        ...state,
        errors: [
          ...state.errors,
          action.message,
        ],
      };
    default:
      return state;
  }
};

export default contractReducer;
