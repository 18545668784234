import React from 'react';

import Bike from './Bike';
import Test from '../../../../containers/TestTerms';

import { UnderwriterProps } from '../../../../types/TermsTypes';

const Velocare: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'bike':
      Terms = <Bike contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <Test />;
      break;
  }

  return Terms;
};

export default Velocare;
