import { connect } from 'react-redux';
import { createClaim, submitClaimResolution } from '../actions/claimActions';
import { submitUserBilling } from '../actions/userActions';
import { cancelModal } from '../actions/modalActions';
import ClaimApprovedForm from '../components/Claim/ClaimForm/ClaimApprovedForm';

function mapStateToProps(state: any) {
  return {
    customerName: state.user.details.fullName,
    email: state.user.details.email,
    phone: state.user.details.phoneNumber,
    error: state.claim.error,
    claimFormSuccess: state.claim.claimFormSuccess,
    resolutionPending: state.claim.resolutionPending,
    resolutionError: state.claim.resolutionError,
    isProcessing: state.claim.isProcessing,
    resolutionSuccess: state.claim.resolutionSuccess,
    isOpen: Boolean(state.modal.visibleDict['claim-form']),
    hasBankAccountConnected: state.user.details.hasBankAccountConnected,
    merchantSettings: state.merchantSettings,
  };
}

interface resolutionInfoType {
  resolutionType: string;
  claimId: string;
  data: any;
}

function mapDispatchToProps(dispatch:any) {
  return {
    _cancelModal: () => {
      dispatch(cancelModal());
    },
    _createClaim: (claim: any) => {
      dispatch(createClaim(claim));
    },
    _submitClaimResolution: (resolutionInfo: resolutionInfoType) => {
      dispatch(submitClaimResolution(resolutionInfo));
    },
    _submitUserBilling: (billingInfo: any) => {
      dispatch(submitUserBilling(billingInfo));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClaimApprovedForm);
