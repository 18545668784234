import React from 'react';
import { ReactComponent as PencilEdit } from '../../../images/pencil-edit.svg';
import { TransferOrderDataI } from '../../../types/ProductTypes';

interface ContractTransferReviewProps {
  transferOrderData: TransferOrderDataI;
  setStep: any;
}

const ContractTransferReview = ({
  transferOrderData,
  setStep,
}: ContractTransferReviewProps) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    address1,
    address2,
    city,
    province,
    zip,
  } = transferOrderData;

  return (
    <div className='contract-transfer-review'>
      <div className='contract-transfer-review__header'>
        <h3>Recipient Information</h3>
        <PencilEdit className='icon-transfer-edit' onClick={ () => setStep(0)} data-test='edit'/>
      </div>
      <hr className='review-divider' />
      <div className='contract-transfer-review__info' data-test='contractTransferReview'>
        <span>{ `${firstName} ${lastName}`}</span>
        <span>{ email }</span>
        <span className='m-b-16'>{ phone }</span>
        <span>{ `${address1}${address2 ? `, ${address2}` : ''}`}</span>
        <span>{ `${city}, ${province} ${zip}`}</span>
      </div>
    </div>
  );
};

export default ContractTransferReview;
