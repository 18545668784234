import React from 'react';
import UserHeader from '../../containers/UserHeader';
import AccountNavigation from './AccountNavigation';
import AccountProfileForm from './AccountProfileForm';
import AccountPasswordForm from './AccountPasswordForm';
import AccountPlaid from './AccoutPlaid';
import LoggedOutFooter from '../Shared/LoggedOutFooter';

// Placeholder interface for now
interface UserAccountProps {
  isUser: boolean;
  userDetails: any;
  error: any;
  changeSuccess: any;
  _getUserDetails: any;
  _changeProfile: any;
  _changePasswordLoggedIn: any;
  _clearAccountMessages: any;
  _submitUserBilling: any;
  match?: any;
  history?: any;
}

class UserAccount extends React.Component<UserAccountProps> {
  componentDidMount() {
    document.title = 'Clyde | Account';
    this.props._getUserDetails();
  }

  componentDidUpdate() {
    if (!this.props.isUser) {
      this.props.history.push('/login');
    } else {
      window.scrollTo(0, 0);
    }
  }

  render() {
    let presented = <AccountProfileForm
      _changeProfile={ this.props._changeProfile }
      _clearAccountMessages={ this.props._clearAccountMessages }
      userDetails={ this.props.userDetails }
      changeSuccess={ this.props.changeSuccess }
      error={ this.props.error } />;

    let activeTab = 'profile';
    try {
      switch (this.props.match.params.action) {
        case 'password':
          activeTab = 'password';
          presented = <AccountPasswordForm
            _changePasswordLoggedIn ={ this.props._changePasswordLoggedIn }
            _clearAccountMessages={ this.props._clearAccountMessages }
            error={ this.props.error }
            changeSuccess={ this.props.changeSuccess } />;
          break;
        case 'billing-info':
          // @ts-ignore
          if (window._env_.SHOW_CLAIM_PAYOUTS === 'true' && false) { // we are currently not processing ach payouts
            activeTab = 'billing-info';
            presented = (
              <AccountPlaid
                _submitUserBilling={this.props._submitUserBilling}
                hasBankAccountConnected={ this.props.userDetails.hasBankAccountConnected }
                error={ this.props.error }
              />
            );
          }
          break;
        default:
          // No action yet;
          break;
      }
    } catch (e) {
      //
    }

    return (
      <React.Fragment>
        <main className="app-container">
          <UserHeader activeTab='account' />
          <div className="account-container">
            <h2>Account</h2>
            <div className='form-container'>
              <AccountNavigation activeTab={ activeTab } />
              { presented }
            </div>
          </div>
          <aside className="app-underlay"></aside>
        </main>
        <LoggedOutFooter />
      </React.Fragment>
    );
  }
}

export default UserAccount;
