import React, { useState, useEffect } from 'react';
import {
  Button, FormGroup, Input, FormText,
} from 'reactstrap';

interface ClaimdDescriptionProps {
  formState: {
    description: string;
    errors: any;
  };
  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  stepOptions: any[],
  productParts?: any[],
}

const ClaimDescription = ({
  formState: { description, errors },
  iterateStep,
  updateField,
  optionalStepIncluded,
} : ClaimdDescriptionProps) => {
  const minCharCount = 100;
  const [charCount, setCharCount] = useState(0);
  const [hasMinCharCount, setHasMinCharCount] = useState(false);
  const [textAreaClass, setTextAreaClass] = useState('');
  const [charCountClass, setCharCountClass] = useState('');

  useEffect(() => {
    const initialCount = description && description.length ? description.length : 0;
    setCharCount(initialCount);
    // If user comes back to edit, apply valid style
    if (initialCount >= minCharCount) {
      setTextAreaClass('claim-form__form__description-valid');
      setCharCountClass('claim-form__form_description-char-count-valid');
    }
  }, [description]);

  const onChangeHandler = (e: any) => {
    const { value } = e.target;
    if (value.length >= minCharCount) {
      setTextAreaClass('claim-form__form__description-valid');
      setCharCountClass('claim-form__form_description-char-count-valid');
      setHasMinCharCount(true);
    } else if (hasMinCharCount && (value.length < minCharCount)) {
      setTextAreaClass('claim-form__form__description-error');
      setCharCountClass('claim-form__form_description-char-count-error');
    }
    setCharCount(value.length);
    updateField('description', value);
  };

  return (<section className={`claim-form__step claim-form__description`}>
    <h2>File a claim</h2>
    <h3 className="claim-form__form-title">Description</h3>
    <p className="claim-form__form-sub-title">What parts of the product's accessories or functionality seem to be impaired?</p>
    <FormGroup>
      <FormText color='muted' className='claim-form__step__secondary-prompt'>
      Be as descriptive as possible. The more details you provide on the experienced issues, the faster we'll be able to process your claim.
      </FormText>
      <Input
        className={`claim-form__form__description ${textAreaClass}`}
        type="textarea"
        placeholder="What happened?"
        invalid={ errors.includes('description')}
        value={ description }
        maxLength={250}
        minLength={minCharCount}
        onChange={ onChangeHandler }
        data-test='description'
      />
      <div className="claim-form__form__characted-count">
        <span className={`claim-form__form__characted-count ${charCountClass}`}>
          {charCount} Characters (Minimum {minCharCount} Characters)
        </span>
      </div>
    </FormGroup>
    <Button
      className='button--general claim-form__step__next-button'
      onClick={iterateStep()}
      disabled={errors.includes('description') || !(charCount >= minCharCount)}
      data-test='next'
    >
      Next
    </Button>
  </section>);
};

export default ClaimDescription;
