import React, { useEffect, useState, useRef } from 'react';

interface ProgressBarCircleProps {
  totalSteps: number;
  currentStep: number;
  completed: boolean;
}

const ProgressBarCircle = ({
  totalSteps,
  currentStep,
  completed,
}: ProgressBarCircleProps) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  const progress = completed || currentStep === -1 ? 0 : (currentStep / totalSteps) * 100;

  const radius = 12.5;
  const center = 15;
  const strokeWidth = 3;
  const circleOneStroke = '#000000';
  const circleTwoStroke = '#FF8400';
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progressOffset = ((100 - progress) / 100) * circumference;
    setOffset(progressOffset);
  }, [setOffset, circumference, progress, offset]);

  return (
    <svg height="30" width="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={ center }
        cy={ center }
        r={ radius }
        stroke={ circleOneStroke }
        strokeWidth={ strokeWidth } />

      <circle
        ref={circleRef}
        className="svg-circle"
        stroke={ circleTwoStroke }
        cx={ center }
        cy={ center }
        r={ radius }
        strokeWidth={ strokeWidth }
        strokeDasharray={ circumference }
        strokeDashoffset={ offset } />
    </svg>
  );
};

export default ProgressBarCircle;
