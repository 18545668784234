import React from 'react';
import { Button, FormGroup } from 'reactstrap';
import classnames from 'classnames';

interface ClaimTypeProps {
  formState: {
    issueTypes: any[];
  };
  updateField: (field: string, value: any) => void;
  iterateStep: () => any;
  optionalStepIncluded?: boolean;
  stepOptions: any[],
  productParts?: any[],
}

const ClaimType = ({
  iterateStep,
  updateField,
  formState: { issueTypes: selectedTypes },
  stepOptions,
} : ClaimTypeProps) => {
  const onSelectIssueType = (type: any) => (e: any) => {
    const { key, type: eventType } = e;
    if (eventType === 'keydown' && key !== 'Enter') {
      return;
    }

    const typesCopy = [...selectedTypes];
    let typeFiltered = false;

    const updatedTypes = typesCopy.filter((existing) => {
      const keepType = existing.id !== type.id;
      if (!keepType) {
        typeFiltered = true;
      }
      return keepType;
    });

    if (!typeFiltered) {
      updatedTypes.push(type);
    }

    updateField('issueTypes', updatedTypes);
  };

  return (
    <section className={`claim-form__step claim-form__type`}>
      <h2>File a claim</h2>
      <h3>Product Issue</h3>
      <p className='claim-form__step__issue-tagline'>What is wrong with your product?</p>
      <ul className='claim-form__issue-type__list'>
        { stepOptions.map((type: any) => <div
            className={classnames(
              'claim-form__issue-type__type-item',
              { selected: selectedTypes.findIndex((iss:any) => iss.id === type.id) > -1 },
            )}
            onClick={ onSelectIssueType(type) }
            key={ type.id }
            tabIndex={ 1 }
            role='button'
            onKeyDown={ onSelectIssueType(type) }
            data-test={`productIssueItem-${type.id}`}
          >
            { type.label }
          </div>)}
      </ul>
      <FormGroup>
        <Button
          className='button--general claim-form__step__next-button'
          onClick={ iterateStep() }
          disabled={ !selectedTypes.length }
          data-test='next'
        >
          Next
        </Button>
      </FormGroup>
    </section>
  );
};

export default ClaimType;
