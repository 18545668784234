import React from 'react';
import classnames from 'classnames';

interface ClaimPhotoInputProps {
  name: string;
  index: number;
  fileNames: string[];
  deleteFile: (index: number) => any;
  sendUpdate: (index: number) => any;
}

const ClaimPhotoInput = ({
  name,
  index,
  fileNames,
  deleteFile,
  sendUpdate,
} : ClaimPhotoInputProps) => {
  const shouldBeDisabled = index > 0 && fileNames[index - 1] === '';
  const labelClass = classnames('claim-form__documentation__upload', {
    'claim-form__documentation--hidden-mobile': !name && index && !fileNames[index - 1],
    'input-disabled': shouldBeDisabled,
  });

  let displayName = name.split('\\').pop();
  if (displayName && displayName.length > 15) {
    displayName = `${displayName.slice(0, 12)}...`;
  }

  if (!displayName) {
    displayName = `Image ${index + 1}`;
  }

  return (
    <label
      className={ labelClass }
      key={`claim-form-file-${index}`}
      htmlFor={`claim-form-file-${index}`}
    >
      <div className='claim-form__documentation__prompt-text' >
        { displayName }
        <br />
      </div>
      <div>
        <span className='claim-form__documentation__upload__input-text'>
          { name ? 'Replace' : 'Upload' }
        </span>
        { index > 0 && name
          && <>
            <span
              className='claim-form__documentation__upload__input-text'
              onClick={ deleteFile(index) }
            >
              Delete
            </span>
          </>
        }
        <input
          className="claim-form__documentation__upload__input"
          type="file"
          accept='image/*, .pdf'
          key={ `${new Date()} - ${index}` }
          name="ClaimPhotoInput"
          id={`claim-form-file-${index}`}
          onChange={ sendUpdate(index) }
          data-test={`uploadImage-${index}`}
          multiple={false}
          disabled={ shouldBeDisabled }
        />
      </div>
    </label>
  );
};

export default ClaimPhotoInput;
