import React from 'react';
import { ReactComponent as RightArrow } from '../../../images/right-arrow.svg';
import { ReactComponent as ExclamationCircle } from '../../../images/exclamation-circle.svg';

const ContractTransferBanner = (props: any) => (
    <div className='pdp-transfer-contract'
    onClick={ () => props.handleShowModal('contract-transfer') } data-test="contractTransferButton">
      <div className='svg-container-left'>
        <ExclamationCircle />
      </div>
      <div className='svg-container-middle'>Looking to change the owner of this plan? Click here to send plan to a friend or family.</div>
      <div className='svg-container-right'>
        <RightArrow />
      </div>
    </div>
);

export default ContractTransferBanner;
