import React from 'react';

interface DownloadBarProps {
  contractTerms: any;
}

const DownloadBar: React.FC<DownloadBarProps> = (props) => {
  const { underwriterName, termsKey } = props.contractTerms;
  return (
    <div className="contract-terms-page-download-bar">
      {
        // Marker: AJC 01/09/20 A stop-gap check to prevent errors until we figure out the cause
        underwriterName
          ? <a
              className={ `${termsKey === 'test' ? 'link--disabled' : ''}` }
              download="Clyde Contract Terms and Conditions.pdf"
              href={ `/TermsAndConditions/${underwriterName.replace(' ', '')}/${termsKey}.pdf` }>
              <button className={ `btn contract-terms-page-download-bar__button ${termsKey === 'test' ? 'button--disabled' : 'button--inverted'}` } data-test='downloadPdf'>
                Download PDF
              </button>
            </a>
          : null
      }
    </div>
  );
};

export default DownloadBar;
