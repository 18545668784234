import { connect } from 'react-redux';
import { showModal, cancelModal } from '../actions/modalActions';
import ClydeModal from '../components/Shared/ClydeModal';

function mapStateToProps(state: any) {
  return {
    visibleDict: state.modal.visibleDict,
  };
}

function mapDispatchToProps(dispatch:any) {
  return {
    _showModal: (modalID: string) => {
      dispatch(showModal(modalID));
    },
    _cancelModal: () => {
      dispatch(cancelModal());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ClydeModal);
