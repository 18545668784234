import React from 'react';
import { ReactComponent as UploadIcon } from '../../../../../images/upload-icon.svg';

interface ReceiptCaptureProps {
  receiptFileName: string,
  updateReceipt: any;
}

const ReceiptCapture = ({
  receiptFileName,
  updateReceipt,
} : ReceiptCaptureProps) => {
  let displayName = receiptFileName.split('\\').pop();
  if (displayName && displayName.length > 15) {
    displayName = `${displayName.slice(0, 12)}...`;
  }

  displayName = displayName || 'Upload Receipt';

  return (
    <section className='claim-form__documentation__receipt'>
      <p className="claim-form__form-sub-title">Please upload a photo of your receipt.</p>
      <label
        className='claim-form__documentation__receipt__upload'
        htmlFor='claim-form-file-receipt'
      >
        <UploadIcon />
        <div>
          <span className='claim-form__documentation__receipt__upload__input-text'>
            { displayName }
          </span>
          <input
            className="claim-form__documentation__upload__input"
            type="file"
            accept='image/*, .pdf'
            name="ReceiptCapture"
            id='claim-form-file-receipt'
            onChange={ updateReceipt }
          />
        </div>
      </label>
    </section>
  );
};

export default ReceiptCapture;
