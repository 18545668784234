import React from 'react';

import DOP from './DOP';
import DOPCommercial from './DOPCommercial';
import DOPEbikes from './DOPEbikes';
import DOPBackMarket from './DOPBackMarket';
import EXTEbikes from './EXTEbikes';
import EXT from './EXT';
import EXTCommercial from './EXTCommercial';

import { UnderwriterProps } from '../../../../types/TermsTypes';
import PDFOnlyTerms from '../PDFOnlyTerms';

const Asurion: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'dop':
      Terms = <DOP contractTerms={ contractTerms } />;
      break;
    case 'dopCommercial':
      Terms = <DOPCommercial contractTerms={ contractTerms } />;
      break;
    case 'dopEbikes':
      Terms = <DOPEbikes contractTerms={ contractTerms } />;
      break;
    case 'dopBackMarket':
      Terms = <DOPBackMarket contractTerms={ contractTerms } />;
      break;
    case 'ext':
      Terms = <EXT contractTerms={ contractTerms } />;
      break;
    case 'extCommercial':
      Terms = <EXTCommercial contractTerms={ contractTerms } />;
      break;
    case 'extEbikes':
      Terms = <EXTEbikes contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <PDFOnlyTerms contractTerms={ contractTerms } />;
      break;
  }

  return Terms;
};

export default Asurion;
