/* eslint-disable eqeqeq */
import React from 'react';
import { Link } from 'react-router-dom';
import { ClydeButton } from '@joinclyde/clyde-react-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ProductClaimsEventItem } from '../../types/ClaimTypes';
import { claimStatusSwitcher, displayResolutionButton } from '../Shared/claimHelper';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

interface ProductClaimsProps {
  claimHistory: ProductClaimsEventItem[];
  claimInfo: any;
  claims: any[];
  hashedSaleID: string;
  orderId?: string;
  externalShopId?: string;
  shopUrl?: string;
  _showModal: any;
}

const ProductClaims: React.FC<ProductClaimsProps> = (props) => {
  const {
    claimInfo,
    claimHistory,
    claims,
    _showModal,
  } = props;

  const claimHistoryItems = claimHistory.length;
  const latestHistoryItem = claimHistory[claimHistoryItems - 1];

  const shouldDisplayResolutionButton = displayResolutionButton(latestHistoryItem, claimInfo);

  const claimElements = claims ? claims.map((claim) => {
    const date = dayjs(claim.createdAt).utc().local().format('MMMM Do, YYYY');
    const dateText = `claim submitted ${date}`;
    const lastFive = claim.id.slice(claim.id.length - 5, claim.id.length);
    const idText = `claim id ending in ${lastFive}`;
    return (
      <div key={`claim-item-${lastFive}`} className="claim-item">
        <div className="claim-item__header">
          <p className="claim-item__info">{ dateText.toUpperCase() }</p>
          <p className="claim-item__divider">|</p>
          <p className="claim-item__info">{ idText.toUpperCase() }</p>
        </div>
        <div className="claim-item__body">
          <h3 className="claim-item__title">{ claimStatusSwitcher((claim.resolutionState || claim.claimState), claim.serviceType || '') }</h3>
          { shouldDisplayResolutionButton && <ClydeButton
            className='claim-item__button'
            onClick={ () => _showModal('claim-resolution-form') }
            data-test="viewResolution"
          >
            View Claim Resolution
          </ClydeButton> }
        </div>
        <div className="claim-item__footer">
          <Link className='clyde-link' to={ `/claims/${claim.id}` } data-test='viewDetails'>View Claim Details</Link>
          <p className="claim-item__divider">|</p>
          <p className='pseudo-link claim-item__history' onClick={ () => _showModal('claim-history') } data-test='viewHistory'>View Claim History</p>
        </div>
      </div>
    );
  }) : [];

  return (
    <section className="claim-history">
      { claimElements }
    </section>
  );
};

export default ProductClaims;
