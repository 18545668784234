const defaultState = {
  selectedContract: null,
  error: false,
  contracts: [],
};

const contractReducer = function user(state:any = defaultState, action:any) {
  switch (action.type) {
    case 'UPDATE_GET_CONTRACT':
      return {
        ...state,
        error: false,
        selectedContract: {
          ...action.contract,
        },
      };
    case 'FAIL_GET_CONTRACT':
      return {
        ...state,
        selectedContract: null,
        error: true, // This is {type: "FAIL_GET_CONTRACT", status: 404}
      };
    case 'RESET_SELECTED_CONTRACT':
      return {
        ...state,
        selectedContract: null,
        error: false,
      };
    default:
      return state;
  }
};

export default contractReducer;
