import http from '../config/http';

const ANALYTICS_URL = '/ana';

export function sendAnalytics() {
  return (dispatch:any) => (
    // Could send a processing here, but not really needed
    http.get(ANALYTICS_URL)
      .then()
      .catch((err) => {
        console.warn('Clyde analytics error:', err);
      }));
}
