import React from 'react';
import ProgressBarCircle from './ProgressBarCircle';

import { ReactComponent as ArrowCircleLeft } from '../../../../images/arrow-circle-left.svg';
import { ReactComponent as ArrowCircleRight } from '../../../../images/arrow-circle-right.svg';

interface ClaimFormProgressBarProps {
  currentStep: any;
  selectStep: any;
  selectPrevStep: any;
  optionalStepIncluded: boolean | undefined;
  partsStepIncluded?: boolean;
}

const ClaimFormProgressBar = ({
  currentStep,
  selectStep,
  selectPrevStep,
  optionalStepIncluded,
  partsStepIncluded,
}: ClaimFormProgressBarProps) => {
  // Super tightly coupled to this implementation because of how steps work.
  const steps = [
    { label: 'Contact Info' },
    { label: 'Issue Type' },
    { label: 'Description' },
    { label: 'Documentation' },
    { label: 'Failure Date' },
  ];
  if (partsStepIncluded) {
    steps.splice(2, 0, { label: 'Part Issues' });
  }

  if (optionalStepIncluded) {
    const insertIndex = partsStepIncluded ? 3 : 2;
    steps.splice(insertIndex, 0, { label: 'Usage' });
  }

  const nextStep = currentStep !== steps.length - 1 ? currentStep + 1 : -1;
  const prevStep = currentStep === -1 ? steps.length - 1 : currentStep - 1;

  return (
    <div className="claim-form-progress-bar">
      <ProgressBarCircle
        totalSteps={ steps.length }
        currentStep={ currentStep + 1 }
        completed={currentStep === -1}
      />

      <div className="claim-form-progress-bar__text">
        { currentStep === -1
          ? 'Completed'
          : `Step ${currentStep + 1}/${steps.length} ${steps[currentStep].label}`
        }
      </div>

      <div className="claim-form-progress-bar__navigation">
        <button
          className="claim-form-progress-bar__navigation--back"
          onClick={ selectPrevStep(prevStep) }
          disabled={ currentStep === 0 }>
          <ArrowCircleLeft />
        </button>
        <button
          className="claim-form-progress-bar__navigation--forward"
          onClick={ selectStep(nextStep) }
          disabled={ currentStep === -1 }>
          <ArrowCircleRight />
        </button>
      </div>

    </div>
  );
};

export default ClaimFormProgressBar;
