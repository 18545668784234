/* eslint-disable no-case-declarations */
const defaultState = {
  products: [],
  resolutionAlerts: [],
  selectedProductHistory: null,
  receiptUploadProcessing: false,
  receiptUploadError: false,
  loadingProducts: false,
  transferSuccess: false,
  selectedProduct: {
    isEligibleForTransfer: false,
    state: '',
    price: '',
    receiptFile: 'placeholder', // for logic around receipt upload ui
    csSource: '',
  },
};

// @ts-ignore:next-line
const showPayoutResolution = window._env_.SHOW_CLAIM_PAYOUTS === 'true';

const productReducer = function user(state = defaultState, action:any) {
  switch (action.type) {
    case 'SET_SELECTED_PRODUCT':
      const selectedProduct = state.products.find((product) => {
        const { contractSaleId } = product;
        return contractSaleId === action.contractSaleId;
      }) || {};
      return {
        ...state,
        selectedProduct,
      };
    case 'UPDATE_GET_PRODUCTS':
      const { products } = action;

      const resolutionAlerts = products.filter((product: any) => (
        // gates alerts to promo code resolutions
        // should be updated for additional resolutions
        !product.resolutionViewed && (
          (product.serviceType === 'payout' && showPayoutResolution && product.hiClydeEnabled)
          || (product.resolutionPromoCode && product.serviceType === 'promoCode')
          || product.serviceType === 'carryIn'
          || product.serviceType === 'inHome'
          || product.serviceType === 'depot'
          || product.serviceType === 'customerInstall'
          || product.serviceType === 'clydeReplace'
          || product.serviceType === 'retailerReplace'
        )));

      return {
        ...state,
        products,
        resolutionAlerts,
        loadingProducts: false,
        transferSuccess: false,
      };
    case 'LOADING_GET_PRODUCTS':
      return {
        ...state,
        loadingProducts: true,
      };
    case 'FAIL_GET_PRODUCTS':
      return {
        ...state,
        error: action.error,
        loadingProducts: false,
      };
    case 'UPDATE_GET_PRODUCT':
      return {
        ...state,
        selectedProduct: action.product,
      };
    case 'LOADING_GET_PRODUCT':
      return {
        ...state,
      };
    case 'FAIL_GET_PRODUCT':
      return {
        ...state,
        error: action.error,
      };
    case 'FAIL_GET_PRODUCT_HISTORY':
      return {
        ...state,
        error: action.error,
      };
    case 'UPDATE_LOGOUT':
      return {
        ...state,
        products: [],
        selectedProductHistory: null,
        selectedProduct: {},
      };
    case 'UPDATE_GET_PRODUCT_HISTORY':
      return {
        ...state,
        selectedProductHistory: {
          ...action.history,
        },
      };
    case 'UPDATE_CREATE_CLAIM':
      const setFulfilled = action
        .claimInfo.resolutionCost
        && action.claimInfo.resolutionCost >= state.selectedProduct.price;
      const oldSelectedProductHistory: any = state.selectedProductHistory;
      const newSelectedProductHistory: any = {};
      newSelectedProductHistory.claimHistory = action.claimHistory;
      newSelectedProductHistory.claimInfo = {
        ...action.claim,
        ...action.claimInfo,
        claimEmail: action.claim.email,
        claimId: action.claim.id,
        claimPhone: action.claim.phoneNumber,
        claimState: action.claim.claimState,
        createdAt: action.claim.createdAt,
        customerAddress: {
          zip: action.claim.zip,
          city: action.claim.city,
          country: action.claim.country,
          address1: action.claim.address,
          address2: null,
          state: action.claim.state,
        },
        descriptionOfProblem: action.claim.descriptionOfProblem,
      };
      newSelectedProductHistory.claims = [
        ...oldSelectedProductHistory.claims,
        {
          ...action.claimHistory[action.claimHistory.length - 1],
          id: newSelectedProductHistory.claimInfo.id,
        },
      ];

      return {
        ...state,
        selectedProductHistory: newSelectedProductHistory,
        selectedProduct: {
          ...state.selectedProduct,
          state: setFulfilled ? 'fulfilled' : state.selectedProduct.state,
        },
      };
    case 'MARK_RESOLUTION_READ':
      const updatedAlerts = state
        .resolutionAlerts.filter((resolutionProduct: any) => resolutionProduct.resolutionId !== action.resolutionId);
      return {
        ...state,
        resolutionAlerts: updatedAlerts,
      };
    case 'UPDATE_RECEIPT_UPLOAD':
      const { receiptFile } = action;

      return {
        ...state,
        receiptUploadProcessing: false,
        receiptUploadError: false,
        selectedProduct: {
          ...state.selectedProduct,
          receiptFile,
        },
      };
    case 'LOADING_RECEIPT_UPLOAD':
      return { ...state, receiptUploadProcessing: true, receiptUploadError: false };
    case 'FAIL_RECEIPT_UPLOAD':
      return {
        ...state,
        receiptUploadProcessing: false,
        receiptUploadError: true,
      };
    case 'CLEAR_PRODUCT_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'UPDATE_TRANSFER_CONTRACT':
      return {
        ...state,
        transferSuccess: true,
      };
    default:
      return state;
  }
};

export default productReducer;
