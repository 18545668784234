import { combineReducers } from 'redux';
import appReducer from './appReducer';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import productReducer from './productReducer';
import guestContractReducer from './guestContractReducer';
import claimReducer from './claimReducer';
import checkoutReducer from './checkoutReducer';
import contractTermsReducer from './contractTermsReducer';
import modalReducer from './modalReducer';
import merchantReducer from './merchantReducer';

const rootReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  product: productReducer,
  guestContract: guestContractReducer,
  claim: claimReducer,
  checkout: checkoutReducer,
  contractTerms: contractTermsReducer,
  modal: modalReducer,
  merchantSettings: merchantReducer,
  error: errorReducer,
});

export default rootReducer;
