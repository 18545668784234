import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ContractTransferHeader from './ContractTranferHeader';
import ContractTransferBody from './ContractTransferBody';
import ContractTransferFooter from './ContractTransferFooter';

import { cancelModal } from '../../../actions/modalActions';
import { transferContract } from '../../../actions/productActions';

import { TransferOrderDataI } from '../../../types/ProductTypes';

interface ContractTransferFormProps {
  cartId: string;
  shopId: string;
  transferSuccess: boolean;
  history: any;
  source?: string;
}

const buildTransferOrderObject = () => ({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  address1: '',
  address2: '',
  city: '',
  province: '',
  zip: '',
  country: 'US',
});

const ContractTransferForm = ({
  cartId,
  shopId,
  transferSuccess,
  history,
  source,
}: ContractTransferFormProps) => {
  const baseTransferObject: TransferOrderDataI = buildTransferOrderObject();
  const [step, setStep] = useState(0);
  const [transferOrderData, setTransferOrderData] = useState(baseTransferObject);
  const [formErrors, setFormErrors] = useState({});
  const [disableTransferButton, setDisableTransferButton] = useState(false);

  const dispatch = useDispatch();

  const onSubmitTransfer = () => {
    const requestBody = {
      customer: transferOrderData,
      shopId,
    };
    setDisableTransferButton(true);
    dispatch(transferContract(cartId, requestBody));
  };

  const closeModal = () => {
    setTransferOrderData(buildTransferOrderObject());
    setStep(0);
    dispatch(cancelModal());
  };

  const validateAndAdvanceForm = () => {
    const errors: {[index: string]:any} = {};
    const fieldsNotRequired = ['address2', 'phone'];
    for (const field in transferOrderData) {
      if (fieldsNotRequired.indexOf(field) === -1 && transferOrderData[field] === '') {
        errors[field] = true;
      } else {
        errors[field] = false;
      }
    }
    setFormErrors(errors);
    if (!Object.values(errors).every((value: boolean) => value === false)) {
      return;
    }
    setStep(1);
  };

  const redirectToProducts = () => {
    if (source && source === 'product-list') {
      history.go(0);
    } else {
      history.push('/products');
    }
  };

  if (step === 1 && transferSuccess) {
    setDisableTransferButton(false);
    setStep(2);
  }

  return (
    <div className='contract-transfer' data-test='contractTransferModal'>
      <ContractTransferHeader
        step={ step }
        name={ `${transferOrderData.firstName} ${transferOrderData.lastName}` }
        _closeModal={ closeModal }
      />

      <ContractTransferBody
        step={ step }
        transferOrderData={ transferOrderData }
        formErrors={ formErrors }
        setTransferOrderData={ setTransferOrderData }
        setStep={ setStep }
      />

      <ContractTransferFooter
        step={ step }
        setStep={ validateAndAdvanceForm }
        _closeModal={ closeModal }
        _onSubmitTransfer={ onSubmitTransfer }
        _redirectToProducts={ redirectToProducts }
        disableTransferButton={ disableTransferButton }
      />
    </div>
  );
};

export default ContractTransferForm;
