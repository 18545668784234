import React, { Component } from 'react';
import ReplaceRepairSnippet from './ReplaceRepairSnippet';
import PayoutSnippet from './PayoutSnippet';
import { ReactComponent as LoadingBars } from '../../../../images/loading-bars.svg';
import ClaimSuccessInfo from './ClaimSuccessInfo';
import ApprovedSteps from './ApprovedSteps';
import CloseModalButton from '../../../Shared/CloseModalButton';

interface claimApprovedFormProps {
  phone: string;
  email: string;
  isOpen: boolean;
  resolutionPending: boolean;
  resolutionError: any;
  claimInfo: any;
  resolutionSuccess: boolean;
  hasBankAccountConnected: boolean;
  merchantSettings: any;
  customerName: string;

  _createClaim: any;
  _cancelModal: any;
  _submitClaimResolution: any;
  _submitUserBilling: any;

  contract?: any;
  error?: any;
  claimFormSuccess?: boolean;
  isProcessing?: boolean;
}

class ClaimApprovedForm extends Component<claimApprovedFormProps, any> {
  static resolutionSteps: any = {
    payout: ['Payout'],
    replace: ['Shipping'],
  };

  state = {
    step: 0,
    issueType: '',
    errors: [''],
    payoutType: '',
    addressConfirmed: null,
    payoutAddress: {
      address1: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
    customerName: '',
    mailToLink: '',
    claimState: [],
    nextSteps: [],
    payoutStep: 'payoutTypeSelection',
  };

  componentDidUpdate = (prevProps: claimApprovedFormProps) => {
    const { claimInfo: { serviceType } } = this.props;
    const { claimInfo: { serviceType: prevMethod } } = prevProps;

    if (prevMethod === serviceType) {
      return;
    }

    // @ts-ignore
    const showPayoutResolution = window._env_.SHOW_CLAIM_PAYOUTS === 'true';

    if (serviceType === 'payout' && !showPayoutResolution) {
      return;
    }

    this.setState({ nextSteps: ClaimApprovedForm.resolutionSteps[serviceType] || [] });
  };

  setPayoutType = (payoutType: string) => () => {
    this.setState({ payoutType });
  };

  confirmAddress = (addressConfirmed: boolean) => () => {
    this.setState({ addressConfirmed });
  };

  setPayoutStep = (payoutStep: string) => () => {
    this.setState({ payoutStep });
  };

  setErrors = (error: any) => {
    const errors = [...this.state.errors];
    this.state.errors.push(error);
    this.setState({ errors });
  };

  submitPayoutResolution = (contact: string, data?: any) => () => {
    const { claimInfo, _submitClaimResolution } = this.props;
    _submitClaimResolution({
      resolutionType: claimInfo.resolutionType,
      claimId: claimInfo.claimId || claimInfo.id,
      data: {
        contact,
        ...data,
        payoutType: this.state.payoutType,
        customerName: this.state.customerName,
      },
    });
    this.setState({ step: -1 });
  };

  submitRepairReplace = (customerInfo: any) => {
    const { claimInfo, _submitClaimResolution } = this.props;
    _submitClaimResolution({
      resolutionType: claimInfo.resolutionType,
      claimId: claimInfo.claimId || claimInfo.id,
      data: { ...customerInfo },
    });
    this.setState({ step: -1 });
  };

  setStep = (step: number) => () => {
    this.setState({ step });
  };

  render() {
    const {
      props: {
        contract,
        email,
        phone,
        customerName,
        claimInfo,
        resolutionPending,
        resolutionError,
        resolutionSuccess,
        hasBankAccountConnected,
        _submitUserBilling,
        _cancelModal,
      },
      state: {
        step,
        nextSteps,
        payoutType,
        payoutStep,
        addressConfirmed,
      },
      setStep,
    } = this;

    const {
      serviceType, programType, address, city, state, zip,
    } = claimInfo;

    const listedAddress = {
      address1: address,
      city,
      state,
      zip,
    };

    // @ts-ignore
    const showPayoutResolution = window._env_.SHOW_CLAIM_PAYOUTS === 'true';

    return (
      <section className='claim-form claim-form--response claim-form--response-approved'>
        <div className='claim-form__response__container'>
          <section className='claim-form__response__body' data-test='claimResolutionModal'>
            { (step === -1 || nextSteps.length === 0)
              && <CloseModalButton onClick={ _cancelModal } className='clyde-modal__close'/>
            }
            { resolutionPending
              && <section className='claim-form__step'>
                <p>...processing your resolution...</p>
                <div className='claim-form__loading-bars'>
                  <LoadingBars />
                </div>
              </section>
            }

            { step === 0
              && <ClaimSuccessInfo
                contract={ contract }
                claimInfo={ claimInfo }
                showPayoutResolution={ showPayoutResolution }
                _showNextStep={ setStep }
                _closeModal={ _cancelModal }
              />
            }

            { !resolutionPending && step !== 0
              && <>
                { serviceType === 'payout' && showPayoutResolution
                  && <PayoutSnippet
                    payoutType={ payoutType }
                    payoutStep={ payoutStep }
                    phone={ phone }
                    email={ email }
                    customerName={ customerName }
                    listedAddress={ listedAddress }
                    addressConfirmed={ addressConfirmed }
                    hasBankAccountConnected={ hasBankAccountConnected }
                    resolutionSuccess={ resolutionSuccess }
                    resolutionError={ resolutionError }
                    _closeModal={ _cancelModal }
                    _setPayoutType={ this.setPayoutType }
                    _setPayoutStep={ this.setPayoutStep }
                    _confirmAddress={ this.confirmAddress }
                    _submitResolution={ this.submitPayoutResolution }
                    _setErrors={ this.setErrors }
                    _submitUserBilling={ _submitUserBilling }
                  />
                }
                { (
                  (serviceType === 'clydeReplace' || serviceType === 'retailerReplace')
                  || programType === 'repair')
                  && <ReplaceRepairSnippet
                    listedAddress={ contract.customerAddress }
                    resolutionError={ resolutionError }
                    resolutionSuccess={ resolutionSuccess }
                    resolutionType={ programType }
                    _closeModal={ _cancelModal }
                    _submitResolution={ this.submitRepairReplace }
                  />
                }
              </>
            }
            { nextSteps.length > 0 && !resolutionPending && step !== -1
              && <ApprovedSteps
                steps={ nextSteps }
                currentStep={step}
                _setStep={ setStep }
              />
            }
          </section>
        </div>
      </section>
    );
  }
}

export default ClaimApprovedForm;
