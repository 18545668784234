import React from 'react';
import TermsAccordion from '../../Shared/TermsAccordion';

import { TermsProps, TermsState } from '../../../../types/TermsTypes';

class FurniturePlus4WC extends React.Component<TermsProps, TermsState> {
  constructor(props: TermsProps) {
    super(props);
    this.state = {
      definitionsOpen: false,
      termOpen: false,
      whatIsCoveredOpen: false,
      leaseToOwnOpen: false,
      howServiceIsProvidedOpen: false,
      liabilityOpen: false,
      exclusionsOpen: false,
      conditionsOpen: false,
      renewOpen: false,
      transferabilityOpen: false,
      deductibleOpen: false,
      territoriesOpen: false,
      subrogationOpen: false,
      cancelationOpen: false,
      magnusonMossOpen: false,
      limitationOfLiabilityOpen: false,
      privacyOpen: false,
      disputeResolutionOpen: false,
      entireAgreementOpen: false,
      insuranceOpen: false,
      financialGuaranteeOpen: false,
      statesOpen: false,
    };
  }

  toggle = (section: string) => {
    this.setState((state: any) => ({ [section]: !state[section] }));
  };

  render() {
    return (
      <div className="terms-wrapper">
        <div className="terms-section-static">
          <p>THIS <strong>AGREEMENT</strong> DESCRIBES THE PROTECTION <strong>YOU</strong> WILL RECEIVE IN RETURN FOR <strong>YOUR</strong> PAYMENT OF THE PURCHASE PRICE OF THIS <strong>AGREEMENT</strong> AND COVERS
            PRODUCT WITH A TOTAL RETAIL PRICE OF <strong>$25,000.00</strong> OR LESS, EXCLUDING SALES TAX, DELIVERY AND SETUP.  This <strong>Agreement</strong> contains a Dispute Resolution/Arbitration
            Agreement and Class Action Waiver.  <strong>You</strong> must keep this <strong>Agreement</strong>, Your sales invoice or receipt for the product <strong>You</strong> purchased.  They are integral parts of this <strong>Agreement</strong> and <strong>You</strong>
            may be required to produce them in order to obtain service.  <strong>You</strong> must maintain the <strong>Covered Product</strong> as recommended by the manufacturer’s owner’s manual and product warranty.
            Refer to <strong>Your</strong> sales invoice or receipt to determine the term of this <strong>Agreement</strong>.  <strong>You</strong> acknowledge <strong>Your</strong> understanding of the Limited Applicability of the Federal Magnuson-Moss
            Warranty Act as set out below in this <strong>Agreement</strong>.</p>
          <p><strong>NOTICES: (1) THE PURCHASE OF THIS AGREEMENT IS NOT REQUIRED TO EITHER PURCHASE YOUR COVERED PRODUCT OR TO OBTAIN FINANCING; (2) THIS
            AGREEMENT DOES NOT REPLACE NOR EXTEND THE MANUFACTURER’S WARRANTY OR SELLING RETAILER’S LIMITED PRODUCT WARRANTY, IF ANY, ON YOUR
            COVERED PRODUCT. </strong></p>
        </div>

        <TermsAccordion
          toggle={ this.state.definitionsOpen }
          _toggleFunc={ () => this.toggle('definitionsOpen') }
          header="DEFINITIONS"
          content={
            <React.Fragment>
              <ol>
                <li><strong>“We”, “Us”, “Our”, “Obligor”, “Provider” and “Service Provider”:</strong> the company or provider obligated under this <strong>Agreement, 4Warranty Corporation</strong>, 10751 Deerwood Park
                  Blvd., Suite 200, Jacksonville Florida 32256 (800-867-2216), in all states except in Florida and Oklahoma where it is <strong>LYNDON SOUTHERN INSURANCE COMPANY</strong>,
                  10751 Deerwood Park Blvd, Suite 200, Jacksonville, FL 32256 (800) 888-2738, Florida License No. 03698 and Oklahoma License No. 44200929, and in Wisconsin where it is
                  <strong>The Service Doc Inc.</strong>, 10751 Deerwood Park Blvd, Suite 200, Jacksonville, FL 32256 (800) 867-2216
                </li>
                <li><strong>“You”, “Your”, “Service Agreement Holder”, and “Agreement Holder”:</strong> the purchaser of the <strong>Covered Product(s)</strong> and any authorized transferee/assignee of the purchaser.</li>
                <li><strong>“Administrator”: LOTSolutions, Inc.,</strong> 10751 Deerwood Park Blvd., Suite 200, Jacksonville, FL 32256 (800-867-2216).</li>
                <li><strong>“Selling Retailer”:</strong> he entity selling the <strong>Covered Product</strong> and this <strong>Agreement</strong>.</li>
                <li><strong>“Covered Product”:</strong> he consumer item(s) which You purchased concurrently with and is covered by this <strong>Agreement.</strong></li>
                <li><strong>“Stains &amp; Accidental Damage”:</strong> a single, unexpected, sudden and unintentional event and does not include accumulated damage from continual or multiple events, failure to take
                  sufficient care to protect the <strong>Covered Product,</strong> protection against reckless, abusive, willful or intentional mishandling and use of the <strong>Covered Product,</strong>, and any other limitations
                  listed in the <strong>“What is Not Covered-Exclusions”</strong> section of this <strong>Agreement.</strong></li>
              </ol>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.termOpen }
          _toggleFunc={ () => this.toggle('termOpen') }
          header="TERM"
          content={
            <React.Fragment>
              <p>The term of this <strong>Agreement</strong> will begin on the date of delivery of <strong>Your Covered Product</strong> and continue for the period indicated on <strong>Your</strong> sales receipt or invoice.  The listed
                manufacturer defects coverage will begin upon expiration of the shortest portion of the manufacturer’s warranty.  The listed <strong>Stain and Accidental Damage</strong> coverage is effective upon
                date of delivery.  In the event <strong>Your Covered Product</strong> is being serviced by an authorized service center when this <strong>Agreement</strong> expires, the term of this <strong>Agreement</strong> will be extended, for
                this loss, until covered repair has been completed.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.whatIsCoveredOpen }
          _toggleFunc={ () => this.toggle('whatIsCoveredOpen') }
          header="WHAT IS COVERED"
          content={
            <React.Fragment>
              <p>NOT ALL DEFECTS, <strong>STAINS AND ACCIDENTAL DAMAGE</strong> TO THE PRODUCT ARE COVERED UNDER THIS <strong>AGREEMENT</strong>.  Please read it carefully.  The
                use of this coverage requires an explanation of where and when the accident occurred as well as a detailed description of the actual event.  The origin of a stain must be specified as it
                will determine the type of chemical(s) and process to remove the stain. <strong>We</strong> will only be responsible for servicing the stained, damaged or defective area of the <strong>Covered Product</strong> due to a
                manufacturer defect or unintentional accidental damage from a single incident as follows</p>
              <ul>
                <li><strong>Stains:</strong> a) Accidental stains, except as noted in <strong>“WHAT IS NOT COVERED-EXCLUSIONS”</strong>, only to area rugs, fabric, vinyl or leather (except nubuck, suede and buffed
                  leather) as a result of normal spills from food and beverage and human or pet biological stains (except perspiration and hair and body oils); ball point pen ink.</li>
                <li><strong>Accidental Damage to Fabric, Leather &amp; Vinyl Upholstery:</strong> (a) Punctures or rips to fabric, leather or vinyl from external causes; and (b) nail polish only on vinyl and leather
                  (except nubuck and suede leather).</li>
                <li><strong>Accidental Damage to Wood, Wood Veneer and Wood Laminate Products:</strong> (a) Chipping, scratches or gouges to wood and other case good surfaces (except faux stone,
                  marble or granite) that penetrate the finish exposing the substrate; (b) Breakage to mirrors; glass breakage; (c) Minor burn marks solely from cigarette, cigar and pipe
                  tobacco; (d) Liquid marks or rings caused from household food and beverages; (e) Heat marks from normal household items. </li>
                <li><strong>Manufacturer Defects:</strong> (a) Checking, cracking, bubbling or peeling of the finish on solid wood, wood veneered or wood laminated furniture; lifting of wood veneer (b)
                  Warping, cracking, breaking or separation of frame and frame components (except faux stone, marble and granite); (c) The breaking or bending of mechanisms, springs and
                  coils (except box springs and mattress); and (d) Failure of electrical components including clocks, motors and hydraulics, (e) loss of silvering to mirrors.</li>
              </ul>
              <p>Parts will be replaced at <strong>Our</strong> option with those of like kind and quality as solely determined by <strong>Us</strong>, and may be new or remanufactured.  <strong>We</strong> are not responsible for and do not guarantee
                color match or dye lots on fabric, leather, vinyl or wood finishes, or manufacturer’s discontinuation.  If the <strong>Covered Product</strong> (1) cannot be repaired, (2) if the cost of the repair exceeds the
                original purchase price, or (3) if the parts are no longer available or are discontinued by the manufacturer, the <strong>Covered Product</strong> will be replaced with a product of similar quality and
                features as determined by <strong>Us</strong>, not to exceed the purchase price of the <strong>Covered Product</strong>, excluding sales tax, delivery and installation costs.  <strong>You</strong> are responsible for any sales tax,
                delivery and installation costs associated with a replacement item. </p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.leaseToOwnOpen }
          _toggleFunc={ () => this.toggle('leaseToOwnOpen') }
          header='LEASE TO OWN ("LTO") ARRANGEMENTS'
          content={
            <React.Fragment>
              <p>Where the <strong>Covered Product</strong> was initially acquired under a LTO Arrangement, any cash settlement or refund will be paid to the owner of
                the <strong>Covered Product</strong> at the time the settlement is made. This will be the Lessor if <strong>You</strong> have not yet acquired ownership of the property pursuant to the terms and conditions of <strong>Your</strong> LTO
                Arrangement, in which case any refund will be credited to <strong>Your</strong> account.  In all other respects, the Lessee will retain a beneficial interest in this <strong>Agreement</strong> and all non-cash benefits
                described herein shall be rendered to the Lessee. Any owner obligations related to maintenance of the <strong>Covered Product</strong> shall be the responsibility of the Lessee during the term of any
                LTO Arrangement except as provided by law.  Any reference to purchased, sold, or similar terms shall include leased and its derivatives.  Any reference to purchaser shall mean the
                Lessee under the LTO Arrangement and not the Lessor.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.howServiceIsProvidedOpen }
          _toggleFunc={ () => this.toggle('howServiceIsProvidedOpen') }
          header="HOW TO OBTAIN SERVICE"
          content={
            <React.Fragment>
              <p><strong>YOU</strong> MUST CONTACT THE <strong>ADMINISTRATOR</strong> FOR AUTHORIZED SERVICE WITHIN THIRTY (30) DAYS OF NOTICING THE DEFECT OR DAMAGE
                TO THE <strong>COVERED PRODUCT</strong>.  PLEASE READ THE <strong>“WHAT IS COVERED”</strong> AND <strong>“WHAT IS NOT COVERED – EXCLUSIONS SECTION OF THIS AGREEMENT”</strong>.  Call
                <strong>Administrator</strong> toll-free at <strong>800-867-2216</strong> between the hours of 8:00 AM and 5:00 PM eastern standard time Monday through Friday, or visit <strong>www.4repairs.net</strong> to submit <strong>Your</strong> claim
                online.  Prior to <strong>Administrator</strong> dispatching service to <strong>Your</strong> location, <strong>You</strong> may be required to provide <strong>Administrator</strong> with pictures of the defective or damaged product.  <strong>You</strong> may be
                required to provide <strong>Administrator</strong> with a valid credit card number prior to <strong>Administrator</strong> dispatching service.  All repairs must be authorized by the <strong>Administrator</strong> prior to performance of
                work. Claims on unauthorized repairs may be denied.  If <strong>You</strong> refuse service on a covered item after <strong>Administrator</strong> has dispatched the repair servicer to <strong>Your</strong> location, <strong>You</strong> will be billed
                for that servicer’s applicable trip charge.  Except for delivery damage, if <strong>You</strong> refuse delivery of <strong>Your</strong> replacement item <strong>You</strong> will be reimbursed the purchase price of this <strong>Agreement</strong> and
                <strong>We</strong> will have no further liability.</p>
              <ul>
                <li><strong>Stains:</strong> <strong> DO NOT ATTEMPT TO REMOVE THE STAIN YOURSELF, OR USE WATER OR OTHER CHEMICALS AS THIS MAY VOID THIS AGREEMENT.</strong> Contact the
                  <strong>Administrator</strong> as soon as <strong>You</strong> notice the stain.  <strong>You</strong> must be able to advise <strong>Administrator</strong> of the origin and type of stain in order to obtain service.  Clean the stained area ONLY as directed by <strong>Administrator</strong>.  If the covered stain persists, <strong>Administrator</strong> will provide <strong>You</strong> with a cleaning kit or have the covered stain serviced by a professional.  Cleaning or
                  servicing is limited to the covered stained or damaged area only.</li>
                <li><strong>In-Home:</strong> Service will be performed in <strong>Your</strong> home whenever possible.  The authorized service center may opt to remove the <strong>Covered Product</strong> to perform service in-shop and will return
                  the <strong>Covered Product</strong> upon completion. Additional time and mileage charges for in-home repairs outside of twenty-five (25) miles or the normal service radius of the authorized service
                  center are not covered by this <strong>Agreement</strong>, and are <strong>Your</strong> responsibility. If <strong>You</strong> are not within one of the <strong>Administrator's</strong> authorized service areas, <strong>You</strong> may request termination
                  and refund of the <strong>Agreement</strong> purchase price subject to the cancellation provision in this <strong>Agreement</strong>.  If <strong>You</strong> choose, the <strong>Administrator</strong> will provide service at the nearest
                  service location and <strong>You</strong> must provide the necessary deliveries and pickups at <strong>Your</strong> expense. Service is available during the regular business hours of the servicer. <strong>We</strong> do
                  not guarantee days or time of service.  <strong>We</strong> will not be liable for any damages arising out of delays, either before or after a day or time of service is agreed upon.  <strong>You</strong> must
                  make the <strong>Covered Product</strong> reasonably accessible to the repair person.  If the <strong>Covered Product</strong> is not accessible, <strong>We</strong> may decline to provide service or assess <strong>You</strong> an
                  additional charge, proportionate with the difficulty in working on the <strong>Covered Product</strong>. </li>
              </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.liabilityOpen }
          _toggleFunc={ () => this.toggle('liabilityOpen') }
          header="LIMIT OF COVERAGE LIABILITY"
          content={
            <React.Fragment>
              <p><strong>OUR</strong> LIMIT OF COVERAGE LIABILITY FOR <strong>YOUR COVERED PRODUCT</strong> IS THE LESSER OF THE COST OF AUTHORIZED REPAIRS OR
                REPLACEMENT WITH A PRODUCT WITH SIMILAR FEATURES AS SOLELY DETERMINED BY <strong>US</strong>, PROVIDED HOWEVER, IN NO EVENT WILL <strong>OUR</strong> TOTAL LIABILITY FOR
                REPAIRS OR REPLACEMENT EXCEED THE PURCHASE PRICE OF <strong>YOUR</strong> AFFECTED ITEM(S), EXCLUDING SALES TAX, DELIVERY AND INSTALLATION. UPON
                REPLACEMENT, <strong>WE</strong> NO LONGER HAVE ANY OBLIGATION FOR THE REPLACED PRODUCT UNDER THIS <strong>AGREEMENT</strong>.  AT <strong>OUR</strong> OPTION <strong>WE</strong> ARE ENTITLED TO TAKE
                POSSESSION OF ANY <strong>COVERED PRODUCT(S)</strong> THAT <strong>WE</strong> REPLACE.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.exclusionsOpen }
          _toggleFunc={ () => this.toggle('exclusionsOpen') }
          header="WHAT IS NOT COVERED - EXCLUSIONS"
          content={
            <React.Fragment>
              <p><strong>We will not cover loss or damage caused by the following: (a) Any stain, loss or damage not specifically listed under “WHAT IS
                COVERED” (no other stain, defect or damage will be covered under this Agreement); (b) Product repairs that should be covered by the manufacturer’s warranty or Selling
                Retailer’s Limited Product Warranty, or are a result of recall, regardless of the manufacturer’s or Selling Retailer’s ability to pay for such repairs;  (c) Pre-existing conditions
                that occur prior to the effective date of this Agreement and/or any product sold used, damaged or “AS-IS”; (d) Periodic checkups, preventive maintenance, lubrication and
                general cleaning as directed by the manufacturer; (e) Repeated stains or damage of any kind that are not related to a single incident are considered preventable occurrences
                and  shall  not  be  eligible  for  continued  stain  and  accidental  damage  coverage,  accumulated  stains  or  damage  from  continual  and  multiple  events,  and  failure  to  take
                sufficient care to protect the Covered Product; (f) Parts or repairs due to normal wear and tear; damage caused by You in the assembly of RTA (ready-to-assemble) furniture;
                (g)  Except as noted in “WHAT IS COVERED”, damage from accident, abuse, misuse, mishandling, introduction of foreign objects into,  on  or  about  the  Covered  Product,
                modifications  or  alterations  to  a  Covered  Product;  failure to follow the manufacturer’s instructions; external causes of any kind, including without limitation, third party
                actions,  fire,  theft,  insects  and  insect  infestation,  animals,  exposure  to  weather  conditions,  extreme  temperature,  windstorm,  sand,  dirt,  hail,  earthquake,  flood,  water;  (h)
                Incidental,  consequential  or  secondary  damages  or  delay  in  rendering  service  under  this  Agreement,  or  loss  of  use  during  the  period  that  the  Covered  Product  is  at  an
                authorized  service  center  or  otherwise  awaiting  parts;  (i)  Any  product  used  in  a  commercial  setting  or  rental  basis;  (j)  Nonfunctional  or  aesthetic  parts  including  but  not
                limited to plastic parts, knobs, rollers, baskets; accessories used in conjunction with the Covered Product such as pillows; buttons; (k) Unauthorized repairs and/or parts; (l)
                Service where no problem can be found; noises or squeaks; odors; (m) Any malfunction, damage or disrepair not occurring or reported within the term of this Agreement;
                (n) Damage to brass or other plating, pictures, lamps or accessories; faux stone, granite and marble; (o) Wear related damage such as furniture that is leaning or listing to
                one side due to continued use and weight applied to the affected side; seam separation of any kind, including fraying, tearing or shredding of fabric; decorative stitching;
                loss of foam resiliency in cushion cores, backs and arms; (p) Except as noted in “WHAT IS COVERED”, fabric; tears and wear-through, punctures, scratches, dents, burns,
                dirt, or color-fading or discoloration; zippers; (q) Compressed paper-board, paper veneered and paper laminated surfaces; Rattan, wicker and plastic furniture; Mattresses &amp;
                box springs or Bunkie boards; (r) Bodily injury; damage to personal property.</strong></p>
                <ul>
                  <li><strong>Specific  to  Manufacturer  Defects:  (a) Products not originally covered by a  manufacturer’s warranty  or  Selling  Retailer’s Limited  Product  Warranty;  (b) Natural
                    grains and/or markings on wood and/or leather, including but not limited to scars, insect bites, brand marks, embossing, wrinkles; (c) Stress tears; split leathers
                    used in seat cushions, back cushions or top inside arm areas; suede, buffed or nubuck leathers; (d) Cracking, peeling or scaling of leather and vinyl; shrinkage
                    from cleaning; rust or corrosion.</strong>
                  </li>
                  <li><strong>Specific to Stains &amp; Accidental Damage: (a) Except as noted in ‘WHAT IS COVERED” damage resulting from bleach, paint, acid or corrosive products; chewing
                    gum; tar; grass stains; permanent marker of any kind; nail polish remover; (b) Accumulation and  buildup of stains and soil over time, including darkened areas
                    where  the  body  comes  into  contact  with  the  upholstery;  perspiration  and  body  or  hair  oils;  (c)  Fading,  soiling  or  damage  caused  by  pets  (other  than  bodily
                    fluids); (d) Damage from use of cleaning products not approved by the manufacturer or Us; normal  stains and soiling from everyday use; mold and mildew; (e)
                    Stains of unknown origin; “X” coded and non-colorfast fabrics and leather; (f) Stains or damage to the material caused by the owner’s failure to use reasonable
                    caution  and  care  to  protect  the  Covered  Product;  (g)  willful  damage  caused  by  minor  children;  (h)  Stains  or  damage  occurring  prior  to  and  during  delivery  or
                    setup  of  Your  Covered  Product,  or  when  the  product  is  in  storage,  or  being  moved  into  or  out  of  storage,  or  being  moved  within  or  between  residences;  dye
                    transfer,  except  as  noted  in  “WHAT’S  IS  COVERED”;  stains  caused  by  medical  incontinence;  stains  or  damage  caused  by  independent  contractors  or
                    maintenance personnel; stains from leaks in appliances, sky lights or roof.</strong></li>
                </ul>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.conditionsOpen }
          _toggleFunc={ () => this.toggle('conditionsOpen') }
          header="CONDITIONS"
          content={
            <React.Fragment>
              {/* Intentionally left blank as in the PDF document */}
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.renewOpen }
          _toggleFunc={ () => this.toggle('renewOpen') }
          header="RENEWAL"
          content={
            <React.Fragment>
              <p>This <strong>Agreement</strong> is not renewable.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.transferabilityOpen }
          _toggleFunc={ () => this.toggle('transferabilityOpen') }
          header="TRANSFERABILITY"
          content={
            <React.Fragment>
              <p>This <strong>Agreement</strong> is transferable by the original purchaser for the balance of the original term and requires no transfer fee.  The <strong>Covered Product</strong> may be
                registered by mailing a copy of this <strong>Agreement</strong> and invoice to the <strong>Administrator</strong>, and providing the date of new ownership, new owner’s name, complete address, and telephone
                number.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.deductibleOpen }
          _toggleFunc={ () => this.toggle('deductibleOpen') }
          header="DEDUCTIBLE"
          content={
            <React.Fragment>
              <p>This <strong>Agreement</strong> has a $0 deductible.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.territoriesOpen }
          _toggleFunc={ () => this.toggle('territoriesOpen') }
          header="TERRITORIES"
          content={
            <React.Fragment>
              <p>This <strong>Agreement</strong> erritory is limited to the United States of America, including the District of Columbia, only. It does not include Mexico, Canada or U.S. Territories including
                but not limited to Guam, Puerto Rico, or U.S. Virgin Islands.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.subrogationOpen }
          _toggleFunc={ () => this.toggle('subrogationOpen') }
          header="SUBROGATION"
          content={
            <React.Fragment>
              <p>If <strong>We</strong> pay or render service for a loss,  <strong>We</strong> may require <strong>You</strong> to assign Us <strong>Your</strong> rights of recovery against others. <strong>We</strong> will not pay or render service for a loss if  <strong>You</strong>
                impair these rights to recover. <strong>Your</strong> rights to recover from others may not be waived.  <strong>You</strong> will be made whole, subject to the terms of this <strong>Agreement</strong>, before <strong>We</strong> retain any amount <strong>We</strong>
                may recover</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.cancelationOpen }
          _toggleFunc={ () => this.toggle('cancelationOpen') }
          header="CANCELLATION"
          content={
            <React.Fragment>
              <p><strong>You</strong> may cancel this <strong>Agreement</strong> for any reason at any time. If <strong>You</strong> cancel <strong>Your</strong> <strong>Agreement</strong> within thirty (30) days of receipt of <strong>Your</strong> Agreement <strong>You</strong> must return to
                the  <strong>Selling  Retailer</strong>  for  a  full  refund.    If  <strong>You</strong>  cancel after  thirty  (30)  days  of  receipt  of  <strong>Your</strong>  <strong>Agreement</strong>,  <strong>You</strong>  must  return  to  the  <strong>Selling  Retailer</strong>  pro-rata  refund  as  described  below.
                Should  the  <strong>Selling  Retailer</strong>  not  be  available  <strong>You</strong>  should  contact  the  <strong>Administrator</strong>,  <strong>You</strong>  will  receive  a  pro-rata  refund  based  on  the  time  expired  less  a  twenty-five  dollar  ($25)
                cancellation fee, or ten percent (10%) of the <strong>Agreement</strong> Purchase Price (whichever is less), less the cost of claims paid.  <strong>We</strong> may not cancel this <strong>Agreement</strong> except for fraud, material
                misrepresentation, or non-payment by <strong>You</strong>, or if required to do so by a regulatory authority. Notice of such cancellation will be in writing and given at least (30) days prior to cancellation.
                If <strong>We</strong> cancel, the return premium is based upon one hundred percent (100%) of the unearned pro-rata premium.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.magnusonMossOpen }
          _toggleFunc={ () => this.toggle('magnusonMossOpen') }
          header="LIMITED APPLICABILITY OF THE FEDERAL MAGNUSON MOSS WARRANTY ACT"
          content={
            <React.Fragment>
              <p><strong>You</strong> agree and acknowledge that <strong>You</strong> have paid an additional fee for this <strong>Agreement</strong> that is
                separate and apart from the purchase price <strong>You</strong> paid for the <strong>Covered Product(s)</strong>.  Because of that separately stated consideration, <strong>You</strong> agree and acknowledge that this <strong>Agreement</strong> is
                not part of the basis of the bargain for <strong>Your</strong> purchase of the <strong>Covered Product(s)</strong>.  <strong>You</strong> further agree and acknowledge that <strong>We</strong>, and the <strong>Administrator</strong> under this <strong>Agreement</strong>, are not
                the supplier of the <strong>Covered Product(s)</strong>.  Consequently, this <strong>Agreement</strong> is not a “written warranty” under the federal Magnuson Moss Warranty Act.  As a result, this <strong>Agreement</strong> is not
                subject to the provisions of the Magnuson Moss Warranty Act that apply only to a “written warranty”. </p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.limitationOfLiabilityOpen }
          _toggleFunc={ () => this.toggle('limitationOfLiabilityOpen') }
          header="LIMITATION OF LIABILITY"
          content={
            <React.Fragment>
              <p><strong>THIS AGREEMENT SETS OUT THE FULL EXTENT OF OUR RESPONSIBILITIES. NEITHER THE OBLIGOR NOR THE ADMINISTRATOR SHALL BE
                LIABLE FOR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF BUSINESS
                PROFITS, BUSINESS INTERRUPTION, EXPENSES ARISING OUT OF THIRD PARTY CLAIMS, LOSS OF USE OF THE COVERED PRODUCT, INCONVENIENCE, OR ANY OTHER LOSS), WHETHER OR NOT CAUSED BY OR RESULTING FROM BREACH OF CONTRACT, NEGLIGENCE, OR OTHER WRONGFUL ACT OR OMISSION, EVEN IF IT HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  NEITHER THE OBLIGOR NOR THE ADMINISTRATOR AUTHORIZE ANY PERSON, ENTITY OR SELLING RETAILER TO
                CREATE FOR THEM ANY OTHER OBLIGATION OR LIABILITY IN CONNECTION WITH THIS AGREEMENT.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.limitationOfLiabilityOpen }
          _toggleFunc={ () => this.toggle('limitationOfLiabilityOpen') }
          header="PRIVACY POLICY"
          content={
            <React.Fragment>
              <p><strong>It is Our policy to respect the privacy of Our customers.  For information on Our privacy practices, please review Our privacy policy at www.fortegra.com.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.disputeResolutionOpen }
          _toggleFunc={ () => this.toggle('disputeResolutionOpen') }
          header="DISPUTE RESOLUTION/ARBITRATION AGREEMENT and CLASS ACTION WAIVER"
          content={
            <React.Fragment>
              <p><strong>PLEASE READ THIS DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER, INCLUDING THE OPT-OUT PROVISION, CAREFULLY TO
                UNDERSTAND YOUR RIGHTS. IT REQUIRES THAT CLAIMS (AS DEFINED BELOW) BE RESOLVED SOLELY THROUGH BINDING ARBITRATION ON AN INDIVIDUAL BASIS,
                RATHER THAN BY A JURY OR IN A CLASS ACTION. </strong></p>

              <p><strong>Arbitration is a method of resolving any Claim without filing a lawsuit. In this Arbitration Agreement and Class Action Waiver (collectively including all of this section of this
                Agreement), You, We, and the Administrator (the “Parties”) are agreeing to submit any and all Claims to binding arbitration on an individual basis for resolution.  This
                Arbitration Agreement and Class Action Waiver sets forth the terms and conditions of our agreement to binding arbitration.  The Parties agree that any and all claims,
                disputes and controversies arising under or related in any way to this Agreement, including but not limited to claims related to the underlying transaction giving rise to this
                Agreement, claims related to the sale or fulfillment of this Agreement, and claims against any third-party (including the Selling Retailer and/or any of its owners,
                shareholders, members, affiliates, subsidiaries, divisions, directors, officers, employees, representatives, successors, and assigns) arising under or related in any way to
                this Agreement or the underlying transaction or the sale or fulfillment of this Agreement (collectively, “Claims”), shall be resolved by final and binding arbitration.  “Claims”
                shall be given the broadest meaning possible and includes, without limitation, Claims arising under contract, tort, statute, regulation, rule, ordinance or other rule of law or
                equity, and Claims against any of Our or the Administrator’s owners, shareholders, members, affiliates, subsidiaries, divisions, directors, officers, employees,
                representatives, successors, or assigns. “Claims” does not include a claim for public injunctive relief brought under any California statute enacted for a public reason,
                provided that You are a California resident or purchased Your Service Contract in California.  In arbitration, Claims are resolved by an arbitrator and not by a judge or jury.
                THE PARTIES, INCLUDING YOU, WAIVE ANY RIGHT TO HAVE CLAIMS DECIDED BY A JUDGE OR JURY.  In addition, except as expressly stated in the Class Action Waiver
                or otherwise expressly stated herein, the arbitrator shall have exclusive authority to decide all issues related to the enforcement, applicability, scope, validity, and
                interpretation of this Arbitration Agreement, including but  not limited to any unconscionability challenge or any other challenge that the Arbitration Agreement is void,
                voidable or otherwise invalid. Notwithstanding this agreement to arbitrate, each of the Parties retains the right to seek remedies in small claims court to resolve any Claim,
                on an individual basis, within the jurisdiction of small claims court. You acknowledge Your understanding that all Parties hereunder are waiving their rights to go to court,
                except for small claims court, to resolve any Claims arising under or related in any way to this Agreement.</strong></p>

              <p><strong>The Parties agree and acknowledge that the transaction evidenced by this Agreement affects interstate commerce. The Parties further agree that all issues relating to this
                Arbitration Agreement and Class Action Waiver, including its enforcement, scope, validity, interpretation, and implementation, will be determined pursuant to federal
                substantive law and the substantive and procedural provisions of the Federal Arbitration Act (“Act”), 9 U.S.C. §§ 1-16.  If federal substantive law holds that state law should
                apply to any issue relating to this Arbitration Agreement and Class Action Waiver, then the law of the state where You purchased the Agreement shall apply, without regards
                to conflicts of law. </strong></p>

              <p><strong>CLASS ACTION WAIVER.  All Claims must be brought solely in an individual capacity, and not as a plaintiff or class member in any purported class action, collective action,
                representative action, mass action, private attorney general action or action on behalf of the general public, or similar proceeding (any such action is referred to herein as a
                “Class Action”).  NO CLAIM WILL BE ARBITRATED ON A CLASS ACTION BASIS.  The Parties, including You, expressly waive any right or ability to bring, assert, maintain,
                or participate as a class member in any Class Action in court, arbitration, or any other forum, and the right for anyone to do so on Your behalf.  The arbitrator may not
                consolidate more than one person or entity’s claims, and may not otherwise preside over any Class Action.  The arbitrator shall not have the authority to combine or
                aggregate multiple persons’ or entities’ Claims or discovery, to conduct a Class Action or to make an award to any person or entity not a party to the arbitration.
                Notwithstanding anything to the contrary, the Parties agree that the enforcement, applicability, scope, validity, and/or interpretation of this Class Action Waiver shall be
                decied by a court of competent jurisdiction and not by an arbitrator.  If this Class Action Waiver is ruled unenforceable or is interpreted to not prevent a Class Action, then
                the Arbitration Agreement shall be null and void, and any Claims shall proceed in a court of law and not in arbitration.  The Parties agree that if an arbitrator renders a
                deciion regarding the enforcement, applicability, scope, validity, and/or interpretation of this Class Action Waiver, or determines that a Class Action may proceed in
                arbitration, then: (1)  the arbitrator has exceeded his powers, pursuant to §10(a)(4) of the FAA, by taking such action; (2) either party may seek immediate review of that
                decision by a court of competent jurisdiction; and (3) a court of competent jurisdiction shall apply a “de novo” standard of review of that decision if such standard of review
                is allowed by the common law or statutes of that state.  The Parties, including You, agree that if for any reason a Claim proceeds to Court, rather than arbitration, (1)  he
                Claim will proceed solely on an individual, non-class, non-representative basis, and (2) no Party may be a class representative or class member or otherwise participate in
                any Class Action.</strong></p>

              <p><strong>The arbitration shall be administered by the American Arbitration Association (“AAA”). The arbitration shall be conducted pursuant to the AAA Consumer Arbitration Rules
                (the “Code”). Information on AAA and a copy of the Code may be found at the following number and URL: American Arbitration Association, (800) 778-7879, www.adr.org.
                The arbitration will be governed by federal substantive law and the substantive and procedural provisions of the Federal Arbitration Act (“Act”), 9 U.S.C. §§ 1-16.  If federal
                substantive law holds that state law should apply to any issue relating to the arbitration, then the law of the state where You purchased the Agreement shall apply, without
                regards to conflicts of law.  The arbitration will occur before a single, neutral arbitrator selected in accordance with the Code in effect at the time the arbitration is
                commenced. If Your total damage claims (not including attorney’s fees) do not exceed $25,000, then all Claims shall be resolved by the Code’s Procedures for the Resolution
                of Disputes through Document Submission, except that a Party may ask for a hearing or the arbitrator may decide that a hearing is necessary.  If a hearing is held, You have
                a right to attend the arbitration hearing in person, and You may choose to have any arbitration hearing held in the county in which You live, the closest AAA location to Your
                residence, or via telephone.  In the event that the specified arbitration forum is unavailable, the Parties may agree on a substitute arbitration forum.  If the Parties cannot
                agree, a court of competent jurisdiction may appoint a substitute arbitration forum.  For information about how to initiate arbitration with the AAA, the Parties may refer to
                the AAA Code and forms at www.adr.org or call (800) 778–7879. If You initiate arbitration with AAA, You must pay the AAA filing fee in an amount no greater than the fee You
                would have to pay if You filed a complaint in federal court.  We will pay any remaining Costs of arbitration required by the Code (“Arbitration Costs”); however, if the
                arbitrator determines that any of your claims are frivolous, you shall bear all of the Arbitration Costs.  If We initiate arbitration against You, We will pay the AAA filing fee and
                the Arbitration Costs. Each party will pay his/her/its own attorney’s fees, as well as costs relating to proof and witnesses, regardless of who prevails, unless applicable law
                and/or the Code gives a party the right to recover any of those fees from the other party.  An arbitration award may not be set aside except upon the limited circumstances
                set forth in the Federal Arbitration Act. An award in arbitration will be enforceable under the Federal Arbitration Act by any court having jurisdiction.  The time for
                commencing an arbitration asserting any Claim shall be determined by reference to the applicable statute(s) of limitations, including the applicable rules governing the
                commencement of the limitations period, and a Claim in arbitration is barred to the same extent it would be barred if it were asserted in court of law or equity rather than in
                arbitration.</strong></p>

              <p><strong>If any portion of this Arbitration Agreement is deemed invalid or unenforceable, all the remaining portions of this Arbitration Agreement shall nevertheless remain valid and
                enforceable, provided, however, that if any portion of the Class Action Waiver is deemed invalid or unenforceable, then this Arbitration Agreement shall be invalidated and
                unenforceable in its entirety.  In the event of a conflict or inconsistency between this Arbitration Agreement and Class Action Waiver and the other provisions of this
                Agreement or any other agreement, this Arbitration Agreement and Class Action Waiver governs.</strong></p>

              <p><strong>OPT-OUT PROVISION.  YOU SHALL HAVE THE RIGHT TO OPT OUT OF THIS ARBITRATION AGREEMENT AND CLASS ACTION WAIVER BY PROVIDING WRITTEN NOTICE
                OF YOUR INTENTION TO DO SO TO US WITHIN THIRTY (30) DAYS OF THE PURCHASE OF THIS AGREEMENT (THE DATE OF PURCHASE BEING INDICATED ON YOUR
                SALES ORDER AND RECEIPT FROM THE SELLING RETAILER).  To opt out, You must send written notice to either: (1) 10751 Deerwood Park Blvd., Suite 200, Jacksonville,
                FL 32256, Attn: Legal or (2) legal@fortegra.com, with the subject line, “Arbitration Opt Out.”  You must include in Your opt out notice: (a) Your name and address; (b) the
                date You purchased Your Agreement; and (c) the Selling Retailer.  If You properly and timely opt out, then all Claims will be resolved in court rather than arbitration.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.entireAgreementOpen }
          _toggleFunc={ () => this.toggle('entireAgreementOpen') }
          header="ENTIRE AGREEMENT"
          content={
            <React.Fragment>
              <p>This is the entire <strong>Service Agreement</strong> between the parties, and no representation, promise or condition not contained herein shall modify these items.</p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.insuranceOpen }
          _toggleFunc={ () => this.toggle('insuranceOpen') }
          header="INSURANCE"
          content={
            <React.Fragment>
              <p><strong>4WARRANTY’S OBLIGATIONS TO PERFORM UNDER THIS AGREEMENT ARE INSURED BY LYNDON SOUTHERN INSURANCE COMPANY, 10751 DEERWOOD PARK BLVD.,
                SUITE 200, JACKSONVILLE, FL 32256 (800) 888-2738, EXCEPT IN CALIFORNIA, GEORGIA, NEW YORK, WASHINGTON, AND WISCONSIN.</strong></p>
              <p><strong>CALIFORNIA - THE OBLIGOR IS INSURED BY RESPONSE INDEMNITY COMPANY OF CALIFORNIA, 10751 DEERWOOD PARK BLVD., SUITE 200, JACKSONVILLE, FL 32256
                (800) 888-2738.</strong></p>
              <p><strong>GEORGIA - THE OBLIGOR IS INSURED BY INSURANCE COMPANY OF THE SOUTH, 10751 DEERWOOD PARK BLVD., SUITE 200, JACKSONVILLE, FL 32256 (800) 888-2738.</strong></p>
              <p><strong>NEW YORK AND WISCONSIN - THE OBLIGOR IS INSURED BY BLUE RIDGE INDEMNITY COMPANY, 10751 DEERWOOD PARK BLVD., SUITE 200, JACKSONVILLE, FL 32256
                (800) 888-2738.</strong></p>
              <p><strong>IF THE OBLIGOR FAILS TO PAY AN AUTHORIZED CLAIM WITHIN SIXTY (60) DAYS, OR IF THE OBLIGOR BECOMES INSOLVENT OR CEASES TO CONDUCT BUSINESS
                DURING THE TERM OF THIS AGREEMENT, YOU MAY SUBMIT YOUR CLAIM DIRECTLY TO THE APPLICABLE INSURER AT THE ABOVE ADDRESS FOR CONSIDERATION.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.financialGuaranteeOpen }
          _toggleFunc={ () => this.toggle('financialGuaranteeOpen') }
          header="FINANCIAL GUARANTEE"
          content={
            <React.Fragment>
              <p><strong>IN WASHINGTON, OUR OBLIGATIONS UNDER THIS AGREEMENT ARE BACKED BY THE FULL FAITH AND CREDIT OF THE SERVICE CONTRACT PROVIDER, 4WARRANTY
                CORPORATION.  IF ANY PROMISE MADE IN THE AGREEMENT HAS BEEN DENIED OR HAS NOT BEEN HONORED YOU MAY CONTACT FORTEGRA FINANCIAL
                CORPORATION AT (800) 888-2738.</strong></p>
            </React.Fragment>
          }
        />

        <TermsAccordion
          toggle={ this.state.statesOpen }
          _toggleFunc={ () => this.toggle('statesOpen') }
          header="STATE REQUIREMENTS AND DISCLOSURES"
          content={
            <React.Fragment>
              <p><strong>Alabama: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of
                returned <strong>Service Agreement.</strong></p>
              <p><strong>Arizona: CANCELLATION</strong> section is amended as follows: No claim incurred or paid will be deducted from the amount to be returned in the event of cancellation.  An administrative fee
                not to exceed ten percent (10%) of the <strong>Provider</strong> fee paid by the <strong>Agreement Holder</strong> may be charged by the <strong>Provider</strong>.  The <strong>Provider</strong> fee is the purchase price for which <strong>You</strong> paid for this
                <strong>Agreement.</strong>.  The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> does not preclude the consumer’s right to file a complaint with the Arizona
                Department of Insurance and Financial Institutions Consumer Affairs Division, (800) 325-2548.  <strong>Exclusions listed in the Agreement apply once the Covered Product is owned by You</strong>.
                This <strong>Agreement</strong> may not exclude pre-existing conditions if such conditions were known or should reasonably have been known to <strong>Us</strong> or the <strong>Selling Retailer</strong> selling the
                <strong>Agreement</strong> on <strong>Our</strong> behalf.</p>
              <p><strong>Arkansas: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of
                returned <strong>Service Agreement</strong>.
                <strong>INSURANCE</strong> section is amended as follows: Obligations of the <strong>Provider</strong> under this <strong>Agreement</strong> are guaranteed under a service contract reimbursement insurance policy. If the <strong>Provider</strong>
                fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company. </p>
              <p><strong>California: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within thirty (30) days of receipt of
                returned <strong>Service Agreement</strong>.  For all products other than home appliances and home electronic products, if the <strong>Agreement</strong> is cancelled: (a) within sixty (60) days of receipt of this
                <strong>Agreement</strong>, <strong>You</strong> shall receive a full refund of the purchase price of this <strong>Agreement</strong> provided no service has been performed, or (b) after sixty (60) days, <strong>You</strong> will receive a pro rata
                refund, less the cost of any service received.  The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT</strong> AND CLASS ACTION WAIVER provision does not prohibit a California
                resident from following the process to resolve complaints as outlined by the California Bureau of Household Goods and Services (BHGS).  To learn more about this process, <strong>You</strong> may
                contact BHGS at 1-916-999-2041, or <strong>You</strong> may write to Department of Consumer Affairs, 4244 S. Market Court, Suite D, Sacramento, CA 95834, or <strong>You</strong> may visit their website at
                www.bhgs.dca.ca.gov.  Informal dispute resolution is not available.</p>
              <p><strong>Colorado: INSURANCE</strong> section is amended as follows: Obligations of the <strong>Provider</strong> under this are guaranteed under a service contract reimbursement insurance policy. If the <strong>Provider</strong>
                fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company.</p>
              <p><strong>Connecticut:</strong> If <strong>You</strong> purchased this <strong>Agreement</strong> in Connecticut, <strong>You</strong> may pursue arbitration to settle disputes between <strong>You</strong> and the <strong>Provider</strong> of this <strong>Agreement</strong>. <strong>You</strong> may mail <strong>Your</strong>
                complaint to: State of Connecticut, Insurance Department, P.O. Box 816, Hartford, Connecticut 06142-0816, Attention: Consumer Affairs.  The written complaint must describe the
                dispute, identify the price of the product and cost of repair, and include a copy of this <strong>Agreement</strong>.  <strong>CANCELLATION</strong> section is amended as follows: <strong>You</strong> may cancel this <strong>Agreement</strong> if
                <strong>You</strong> return the Product or the Product is sold, lost, stolen, or destroyed.</p>
              <p><strong>Florida:</strong> This <strong>Agreement</strong> is between the <strong>Provider</strong>, Lyndon Southern Insurance Company (License No. 03698) and <strong>You</strong>, the purchaser.  If <strong>You</strong> cancel this <strong>Agreement</strong>, return of
                premium shall be based upon ninety percent (90%) of the unearned pro-rata premium less any claims that have been paid or less the cost of repairs made on <strong>Your</strong> behalf.  If this
                <strong>Agreement</strong> is cancelled by the <strong>Provider</strong> or <strong>Administrator</strong>, return of premium shall be based upon one hundred percent (100%) of the unearned pro-rata premium less any claims that
                have been paid or less the cost of repairs made on <strong>Your</strong> behalf.  <strong>The rate charged for this service contract is not subject to regulation by the Florida Office of Insurance Regulation</strong>
                .  The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section is amended to add the following: Arbitration proceedings shall be
                conducted in the county in which the consumer resides.</p>
              <p><strong>Georgia: </strong>Coverage is effective upon the expiration of the shortest portion of the manufacturer’s warranty. <strong>CANCELLATION</strong> section is amended as follows: If <strong>You</strong> cancel after thirty (30)
                days of receipt of <strong>Your Agreement</strong>, <strong>You</strong> will receive a pro rata refund of the <strong>Agreement</strong> Purchase Price. In the event of cancellation by <strong>Us</strong>, notice of such cancellation will be in writing
                and given at least thirty (30) days prior to cancellation. Cancellation will comply with Section 33-24-44 of the Code of Georgia. Claims paid and cancellation fees shall not be deducted
                from any refund owed as a result of cancellation. Any refund owed and not paid as required is subject to a penalty equal to twenty-five percent (25%) of the refund owed and interest of
                eighteen percent (18%) per year until paid; however, such penalty shall not exceed fifty percent (50%) of the amount of the refund.  <strong>We</strong> may not cancel this Agreement except for fraud,
                material misrepresentation, or non-payment by <strong>You</strong>.  DISPUTE RESOLUTION/ARBITRATION AGREEMEN AND CLASS ACTION WAIVER section of this <strong>Agreement</strong> is removed.
                The <strong>“WHAT IS NOT COVERED-EXCLUSIONS”</strong> section of this <strong>Agreement</strong> is amended as follows: Any and all pre-existing conditions known by <strong>You</strong> that occur prior to the effective date
                of this <strong>Agreement</strong> or any sold “AS-IS” including but not limited to floor models, demonstration models, etc.
                <strong>HOW TO OBTAIN SERVICE section is amended as follows: Stains: DO NOT ATTEMPT TO REMOVE THE STAIN YOURSELF, OR USE WATER OR OTHER CHEMICALS AS THIS
                MAY VOID COVERAGE UNDER THIS AGREEMENT.</strong></p>
              <p><strong>Hawaii: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of
                returned Service <strong>Agreement</strong>.</p>
              <p><strong>Idaho: INSURANCE</strong> section is amended as follows: Obligations of the <strong>Provider</strong> under this <strong>Agreement</strong> are guaranteed under a service contract reimbursement insurance policy. If the
                <strong>Provider</strong> fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, You are entitled to make a claim directly against the insurance company.</p>
              <p><strong>Indiana: INSURANCE</strong> section is amended as follows: Obligations of the <strong>Provider</strong> under this <strong>Agreement</strong> are guaranteed under a service contract reimbursement insurance policy. If the
                <strong>Provider</strong> fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company.</p>
              <p><strong>Iowa: CANCELLATION </strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within thirty (30) days of receipt of returned
                <strong>Service Agreement</strong>.</p>
              <p><strong>Kentucky: </strong> <strong>You</strong> are entitled to make a direct claim against the insurer if <strong>We</strong> fail to pay any covered claim within sixty (60) days after the claim has been filed.  The insurer is: LYNDON
                SOUTHERN INSURANCE COMPANY, 10751 DEERWOOD PARK BLVD., SUITE 200, JACKSONVILLE, FL 32256</p>
              <p><strong>Maine: CANCELLATION</strong> section is amended as follows: The <strong>Provider</strong> of the <strong>Agreement</strong> shall mail a written notice to the <strong>Service Agreement Holder</strong> at the last known address of the
               <strong>Service Agreement Holder</strong> contained in the records of the <strong>Provider</strong> or Administrator at least fifteen (15) days prior to cancellation by the <strong>Provider</strong>.  The notice must state the effective
                date of the cancellation and the reason for the cancellation.  If an <strong>Agreement</strong> is cancelled by the <strong>Provider</strong> for a reason other than nonpayment of the <strong>Provider</strong> fee, the <strong>Provider</strong> shall
                refund to the Service <strong>Agreement</strong> Holder one hundred percent (100%) of the unearned pro-rata provider fee, less any claims paid.  An administrative fee not to exceed ten percent (10%)
                of the <strong>Provider</strong> fee paid by the <strong>Service Agreement Holder</strong> may be charged by the <strong>Provider</strong>.  A monthly penalty equal to ten percent (10%) of the outstanding refund will be applied to a
                refund that is not paid or credited within forty-five (45) days after the return of the <strong>Agreement</strong> to the <strong>Provider</strong>.
                INSURANCE section is amended as follows: If the <strong>Provider</strong> fails to pay or provide service on a claim, including any claim for the return of the unearned portion of the <strong>Provider</strong> fee, within
                sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company.</p>
              <p><strong>Maryland: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of
                returned <strong>Service Agreement</strong>.</p>
              <p><strong>Massachusetts: CANCELLATION</strong> section is amended as follows: The <strong>Provider</strong> shall mail a written notice to the <strong>Service Agreement Holder</strong>, including the effective date of the
                cancellation and the reason for the cancellation at the last known address of the <strong>Service Agreement Holder</strong> contained in the records of the provider or <strong>Administrator</strong> at least five (5)
                days prior to cancellation by the <strong>Provider</strong> unless the reason for cancellation is nonpayment of the <strong>Provider</strong> fee, material misrepresentation or a substantial breach of duties by the
                <strong>Service Agreement Holder</strong> relating to the <strong>Covered Product</strong> or its use.  A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of
                receipt of returned <strong>Service Agreement</strong>.</p>
              <p><strong>Michigan:</strong> If performance under this <strong>Agreement</strong> is interrupted because of a strike or work stoppage at Our place of business, the effective period of the <strong>Agreement</strong> shall be extended for
                the period of the strike or work stoppage.</p>
              <p><strong>Minnesota: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt
                of returned <strong>Service Agreement</strong></p>
              <p><strong>Mississippi: IMPORTANT NOTICE ABOUT YOUR COVERAGE:</strong></p>
              <ol>
                <li>This <strong>Agreement</strong> includes a binding <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> agreement.</li>
                <li>The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> agreement requires that any dispute related to Your coverage must be resolved by
                  arbitration and not in a court of law.</li>
                <li>The results of the arbitration are final and binding on <strong>You</strong> and <strong>Us</strong></li>
                <li>n an arbitration, one or more arbitrators, who are independent, neutral decision makers, render a decision after hearing the positions of the parties.</li>
                <li>When <strong>You</strong> become an <strong>Agreement Holder</strong> under this <strong>Agreement You</strong> must resolve any dispute related to the <strong>Agreement</strong> by binding arbitration instead of a trial in court, including
                  a trial by jury</li>
                <li>Binding arbitration generally takes the place of resolving disputes by a judge and jury.</li>
                <li>Should <strong>You</strong> need additional information regarding the binding arbitration provision in the <strong>Agreement</strong>, <strong>You</strong> may contact <strong>Our</strong> toll-free assistance line at <strong>(800) 867-2216</strong></li>
              </ol>
              <p><strong>CANCELLATION </strong>section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of returned <strong>Service Agreement.</strong></p>
              <p><strong>Missouri: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of
                returned <strong>Service Agreement</strong>. <strong>INSURANCE</strong> section is amended as follows: Obligations of the <strong>Provider</strong> under this <strong>Agreement</strong> are guaranteed under a service contract reimbursement insurance policy. If the <strong>Provider</strong>
                fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company.</p>
              <p><strong>Nevada: CANCELLATION</strong> section is amended as follows: No claim incurred or paid will be deducted from the amount to be returned in the event of cancellation. <strong>We</strong> may not cancel
                this <strong>Agreement</strong> without providing <strong>You</strong> with written notice at least fifteen (15) days prior to the effective date of cancellation. Such notice shall include the effective date of cancellation
                and the reason for cancellation.  A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of returned <strong>Service Agreement</strong>
                . The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section of this <strong>Agreement</strong> is removed.
                The cost of claims paid, or services provided will not, under any circumstances, be deducted from any refund issued pursuant to this <strong>Agreement</strong>.
                If <strong>You</strong> are not satisfied with the manner in which <strong>We</strong> are handling a claim under this <strong>Agreement</strong>, <strong>You</strong> may contact the Nevada Division of Insurance toll free at 888-872-3234.
                This <strong>Agreement</strong> will not cover any unauthorized or non-manufacturer-recommended modifications to the <strong>Covered Product</strong>, or any damages arising from such unauthorized or non-
                manufacturer-recommended modifications.  However, if the <strong>Covered Product</strong> is modified or repaired in an unauthorized or non-manufacturer-recommended manner, <strong>We</strong> will not
                automatically suspend all coverage.  Rather, this <strong>Agreement</strong> will continue to provide any applicable coverage that is not related to the unauthorized or non-manufacturer-recommended
                modification or any damages arising therefrom, unless such coverage is otherwise excluded by the terms of this <strong>Agreement</strong>.</p>
              <p><strong>New Hampshire: </strong>In the event <strong>You</strong> do not receive satisfaction under this <strong>Agreement</strong>, <strong>You</strong> may contact the New Hampshire Insurance Department, 21 South Fruit Street, Concord, NH
                03301, (603) 271-2261.  The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section of this <strong>Agreement</strong> is removed</p>
              <p><strong>New Jersey: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt
                of returned <strong>Service Agreement</strong></p>
              <p><strong>New Mexico: CANCELLATION</strong> section is amended as follows: <strong>We</strong> may not cancel this <strong>Agreement</strong> without providing <strong>You</strong> with written notice at least fifteen (15) days prior to the
                effective date of cancellation. Such notice shall include the effective date of cancellation and the reason for cancellation. If this <strong>Agreement</strong> has been in force for a period of seventy (70)
                days, <strong>We</strong> may not cancel it before the expiration of the <strong>Agreement</strong> term or one (1) year, whichever occurs first, unless: 1) <strong>You</strong> fail to pay any amount due; 2) <strong>You</strong> are convicted of a
                crime which results in an increase in the service required under the <strong>Agreement</strong>; 3) <strong>You</strong> engage in fraud or material misrepresentation in obtaining this <strong>Agreement</strong>; or 4) <strong>You</strong> commit any
                act, omission, or violation of any terms of this <strong>Agreement</strong> after the effective date of this <strong>Agreement</strong> which substantially and materially increases the service required under this
                <strong>Agreement</strong>. A ten percent (10%) penalty per month will be applied to refunds not paid or credited within sixty (60) days of receipt of a returned <strong>Agreement</strong>. </p>
              <p><strong>New York: CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will be applied to refunds not paid or credited within thirty (30) days of receipt of
                returned <strong>Service Agreement</strong>.
                INSURANCE section is amended as follows: Obligations of the <strong>Provider</strong> under this <strong>Agreement</strong> are guaranteed under a service contract reimbursement insurance policy. If the <strong>Provider</strong>
                fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company.</p>
              <p><strong>North Carolina: CANCELLATION</strong> section is amended as follows: <strong>We</strong> may not cancel this <strong>Agreement</strong> except for non-payment by <strong>You</strong> or for violation of any of the terms and conditions
                of this <strong>Agreement</strong>.</p>
              <p><strong>Ohio: INSURANCE</strong> section is amended as follows: Obligations of the <strong>Provider</strong> under this <strong>Agreement</strong> are guaranteed under a service contract reimbursement insurance policy. If the
                <strong>Provider</strong> fails to pay or provide service on a claim within sixty (60) days after proof of loss has been filed, <strong>You</strong> are entitled to make a claim directly against the insurance company</p>
              <p><strong>Oklahoma:</strong> This <strong>Agreement</strong> is not a contract of insurance.  Coverage afforded under this contract is not guaranteed by the Oklahoma Insurance Guaranty Association.
                <strong>CANCELLATION</strong> section is amended as follows: In the event <strong>You</strong> cancel this <strong>Agreement</strong>, return of premium shall be based upon ninety percent (90%) of the unearned pro rata contract
                cost, less any claims that have been paid or less the cost of repairs made on <strong>Your</strong> behalf.  In the event <strong>We</strong> cancel this <strong>Agreement</strong>, return of <strong>Provider</strong> fee shall be based upon one
                hundred percent (100%) of unearned pro rata <strong>Provider</strong> fee less any claims that have been paid or less the cost of repairs made on <strong>Your</strong> behalf.  <strong>DISPUTE
                RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> – While arbitration is mandatory, the outcome of any arbitration shall be non-binding on the parties, and
                either party shall, following arbitration, have the right to reject the arbitration award and bring suit in a district court of Oklahoma</p>
              <p><strong>Oregon:</strong> Upon failure of the <strong>Obligor</strong> to perform under the <strong>Agreement</strong>, the insurer shall pay on behalf of the <strong>Obligor</strong> any sums the <strong>Obligor</strong> is legally obligated to pay and any service
                that the <strong>Obligor</strong> is legally obligated to perform. Termination of the reimbursement policy shall not occur until a notice of termination has been mailed or delivered to the Director of the
                Department of Consumer and Business Services.  This notice must be mailed or delivered at least thirty (30) days prior to the date of termination.  <strong>CANCELLATION</strong> section is amended
                as follows: <strong>You</strong>, the <strong>Service Agreement Holder</strong> may apply for reimbursement directly to the insurer if a refund or credit is not paid before the 46th day after the date on which <strong>Your</strong>
                <strong>Agreement</strong> is returned to the <strong>Provider</strong>.  The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section of this <strong>Agreement</strong> is removed. </p>
              <p><strong>South Carolina:</strong> If <strong>You</strong> purchased this <strong>Agreement</strong> in South Carolina, complaints or questions about this <strong>Agreement</strong> may be directed to the South Carolina Department of Insurance,
                P.O. Box 100105, Columbia, South Carolina 29202-3105, telephone number 803-737-6180.  <strong>CANCELLATION</strong> section is amended as follows: A ten percent (10%) penalty per month will
                be applied to refunds not paid or credited within forty-five (45) days of receipt of returned <strong>Service Agreement</strong></p>
              <p><strong>Texas: </strong> If <strong>You</strong> purchased this <strong>Agreement</strong> in Texas, unresolved complaints or questions concerning the regulations of service contracts may be addressed to the Texas Department of
                Licensing and Regulation, P.O. Box 12157, Austin, Texas 78711, telephone number (512) 463-2906 or (800) 803-9202. <strong>Administrator</strong>: LOTSolutions, Inc., 10751 Deerwood Park Blvd.,
                Suite 200, Jacksonville, FL 32256 (800-867-2216) Lic # 290.  <strong>CANCELLATION</strong> section is amended as follows: <strong>You</strong>, the <strong>Service Agreement Holder</strong> may apply for reimbursement
                directly to the insurer if a refund or credit is not paid before the 46th day after the date on which <strong>Your Agreement</strong> is returned to the <strong>Provider</strong>.</p>
              <p><strong>Utah: </strong> This <strong>Agreement</strong> is subject to limited regulation by the Utah Insurance Department.  To file a complaint, contact the Utah Insurance Department.  Coverage afforded under this
                <strong>Agreement</strong> is not guaranteed by the Utah Property and Casualty Guaranty Association. Proof of loss should be furnished by <strong>You</strong> to the Administrator as soon as reasonably possible.
                Failure to furnish such notice or proof within the time required by this <strong>Agreement</strong> does not invalidate or reduce a claim.  <strong>CANCELLATION</strong> section is amended as follows: <strong>We</strong> can cancel
                this <strong>Agreement</strong> during the first sixty (60) days of the initial annual term by mailing to <strong>You</strong> a notice of cancellation at least thirty (30) days prior to the effective date of cancellation except
                that <strong>We</strong> can also cancel this <strong>Agreement</strong> during such time period for non-payment of <strong>Provider</strong> fee by mailing <strong>You</strong> a notice of cancellation at least ten (10) days prior to the effective date
                of cancellation. After sixty (60) days have elapsed, <strong>We</strong> may cancel this <strong>Agreement</strong> by mailing a cancellation notice to <strong>You</strong> at least ten (10) days prior to the cancellation date for non-
                payment of <strong>Provider</strong> fee and thirty (30) days prior to the cancellation date for any of the following reasons: (a) material misrepresentation, (b) substantial change in the risk assumed,
                unless the <strong>We</strong> should reasonably have foreseen the change or contemplated the risk when entering into the <strong>Agreement</strong> or (c) substantial breaches of contractual duties, conditions, or
                warranties. The notice of cancellation must be in writing to <strong>You</strong> at <strong>Your</strong> last known address and contain all of the following: (1) the <strong>Agreement</strong> number, (2) the date of notice, (3) the
                effective date of the cancellation and, (4) a detailed explanation of the reason for cancellation.
                Any matter in dispute between <strong>You</strong> and the company may be subject to binding arbitration as an alternative to court action pursuant to the rules of (the American Arbitration Association
                or other recognized arbitrator), a copy of which is available on request from the company. Any decision reached by arbitration shall be binding upon both <strong>You</strong> and the company. The
                arbitration award may include attorney's fees if allowed by state law and may be entered as a judgment in any court of proper jurisdiction.
                <strong>EMERGENCY SERVICE:</strong> If <strong>You</strong> are unable to reach <strong>Administrator</strong> and <strong>You</strong> require emergency repair, <strong>You</strong> may contact any manufacturer authorized service repair facility listed in <strong>Your</strong>
                phone book or online.  Mail <strong>Your</strong> original repair bill, including proof of payment, along with the technician’s report and a copy of the <strong>Agreement</strong> to <strong>Administrator</strong> for reimbursement.  All coverage
                and exclusions in this <strong>Agreement</strong> will apply.</p>
              <p><strong>Virginia: </strong> If any promise made in this <strong>Agreement</strong> has been denied or has not been honored within sixty (60) days after Your request, You may contact the Virginia Department of
                Agriculture and Consumer Services, Office of Charitable and Regulatory Programs at www.vdacs.virginia.gov/food-extended-service-contract-providers.shtml to file a complaint.</p>
              <p><strong>Washington: </strong>All references to <strong>Obligor</strong> throughout this <strong>Agreement</strong> are replaced with <strong>Service Provider</strong>.  A ten percent (10%) penalty per month will be applied to refunds not paid or
                credited within thirty (30) days of receipt of returned Service <strong>Agreement</strong>.  We may not cancel this <strong>Agreement</strong> without providing You with written notice at least twenty-one (21) days
                prior to the effective date of cancellation. Such notice shall include the effective date of cancellation and the reason for cancellation.  <strong>You</strong> are not required to wait sixty (60) days before
                filing a claim directly with the <strong>Service Provider</strong>. The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section is amended to add the following:
                The Insurance Commissioner of Washington is the <strong>Service Provider’s</strong> attorney to receive service of process in any action, suit or proceeding in any court, and the state of Washington
                has jurisdiction of any civil action in connection with this <strong>Agreement</strong>.  Arbitration proceedings shall be held at a location in closest proximity to the <strong>Service Agreement Holder’s</strong>
                permanent residence.  <strong>You</strong> may file a direct claim with the <strong>Service Provider</strong> at any time.
                <strong>EMERGENCY SERVICE:</strong> If <strong>You</strong> are unable to reach <strong>Administrator</strong> and <strong>You</strong> require emergency repair, <strong>You</strong> may contact any manufacturer authorized service repair facility listed in <strong>Your</strong>
                phone book or online. Mail <strong>Your</strong> original repair bill along with the technician’s report and a copy of the <strong>Agreement</strong> to <strong>Administrator</strong> for reimbursement.  All coverage and exclusions in this
                <strong>Agreement</strong> will apply. </p>
              <p><strong>Wisconsin:</strong> The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section of this <strong>Agreement</strong> is removed.  <strong>CANCELLATION</strong> section is
                amended as follows: This <strong>Agreement</strong> may be cancelled by <strong>Us</strong> only for non-payment of the Provider fee, material misrepresentation by <strong>You</strong> to <strong>Us</strong> or the <strong>Administrator</strong>, or substantial
                breach of duties by <strong>You</strong> relating to the <strong>Covered Product</strong> or it use.  If <strong>We</strong> cancel this <strong>Agreement</strong>, <strong>We</strong> will provide written notice of cancellation, including the effective date of the
                cancellation and the actual reason for the cancellation, to the last known mailing address at least five (5) days prior to the effective date of the cancellation.  If <strong>We</strong> cancel this
                <strong>Agreement</strong>, <strong>We</strong> or the <strong>Selling Retailer</strong> will refund <strong>You</strong> one hundred percent (100%) of the <strong>Agreement</strong> purchase price, less any claims paid on <strong>Your</strong> <strong>Agreement</strong>.  <strong>We</strong> may charge a
                reasonable administrative fee for cancellation, which may not exceed ten percent (10%) of the Provider fee. If <strong>You</strong> cancel within thirty (30) days of receipt of <strong>Your</strong> <strong>Agreement</strong>, You
                must first retur to the <strong>Selling Retailer</strong> or to the <strong>Obligor</strong> should the <strong>Selling Retailer</strong> not be available.  Proof of loss should be furnished by <strong>You</strong> to the <strong>Administrator</strong> as soon as
                reasonably possible an  within one (1) year after the time required by this <strong>Agreement</strong>.  Failure to furnish such notice or proof within the time required by this <strong>Agreement</strong> does not
                invalidate or reduce a claim.  A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of returned <strong>Agreement</strong>.  In the
                event of a total loss of property that is not covered, <strong>You</strong> shall be entitled to cancel the <strong>Agreement</strong> and receive a pro rata refund of any unearned Provider fee, less any claims paid (No
                cancellation fee will be assessed in this situation).
                <strong>THIS CONTRACT IS SUBJECT TO LIMITED REGULATION BY THE OFFICE OF THE COMMISSIONER OF INSURANCE</strong>.  If <strong>Administrator</strong> fails to provide, or reimburse or pay for, a
                service that is covered under this <strong>Agreement</strong> within sixty-one (61) days after <strong>You</strong> provide proof of loss, or if the <strong>Administrator</strong> becomes insolvent or otherwise financially impaired, <strong>You</strong>
                may file a claim directly with the insurer for reimbursement, payment, or provision of the service.</p>
              <p><strong>Wyoming:</strong> <strong>CANCELLATION</strong> section is amended as follows:  A ten percent (10%) penalty per month will be applied to refunds not paid or credited within forty-five (45) days of receipt of
                returned <strong>Service Agreement</strong>.  The <strong>DISPUTE RESOLUTION/ARBITRATION AGREEMENT AND CLASS ACTION WAIVER</strong> section of this <strong>Agreement</strong> is removed. </p>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

export default FurniturePlus4WC;
