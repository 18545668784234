import React from 'react';
import { Row, Col } from 'reactstrap';

import Tile from './Tile';
import { ReactComponent as AccidentalDamage } from '../../../images/accidental-damage.svg';
import { ReactComponent as AllStains } from '../../../images/all-stains.svg';
import { ReactComponent as Defects } from '../../../images/defects.svg';
import { ReactComponent as ExtendedWarranty } from '../../../images/extended-warranty.svg';
import { ReactComponent as Jewelry } from '../../../images/jewelry.svg';
import { ReactComponent as PowerSurge } from '../../../images/power-surge.svg';
import { ReactComponent as WearAndTear } from '../../../images/wear-tear.svg';

interface SummaryTilesProps {
  contractTerms: any;
  isModal?: any;
}

const customTileImageSwitcher = (imageKey:string) => {
  switch (imageKey) {
    case 'AllStains':
      return AllStains;
    case 'Defects':
      return Defects;
    case 'ExtendedWarranty':
      return ExtendedWarranty;
    case 'Jewelry':
      return Jewelry;
    case 'PowerSurge':
      return PowerSurge;
    case 'WearAndTear':
      return WearAndTear;
    case 'AccidentalDamage':
    default:
      return AccidentalDamage;
  }
};

const SummaryTiles: React.FC<SummaryTilesProps> = (props) => {
  const { contractAdh, summaryOptions } = props.contractTerms;
  const displayedTiles = [];

  // Test (preempts all other tiles)
  if (summaryOptions && summaryOptions.test) {
    for (let i = 0; i < 3; i++) {
      displayedTiles.push(
        <Tile
          Image={ AccidentalDamage }
          title="Test protection"
          text="A test summary tile to simulate what your customers will see." />,
      );
    }
  }

  // Custom tiles (preempts standard tiles)
  if (summaryOptions && summaryOptions.custom) {
    for (const option of summaryOptions.customTiles) {
      displayedTiles.push(
        <Tile
          Image={ customTileImageSwitcher(option.imageKey) }
          title={ option.title }
          text={ option.text } />,
      );
    }
  }

  // Tier 1
  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.extendedWarranty) {
    displayedTiles.push(
      <Tile
        Image={ ExtendedWarranty }
        title="Extended Warranty"
        text="This contract extends your manufacturer's warranty, starting immediately after the shortest term of the manufacturer's warranty." />,
    );
  }

  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.dopProtectionPlan) {
    displayedTiles.push(
      <Tile
        Image={ ExtendedWarranty }
        title="Protection Plan"
        text="This contract extends your manufacturer's warranty and begins at the date of purchase." />,
    );
  }

  // Tier 2
  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.jewelry) {
    displayedTiles.push(
      <Tile
        Image={ Jewelry }
        title="Jewelry"
        text="Protects your jewelry &amp; watches from cracks, scratches, and tears." />,
    );
  }
  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.wearTear) {
    displayedTiles.push(
      <Tile
        Image={ WearAndTear }
        title="Wear and Tear"
        text="Protects your product from wear and tear that affects mechanical or electrical performance." />,
    );
  }
  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.allStains) {
    displayedTiles.push(
      <Tile
        Image={ AllStains }
        title="All Stains"
        text="Food &amp; beverage, dye, ink, bleach, nail polish and more..." />,
    );
  }

  // Tier 3
  if (displayedTiles.length < 3 && contractAdh) {
    displayedTiles.push(
      <Tile
        Image={ AccidentalDamage }
        title="Accidental Damage"
        text="Protects your product from accidents while handling such as drops and spills." />,
    );
  }

  // Tier 4
  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.defects) {
    displayedTiles.push(
      <Tile
        Image={ Defects }
        title="Defects"
        text="Mechanical / electrical failure, frame breakage / separation, warping, loss of silvering, defects in springs, coils, and hydraulics." />,
    );
  }
  if (displayedTiles.length < 3 && summaryOptions && summaryOptions.powerSurge) {
    displayedTiles.push(
      <Tile
        Image={ PowerSurge }
        title="Power Surge"
        text="Protects your product from the effects of a power surge." />,
    );
  }

  let largeWidth: any = null;
  let extraLargeWidth: any = null;
  if (props.isModal) {
    extraLargeWidth = 12 / displayedTiles.length;
  } else {
    largeWidth = 12 / displayedTiles.length;
  }

  return (
    <Row className="terms-summary-tiles">
      {
        displayedTiles.map((tile, idx) => (
          <Col xs={ 12 } lg={ largeWidth } xl={ extraLargeWidth } key={ `st-${idx}` }>{ tile }</Col>
        ))
      }
    </Row>
  );
};

export default SummaryTiles;
