import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { generateShopUrls } from '../Claim/ClaimForm/claim-utilities';

import { ReactComponent as ResolvedRepair } from '../../images/resolved-repaired.svg';
import { ReactComponent as ClaimApproved } from '../../images/claim-approved.svg';
import { ReactComponent as PromoImage } from '../../images/claim-promo.svg';
import ClickCopy from '../Shared/ClickCopy';

interface resolutionModalProps {
  resolutionInfo: any;
  _markAsRead: any;
}

const ResolutionAlertModal = (props: resolutionModalProps) => {
  const {
    resolutionInfo,
    _markAsRead,
  } = props;

  if (!resolutionInfo) {
    return null;
  }

  const {
    name,
    serviceType,
    resolutionPromoCode,
    shopName,
    contractSaleId,
    shopUrl,
    hiClydeEnabled,
    claimId,
  } = resolutionInfo;

  const { modifiedUrl } = generateShopUrls(shopUrl);

  const displayName = name.length > 40 ? `${name.slice(0, 35)}(...)` : name;
  // @ts-ignore
  const showPayoutResolution = window._env_.SHOW_CLAIM_PAYOUTS === 'true';

  let modalBody = <></>;

  switch (serviceType) {
    case 'promoCode':
      modalBody = <>
        <span className='claim-form__response__body__icon svg-wrapper--clyde-black'>
          <PromoImage />
        </span>

        <h3 className='claim-form__response__header'>Your promo code has arrived!</h3>
        <p>Congrats! Your promo code for your { displayName } has arrived, click below to copy the code to your clipboard.</p>
        <div className='claim-form__response__promo-code-buttons'>
          <ClickCopy copyValue={ resolutionPromoCode } />
          <a href={ modifiedUrl } target='_blank' rel='noreferrer'>
            <Button className='claim-form__response__out-button button--general'>
              Visit Store
            </Button>
          </a>
        </div>
      </>;
      break;
    case 'carryIn':
    case 'inHome':
    case 'depot':
    case 'customerInstall':
      modalBody = <>
        <span className='claim-form__response__body__icon svg-wrapper--clyde-black'>
          <ResolvedRepair />
        </span>

        <h3 className='claim-form__response__header'>Your claim has been approved for a repair!</h3>
        <p className='claim-form__handoff__next-steps'>Your { displayName } will be fixed in no time! {shopName} will be reaching out with details soon.</p>

      </>;
      break;
    case 'payout':
      if (!showPayoutResolution || !hiClydeEnabled) {
        return null;
      }

      modalBody = <>
        <span className='claim-form__response__body__icon svg-wrapper--clyde-black'>
          <ClaimApproved />
        </span>

        <h3 className='claim-form__response__header'>Congrats, you have been approved for a direct payment!</h3>
        <p className='claim-form__handoff__next-steps'>Go to your claim to select a payout option.</p>
        <Link
          to={`/claims/${claimId}`}
          className='claim-form__response__out-link'
          onClick={ _markAsRead }
        >
          <Button className='claim-form__response__out-button button--general'>Go To Claim</Button>
        </Link>
      </>;
      break;
    case 'retailerReplace':
    case 'clydeReplace':
      modalBody = <>
        <span className='claim-form__response__body__icon svg-wrapper--clyde-black'>
          <PromoImage />
        </span>
        <h3 className='claim-form__response__header'>You are approved for a replacement!</h3>
        <p className='claim-form__handoff__next-steps'>Go to your claim to confirm your shipping details.</p>
        <Link
          to={`/products/${contractSaleId}`}
          className='claim-form__response__out-link'
          onClick={ _markAsRead }
        >
          <Button className='claim-form__response__out-button button--general'>Go To Claim</Button>
        </Link>
      </>;
      break;
    default:
      return null;
  }

  return (
    <section className='claim-form__response__body'>
      { modalBody }
    </section>
  );
};

export default ResolutionAlertModal;
