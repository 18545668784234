/**
 * Product actions
 */

import http from '../config/http';

const GET_PRODUCTS_URL = '/purchase';
const GET_PRODUCT_URL = '/purchase';// a sku or ID will be attached to the end of this
const GET_PRODUCT_HISTORY_URL = '/purchase/history';
const MARK_AS_READ_URL = '/claim/resolution/mark-as-read';

export function updateGetProducts(products:any) {
  return {
    type: 'UPDATE_GET_PRODUCTS',
    products,
  };
}

export function loadingGetProducts() {
  return {
    type: 'LOADING_GET_PRODUCTS',
  };
}

export function failedGetProducts(err:any) {
  return {
    type: 'FAIL_GET_PRODUCTS',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getProducts() {
  return (dispatch:any) => {
    dispatch(loadingGetProducts());
    return http.get(GET_PRODUCTS_URL)
      .then((response) => {
        dispatch(updateGetProducts(response.data));
      })
      .catch((err) => {
        dispatch(failedGetProducts(err));
      });
  };
}

export function updateGetProductHistory(history:any) {
  return {
    type: 'UPDATE_GET_PRODUCT_HISTORY',
    history,
  };
}

export function loadingGetProductHistory() {
  return {
    type: 'LOADING_GET_PRODUCT_HISTORY',
  };
}

export function failedGetProductHistory(err:any) {
  return {
    type: 'FAIL_GET_PRODUCT_HISTORY',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getProductHistory(contractSaleId: string) {
  return (dispatch:any) => {
    dispatch(loadingGetProductHistory());
    return http.get(`${GET_PRODUCT_HISTORY_URL}/${contractSaleId}`)
      .then((response) => {
        dispatch(updateGetProductHistory(response.data));
      })
      .catch((err) => {
        dispatch(failedGetProductHistory(err));
      });
  };
}

export function updateGetProduct(product:any) {
  return {
    type: 'UPDATE_GET_PRODUCT',
    product,
  };
}

export function loadingGetProduct() {
  return {
    type: 'LOADING_GET_PRODUCT',
  };
}

export function failedGetProduct(err:any) {
  return {
    type: 'FAIL_GET_PRODUCT',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getProduct(contractSaleId:string) {
  return (dispatch:any) => {
    dispatch(loadingGetProduct());
    return http.get(`${GET_PRODUCT_URL}/${contractSaleId}`)
      .then((response) => {
        dispatch(updateGetProduct(response.data));
        dispatch(getProductTermsInfo(response.data));
      })
      .catch((err) => {
        dispatch(failedGetProduct(err));
      });
  };
}

export function getProductTermsInfo(product: any) {
  return {
    type: 'GET_PRODUCT_TERMS_INFO',
    product,
  };
}

export function setSelectedProduct(contractSaleId: any) {
  return {
    type: 'SET_SELECTED_PRODUCT',
    contractSaleId,
  };
}

export const markProductResolutionRead = (resolutionId: string) => {
  http.put(MARK_AS_READ_URL, { resolutionId });

  return {
    type: 'MARK_RESOLUTION_READ',
    resolutionId,
  };
};

// receipt upload actions

export const updateReceiptUpload = (data:any) => {
  const { receiptFile } = data;

  return {
    type: 'UPDATE_RECEIPT_UPLOAD',
    receiptFile,
  };
};

export const loadingReceiptUpload = () => ({ type: 'LOADING_RECEIPT_UPLOAD' });

export const failedReceiptUpload = (err:any) => ({
  type: 'FAIL_RECEIPT_UPLOAD',
  error: err.response ? err.response.data : 'Network Error',
  status: err.response ? err.response.status : 503,
});

export const uploadReceiptPicture = (contractSaleId: string, uploadData: any) => (dispatch:any) => {
  dispatch(loadingReceiptUpload());
  return http.post(`/purchase/${contractSaleId}/upload-receipt`, uploadData)
    .then((response) => {
      dispatch(updateReceiptUpload(response.data));
    })
    .catch((err) => {
      dispatch(failedReceiptUpload(err));
    });
};

export const clearProductError = () => ({
  type: 'CLEAR_PRODUCT_ERROR',
});

export const loadingTransferContract = () => ({ type: 'LOADING_TRANSFER_CONTRACT' });
export const updateTrasferContract = (data:any) => {
  const { transferData } = data;

  return {
    type: 'UPDATE_TRANSFER_CONTRACT',
    transferData,
  };
};

export const failedTransferContract = (err:any) => ({
  type: 'FAIL_TRANSFER_CONTRACT',
  error: err.response ? err.response.data : 'Network Error',
  status: err.response ? err.response.status : 503,
});

export const transferContract = (cartId: string, transferOrderData: any) => (dispatch:any) => {
  dispatch(loadingTransferContract());
  return http.post(`/product/contract/${cartId}/transfer`, transferOrderData)
    .then((response) => {
      dispatch(updateTrasferContract(response.data));
    })
    .catch((err) => {
      dispatch(failedTransferContract(err));
    });
};
