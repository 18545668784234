import { connect } from 'react-redux';
import {
  getContractTerms,
  changeTermsKey,
  clearContractTerms,
  setTermsError,
} from '../actions/contractTermsActions';
import ContractTermsPage from '../components/ContractTerms/ContractTermsPage';

function mapStateToProps(state: any) {
  return {
    isUser: state.user.isLoggedIn && state.user.emailVerified,
    contractTerms: state.contractTerms,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    _getContractTerms: (params: any) => {
      dispatch(getContractTerms(params));
    },
    _changeTermsKey: (termsKey:string) => {
      dispatch(changeTermsKey(termsKey));
    },
    _clearContractTerms: () => {
      dispatch(clearContractTerms());
    },
    _setTermsError: (message: string) => {
      dispatch(setTermsError(message));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractTermsPage);
