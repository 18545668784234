import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import {
  Button, Form, FormGroup,
  Label, Input, FormFeedback,
} from 'reactstrap';

import { ReactComponent as LoadingBars } from '../../images/loading-bars.svg';

interface CheckoutFormProps {
  customer?: any;
  error: any;
  hiClydeLinkSource: string | null;
  isUser: boolean;
  isProcessing: boolean;
  lineitems?: any[];
  stripe?: any;
  tax: number;
  total: number;
  totalItems: number;
  _checkout?: any;
}

class CheckoutForm extends React.Component<CheckoutFormProps, any> {
  constructor(props: CheckoutFormProps) {
    super(props);
    this.state = {
      email: '',
      fullName: '',
      isStripeProcessing: false,
      stripeError: null,
    };
  }

  handleChange = (event: any) => {
    const {
      currentTarget: { name },
      target: { value },
    } = event;
    this.setState({ [name]: value });
  };

  handleSubmit = async (event: any) => {
    event.preventDefault();

    const {
      hiClydeLinkSource,
      lineitems,
      stripe,
      total,
      _checkout,
    } = this.props;
    const {
      email,
      fullName,
    } = this.state;

    this.setState({
      isStripeProcessing: true,
      stripeError: null,
    });

    const stripeResults = await stripe.createToken({
      name: fullName,
      address_country: 'US',
      currency: 'usd',
    });
    if (!stripeResults.token) {
      this.setState({
        isStripeProcessing: false,
        stripeError: stripeResults.error, // If we ever want to display the stripe error
      });
      return;
    }

    const order = {
      customer: {
        fullName,
        email: email || null,
      },
      lineitems,
      hiClydeLinkSource,
      token: stripeResults.token,
      total,
    };

    _checkout(order);
    this.setState({
      isStripeProcessing: false,
      stripeError: null,
    });
  };

  render() {
    const {
      error,
      isProcessing,
      isUser,
      total,
      totalItems,
    } = this.props;
    const {
      email,
      fullName,
      isStripeProcessing,
      stripeError,
    } = this.state;

    const emailInput = <FormGroup>
      <Label for='email'>Email</Label>
      <Input
        type='email'
        name='email'
        id='email'
        invalid={ error.fields && error.fields.indexOf('email') > -1 }
        placeholder='Email address'
        value={ email }
        onChange={ this.handleChange }
      />
      <FormFeedback>{ error.clientDesc }</FormFeedback>
    </FormGroup>;

    return (
      <div className='checkout-form clyde-shadow'>
        <h2>Checkout</h2>
        <Form className='profile-form-container__form' onSubmit={ this.handleSubmit } >
          { isUser !== true ? emailInput : '' }
          <FormGroup>
            <Label>Credit or Debit Card</Label>
            <CardElement className='stripe-form' />
          </FormGroup>
          <FormGroup>
            <Label for='fullName'>Name on Card</Label>
            <Input
              type='text'
              name='fullName'
              id='fullName'
              placeholder='First &amp; Last Name'
              invalid={ error.fields && error.fields.indexOf('fullName') > -1 }
              value={ fullName }
              onChange={ this.handleChange }
            />
            <FormFeedback>{ error.clientDesc }</FormFeedback>
          </FormGroup>
          <Button
            id='checkout-button'
            type='submit'
            className='button--general profile-form-container__form__button'
            disabled={ !(totalItems > 0 && !isProcessing && !isStripeProcessing) }>
            { !isProcessing && !isStripeProcessing ? `Pay $${total.toFixed(2)}` : <LoadingBars /> }
          </Button>
          {
            stripeError
              && <div className='clyde-form-feedback clyde-form-feedback--error checkout-form__feedback'>
                { stripeError.message }
              </div>
          }
          {
            error.fields && error.fields.indexOf('form') > -1
              && <div className='clyde-form-feedback clyde-form-feedback--error checkout-form__feedback'>
                { error.clientDesc }
              </div>
          }
        </Form>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);
