import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Form, FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import { ReactComponent as LoadingBars } from '../../images/loading-bars.svg';
import { ReactComponent as SuccessImage } from '../../images/claim-resolution-confirmed.svg';

interface RequestPasswordProps {
  _requestPassword: any;
  _clearAuthErrors: any;
  _setPasswordResetSuccessFalse: any;
  passwordResetSuccess: boolean;
  user: any;
  error: any;
  csrf: string;
}

interface RequestPasswordState {
  email?: string,
}

class RequestPassword extends React.Component<RequestPasswordProps, RequestPasswordState> {
  constructor(props:RequestPasswordProps) {
    super(props);
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    document.title = 'Clyde | Request New Password';
  }

  componentDidUpdate() {
    if (this.props.passwordResetSuccess) {
      setTimeout(() => { this.props._setPasswordResetSuccessFalse(); }, 5000);
    }
  }

  componentWillUnmount() {
    this.props._clearAuthErrors();
  }

  handleChange = (event:any) => {
    this.setState({
      [event.currentTarget.name]: event.target.value,
    });
  };

  handleSubmit = (event:any) => {
    if (!this.props.passwordResetSuccess) {
      this.props._requestPassword(this.state, this.props.csrf);
    }
    event.preventDefault();
  };

  render() {
    return (
    <section className="auth-form-container auth-form-container--password-reset" >
      { this.props.passwordResetSuccess
        ? <>
          <h2 className="auth-form-container__title auth-form-container__title--new-pw">
            <SuccessImage />
            Request Sent
          </h2>
          <p className="auth-form-container__copy" >
            An email has been sent to your address. <br />
            Please check your inbox (and spam folder) for next steps.
          </p>
        </>
        : <>
          <h2 className="auth-form-container__title auth-form-container__title--new-pw">Request New Password</h2>
          <p className="auth-form-container__copy" >
            Request a password to access your products and contracts.
          </p>
          <Form className="auth-form-container__form" >
            <FormGroup>
              <Label for="password">Email</Label>
              <Input
                type="email" name="email"
                id="email" placeholder=""
                value={this.state.email} onChange={this.handleChange}
                invalid={ !!this.props.error.clientDesc}
              />
              {
              this.props.error.clientDesc && this.props.error.fields && this.props.error.fields.indexOf('form') === -1
                ? <FormFeedback className="clyde-form-feedback clyde-form-feedback--error">{ this.props.error.clientDesc }</FormFeedback>
                : ''
            }
            </FormGroup>
            {
              this.props.error.clientDesc && this.props.error.fields && this.props.error.fields.indexOf('form') > -1
                ? <div className="clyde-form-feedback clyde-form-feedback--error">{ this.props.error.clientDesc }</div>
                : ''
            }
            <Button
              id="new-pw-button"
              className={ `button--general auth-form-container__form__button${this.props.passwordResetSuccess ? ' auth-form-container__form__button--success' : ''}` }
              type="submit"
              disabled={ !!this.props.user.isProcessing }
              onClick={this.handleSubmit} >
              { this.props.user.isProcessing ? <LoadingBars /> : 'Request New Password' }
            </Button>
          </Form>
        </>
      }
      <div className="auth-form-container__subsection auth-form-container__subsection--change-pw">
        <Link id="login" to="/login" className="clyde-link">Back to Login</Link>
      </div>
    </section>
    );
  }
}

export default RequestPassword;
