import http from '../config/http';

const GET_CONTRACTS_URL = '/terms';

const loadingGetContractTerms = () => ({
  type: 'LOADING_GET_CONTRACT_TERMS',
});

const successGetContractTerms = (contractTerms: any) => ({
  type: 'SUCCESS_GET_CONTRACT_TERMS',
  contractTerms,
});

const failureGetContractTerms = (err: any) => ({
  type: 'FAILURE_GET_CONTRACT_TERMS',
  error: err.response ? err.response.data : 'Network Error',
  status: err.response ? err.response.status : 503,
});

export const changeTermsKey = (termsKey:string) => ({
  type: 'CHANGE_TERMS_KEY',
  termsKey,
});

export const clearContractTerms = () => ({
  type: 'CLEAR_CONTRACT_TERMS',
});

export const getContractTerms = (params:any) => (dispatch: any) => {
  let query = '';
  for (const key in params) {
    if (query) {
      query += '&';
    }
    query += `${key}=${params[key]}`;
  }
  dispatch(loadingGetContractTerms());
  return http.get(`${GET_CONTRACTS_URL}?${query}`)
    .then((response) => dispatch(successGetContractTerms(response.data)))
    .catch((error) => dispatch(failureGetContractTerms(error)));
};

const successGetTestContracts = (testTerms: any) => ({
  type: 'SUCCESS_GET_TEST_CONTRACTS',
  testTerms,
});

const failureGetTestContracts = (err: any) => ({
  type: 'FAILURE_GET_TEST_CONTRACTS',
  error: err.response ? err.response.data : 'Network Error',
  status: err.response ? err.response.status : 503,
});

export const getTestContracts = () => (dispatch: any) => http.get(`${GET_CONTRACTS_URL}/test`)
  .then((response) => dispatch(successGetTestContracts(response.data)))
  .catch((error) => dispatch(failureGetTestContracts(error)));

export const setTermsError = (message: string) => ({
  type: 'SET_TERMS_ERROR',
  message,
});
