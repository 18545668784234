import React from 'react';
import { Elements } from 'react-stripe-elements';

import Cart from './Cart';
import CheckoutForm from './CheckoutForm';

import { HiClydeCartLineItem, AvailableItemType } from '../../types/LineItemTypes';

interface CheckoutMainProps {
  availableItems: AvailableItemType[];
  customer: any;
  error: any;
  hiClydeLinkSource: string | null;
  isUser: boolean;
  isProcessing: boolean;
  lineitems: HiClydeCartLineItem[];
  removedItems: AvailableItemType[];
  tax: number;
  total: any;
  totalContracts: number;
  _checkout: any;
  _reAddItem: any;
  _removeItem: any;
  _updateOrder: any;
}

const CheckoutMain = (props: CheckoutMainProps) => {
  const {
    availableItems,
    customer,
    error,
    hiClydeLinkSource,
    isProcessing,
    isUser,
    lineitems,
    removedItems,
    tax,
    total,
    totalContracts,
    _checkout,
    _reAddItem,
    _removeItem,
    _updateOrder,
  } = props;

  return (
    <div className='checkout-container__checkout'>
      {
        availableItems.length === 0 && removedItems.length === 0 && !isProcessing
          ? <div className='checkout-container__checkout__nothing'>
              Unfortunately our warranty partners no longer provide
              protection for the product you are looking to cover.
              We apologize for the inconvenience.
            </div>
          : <React.Fragment>
              <div className='checkout__left'>
                <div className='checkout__left__header'>
                  <h2>Add Coverage to Your Purchase</h2>
                </div>

                <Cart
                  availableItems={ availableItems }
                  removedItems={ removedItems }
                  tax={ tax }
                  total={ total }
                  totalContracts= { totalContracts }
                  _reAddItem={ _reAddItem }
                  _removeItem={ _removeItem }
                  _updateOrder={ _updateOrder }
                />
              </div>

              <div className='checkout__right'>
                <Elements>
                  <CheckoutForm
                    customer={ customer }
                    error={ error }
                    hiClydeLinkSource={ hiClydeLinkSource }
                    isProcessing={ isProcessing }
                    isUser={ isUser }
                    lineitems={ lineitems }
                    tax={ tax }
                    total={ total }
                    totalItems={ availableItems.length }
                    _checkout={ _checkout }
                  />
                </Elements>
              </div>
            </React.Fragment>
      }
    </div>
  );
};

export default CheckoutMain;
