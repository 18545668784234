import React from 'react';

interface ClaimCardProps {
  Image: any;
  title: string;
  text: string;
}

const ClaimCard: React.FC<ClaimCardProps> = (props) => {
  const { Image, title, text } = props;
  return (
    <div className='file-claim-handoff__modal__cards__card'>
      <div className='file-claim-handoff__modal__cards__card__image'>
        <Image />
      </div>
      <div className='file-claim-handoff__modal__cards__card__title'>
        <h5>{ title }</h5>
      </div>
      <div className='file-claim-handoff__modal__cards__card__text'>
        <p>{ text }</p>
      </div>
    </div>
  );
};

export default ClaimCard;
