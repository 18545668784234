import React from 'react';
import { withRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import stripeConfig from '../../config/stripe';

import CheckoutSuccess from './CheckoutSuccess';
import CoBranding from '../Shared/CoBranding';
import LoggedOutFooter from '../Shared/LoggedOutFooter';
import CheckoutMain from './CheckoutMain';
import UserHeader from '../../containers/UserHeader';

import { HiClydeCartLineItem, AvailableItemType } from '../../types/LineItemTypes';

interface CheckoutProps {
  availableItems: AvailableItemType[];
  cartInfo: any;
  customer: any;
  error: any;
  hiClydeLinkSource: string | null;
  history?: any;
  isProcessing: boolean;
  isSuccess: boolean;
  isUser: boolean;
  lineitems: HiClydeCartLineItem[];
  location?: any;
  match?: any;
  removedItems: AvailableItemType[];
  tax: number;
  total: any;
  totalContracts: number;
  _cancelModal: any;
  _checkHiClydeLinkSource: any;
  _checkout: any;
  _clearRemovedItems: any;
  _getAvailableContracts: any;
  _getCartInfo: any;
  _inflateLocalCart: any;
  _reAddItem: any;
  _removeItem: any;
  _resetCheckout: any;
  _setHiClydeLinkSource: any;
  _updateOrder: any;
}

class Checkout extends React.Component<CheckoutProps, any> {
  constructor(props: CheckoutProps) {
    super(props);
    this.state = {
      firstLoad: true,
    };
  }

  componentDidMount() {
    const {
      availableItems,
      _getCartInfo,
      _checkHiClydeLinkSource,
      _setHiClydeLinkSource,
      location: { search: paramsString },
      match: {
        params: {
          cartID,
          orderID,
          slug,
        },
      },
    } = this.props;

    document.title = 'Clyde | Checkout';
    window.scrollTo(0, 0);

    // Read link source from query; set to sessionStorage (will validate on backend)
    const queryParams = new URLSearchParams(paramsString);
    const hiClydeLinkSource = queryParams.get('source');
    if (hiClydeLinkSource) {
      _setHiClydeLinkSource(hiClydeLinkSource.toLowerCase());
    } else {
      _checkHiClydeLinkSource();
    }

    // If cart id, coming from link, gets the whole cart
    if (cartID || (slug && orderID)) {
      _getCartInfo({ cartID, slug, orderID });
      this.setState({ firstLoad: false });
    } else if (availableItems.length > 0) {
      // If no cart id, this is coming from an internal click and just loading more line item info
      const lastItem = availableItems[availableItems.length - 1];
      const { lineitemId } = lastItem;
      this.props._getAvailableContracts(lineitemId, true);
    }
  }

  componentWillUnmount() {
    const { _clearRemovedItems } = this.props;
    _clearRemovedItems();
  }

  resetAndGoToProducts = () => {
    const {
      history,
      _resetCheckout,
    } = this.props;

    _resetCheckout();
    history.push('/products');
  };

  render() {
    const {
      availableItems,
      cartInfo,
      customer,
      error,
      hiClydeLinkSource,
      isProcessing,
      isSuccess,
      isUser,
      lineitems,
      removedItems,
      tax,
      total,
      totalContracts,
      _cancelModal,
      _checkout,
      _reAddItem,
      _removeItem,
      _resetCheckout,
      _updateOrder,
    } = this.props;
    const { firstLoad } = this.state;

    if (availableItems.length === 0 && removedItems.length === 0 && !isProcessing && !firstLoad) {
      this.resetAndGoToProducts();
      return <div></div>;
    }

    return (
      <StripeProvider apiKey={ `${stripeConfig.API_KEY}` } >
        <React.Fragment>
          <main className='app-container'>
            <UserHeader activeTab='checkout' />

            <section className='checkout-container'>
              <CoBranding imgSrc={ cartInfo.shopLogo } />
              <CheckoutMain
                availableItems={ availableItems }
                customer={ customer }
                error={ error }
                hiClydeLinkSource={ hiClydeLinkSource }
                isUser={ isUser }
                isProcessing={ isProcessing }
                lineitems={ lineitems }
                removedItems={ removedItems }
                tax={ tax }
                total={ total }
                totalContracts={ totalContracts }
                _checkout={ _checkout }
                _reAddItem={ _reAddItem }
                _removeItem={ _removeItem }
                _updateOrder={ _updateOrder }
              />
            </section>

            <aside className='app-underlay'></aside>

            { isSuccess
              && <CheckoutSuccess
                isPlural={ totalContracts > 1 }
                _resetCheckout={ _resetCheckout }
                _closeModal={ _cancelModal }
                isUser={ isUser }
              />
            }
          </main>
          <LoggedOutFooter />
        </React.Fragment>
      </StripeProvider>
    );
  }
}

// @ts-ignore
export default withRouter(Checkout);
