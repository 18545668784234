const defaultState = {
  claims: [],
  newClaim: {},
  error: null,
  claimFormSuccess: false,
  clearClaimForm: false,
  isProcessing: false,
  resolutionPending: false,
  resolutionError: {},
  resolutionSuccess: false,
  issueTypes: [],
  issueTypesProcessing: false,
  claim: null,
};

const claimReducer = function user(state = defaultState, action:any) {
  switch (action.type) {
    case 'LOADING_GET_CLAIMS':
      return {
        ...state,
      };
    case 'FAIL_GET_CLAIM':
      return {
        ...state,
        error: action.error,
      };
    case 'UPDATE_GET_CLAIM':
      return {
        ...state,
        claim: action.claim,
      };
    case 'LOADING_CREATE_CLAIM':
      return {
        ...state,
        claimFormSuccess: false,
        isProcessing: true,
      };
    case 'UPDATE_CREATE_CLAIM':
      return {
        ...state,
        claimFormSuccess: true,
        isProcessing: false,
        userClaims: [
          ...state.claims,
        ],
        newClaim: {
          ...action.claim,
          discount: action.claimInfo.discount,
          discountPromoCode: action.claimInfo.discountPromoCode,
          promoCode: action.claimInfo.promoCode,
          promoCodeValue: action.claimInfo.resolutionCost,
          rejectionDiscount: action.claimInfo.rejectionDiscount,
          resolutionCost: action.claimInfo.resolutionCost,
          serviceType: action.claimInfo.serviceType,
          programType: action.claimInfo.programType,
        },
      };
    case 'FAIL_CREATE_CLAIM':
      return {
        ...state,
        claimFormSuccess: false,
        isProcessing: false,
        error: action.error,
      };
    case 'RESET_CLAIM_FORM':
      return {
        ...state,
        claimFormSuccess: false,
        error: null,
        newClaim: {},
      };
    case 'CANCEL_MODAL':
      return {
        ...state,
        claimFormSuccess: false,
        clearClaimForm: true,
        error: null,
        newClaim: {},
      };
    case 'CLAIM_RESOLUTION_PENDING':
      return {
        ...state,
        resolutionPending: true,
      };
    case 'CLAIM_RESOLUTION_SUCCESS':
      return {
        ...state,
        resolutionPending: false,
        resolutionSuccess: action.data.resolutionSuccess,
      };
    case 'CLAIM_RESOLUTION_FAILURE':
      return {
        ...state,
        resolutionPending: false,
        resolutionError: action.error,
      };
    case 'RESET_CLAIM_RESOLUTION_SUCCESS':
      return {
        ...state,
        resolutionSuccess: false,
      };
    case 'RESET_SELECTED_CLAIM':
      return {
        ...state,
        claim: null,
      };
    case 'LOADING_GET_ISSUE_TYPES':
      return {
        ...state,
        issueTypesProcessing: true,
      };
    case 'UPDATE_GET_ISSUE_TYPES':
      return {
        ...state,
        issueTypes: action.issueTypes,
        issueTypesProcessing: false,
      };
    case 'FAIL_GET_ISSUE_TYPES':
      return {
        ...state,
        error: action.error,
        issueTypesProcessing: false,
      };
    default:
      return state;
  }
};

export default claimReducer;
