import React, { useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { ReactComponent as QuestionCicle } from '../../../images/question-circle.svg';
import { ReactComponent as ChatIcon } from '../../../images/chat-help.svg';

import { getLocalTimeFormatFromDate } from '../../../utils/timeUtils';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

interface ContractDatesModalProps {
  product: any;
  agnosticExpires: string;
}

const ContractDatesModal = (props: ContractDatesModalProps) => {
  const {
    product,
    agnosticExpires,
  } = props;

  const {
    shipDate,
    deliveryDate,
    reportsShip,
    reportsDelivery,
  } = product;

  const [showEffectiveNote, setEffectiveInfo] = useState(false);
  const toggleEffectiveInfo = (e: any) => {
    if (e.type === 'keydown' && e.key !== 'Enter') {
      return;
    }
    setEffectiveInfo(!showEffectiveNote);
  };

  const setDate = (dateValue?: string) => {
    if (!dateValue) {
      return '';
    }
    return dayjs(dateValue).utc().local().format('MM/DD/YYYY');
  };

  let effectiveType = reportsShip && shipDate ? 'shipment' : 'purchase';
  effectiveType = reportsDelivery && deliveryDate ? 'delivery' : effectiveType;

  const contractEffectiveDate = getLocalTimeFormatFromDate(product.contractEffectiveDate) || 'Pending';
  const contractExpiresDate = product.contractEffectiveDate ? setDate(agnosticExpires) : 'Pending';

  let contractProgress = 0;
  if (contractExpiresDate !== 'Pending' && contractEffectiveDate !== 'Pending') {
    const now = Date.now();

    let effectiveMilli: any = new Date(contractEffectiveDate);
    effectiveMilli = effectiveMilli.getTime();

    let expiresMilli: any = new Date(contractExpiresDate);
    expiresMilli = expiresMilli.getTime();

    contractProgress = (now - effectiveMilli) / (expiresMilli - effectiveMilli);
    contractProgress *= 100;
  }

  const contractDates = [
    {
      date: setDate(product.purchaseDate),
      modifierClass: '',
      label: 'Date of Purchase',
      key: `contract-date-${product.id}-purchase`,
    },
    {
      date: product.reportsShip && setDate(product.shipDate),
      modifierClass: '',
      label: 'Order Shipped',
      key: `contract-date-${product.id}-shipped`,
    },
    {
      date: product.reportsDelivery && setDate(product.deliveryDate),
      modifierClass: '',
      label: 'Order Fulfilled',
      key: `contract-date-${product.id}-delivered`,
    },
    {
      date: contractEffectiveDate,
      modifierClass: 'date-list__item--effective',
      label: <>
        Contract Start Date
        <span className='date-list__item--effective__term--desktop-only' data-test='term'>&nbsp;({product.termLabelSingular} Term)</span>
      </>,
      key: `contract-date-${product.id}-effective`,
    },
    {
      date: contractExpiresDate,
      modifierClass: 'date-list__item--expires',
      label: `Contract Expires `,
      key: `contract-date-${product.id}-expires`,
    },
  ];

  return (
    <div className='contract-dates-modal__container' data-test='contractHistoryModal'>
      <h3>Contract History</h3>
      <ul className='contract-dates-modal__date-list'>
        { contractDates.map((dateItem) => dateItem.date && (
          <li
            className={ `date-list__item ${dateItem.modifierClass}` }
            key={ dateItem.key }
            data-test= { `historyItem-${dateItem.key}` }
          >
            <div className='date-list__item__line'>
              <div
                className='date-list__item__line date-list__item__line--progress'
                style={ { height: `${contractProgress}%` } }
              />
            </div>
            <span className='date-list__item__dot' />
            <span className='date-list__item__date'>({ dateItem.date }):</span>
            <span>{ dateItem.label }</span>
            { dateItem.modifierClass === 'date-list__item--effective'
              && <>
                <div className='date-list__item--effective__term--mobile-only'>({product.termLabelSingular} Term)</div>
                <div className='date-list__item__effective-info'>
                  { dateItem.date === 'Pending'
                    ? <span>Your contract start date is still processing, check back in a few days for an update.</span>
                    : <>
                      <span
                        className='date-list__item__effective-info__button'
                        onClick={ toggleEffectiveInfo }
                        onKeyDown={ toggleEffectiveInfo }
                        aria-label='show more info button'
                        aria-role='button'
                        tabIndex={ 1 }
                        data-test='moreInfo'
                      >
                        <QuestionCicle />More info on your contract start date
                      </span>
                      { showEffectiveNote
                        && <span className='date-list__item__effective-info__more' data-test='moreInfoCard'>
                          To ensure you get the full value of your coverage,
                          this contract is effective based on the date of { effectiveType }.
                        </span>
                      }
                    </>
                  }
                </div>
              </>
            }
          </li>
        ))}
      </ul>
      { !product.hideClaimSupportLink
        && <div className='contract-dates-modal__help' data-test='contractQuestions'>
          <ChatIcon />
          <p>
            Have more questions about your contract? <a href='https://clydetechnologies.zendesk.com/hc/en-us/requests/new' data-test='contactSupport'>
              Contact Support
            </a>
          </p>
        </div>
    }
    </div>
  );
};

export default ContractDatesModal;
