import React from 'react';
import classNames from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import {
  Button, Form, FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';
import { ReactComponent as LoadingBars } from '../../images/loading-bars.svg';

interface LoginFormProps {
  _authUser: any;
  _googleOauthUser: any;
  _failGoogleOauthUser: any;
  _clearAuthErrors: any;
  _resendVerify: any;
  user: any;
  error: any;
  csrf: string;
  isProcessing: boolean;
  changeSuccess: any;
}

interface LoginFormState {
  email?: string;
  password?: string;
  isRememberMe?: boolean;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props:LoginFormProps) {
    super(props);
    this.state = {
      email: localStorage.getItem('savedEmail') || '',
      password: '',
      isRememberMe: JSON.parse(localStorage.getItem('isRememberMe') || 'false'),
    };
  }

  componentDidMount() {
    document.title = 'Clyde | Login';
    let attempts = 0;

    const interval = setInterval(() => {
      attempts += 1;
      if (attempts > 10) {
        console.warn('Too many tries to load login', attempts);
        clearInterval(interval);
        return;
      }
      // @ts-ignore
      if (window.gapi && window.gapi.auth2?.getAuthInstance()) {
        // @ts-ignore
        const auth2 = window.gapi.auth2?.getAuthInstance();

        auth2.attachClickHandler('custom-google-signin-button', {
          cookiepolicy: 'single_host_origin',
          scope: 'profile email', // might not be needed
        }, this.onSuccess.bind(this), this.onFailure.bind(this));

        clearInterval(interval);
      }
    }, 100);
  }

  onSuccess = (googleUser: any) => {
    // eslint-disable-next-line camelcase
    const { id_token } = googleUser.getAuthResponse();
    // eslint-disable-next-line camelcase
    if (id_token) {
      this.props._googleOauthUser(id_token);
    }
  };

  onFailure(error: any) {
    this.props._failGoogleOauthUser();
  }

  componentWillUnmount() {
    this.props._clearAuthErrors();
  }

  handleChange = (event: any) => {
    if (event.currentTarget.name === 'rememberMe') {
      localStorage.setItem('isRememberMe', event.target.checked);
      this.setState({ isRememberMe: event.target.checked });
    }

    this.setState({
      [event.currentTarget.name]: event.target.value,
    });
  };

  handleSubmit = (event: any) => {
    this.props._authUser({
      email: this.state.email,
      password: this.state.password,
    }, this.props.csrf);
    event.preventDefault();
  };

  handleResend = (event: any) => {
    this.props._resendVerify(this.state.email);
  };

  render() {
    const lastPath = localStorage.getItem('redirectPath') || '';
    const redirectPath = lastPath || '/products';

    const {
      isProcessing, error, isLoggedIn, emailVerified,
    } = this.props.user;
    const { changeSuccess } = this.props;

    const needVerifyLink = changeSuccess.resend
      ? <div>A link will be provided to verify your account.</div>
      : <div className='fake-link' onClick={ this.handleResend }>Click here to resend verification email.</div>;

    const needVerifyBlock = <React.Fragment>
      <h2 className='auth-form-container__title'>Account needs verification.</h2>
      <p>Please check your email to verify your account.</p>
      { needVerifyLink }
    </React.Fragment>;

    const formBlock = <React.Fragment>
      <h1 className='auth-form-container__title' data-test='authFormTitle'>
        Log in to view protection plans
      </h1>
        <div className='auth-form-container__subsection'>
          <button className='auth-form-container__form__google-button' id='custom-google-signin-button' data-test='googleAuthButton'>
            <span className='auth-form-container__form__google-button__google-icon'></span>
            <span className='auth-form-container__subsection--text auth-form-container__subsection--login-text'>Log in with Google</span>
          </button>
          {
          this.props.error.clientDesc && this.props.error.fields && this.props.error.fields.indexOf('google_auth') > -1
            ? <div className='clyde-form-feedback clyde-form-feedback--error clyde-form-feedback--spacing'>
                { this.props.error.split['.'].length < 2 ? this.props.error : this.props.error }
                </div>
            : ''
          }
        </div>
      <Form className='auth-form-container__form'>
        <div className='auth-form-container__form__divider auth-form-container__subsection'><span>OR</span></div>
        <FormGroup className='auth-form-container__subsection'>
          <Label for='email'>Email</Label>
          <Input
            invalid={ error.fields?.indexOf('email') > -1 || error.fields?.indexOf('form') > -1}
            className={classNames({ 'input-errored': error.fields?.indexOf('email') > -1 || error.fields?.indexOf('form') > -1 })}
            type='email'
            name='email'
            id='email'
            data-test='email'
            placeholder='name@email.com'
            value={ this.state.email }
            onChange={ this.handleChange } />
            <FormFeedback>{ error.fields?.indexOf('email') > -1 && error.clientDesc }</FormFeedback>
        </FormGroup>
        <FormGroup className='auth-form-container__subsection'>
          <Label for='password'>Password</Label>
          <Input
            invalid={error.fields?.indexOf('password') > -1 || error.fields?.indexOf('form') > -1}
            type='password'
            name='password'
            id='password'
            data-test='password'
            placeholder='password'
            value={ this.state.password }
            onChange={ this.handleChange } />
          <FormFeedback>{ error.fields?.indexOf('password') > -1 && error.clientDesc }</FormFeedback>
          {
            this.props.error.clientDesc && this.props?.error?.fields?.indexOf('form') > -1 && (
              <div className='clyde-form-feedback clyde-form-feedback--error'>
                {
                  this.props.error.clientDesc
                    ?.split('.')
                    ?.map((value : any, index: any, { length } : any) => (
                  <span key={`login-form-error-${index}`}>
                    {`${value}.`}
                    {index < (length - 2) && <br/>}
                  </span>))
                }
              </div>
            )
          }
        </FormGroup>
        <div className='auth-form-container__subsection auth-form-container__subsection--single-line'>
          <FormGroup className='auth-form-container__subsection--checkbox'>
            <Input
              type='checkbox'
              name='rememberMe'
              data-test='rememberMeCheckbox'
              aria-label='Remember me'
              checked={ this.state.isRememberMe }
              onChange={ this.handleChange }
            />
            <div className='auth-form-container__subsection--text auth-form-container__subsection--remember-me'>Remember me</div>
          </FormGroup>
          <Link to='/request-pw' className='auth-form-container__subsection--text auth-form-container__subsection--link' data-test='forgotPassword'>Forgot your password?</Link>
        </div>
        <Button
          id='login-button'
          data-test='login'
          className='button--general auth-form-container__form__button'
          onClick={this.handleSubmit}
          disabled={ !!isProcessing }
          type='submit'>
          { isProcessing ? <LoadingBars /> : 'Log in' }
        </Button>
        <div className='auth-form-container__not-a-user'>
          <Link id='signup' to='/signup' className='auth-form-container__subsection--text auth-form-container__subsection--link' data-test='signUp'>Don’t have an account?<strong>{` Sign up`}</strong></Link>
          <Link id='continue-guest' to='/get-contract' className='auth-form-container__subsection--text auth-form-container__subsection--link' data-test='continueAsGuest'>Continue as guest</Link>
        </div>
      </Form>
    </React.Fragment>;

    return (
      <section className='auth-form-container'>
        {
          (isLoggedIn && emailVerified)
            ? <Redirect to={ redirectPath } />
            : ''
        }
        {
          (isLoggedIn && !emailVerified)
            ? needVerifyBlock
            : formBlock
        }
      </section>
    );
  }
}

export default LoginForm;
