import http from '../config/http';

const GET_USER_DETAILS_URL = '/user';
const CHANGE_PROFILE_URL = '/user';
const UPDATE_BILLING_URL = '/user/billing';
const CREATE_USER_URL = '/user';
const AUTH_USER_URL = '/user/auth';
const GOOGLE_OAUTH_URL = '/user/oauth';
const REQUEST_PASSWORD_URL = '/user/request-pw';
const CHANGE_PASSWORD_URL = '/user/change-pw';
const CHANGE_PASSWORD_LOGGED_IN = '/user/change-pw-in';
const RESEND_VERIFY_LINK_URL = '/user/re-send-link';
const LOGOUT_URL = '/user/logout';
const CSRF_URL = '/user/_csrf';

export function checkLogin() {
  return {
    type: 'CHECK_LOGIN',
  };
}

export function updateCreateUser(user:any) {
  return {
    type: 'UPDATE_CREATE_USER',
    user,
  };
}

export function loadingCreateUser() {
  return {
    type: 'LOADING_CREATE_USER',
  };
}

export function failedCreateUser(err:any) {
  return {
    type: 'FAIL_CREATE_USER',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function createUser(user:any, csrf: string) {
  return (dispatch:any) => {
    dispatch(loadingCreateUser());
    return http.post(CREATE_USER_URL, {
      ...user,
      csrf,
    })
      .then((response) => {
        dispatch(updateCreateUser(response.data));
      })
      .catch((err) => {
        dispatch(failedCreateUser(err));
      });
  };
}

// Auth User
export function updateAuthUser(user:any) {
  return {
    type: 'UPDATE_AUTH_USER',
    user,
  };
}

export function loadingAuthUser() {
  return {
    type: 'LOADING_AUTH_USER',
  };
}

export function failedAuthUser(err:any) {
  return {
    type: 'FAIL_AUTH_USER',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function authUser(user:any, csrf: string) {
  return (dispatch:any) => {
    dispatch(loadingAuthUser());
    return http.post(AUTH_USER_URL, {
      ...user,
      csrf,
    })
      .then((response) => {
        dispatch(updateAuthUser(response.data));
      })
      .catch((err) => {
        dispatch(failedAuthUser(err));
      });
  };
}

export function googleOauthUser(token: string) {
  return (dispatch:any) => (
    // dispatch(loadingAuthUser());
    http.post(GOOGLE_OAUTH_URL, {
      token,
    })
      .then((response) => {
        dispatch(updateAuthUser(response.data));
      })
      .catch((err) => {
        dispatch(failedAuthUser(err));
      }));
}

export function failGoogleOauthUser() {
  return {
    type: 'FAIL_GOOGLE_OAUTH_USER',
  };
}

// Request new password
export function updateRequestPassword(email: string) {
  return {
    type: 'UPDATE_REQUEST_PASSWORD',
    email,
  };
}

// Reset PW reset request status
export function setPasswordResetSuccessFalse() {
  return {
    type: 'SET_PW_RESET_SUCCESS_FALSE',
  };
}

// Reset PW change status
export function setPasswordChangeSuccessFalse() {
  return {
    type: 'SET_PW_CHANGE_SUCCESS_FALSE',
  };
}

export function loadingRequestPassword() {
  return {
    type: 'LOADING_REQUEST_PASSWORD',
  };
}

export function failedRequestPassword(err:any) {
  return {
    type: 'FAIL_REQUEST_PASSWORD',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function requestPassword(user:any, csrf: string) {
  return (dispatch:any) => {
    dispatch(loadingRequestPassword());
    return http.put(REQUEST_PASSWORD_URL, {
      ...user,
      csrf,
    })
      .then((response) => {
        dispatch(updateRequestPassword(response.data));
      })
      .catch((err) => {
        dispatch(failedRequestPassword(err));
      });
  };
}

// Get user details
export function updateGetUserDetails(user:any) {
  return {
    type: 'UPDATE_GET_USER_DETAILS',
    user,
  };
}

export function loadingGetUserDetails() {
  return {
    type: 'LOADING_GET_USER_DETAILS',
  };
}

export function failedGetUserDetails(err:any) {
  return {
    type: 'FAIL_GET_USER_DETAILS',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getUserDetails() {
  return (dispatch:any) => {
    dispatch(loadingGetUserDetails());
    return http.get(GET_USER_DETAILS_URL)
      .then((response) => {
        dispatch(updateGetUserDetails(response.data));
      })
      .catch((err) => {
        dispatch(failedGetUserDetails(err));
      });
  };
}

// Change Profile
export function updateChangeProfile(user:any) {
  return {
    type: 'UPDATE_CHANGE_PROFILE',
    user,
  };
}

export function loadingChangeProfile() {
  return {
    type: 'LOADING_CHANGE_PROFILE',
  };
}

export function failedChangeProfile(err:any) {
  return {
    type: 'FAIL_CHANGE_PROFILE',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function changeProfile(user:any) {
  return (dispatch:any) => {
    dispatch(loadingChangeProfile());
    return http.put(CHANGE_PROFILE_URL, {
      ...user,
    })
      .then((response) => {
        dispatch(updateChangeProfile(response.data));
        dispatch(getUserDetails());
      })
      .catch((err) => {
        dispatch(failedChangeProfile(err));
      });
  };
}

// Change Password
export function updateChangePassword(user:any) {
  return {
    type: 'UPDATE_CHANGE_PASSWORD',
    user,
  };
}

export function loadingChangePassword() {
  return {
    type: 'LOADING_CHANGE_PASSWORD',
  };
}

export function failedChangePassword(err:any) {
  return {
    type: 'FAIL_CHANGE_PASSWORD',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function changePassword(pwDetails:any, csrf: string) {
  return (dispatch:any) => {
    dispatch(loadingChangePassword());
    return http.put(CHANGE_PASSWORD_URL, {
      ...pwDetails,
      csrf,
    })
      .then((response) => {
        dispatch(updateChangePassword(response.data));
      })
      .catch((err) => {
        dispatch(failedChangePassword(err));
      });
  };
}

// Re-send verify link
export function updateResendVerify(user:any) {
  return {
    type: 'UPDATE_RESEND_VERIFY',
  };
}

export function loadingResendVerify() {
  return {
    type: 'LOADING_RESEND_VERIFY',
  };
}

export function failedResendVerify(err:any) {
  return {
    type: 'FAIL_RESEND_VERIFY',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function resendVerify(email:string, csrf: string) {
  return (dispatch:any) => {
    dispatch(loadingResendVerify());
    return http.put(RESEND_VERIFY_LINK_URL, {
      email,
      csrf,
    })
      .then((response) => {
        dispatch(updateResendVerify(response.data));
      })
      .catch((err) => {
        dispatch(failedResendVerify(err));
      });
  };
}

export function changePasswordLoggedIn(pwDetails:any) {
  return (dispatch:any) => {
    dispatch(loadingChangePassword());
    return http.put(CHANGE_PASSWORD_LOGGED_IN, {
      ...pwDetails,
    })
      .then((response) => {
        dispatch(updateChangePassword(response.data));
      })
      .catch((err) => {
        dispatch(failedChangePassword(err));
      });
  };
}

// Clear account messages
export function clearAccountMessages() {
  return {
    type: 'CLEAR_ACCOUNT_MESSAGES',
  };
}

// Clear auth error messages
export function clearAuthErrors() {
  return {
    type: 'CLEAR_AUTH_ERRORS',
  };
}

// Logout
export function updateLogout() {
  return {
    type: 'UPDATE_LOGOUT',
  };
}

export function loadingLogout() {
  return {
    type: 'LOADING_LOGOUT',
  };
}

export function failedLogout(err:any) {
  return {
    type: 'FAIL_LOGOUT',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function clearLogoutFlash() {
  return {
    type: 'CLEAR_LOGOUT_FLASH',
  };
}

// Get/Set CSRF
export function updateGetCSRF(csrf:any) {
  return {
    type: 'UPDATE_GET_CSRF',
    csrf,
  };
}

export function loadingGetCSRF() {
  return {
    type: 'LOADING_GET_CSRF',
  };
}

export function failedGetCSRF(err:any) {
  return {
    type: 'FAIL_GET_CSRF',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getCSRF() {
  return (dispatch:any) => {
    dispatch(loadingGetCSRF());
    return http.get(`${CSRF_URL}`)
      .then((response) => {
        dispatch(updateGetCSRF(response.data));
      })
      .catch((err) => {
        dispatch(failedGetCSRF(err));
      });
  };
}

export function logout() {
  return (dispatch:any) => {
    dispatch(loadingLogout());
    return http.post(LOGOUT_URL)
      .then(async (response) => {
        try {
        // Clean up google logout if need be
        // @ts-ignore
          const { gapi } = window;
          if (gapi) {
            const auth2 = gapi.auth2.getAuthInstance();
            await auth2.signOut();
          }
        } catch (e) {
          console.warn('Error with google signout', e);
        } finally {
          dispatch(updateLogout());
        }
      })
      .catch((err) => {
        dispatch(failedLogout(err));
      });
  };
}

// Update billing
export function updateUserBilling(user:any) {
  return {
    type: 'UPDATE_USER_BILLING',
    user,
  };
}

export function loadingUserBilling() {
  return {
    type: 'LOADING_USER_BILLING',
  };
}

export function failedUserBilling(err:any) {
  return {
    type: 'FAIL_USER_BILLING',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function submitUserBilling(billingInfo: {
  plaidToken: string;
  metaData: any;
}) {
  return (dispatch:any) => {
    dispatch(loadingUserBilling());
    return http.put(UPDATE_BILLING_URL, {
      ...billingInfo,
    })
      .then((response) => {
        dispatch(updateUserBilling(response.data));
        dispatch(getUserDetails());
      })
      .catch((err) => {
        dispatch(failedUserBilling(err));
      });
  };
}
