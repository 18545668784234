import http from '../config/http';

const GET_CONTRACT_URL = '/contract';

export function updateGetContract(contract:any) {
  return {
    type: 'UPDATE_GET_CONTRACT',
    contract,
  };
}

export function loadingGetContract() {
  return {
    type: 'LOADING_GET_CONTRACT',
  };
}

export function failedGetContract(err:any) {
  return {
    type: 'FAIL_GET_CONTRACT',
    error: err.response ? err.response.data : 'Network Error',
    status: err.response ? err.response.status : 503,
  };
}

export function getContract(hash: string) {
  return (dispatch:any) => {
    dispatch(loadingGetContract());
    return http.get(`${GET_CONTRACT_URL}/${hash}`)
      .then((response) => {
        dispatch(updateGetContract(response.data));
      })
      .catch((err) => {
        dispatch(failedGetContract(err));
      });
  };
}

export function resetSelectedContract() {
  return {
    type: 'RESET_SELECTED_CONTRACT',
  };
}
