import React from 'react';

import AdjustableBedBase5Yr from './AdjustableBedBase5Yr';
import AdjustableBedBase10Yr from './AdjustableBedBase10Yr';
import BeddingMattress5yr from './BeddingMattress5yr';
import BeddingMattress7yr from './BeddingMattress7yr';
import BeddingMattress10yr from './BeddingMattress10yr';
import Test from '../../../../containers/TestTerms';

import { UnderwriterProps } from '../../../../types/TermsTypes';

const Uniters: React.FC<UnderwriterProps> = (props) => {
  const { contractTerms } = props;
  let Terms: any;
  switch (contractTerms.termsKey) {
    case 'adjustableBedBase5yr':
      Terms = <AdjustableBedBase5Yr contractTerms={ contractTerms } />;
      break;
    case 'adjustableBedBase10yr':
      Terms = <AdjustableBedBase10Yr contractTerms={ contractTerms } />;
      break;
    case 'beddingMattress5yr':
      Terms = <BeddingMattress5yr contractTerms={ contractTerms } />;
      break;
    case 'beddingMattress7yr':
      Terms = <BeddingMattress7yr contractTerms={ contractTerms } />;
      break;
    case 'beddingMattress10yr':
      Terms = <BeddingMattress10yr contractTerms={ contractTerms } />;
      break;
    default:
      Terms = <Test />;
      break;
  }

  return Terms;
};

export default Uniters;
