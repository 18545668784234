/* eslint-disable eqeqeq */
import React from 'react';
import { Button } from 'reactstrap';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ProductClaimsEventItem } from '../../../types/ClaimTypes';
import { claimStatusSwitcher } from '../../Shared/claimHelper';
import ClickCopy from '../../Shared/ClickCopy';
import { generateShopUrls } from '../ClaimForm/claim-utilities';
import CloseModalButton from '../../Shared/CloseModalButton';
import { ReactComponent as ChatIcon } from '../../../images/chat-help.svg';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

interface ClaimHistoryProps {
  claimHistory: ProductClaimsEventItem[];
  claimInfo: any;
  shopUrl?: string;
  _closeModal: any;
}

const ClaimHistory: React.FC<ClaimHistoryProps> = (props) => {
  const {
    claimHistory,
    claimInfo,
    shopUrl,
    _closeModal,
  } = props;

  const { modifiedUrl } = generateShopUrls(shopUrl || '');

  const hiddenStates = ['resolutionInitiated', 'resolutionOrdered', 'resolutionDelayed'];
  const adjustedHistory = claimHistory.filter((event) => hiddenStates.indexOf(event.claimState) === -1);

  const historyElements = adjustedHistory.map((historyEvent: ProductClaimsEventItem, i: number) => {
    let eventText = '';
    if (historyEvent.claimState === 'hiClydeHandoff') {
      eventText = 'Claim last updated on:';
    } else if (i === 0) {
      eventText = 'Submitted:';
    } else {
      eventText = 'Status changed on:';
    }

    let statusText = 'Status:';
    if (i === 0) {
      statusText = 'New Status:';
    }
    if (i === claimHistory.length - 1) {
      statusText = 'Current Status:';
    }

    const showPromoCodeElement = (
      claimInfo.serviceType === 'promoCode'
      && claimInfo.promoCode
      && historyEvent.claimState === 'resolutionFulfilled'
    ) || (
      claimInfo.discountPromoCode
      && historyEvent.claimState === 'denied'
    );

    const date = dayjs(historyEvent.createdAt).utc().local().format('MM/DD/YYYY');
    const status = claimStatusSwitcher(historyEvent.claimState, claimInfo.serviceType || '');

    return (
      <div className="claim-history__event__list__event" key={ `claim-history-${i}` }>
        { i > 0 && <div className="claim-history__spacer"></div> }
        <div className="claim-history__event__list__event__content">
          <span className="claim-history__dot"></span>
          <span className="claim-history__event__list__event__content__date">
            { date }:
          </span>
          <span className="claim-history__event__list__event__content__status">
            { status }
          </span>
        </div>
        { showPromoCodeElement
          && <div className='claim-history__promo-code'>
            <ClickCopy
              prefix='Code'
              copyValue={ claimInfo.promoCode || claimInfo.discountPromoCode }
              guideline='Click to copy promo code to clipboard'
              className='claim-history__click-copy'
            />
            <a href={ modifiedUrl } target='_blank' rel='noreferrer'>
              <Button className='claim-form__response__out-button button--general'>
                Visit Store
              </Button>
            </a>
          </div>
        }
      </div>
    );
  });

  return (
    <div className="claim-history__event" data-test="claimHistory">
      <div className="claim-history__event__list clyde-shadow">
        <div className="claim-history__header">
          <h3 className="modal-title">Claim History</h3>
          <CloseModalButton onClick={ _closeModal } className='clyde-modal__close history-modal__close'/>
        </div>
        <div className="claim-history__events">
          { historyElements }
        </div>
        <div className='claim-history-modal__help' data-test='contractQuestions'>
          <div>
            <ChatIcon />
            <p>
              Have more questions about your contract? <a data-test='contactSupport' href='https://clydetechnologies.zendesk.com/hc/en-us/requests/new'>
              Contact Support
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimHistory;
