import React from 'react';
import { ReactComponent as Checkmark } from '../../images/checkmark.svg';

interface ContractBulletsProps {
  bullets?: any,
  customClass?: string;
}

const ContractBullets: React.FC<ContractBulletsProps> = (props) => {
  const bullets = props.bullets || '';
  const bulletsArray = bullets.split('|');

  return (
    <div className={`bullets ${props.customClass ? props.customClass : ''}`}>
      {
        bulletsArray.map((bulletText:string, index: number) => (
          <div key={ index } className="bullets__bullet">
            <Checkmark className="bullet__checkmark" />
            <span className="bullet__text">{ bulletText }</span>
          </div>
        ))
      }
    </div>
  );
};

export default ContractBullets;
