import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as RightArrow } from '../../images/right-arrow.svg';

interface AccountNavigationProps {
  activeTab: string;
}

class AccountNavigation extends React.Component<AccountNavigationProps> {
  generateTabClass = (tabName: string) => {
    const { activeTab } = this.props;
    return classnames('account-navigation-container__nav-item', {
      active: activeTab === tabName,
    });
  };

  render() {
    const { generateTabClass } = this;
    // @ts-ignore
    const showPayoutResolution = window._env_.SHOW_CLAIM_PAYOUTS === 'true' && false;
    // we are not currently processing ACH payouts
    return (
    <section className="account-navigation-container clyde-shadow" data-test="accountNavigation">
      <Link
        to="/account/profile"
        className={ generateTabClass('profile') }
        data-test="profileLink"
      >
        <span>Profile</span>
        <span className="account-navigation-container__arrow"><RightArrow /></span>
      </Link>
      { showPayoutResolution
        && <Link
          to="/account/billing-info"
          className={ generateTabClass('billing-info') }
          data-test="billingInfoLink"
        >
          <span>Billing</span>
          <span className="account-navigation-container__arrow" ><RightArrow /></span>
        </Link>
      }
      <Link
        to="/account/password"
        className={ generateTabClass('password') }
        data-test="passwordLink"
      >
        <span>Password</span>
        <span className="account-navigation-container__arrow"><RightArrow /></span>
      </Link>
    </section>
    );
  }
}

export default AccountNavigation;
