import React from 'react';
import { ReactComponent as Target } from '../../images/target-dark.svg';
import { ReactComponent as Heart } from '../../images/heart-dark.svg';
import { ReactComponent as Puzzle } from '../../images/puzzle.svg';

const HowItWorks: React.FC = () => (
    <section className='how-section'>
      <h2 className='how-section__title h2--white' >One easy, breezy <br/> ownership experience</h2>
      <p className='how-section__text'>
        HiClyde is your one stop shop for managing your coverage, easy claims filing,
        and instant, positive resolutions. Set up your account today for a worry-free future and so much more:
      </p>
      <div className='how-bullets'>

        <div className='how-bullet-item'>
          <div className='how-bullet-item__icon'>
            <Target />
          </div>
          <div className='how-bullet-item__text'>
          <h4>One place for every product </h4>
          <p>
            No matter what brand you bought, HiClyde keeps all your products and their protection plans in one spot.
            Rest easy with a bird's eye view of all your Clyde-covered products and their coverage details.
          </p>
          </div>
        </div>

        <div className='how-bullet-item'>
          <div className='how-bullet-item__icon'>
            <Heart />
          </div>
          <div className='how-bullet-item__text'>
            <h4>Peace of mind for all your people</h4>
            <p>If you bought your product (and protection plan) with someone else in mind,
              HiClyde makes it easy to give the gift of serenity, too.
              In just a couple of clicks, transfer ownership of your Clyde contract to anyone you like.
            </p>
          </div>
        </div>

        <div className='how-bullet-item'>
          <div className='how-bullet-item__icon'>
            <Puzzle />
          </div>
          <div className='how-bullet-item__text'>
            <h4>Your ownership experience, accessorized</h4>
            <p>
            Once you've created an account, it's a piece of cake to buy accessories, another product,
            and protection plans for all eligible purchases — right in HiClyde.
            Just a couple of clicks, and you're on your way.
            </p>
          </div>
        </div>
      </div>
    </section>
);

export default HowItWorks;
