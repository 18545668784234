import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Form, FormGroup, Label, Input,
} from 'reactstrap';
import { ReactComponent as LoadingBars } from '../../images/loading-bars.svg';

interface ChangePasswordProps {
  _changePassword: any;
  _clearAuthErrors: any;
  _setPasswordChangeSuccessFalse: any;
  user: any;
  error: any;
  csrf: string;
}

interface ChangePasswordState {
  passwordConfirm?: string,
  password?: string,
}

class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordState> {
  constructor(props:ChangePasswordProps) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: '',
    };
  }

  componentDidMount() {
    document.title = 'Clyde | Reset Password';
  }

  componentWillUnmount() {
    this.props._clearAuthErrors();
  }

  handleChange = (event:any) => {
    this.setState({
      [event.currentTarget.name]: event.target.value,
    });
  };

  handleSubmit = (event:any) => {
    if (!this.props.user.changeSuccess.pw) {
      this.props._changePassword(this.state, this.props.csrf);
    }
    event.preventDefault();
  };

  render() {
    const form = <div>
      <h2 className='auth-form-container__title' >Reset Password</h2>
      <p className='auth-form-container__copy' >
        Reset your password to access your products and contracts.
      </p>
      <Form className='auth-form-container__form' >
        <FormGroup>
          <Label for='password'>New Password</Label>
          <Input
            type='password' name='password'
            id='password' placeholder=''
            value={this.state.password} onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for='passwordConfirm'>Confirm Password</Label>
          <Input
            type='password' name='passwordConfirm'
            id='passwordConfirm' placeholder=''
            value={this.state.passwordConfirm} onChange={this.handleChange}
          />
        </FormGroup>
        {
          this.props.error.clientDesc
            ? <div className='clyde-form-feedback clyde-form-feedback--error'>{ this.props.error.clientDesc }</div>
            : ''
        }
        <Button
          className={ `button--general auth-form-container__form__button${this.props.user.changeSuccess.pw ? ' auth-form-container__form__button--success' : ''}` }
          onClick={this.handleSubmit}
          disabled={ !!this.props.user.isProcessing }
          >
          { this.props.user.isProcessing ? <LoadingBars /> : 'Reset Password' }
        </Button>
        <div className='auth-form-container__subsection auth-form-container__subsection--change-pw'>
          <Link to='/login' className='clyde-link'>Back to Login</Link>
        </div>
      </Form>
    </div>;

    return (
    <section className='auth-form-container' >
        {
          this.props.user.changeSuccess.pw
            ? <div className='clyde-form-feedback clyde-form-feedback--success'>
            <h2>Your password has been successfully reset.</h2>
            <Link to='/login' className='clyde-link'>Login with your new Password</Link>
          </div>
            : form
        }
    </section>
    );
  }
}

export default ChangePassword;
