export const clearError = () => ({
  type: 'CLEAR_ERROR',
  payload: {},
});

export const setError = (error: string) => ({
  type: 'SET_ERROR',
  payload: {
    name: error,
  },
});
const defalutExportValue = {};
export default defalutExportValue;
