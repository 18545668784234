const defaultState = {
  emailVerified: false,
  isLoggedIn: false,
  details: {
    email: '',
    fullName: '',
    phoneNumber: '',
    hasBankAccountConnected: false,
  },
  isProcessing: false,
  passwordResetSuccess: false,
  csrf: '',
  error: {},
  changeSuccess: {},
  billingSuccess: {},
  logoutFlash: false,
};

// eslint-disable-next-line default-param-last
const userReducer = function user(state = defaultState, action:any) {
  const isUser = localStorage.getItem('isUser');
  switch (action.type) {
    case 'LOADING_CREATE_USER':
      return {
        ...defaultState,
        isProcessing: true,
        error: {},
      };
    case 'UPDATE_CREATE_USER':
      return {
        ...state,
        isProcessing: false,
        isLoggedIn: true,
        error: {},
      };
    case 'FAIL_CREATE_USER':
      return {
        ...state,
        isProcessing: false,
        error: action.error,

      };
    case 'LOADING_AUTH_USER':
      return {
        ...defaultState,
        csrf: state.csrf,
        isProcessing: true,
        error: {},
      };
    case 'UPDATE_AUTH_USER':
      if (action.user.emailVerified) {
        localStorage.setItem('isUser', 'true');
      }

      if (!JSON.parse(localStorage.getItem('isRememberMe') || 'false') && localStorage.getItem('savedEmail')) {
        localStorage.removeItem('savedEmail');
      }

      if (JSON.parse(localStorage.getItem('isRememberMe') || 'false') && !localStorage.getItem('savedEmail') !== action.user.email) {
        localStorage.setItem('savedEmail', action.user.email);
      }
      // window.pendo.initialize({
      //   visitor: {
      //     id: action.user.id,
      //     email: action.user.email,
      //     full_name: action.user.firstName,
      //     emailVerified: action.user.emailVerified,
      //     googleId: action.user.googleId,
      //   },
      //   account: {
      //     id:'Clyde-direct' // Highly recommended
      //   }
      // });
      return {
        ...state,
        ...action.user,
        isProcessing: false,
        isLoggedIn: true,
        error: {},
      };
    case 'FAIL_AUTH_USER':
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };
    case 'FAIL_GOOGLE_OAUTH_USER':
      return {
        ...state,
        isProcessing: false,
        error: {
          clientDesc: 'Failed to authenticate with google.',
          fields: ['google_auth'],
        },
      };
    case 'UPDATE_LOGOUT':
      localStorage.setItem('isUser', '');
      return {
        ...defaultState,
        logoutFlash: true,
      };
    case 'CLEAR_LOGOUT_FLASH':
      return {
        ...state,
        logoutFlash: false,
      };
    case 'CHECK_LOGIN':
      if (isUser) {
        return {
          ...state,
          isLoggedIn: true,
          emailVerified: true,
        };
      }
      return {
        ...defaultState,
      };

    case 'LOADING_REQUEST_PASSWORD':
      return {
        ...state,
        isProcessing: true,
        error: {},
        passwordResetSuccess: false,
      };
    case 'FAIL_REQUEST_PASSWORD':
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };
    case 'UPDATE_REQUEST_PASSWORD':
      return {
        ...state,
        isProcessing: false,
        passwordResetSuccess: true,
        error: {},
      };
    case 'SET_PW_RESET_SUCCESS_FALSE':
      return {
        ...state,
        passwordResetSuccess: false,
      };
    case 'SET_PW_CHANGE_SUCCESS_FALSE':
      return {
        ...state,
        changeSuccess: {},
      };
    case 'UPDATE_GET_CSRF':
      return {
        ...state,
        csrf: action.csrf,
      };
    case 'LOADING_CHANGE_PASSWORD':
      return {
        ...state,
        isProcessing: true,
        error: {},
        changeSuccess: {},
      };
    case 'FAIL_CHANGE_PASSWORD':
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        changeSuccess: {},
      };
    case 'UPDATE_CHANGE_PASSWORD':
      return {
        ...state,
        isProcessing: false,
        error: {},
        changeSuccess: { pw: true },
      };
    case 'LOADING_RESEND_VERIFY':
      return {
        ...state,
        isProcessing: true,
        error: {},
        changeSuccess: {},
      };
    case 'FAIL_RESEND_VERIFY':
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        changeSuccess: {},
      };
    case 'UPDATE_RESEND_VERIFY':
      return {
        ...state,
        isProcessing: false,
        error: {},
        changeSuccess: { resend: true },
      };
    case 'UPDATE_GET_USER_DETAILS':
      return {
        ...state,
        details: action.user,
      };
    case 'UPDATE_GET_CONTRACT':
      return {
        ...state,
        details: {
          email: action.contract.customerEmail,
          phoneNumber: action.contract.customerPhone,
        },
      };
    case 'LOADING_CHANGE_PROFILE':
      return {
        ...state,
        error: {},
        changeSuccess: {},
      };
    case 'FAIL_CHANGE_PROFILE':
      return {
        ...state,
        error: action.error,
        changeSuccess: {},
      };
    case 'UPDATE_CHANGE_PROFILE':
      return {
        ...state,
        changeSuccess: { details: true },
      };
    case 'LOADING_USER_BILLING':
      return {
        ...state,
        error: {},
        billingSuccess: {},
      };
    case 'FAIL_USER_BILLING':
      return {
        ...state,
        error: action.error,
        billingSuccess: {},
      };
    case 'UPDATE_USER_BILLING':
      return {
        ...state,
        hasBankAccountConnected: true,
        billingSuccess: { details: true },
      };
    case 'CLEAR_AUTH_ERRORS':
      return {
        ...state,
        error: {},
      };
    case 'CLEAR_ACCOUNT_MESSAGES':
      return {
        ...state,
        error: {},
        changeSuccess: {},
      };
    case 'FAIL_GET_CLAIMS':
    case 'FAIL_GET_CLAIM':
    case 'FAIL_GET_USER_DETAILS':
    case 'FAIL_GET_PRODUCT':
    case 'FAIL_GET_PRODUCTS':
      // 403 status === forbidden, spooky
      if (action.status && action.status === 403) {
        localStorage.setItem('isUser', '');
        return defaultState;
      }
      return state;
    default:
      return state;
  }
};

export default userReducer;
