import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { ClydeButton, LoadingDots } from '@joinclyde/clyde-react-components';
import ProductPlaceholder from '../../../images/product-placeholder.svg';
import UserHeader from '../../../containers/UserHeader';
import LoggedOutFooter from '../../Shared/LoggedOutFooter';
import ClydeModal from '../../../containers/Modal';

import { ReactComponent as RightArrow } from '../../../images/right-arrow.svg';
import { claimStatusSwitcher, displayResolutionButton } from '../../Shared/claimHelper';
import ClaimApprovedForm from '../../../containers/ClaimApprovedForm';
import ClaimHistory from '../ClaimHistory';
import { ProductClaimsEventItem } from '../../../types/ClaimTypes';

interface ClaimDetailPageProps {
  match: any;
  selectedProduct: any;
  claim: any;
  claimHistory: ProductClaimsEventItem[];
  _getClaim: any;
  _showModal: any;
  _cancelModal: any;
  _resetSelectedClaim: any;
}

class ClaimDetailPage extends React.Component<ClaimDetailPageProps, any> {
  componentDidMount() {
    const { claimId } = this.props.match.params;
    this.props._getClaim(claimId);
  }

  componentWillUnmount() {
    // prevent flicker of old data
    this.props._resetSelectedClaim();
  }

  render() {
    const {
      selectedProduct,
      claim,
      _showModal,
      _cancelModal,
    } = this.props;

    let shouldDisplayResolutionButton = false;

    if (claim) {
      const { claimHistory } = claim;
      const claimHistoryItems = claimHistory.length;
      const latestHistoryItem = claimHistory[claimHistoryItems - 1];
      // The location of this flag varies, so need to move it to the top level for the helper
      claim.hiClydeEnabled = claim.product?.hiClydeEnabled;
      shouldDisplayResolutionButton = displayResolutionButton(latestHistoryItem, claim);
    }

    const date = claim ? dayjs(claim.createdAt).utc().local().format('MMMM Do, YYYY') : null;
    const product = claim && claim.product ? claim.product : selectedProduct;

    return (
      <>
        <main className="app-container">
          <UserHeader activeTab='products' />
          <section className="pdp-container">
            <div className='cdp-top'>
              <Link data-test="backToAllProducts" className="link-general cdp-back-link" to="/products">
                <span>All Products</span>
                <span className='breadcrumb-arrow'><RightArrow /></span>
              </Link>
              <Link data-test="backToPDP" className="link-general cdp-back-link" to={`/products/${claim?.contractSaleId}`}>
                <span>Product Details</span>
                <span className='breadcrumb-arrow'><RightArrow /></span>
              </Link>
              <p className="link-general cdp-back-link breadcrumb-current">
                Claim Details
              </p>
            </div>
            { claim
              ? <>
                <section className="claim-details">
                  <div className="claim-details__primary">
                    <div className="claim-details__left">
                      <div className="claim-details__header">
                        <p className="claim-details__info" data-test="submittedDate">{ `claim submitted ${date}`.toUpperCase() }</p>
                        <p className="claim-details__divider">|</p>
                        <p className="claim-details__info" data-test="claimId">CLAIM ID: { claim.id.toUpperCase() }</p>
                      </div>
                      <div className="claim-details__body">
                        <h2 className="claim-details__title" data-test="claimStatus">{ claimStatusSwitcher((claim.resolutionState || claim.claimState), claim.serviceType || '') }</h2>
                      </div>
                      <div className="claim-details__footer">
                        { shouldDisplayResolutionButton && <ClydeButton
                            onClick={ () => _showModal('claim-resolution-form') }
                            data-test="viewResolution"
                          >
                          View Claim Resolution
                        </ClydeButton> }
                        <p className='pseudo-link' onClick={ () => _showModal('claim-history') } data-test='viewHistory'>View Claim History</p>
                        <Link data-test="backToPDPAgain" className="link-general pdp-link"
                          to={`/products/${claim?.contractSaleId}`}>
                          <span>View Product Details</span>
                        </Link>
                      </div>
                    </div>
                    <div className="product-details" data-test="productDetails">
                      <h6 className="product-details__header">YOUR PRODUCT</h6>
                      <div className="product-details__body">
                        <div className="product-details__left">
                          <div
                            className='product-details__image'
                            style={ { backgroundImage: `url(${product.imageLink || ProductPlaceholder})` } }
                            data-test="productImage">
                          </div>
                        </div>
                        <div className="product-details__right">
                          <h3 className="product-details__title" data-test="productName">{ product.name }</h3>
                          <div className="product-details__info">
                            { product.manufacturer
                              && <p className="product-details__text" data-test="manufacturer">{ product.manufacturer.toUpperCase() }</p>
                            }
                            <p className="product-details__text" data-test="contractId">CONTRACT ID: { product.hashedSaleID }</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="claim-details" data-test="claimSummaryCard">
                  <h3>Claim Summary</h3>
                  <div className="claim-summary">
                    <div className="claim-summary__left">
                      <p className="claim-summary__detail" data-test="usage">
                        <span className="claim-summary__title">Usage: </span>
                        { claim.reporterType === 'customer' ? 'Personal' : 'Business' }
                      </p>
                      <div className="claim-summary__detail" data-test="issueTypes">
                        <p className="claim-summary__title">Issue Type(s):</p>
                        <ul>
                          {
                            claim.issueTypes.map((issueType: any) => (
                              <li key={ issueType.label }>{ issueType.label }</li>))
                          }
                        </ul>
                      </div>
                      <p className="claim-summary__detail" data-test="failureDate">
                        <span className="claim-summary__title">Failure Date: </span>
                        { dayjs(claim.failureDate).utc().local().format('MMMM Do, YYYY') }
                      </p>
                      <div className="claim-summary__detail" data-test="contactInfo">
                        <p className="claim-summary__title">Contact Info:</p>
                        <p className='claim-summary__contact' data-test="email">{ claim.email }</p>
                        <p data-test="phone">{ claim.phone }</p>
                      </div>
                    </div>
                    <div className="claim-summary__right">
                      <div className="claim-summary__detail" data-test="description">
                        <p className="claim-summary__title">Description:</p>
                        <p>{ claim.descriptionOfProblem }</p>
                      </div>
                      { claim.supportingFiles && !!claim.supportingFiles.length && <div className="claim-summary__detail" data-test="documentation">
                        <p className="claim-summary__title">Documentation:</p>
                        { claim.supportingFiles.map((file: string) => <li>{ file }</li>) }
                      </div> }
                    </div>
                  </div>
                </section>
              </>
              : <LoadingDots dotColor='clydeBlack' />
            }
          </section>
        </main>
        { claim
          && <ClydeModal modalID="claim-resolution-form" customClass="clyde-modal--full claim-modal clyde-modal--hide-close" >
            <ClaimApprovedForm contract={ product } claimInfo={ claim } />
          </ClydeModal>
        }
        { claim
          && <ClydeModal modalID="claim-history" customClass="clyde-modal--full history-modal clyde-modal--hide-close" >
            <ClaimHistory
              _closeModal={ _cancelModal }
              claimHistory={ claim.claimHistory }
              claimInfo={ claim } />
          </ClydeModal>
        }
        <LoggedOutFooter />
      </>
    );
  }
}

export default ClaimDetailPage;
